import React from "react";
import { useField } from "formik";

const Checkbox = ({ children, ...props }) => {
	// React treats radios and checkbox inputs differently other input types, select, and textarea.
	// Formik does this too! When you specify `type` to useField(), it will
	// return the correct bag of props for you -- a `checked` prop will be included
	// in `field` alongside `name`, `value`, `onChange`, and `onBlur`
	const [field, meta] = useField({ ...props, type: "checkbox" });
	return (
		<>
			<div>
				<input type="checkbox" {...field} {...props} checked />
				{meta.touched && meta.error ? (
					<div className="massage error d-block">{meta.error}</div>
				) : null}
			</div>
		</>
	);
};

export default Checkbox;
