import React, { useEffect } from "react";
import { useField } from "formik";

const DropDown = ({
	label,
	refresh,
	setFieldValue = null,
	setrefresh = null,
	...props
}) => {
	const [field, meta] = useField(props);

	// console.log("Root dro down =>", props);
	let Country = localStorage.getItem("Country");
	let State = localStorage.getItem("State");
	let City = localStorage.getItem("City");
	let District = localStorage.getItem("District");

	useEffect(() => {
		return () => {};
	}, [Country, State, City, District, refresh]);

	return (
		<div key={label} className="form-group input-container">
			<select
				{...field}
				{...props}
				className="form-control "
				autoComplete="true"
				onChange={(e) => {
					if (field.name.includes("Country")) {
						localStorage.setItem("Country", Number(e.target.value));
					}
					if (field.name.includes("State")) {
						localStorage.setItem("State", Number(e.target.value));
					}
					if (field.name.includes("City")) {
						localStorage.setItem("City", Number(e.target.value));
					}
					if (field.name.includes("District")) {
						localStorage.setItem("District", Number(e.target.value));
					}
					// console.log("Value ffff", field.name);
					if (setrefresh != null || setrefresh != undefined) {
						setrefresh(!refresh);
					}
					if (setFieldValue != null || setFieldValue != undefined) {
						setFieldValue(props.name, e.target.value);
					}
				}}
			/>
			<label
				htmlFor={props.id || props.name}
				className="control-label font-medium overline-01"
			>
				{label}
			</label>
			{meta.touched && meta.error ? (
				<small className="massage error d-block">{meta.error}</small>
			) : null}
		</div>
	);
};
export default DropDown;
