import React from "react";
import Table from 'react-bootstrap/Table';

function HiringProcess() {
	return (
		<div className="main-container full_width">
			<section className="section-masthead space-before-breadcrumb section-common-space masthead-mob-space overflow-hidden">
				<div className="container">
					<div className="row d-none d-lg-block">
						<div className="col-lg-12">
							{/*breadcrumb*/}
							<nav aria-label="breadcrumb">
								<ol className="breadcrumb">
									<li className="breadcrumb-item">
										<a href="/">HOME</a>
									</li>
									<li className="breadcrumb-item">
										<a href="/">About</a>
									</li>
									<li className="breadcrumb-item active" aria-current="page">
										Hiring Process
									</li>
								</ol>
							</nav>
							{/*breadcrumb end*/}
						</div>
					</div>
					<div className="row align-items-lg-center">
						<div className="col">
							<h1 className="h1-01 font-black mb-3">Hiring Process</h1>
							<div className="d-lg-none">
								<div className="rounded-circle masthead-rounded-circle-mob overflow-hidden">
									<img
										src="assets/images/career/about.jpg"
										alt="about"
										className="w-100"
									/>
								</div>
							</div>
							<div className="h4-04">
								Jio Institute is a multi-disciplinary education institute,
								dedicated to the pursuit of excellence by bringing together
								global scholars as well as providing an enriching student
								experience through world-class education, relevant research
								platforms and a culture of innovation.
							</div>
						</div>
						<div className="col-auto d-lg-block d-none">
							<div className="rounded-circle masthead-rounded-circle overflow-hidden">
								<img
									src="assets/images/career/about.jpg"
									alt="about"
									className="w-100"
								/>
							</div>
						</div>
					</div>{" "}
					{/* / .row */}
				</div>{" "}
				{/* / .container */}
			</section>
			<section className="section-common-space overflow-hidden">
				<div className="container">
					<div className="row">
						<div className="col-12">
							<h4 className="h3-03 pb-3 font-black">
								DearApplicants:
							</h4>
							<p className="body-01">
							We are happy to announce a fresh faculty recruitment call in different disciplines at Jio Institute. Applications from Overseas Citizens of India, 
							Persons of Indian Origin and Foreign Nationals are also encouraged to apply for full-time faculty positions. 							
							</p>
							<p className="body-01">
								Before you proceed to apply through the button below, you are advised to read the following text for better understanding of the application processing at Jio Institute.
							</p>							
						</div>
					</div>{" "}
					{/* / .row */}
				</div>{" "}
				{/* / .container */}
			</section>

						<section className="section-common-space">
							<div className="container">
								<div className="row">
									<div className="col-12">
										<h4 className="h3-03 pb-3 font-black">
											Application Processing Schedule
										</h4>
										<p className="body-01">
										The applications received will be processed in a sequential and “clustered” manner with a tentative timeline as mentioned in the table below.
										</p>
										<p className="body-01">
										Note that the <b>online application portal is active 24x7</b> allowing you to apply for any Department at any point of time.
										</p>
									</div>	
								</div>
							</div>
						</section>

						<section className="section-common-space">
							<div className="container">
								<div className="row">
										<div className="col-12">
											<Table striped bordered >
											<thead>
												<tr align="center">
												
													<th>Cluster</th>
													<th>Departments (expected)</th>
													<th>Processing</th>
													<th>Final Interview</th>
												
												</tr>
											</thead>
											<tbody >
												<tr align="center">																						
												<td colSpan={4}><b>Processing Schedule for Applications received up to Sep 22, 2022</b>
												</td>											
												</tr>
												<tr align="center">
												<td><b>1*</b></td>
												<td></td>
												<td>May-June</td>
												<td>July-Aug</td>
												</tr>
												<tr align="center">
												<td><b>2*</b></td>
												<td></td>
												<td>June-July</td>
												<td>Aug-Sep</td>
												</tr>
												<tr align="center">
												<td><b>3*</b></td>
												<td></td>
												<td>July-Aug</td>
												<td>Sep-Oct</td>									
												</tr>
												<tr align="center">
												
												<td colSpan={4}><b>For further faculty hiring, we may process more applications as per department 
													requirements and the same will be notified in Jan/Feb.
													You are encouraged to apply continuously and Stay tuned!</b>
												</td>
												
												</tr>
											</tbody>
											</Table> 
										</div>
									</div>
								</div>																	
						</section>
						

						{/* <div className="col-12 p-0">
							<div className="card-icon-slider card-icon-slider-slick equal_height_cards institute-event">
								<div className="item">
									<div className="card h-100 border-0 radius-16 shadow-com overflow-hidden">
										<img
											src="assets/images/career/card-img1.png"
											className="card-img-top radius-top-16"
											alt="Our story"
										/>
										<div className="card-body">
											<p className=" mb-2 body-02 font-medium">
												Global in nature, Indian in character
											</p>
											<p className="card-text">
												In the near future, Jio Institute envisages to cater to
												10,000 students across multiple disciplines covering
												undergraduate, post graduate, doctoral ,and
												post-doctoral programmes.
											</p>
										</div>
									</div>
								</div>
								<div className="item">
									<div className="card h-100 border-0 radius-16 shadow-com overflow-hidden">
										<img
											src="assets/images/career/card-img1.png"
											className="card-img-top radius-top-16"
											alt="Our story"
										/>
										<div className="card-body">
											<p className=" mb-2 body-02 font-medium">
												Global in nature, Indian in character
											</p>
											<p className="card-text">
												In the near future, Jio Institute envisages to cater to
												10,000 students across multiple disciplines covering
												undergraduate, post graduate, doctoral ,and
												post-doctoral programmes.
											</p>
										</div>
									</div>
								</div>
								<div className="item">
									<div className="card h-100 border-0 radius-16 shadow-com overflow-hidden">
										<img
											src="assets/images/career/card-img1.png"
											className="card-img-top radius-top-16"
											alt="Our story"
										/>
										<div className="card-body">
											<p className=" mb-2 body-02 font-medium">
												Global in nature, Indian in character
											</p>
											<p className="card-text">
												In the near future, Jio Institute envisages to cater to
												10,000 students across multiple disciplines covering
												undergraduate, post graduate, doctoral ,and
												post-doctoral programmes.
											</p>
										</div>
									</div>
								</div>
								<div className="item">
									<div className="card h-100 border-0 radius-16 shadow-com overflow-hidden">
										<img
											src="assets/images/career/card-img1.png"
											className="card-img-top radius-top-16"
											alt="Our story"
										/>
										<div className="card-body">
											<p className=" mb-2 body-02 font-medium">
												Global in nature, Indian in character
											</p>
											<p className="card-text">
												In the near future, Jio Institute envisages to cater to
												10,000 students across multiple disciplines covering
												undergraduate, post graduate, doctoral ,and
												post-doctoral programmes.
											</p>
										</div>
									</div>
								</div>
								<div className="item">
									<div className="card h-100 border-0 radius-16 shadow-com overflow-hidden">
										<img
											src="assets/images/career/card-img1.png"
											className="card-img-top radius-top-16"
											alt="Our story"
										/>
										<div className="card-body">
											<p className=" mb-2 body-02 font-medium">
												Global in nature, Indian in character
											</p>
											<p className="card-text">
												In the near future, Jio Institute envisages to cater to
												10,000 students across multiple disciplines covering
												undergraduate, post graduate, doctoral ,and
												post-doctoral programmes.
											</p>
										</div>
									</div>
								</div>
								<div className="item">
									<div className="card h-100 border-0 radius-16 shadow-com overflow-hidden">
										<img
											src="assets/images/career/card-img1.png"
											className="card-img-top radius-top-16"
											alt="Our story"
										/>
										<div className="card-body">
											<p className=" mb-2 body-02 font-medium">
												Global in nature, Indian in character
											</p>
											<p className="card-text">
												In the near future, Jio Institute envisages to cater to
												10,000 students across multiple disciplines covering
												undergraduate, post graduate, doctoral ,and
												post-doctoral programmes.
											</p>
										</div>
									</div>
								</div>
								<div className="item">
									<div className="card h-100 border-0 radius-16 shadow-com overflow-hidden">
										<img
											src="assets/images/career/card-img1.png"
											className="card-img-top radius-top-16"
											alt="Our story"
										/>
										<div className="card-body">
											<p className=" mb-2 body-02 font-medium">
												Global in nature, Indian in character
											</p>
											<p className="card-text">
												In the near future, Jio Institute envisages to cater to
												10,000 students across multiple disciplines covering
												undergraduate, post graduate, doctoral ,and
												post-doctoral programmes.
											</p>
										</div>
									</div>
								</div>
							</div>
						</div> */}
			
			<section className="section-common-space">
				{/* section-common-space  */}
				<div className="container ">
					<div className="row">
						<div className="col-12 pb-3">
							<h3 className="h3-03 pb-3 font-black">Selection Process</h3>
						</div>
					</div>
					<ul className="p-0 m-0 list-style-none">
						<li className="section-common-space pt-0">
							<div className="row">
								<div className="col-12">
									<div className="card-group d-flex selection-process flex-column">
										<div
											className="card"
											onclick="window.location.href = 'javascript:void(0)'"
										>
											<div className="d-flex flex-md-row flex-column align-items-center ">
												<div className="icon light-pink d-flex align-items-center justify-content-around shadow-com-sm">
													<img
														src="assets/images/career/icon-leadership.svg"
														alt=""
														className="img-fluid"
														width="100%"
														height="auto"
													/>
												</div>
												<div className="details">
													<h4 className="h4-04 font-medium">
														Share your Profile
													</h4>
													<ul className="pl-3">
														<li>
															Interested candidates are requested to submit
															their details in the profile form below.{" "}
														</li>
														<li>
															Please attach 3 necessary supporting documents
															which include a Cover letter, a Statement of
															research &amp; teaching, a Curriculum Vitae.
															Candidates may also upload list of publications/
															supporting materials.
														</li>
														<li>
															Statement of research and teaching may include,
															but not limited to, current and future program of
															scholarships, funded activity, impact (journal
															impact factor, citation index, press coverage,
															etc), sample research articles, teaching
															portfolio, teaching philosophy, sample syllabi,
															student evaluation, etc.{" "}
														</li>
														<li>
															Candidates to ensure that the uploaded documents
															are in one of the acceptable formats: pdf, doc,
															docx.{" "}
														</li>
														<li>
															Candidates are requested to note their Submissions
															ID after submission of their details.
														</li>
													</ul>
												</div>
											</div>
										</div>
										<div
											className="card"
											onclick="window.location.href = 'javascript:void(0)'"
										>
											<div className="d-flex flex-md-row flex-column  align-items-center">
												<div className="icon emrald-green light-pink d-flex align-items-center justify-content-around shadow-com-sm">
													<img
														src="assets/images/career/icon-integrated.svg"
														alt=""
														className="img-fluid"
														width="64px"
														height="auto"
													/>
												</div>
												<div className="details">
													<h4 className="h4-04 font-medium">
														Profile Screening, Evaluation &amp; Interaction
													</h4>
													<ul className="pl-3">
														<li>
															Selection Committee will be screening the profiles
															based on the eligibility requirement.{" "}
														</li>
														<li>
															{" "}
															The shortlisted candidate will have a few
															interactions with a view to know each other better
															and provide the candidates an opportunity to know
															more about working at Jio Institute. This may
															include:
															<ol>
																<li>
																	a presentation by the candidates on their
																	areas of research &amp; teaching;
																</li>
																<li>
																	a campus visit and interactions with different
																	teams.
																</li>
															</ol>
														</li>
													</ul>
												</div>
											</div>
										</div>
										<div
											className="card"
											onclick="window.location.href = 'javascript:void(0)'"
										>
											<div className="d-flex flex-md-row flex-column align-items-center">
												<div className="icon emrald-green d-flex align-items-center justify-content-around shadow-com-sm">
													<img
														src="assets/images/career/icon-digital-thinking.svg"
														alt=""
														className="img-fluid"
														width="100%"
														height="auto"
													/>
												</div>
												<div className="details">
													<h4 className="h4-04 font-medium">
														Selection and offer
													</h4>
													<ul className="pl-3">
														<li>
															The Selection Committee will take a final decision
															based on profile, interactions and Institute
															requirements.{" "}
														</li>
														<li>
															Offer would be made to the selected candidates.
														</li>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</li>
					</ul>
				</div>{" "}
				{/* / .container */}
			</section>
			{/* horizontal tabs */}
			{/* <section className="section-common-space bg-white">
				<div className="container">
					<div className="row">
						<div className="col-12">
							<h3 className="h3-03 pb-3 font-black">
								Frequently asked questions
							</h3>
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<div className="faq">
								<div className="accordion" id="acccordionTab-1">
									<div className="card">
										<div className="card-header" id="heading1-2">
											<a
												href="/"
												className="collapsed"
												data-toggle="collapse"
												data-target="#collapse1-2"
												aria-expanded="false"
												aria-controls="collapse1-2"
											>
												When will Jio Institute begin its academic year?
											</a>
										</div>
										<div
											id="collapse1-2"
											className="collapse"
											aria-labelledby="heading1-2"
											data-parent="#acccordionTab-1"
										>
											<div className="card-body">
												Anim pariatur cliche reprehenderit, enim eiusmod high
												life accusamus terry richardson ad squid. 3 wolf moon
												officia aute, non cupidatat skateboard dolor brunch.
												Food truck quinoa nesciunt laborum eiusmod. Brunch 3
												wolf moon tempor, sunt aliqua put a bird on it squid
												single-origin coffee nulla assumenda shoreditch et.
												Nihil anim keffiyeh helvetica, craft beer labore wes
												anderson cred nesciunt sapiente ea proident. Ad vegan
												excepteur butcher vice lomo. Leggings occaecat craft
												beer farm-to-table, raw denim aesthetic synth nesciunt
												you probably haven't heard of them accusamus labore
												sustainable VHS.
											</div>
										</div>
									</div>
									<div className="card">
										<div className="card-header" id="heading1-3">
											<a
												href="/"
												className="collapsed"
												data-toggle="collapse"
												data-target="#collapse1-3"
												aria-expanded="false"
												aria-controls="collapse1-3"
											>
												What will be the curriculum of Jio Institute’s
												programmes?
											</a>
										</div>
										<div
											id="collapse1-3"
											className="collapse"
											aria-labelledby="heading1-3"
											data-parent="#acccordionTab-1"
										>
											<div className="card-body">
												Anim pariatur cliche reprehenderit, enim eiusmod high
												life accusamus terry richardson ad squid. 3 wolf moon
												officia aute, non cupidatat skateboard dolor brunch.
												Food truck quinoa nesciunt laborum eiusmod. Brunch 3
												wolf moon tempor, sunt aliqua put a bird on it squid
												single-origin coffee nulla assumenda shoreditch et.
												Nihil anim keffiyeh helvetica, craft beer labore wes
												anderson cred nesciunt sapiente ea proident. Ad vegan
												excepteur butcher vice lomo. Leggings occaecat craft
												beer farm-to-table, raw denim aesthetic synth nesciunt
												you probably haven't heard of them accusamus labore
												sustainable VHS.
											</div>
										</div>
									</div>
									<div className="card">
										<div className="card-header" id="heading1-4">
											<a
												href="/"
												className="collapsed"
												data-toggle="collapse"
												data-target="#collapse1-4"
												aria-expanded="false"
												aria-controls="collapse1-4"
											>
												What is the Overseas Module?
											</a>
										</div>
										<div
											id="collapse1-4"
											className="collapse"
											aria-labelledby="heading1-4"
											data-parent="#acccordionTab-1"
										>
											<div className="card-body">
												Anim pariatur cliche reprehenderit, enim eiusmod high
												life accusamus terry richardson ad squid. 3 wolf moon
												officia aute, non cupidatat skateboard dolor brunch.
												Food truck quinoa nesciunt laborum eiusmod. Brunch 3
												wolf moon tempor, sunt aliqua put a bird on it squid
												single-origin coffee nulla assumenda shoreditch et.
												Nihil anim keffiyeh helvetica, craft beer labore wes
												anderson cred nesciunt sapiente ea proident. Ad vegan
												excepteur butcher vice lomo. Leggings occaecat craft
												beer farm-to-table, raw denim aesthetic synth nesciunt
												you probably haven't heard of them accusamus labore
												sustainable VHS.
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section> */}
		</div>
	);
}

export default HiringProcess;
