import { combineReducers } from "redux";
import UserActionReducer from "./UserDetails/UserActionReducer";
import ActiveUnitReducer from "./ActiveUnitDetails/ActiveUnitActionReducer";
import RefreshComponentReducer from "./RefreshComponent/RefreshComponentReducer";
import MasterdataReducer from "./Masterdata/MasterdataActionReducer";

const rootReducer = combineReducers({
	user: UserActionReducer,
	activeUnit: ActiveUnitReducer,
	refreshComponent: RefreshComponentReducer,
	masterData: MasterdataReducer,
});

export default rootReducer;
