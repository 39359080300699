import React from "react";
import Spinner from "react-bootstrap/Spinner";

export default function Loading() {
	return (
		<div
			className="d-flex justify-content-center align-items-center mt-5 pt-5"
			style={{ minHeight: "50vh" }}
		>
			<Spinner animation="grow" variant="danger" className="mt-5 " />
			<Spinner animation="grow" variant="danger" className="mt-5 " />
			<Spinner animation="grow" variant="danger" className="mt-5 " />
		</div>
	);
}
