import axios from "axios";
import React, {
	Fragment,
	useCallback,
	useEffect,
	useRef,
	useState,
} from "react";


import SecFooter from "../Common/SecFooter";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import SubStage from "./SubStage";
import References from "../JobApplications/References";
import { useParams } from "react-router-dom";
import mapValues from "lodash/mapValues";
// import { Scrollbar } from "react-scrollbars-custom";
import { useSelector,useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Popup from "../Common/Popup";
import "react-toastify/dist/ReactToastify.css";
import { Button } from "react-bootstrap";
import moment from "moment";
import { number } from "yup/lib/locale";
import "./JobApplication.css";
import _ from "lodash";
import SessionExpireyPopup from "../Common/SessionExpireyPopup";
import { GetCookie } from "../Common/cookie";
import { setUserLogout } from "../redux/UserDetails/UserActions";
import Offline from "react-offline";

function JobApplication() {
	const submitAction = useRef(undefined);
	var diabledstage_id = 1;
	const navigate = useNavigate();
	const location = useLocation();
const dispatch=useDispatch();
	const [StageMasterData, setdata] = useState([]);
	const [SubStageMasterData, setSubStagedata] = useState([]);
	const [stepscount, Setstepscount] = useState(0);
	const [stage_id, setstage_id] = useState(1);
	const [isActive, setActive] = useState(1);
	const [StepIndex, setStepIndex] = useState(0);
	const [Objx, setObjx] = useState({});
	const activeStageInfo = useRef({ stage_id: -1, index: -1 });
	const hasuserSelectedStage = useRef({ selected: false });
	const areAllStepsCompleted = useRef({ completed: false });
	const [savingAsDraft, setsavingAsDraft] = useState(true);
	const [text, settext] = useState("Save and Proceed");
	const [show, setshow] = useState(false);
	const [hideBtn, setHideBtn] = useState(false);
	const [notificationInfo, setnotificationInfo] = useState({
		title: "Confirmation ",
		msg: "Once you submit the form you would not be able to edit it later. Are you sure to submit ?",
	});
	
	const [showSessionpopup, setshowSessionpopup] = useState(false);
	
	const [submit, setsubmit] = useState(false);

	const [showDraftToast, setshowDraftToast] = useState(true);
	const toggleShowDraftToast = () => setshowDraftToast(!showDraftToast);
	const candidate = useSelector((state) => state.user.user.userDetails);
	// console.log(candidate, "candidate")
	var candidatename = candidate.first_name + candidate.last_name;
	const candidate_id = useSelector((state) => state.user.user.userDetails?.candidate_id);
	const Emailid = useSelector((state) => state.user.user.userDetails?.email);
	const params = useParams();
	//   console.log("candidate_id",candidate_id);
	var Jobid = parseInt(atob(params.id));
	var categoryId = parseInt(atob(params.cid));
	var user_applied = location?.state?.user_applied || false;
	const userCategoryId = localStorage.getItem('userCategoryId')
	var category_desc = JSON.parse(localStorage.getItem("__jbt_crx__"));
	const isAuthenticated = useSelector((state) => state.user);

	// var category_desc = JSON.parse(localStorage.getItem("category_desc"));
	//var category_desc = params.category_desc;
	//for stage master nav menu
	useEffect(() => {
		window.scrollTo({ top: 0, behavior: "smooth" });
		findCurrentStage();
		axios
			.get("api/JobApplication/GetJobStageMaster", {
				params: {
					// candidate_id: parseInt(candidate_id),
					categoryId: categoryId,
					trans_hdr_id: parseInt(localStorage.getItem("job_trans_id")),
				},

			})
			.then((response) => {
				// response.data is the users
				const contentdata = response.data;

				setdata(contentdata.data);
				Setstepscount(contentdata.data.length);
				// console.log("GetJobStageMaster", contentdata.data);
			})
			.catch((error) => {
				//  dispatch(fetchContentFailure(error.message));
				//console.log("error axios", error.message);
			});
		return () => {
			localStorage.setItem("stageID", 1);
			localStorage.setItem("index", 0);
		};
	}, []);
	function findCurrentStage() {
		if (
			localStorage.getItem("stageID") != null &&
			localStorage.getItem("index") != null
		) {
			activeStageInfo.current = {
				stage_id: Number(localStorage.getItem("stageID")),
				index: Number(localStorage.getItem("index")),
			};
		}
	}
	useEffect(() => {
		axios
			.get("api/JobApplication/GetjobFieldMasterByStageid", {
				params: {
					stage_id: stage_id,
					// candidate_id: candidate_id,
					category_id: categoryId,
					trans_hdr_id: Number(localStorage.getItem("job_trans_id")),
				},
			})
			.then((response) => {
				// response.data is the users
				const contentdata = response.data;
				// console.log("GetjobFieldMasterByStageid", contentdata.fieldMasterData);

				setSubStagedata(contentdata.subStageData);
				setObjx(contentdata.fieldMasterData);
			})
			.catch((error) => {
				//console.log("error axios", error.message);
			});
		setTimeout(() => {
			if (
				activeStageInfo.current.stage_id == -1 &&
				activeStageInfo.current.index == -1
			) {
				// ViewDetails(1, 1);

				ViewDetails(
					StageMasterData[0]?.stage_id ? StageMasterData[0]?.stage_id : 1,
					1
				);
			}
			if (
				activeStageInfo.current.stage_id !== -1 &&
				activeStageInfo.current.index !== -1
			) {
				ViewDetails(
					activeStageInfo.current.stage_id,
					activeStageInfo.current.index
				);
			}
		}, 600);
	}, [stage_id]);

	
	useEffect(() => {
		if (isAuthenticated["isLoggedIn"]) {
			const isActiveSession = GetCookie("jic_auth_");
		// console.log(isActiveSession,'isActiveSession')
		if(!isActiveSession){
		setshowSessionpopup(true)
	
	}
		}
	}, []);

	function checkActiveStage() {
		let temp = true;
		StageMasterData.forEach(function (element) {
			if (
				element?.stage_id !== stage_id ||
				element?.stage_id !== activeStageInfo.current.stage_id
			) {
				temp = document
					.getElementById(element?.stage_id)
					?.classList?.replace("active", "completed");
			}
		});
		return null;
	}
	checkActiveStage();

	const ViewDetails = useCallback(
		(stage_id, StepIndex, stagename, StageMasterData) => {
			if (stagename == "Referees  & Declaration " || stage_id == 12) {
				settext("Submit");
			} else {
				settext("Save and Proceed");
			}
			// console.log("Inside view", stagename);
			// console.log("Inside view", StageMasterData);
			setstage_id(stage_id);
			setActive(stage_id);
			setStepIndex(StepIndex);
			if (hasuserSelectedStage.current) {
				activeStageInfo.current = {
					stage_id,
					index: StepIndex,
				};
				if (document.getElementById(stage_id)) {
					document.getElementById(stage_id).className = "active";
				}
			}

			axios
				.get("api/JobApplication/GetjobFieldMasterByStageid", {
					params: {
						stage_id: stage_id,
						candidate_id: candidate_id,
						category_id: categoryId,
						trans_hdr_id: Number(localStorage.getItem("job_trans_id")),
					},
				})
				.then((response) => {
					const contentdata = response.data;
					setSubStagedata(contentdata.subStageData);
					setObjx(contentdata.fieldMasterData);
				})
				.catch((error) => {
					//console.log("error axios", error.message);
				});
		},
		[]
	);

	const FieldValue_crud = (aReq, stage_id, isDraft) => {
		if (
			stage_id == StageMasterData[StageMasterData.length - 1].stage_id &&
			isDraft == false
		) {
			// setsubmit(true);
			var parameter = {
				candidate_id: Number(candidate_id),
				job_id: Jobid,
				status_id: "2",
				modified_by: "abc",
				is_bookmarked: false,
				is_applied: true,
				remarks: "abc",
				feedback: "null",
			};
			axios
				.post("api/JobDetails/JobApplyDetails", parameter)
				.then((response) => {
					// alert("Hi submit")
					const contentdata = response.data;

					toast.success("Thank you for submitting application", {
						position: toast.POSITION.TOP_CENTER,
					});

					const article = {
						mailBody: `<p><br>Dear <strong>${candidate.first_name} ${candidate.last_name}</strong>,</p><p> This refers to your application for the position of <strong>${category_desc}</strong> in our Institute. We are pleased to inform you that we have received your application and we shall keep you updated.</p><p>For any queries/clarifications, please feel free to write at <a href='mailto:careers@jioinstitute.edu.in'>careers@jioinstitute.edu.in</a></p><p>Regards,</p><p>Team HR, Jio Institute</p>`,
						mailSubject: `Response to the job application.`,
						empMail: Emailid,
						candidate_id: candidate_id,
						Jobid: Jobid
					};
					axios.post("/api/JobApplication/api/SendEmail", article).then((response) => {
					}).catch((e) => { })

					localStorage.removeItem("subStage_2");
					localStorage.removeItem("subStage_6");
					localStorage.removeItem("subStage_7");

					setTimeout(() => {
						if (userCategoryId == 1) {
							navigate(`/current-openings/Academics`, { replace: false });
						} else if (userCategoryId == 2) {
							navigate(`/current-openings/Jio-Digital-Library`, { replace: false });
						} else if (userCategoryId == 3) {
							navigate(`/current-openings/Non-Academic`, { replace: false });
						} else {
							navigate(`/current-openings/Academics`, { replace: false });
						}
					}, 3000);

				})
				.catch((error) => {
					//console.log("error axios", error.message);
					setHideBtn(false)
				});
		}
		axios
			.post("api/JobApplication/JobFieldValue", aReq)
			.then((response) => {
				const contentdata = response.data;
				if (
					contentdata.success == true &&
					isDraft == false &&
					stage_id !== StageMasterData[StageMasterData.length - 1].stage_id
				) {
					toast.success("Application data saved successfully", {
						position: toast.POSITION.TOP_CENTER,
					});
					setTimeout(() => {
						window.location.reload();
						setHideBtn(false)
					}, 3000);
				}
				if (
					contentdata.success == true &&
					isDraft == true
				) {
					setTimeout(() => {
						setHideBtn(false)
						window.location.reload();
					}, 3000);
				} else {
					setTimeout(() => {
						setHideBtn(false)
					}, 3000);
				}
			})

			.catch((error) => {
				toast.error("Something went wrong", {
					position: toast.POSITION.TOP_CENTER,
				});
				setHideBtn(false)
				//console.log("error axios", error.message);
			});
	};

	const SaveDraft = (stage_id, isdraft) => {
		var parameter = {
			stage_id: parseInt(stage_id),
			candidate_id: parseInt(candidate_id),
			isdraft: parseInt(isdraft),
			trans_hdr_id: Number(localStorage.getItem("job_trans_id")),
		};

		axios
			.post("api/JobApplication/JobApplicationDraft", parameter)
			.then((response) => {
				const contentdata = response?.data;
				if (isdraft == 1) {
					toast.success("Saved as draft", {
						position: toast.POSITION.TOP_CENTER,
					});
				}
			})
			.catch((error) => {
				//console.log("error axios", error.message);
				toast.error("something went wrong. Please try again after sometime.", {
					position: toast.POSITION.TOP_CENTER,
				});
			});

		
	};

	// function sendmail() {
	// 	console.log("email send function")
	// 	// setmailbody(mailbody?.replace("#candidateName", candidatename))
	// 	// console.log(mailbody)
	// 	const article = {
	// 		mailBody:
	// 			`<p><br>Dear <strong>${candidate.first_name} ${candidate.last_name}</strong>,</p><p> Thank you for applying for the position of <strong>${category_desc}</strong> in our Institute. Our team will connect with you shortly.</p><p>Thanks &amp; Regards,</p><p>Srikanth Sridhar.</p>`,
	// 		mailSubject: `Response to job application – `,
	// 		empMail: Emailid,
	// 		candidate_id: candidate_id,
	// 		Jobid: Jobid
	// 	};
	// 	console.log(article)
	// 	axios.post("/api/JobApplication/api/SendEmail", article).then((response) => {
	// 		console.log("Mail response", response);
	// 	}).catch((e) => console.log(e, "error"))
	// }
	const ValidateSchema = Yup.lazy((Objx) =>
		Yup.object(
			mapValues(Objx, (value, key) => {
				//#region  Personal information
				if (key.includes("Salutation")) {
					return Yup.string().required("Required");
				}
				if (key.includes("fname")) {
					return Yup.string()
						.max(50, "Must be 50 characters or less")
						.required("Required")
						.matches(/^[[a-zA-Z][a-zA-Z ]+$/, "Enter valid First Name");
				}
				if (key.includes("lname")) {
					return Yup.string()
						.max(50, "Must be 50 characters or less")
						.required("Required")
						.matches(/^[[a-zA-Z][a-zA-Z ]+$/, "Enter valid Last Name");
				}
				if (key.includes("mname")) {
					return Yup.string()
						.max(50, "Must be 50 characters or less")
						.matches(/^[[a-zA-Z][a-zA-Z ]+$/, "Enter valid Middle Name");
				}
				if (key.includes("PrimaryEmailAddress")) {
					return Yup.string()
						.email("Enter valid email address")
						.required("Required");
				}
				if (key.includes("MobileNumber")) {
					return Yup.string()
						.required("Required")
						.max(10, "Enter Valid Phone Number")
						.test(
							"Validate user input",
							"Enter Valid Phone Number",
							function (value) {
								const phoneRegex =
									/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
								// /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
								// Change this regex based on requirement
								let isValidPhone = phoneRegex.test(value);
								if (isValidPhone) {
									return true;
								}
								return false;
							}
						);
				}
				if (key.toLowerCase().includes("PrimaryEmailAddress")) {
					return Yup.string().email("Invalid email").required("Required");
				}

				if (key.includes("DateofBirth")) {
					return Yup.string().test(
						"DOB",
						"Should be greater than 18",
						(value) => {
							return moment().diff(moment(value), "years") >= 18;
						}
					);
				}
				if (key.includes("MaritalStatus")) {
					return Yup.string()
					//.required("Required");
				}
				if (key.includes("PersonwithDisability")) {
					return Yup.string()
					//.required("Required");
				}
				if (key.includes("Gender")) {
					return Yup.string()
					//.required("Required");
				}
				if (key.includes("AttachCoverLetter")) {
					return Yup
						.string()
						.required("Required");
				}
				if (key.includes("AttachResume")) {
					return Yup
						.string()
						.required("Required");
				}
				if (key.includes("UploadTeachingStatement")) {
					return Yup
						.string()
						.required("Required");
				}
				if (key.includes("UploadResearchStatement")) {
					return Yup
						.string()
						.required("Required");
				}
				if (key.includes("ResearchPapers")) {
					return Yup
						.string()
						.required("Required");
				}
				if (key.includes("AdditionalAttachment")) {
					return Yup
						.string()
				}
				if (key.includes("PositionApplyingfor")) {
					return Yup.string().required("Required");
				}
				if (key.includes("AreaofSpecialisation")) {
					return Yup.string().required("Required");
				}
				if (key.includes("Nationality")) {
					return Yup.string().required("Required");
				}
				if (key.includes("researchinterests_97")) {
					return Yup.string()
						//.required("Required")
						.max(349, "maximun 350 charactor only");

					// // return Yup.string().required("Required")
					// // .test(
					// // 	"maxLenght",
					// // 	"Maximum 350 charactor",
					// // 	 (value) => value.toString().length>350
					// // )
					// return Yup.string().test('len', 'Must be exactly 10 characters', val => val && val.toString().length >10 )
				}
				if (key.includes("researchInterest_98")) {
					return Yup.string()
						//.required("Required")
						.max(349, "maximun 350 charactor only");
				}
				if (key.includes("AddressLine")) {
					return Yup.string().required("Required");
				}
				if (key.includes("Pincode_265")) {
					return Yup.number()
						.typeError("Must be a number")
						.required("Required")
						.test(
							"minLenght",
							"Min 3 digits long ",
							(val) => !isNaN(val) && `${val}`.length >= 3
						)
						.test(
							"maxLenght",
							"Max 6 digits long",
							(val) => !isNaN(val) && `${val}`.length <= 6
						);
				}
				if (key.includes("Country-2_266")) {
					return Yup.string().required("Required");
				}
				if (key.includes("State")) {
					return Yup.string().required("Required");
				}
				if (key.includes("PrimaryEmailAddress")) {
					return Yup.string().required("Required");
				}
				if (key.includes("MobileNumber")) {
					return Yup.string().required("Required");
				}
				if (key.includes("Publications(InternationalJournals)")) {
					return (
						Yup.string()
						//.required("Required")
						//.matches(/^[0-9]+$/, "Must be only digits")
					);
				}
				if (key.includes("Publications(IndianJournals)")) {
					return (
						Yup.string()
						//.required("Required")
						//.matches(/^[0-9]+$/, "Must be only digits")
					);
				}

				if (
					key.includes("ref1FullName") ||
					key.includes("0ref2FullName_31") ||
					key.includes("0ref3FullName_39")
				) {
					return Yup.string()
						.matches(/^[A-Za-z ]*$/, "Please enter valid name")
						.max(255);
					//   .required("Required")
				}
				if (
					key.includes("PositionHeld_24") ||
					key.includes("ref2Designation_32") ||
					key.includes("ref3Designation_40") ||
					key.includes("Position Held_380") ||
					key.includes("Position Held_360")

				) {
					return Yup.string()
						.matches(/^[[a-zA-Z][a-zA-Z ]+$/, "Please enter valid position")
						.max(255);
				}

				if (
					key.includes("Department/Division_382") ||
					key.includes("Department/Division_359") ||
					key.includes("CollegeDepartment_336") ||
					key.includes("BoardUniversity-2_58") ||
					key.includes("BoardUniversity-3_71")
				) {
					return Yup.string()
						.matches(/^[[a-zA-Z][a-zA-Z ]+$/, "Please enter valid department details")
						.max(255);
				}
				if (
					key.includes("Employer_378") ||
					key.includes("Employer_357")
				) {
					return Yup.string()
						.matches(/^[[a-zA-Z][a-zA-Z ]+$/, "Please enter valid employer details")
						.max(255);
				}

				if (
					key.includes("0ref2Organization_33") ||
					key.includes("0ref3Organization_41") ||
					key.includes("0ref1Organization_25")
				) {
					return Yup.string()
						.matches(/^[A-Za-z ]*$/, "Please enter valid University name")
						.max(255);
				}

				if (
					key.includes("0ref1Email_26") ||
					key.includes("0ref2Email_34") ||
					key.includes("0ref3Email_42")
				) {
					return Yup.string().email("Invalid email address");
					// .required("Required")
				}
				if (
					key.includes("PleaseSpecify")
				) {
					return Yup.string().when([], {
						is: () => {
							const aos = localStorage.getItem('aos')
							if (aos == "3506") {
								return true
							} else {
								return false
							}
						},
						then: Yup.string().required('Required'),
						otherwise: Yup.string(),
					})
				}
				// firstName: yup
				// .string()
				// .matches(/^[A-Za-z ]*$/, 'Please enter valid name')
				// .max(40)
				// .required(),
				//    if(key.includes("0Attachyourtop3publications_349"))
				//    {
				//         return  Yup.mixed()
				// 		.test("filenumber", "upload only three files",
				// 		  (val) => val.length <4
				// 		)

				//    }
				// if (key.includes("PostPhDworkexperienceinyears")) {
				// 	return Yup.string()
				// 		.required("Required")
				// 		.matches(/^[0-9]+$/, "Must be only digits");
				// }
				// if (key.includes("PrePhdworkExperienceinyears")) {
				// 	return Yup.string()
				// 		.required("Required")
				// 		.matches(/^[0-9]+$/, "Must be only digits");
				// }
				// if (key.includes("TotalWorkexperience")) {
				// 	return Yup.string()
				// 		.required("Required")
				// 		.matches(/^[0-9]+$/, "Must be only digits");
				// }
				// if (
				// 	key.includes("start") ||
				// 	key.includes("startdate") ||
				// 	key.includes("Datepublished") ||
				// 	key.includes("0Date Published_328") ||
				// 	key.includes("0EndDate-1_188") ||
				// 	key.includes("0StartDate_187")
				// ) {
				// 	return Yup.date()
				// 		.required("Required")
				// 		.test("Start date test", "Invalid  date", function (value) {
				// 			return value < new Date();
				// 		});
				// }
				// if (key.includes("end") || key.includes("enddate")) {
				// 	Yup.date()
				// 		.required("Required")
				// 		.test("end date test", "Invalid  date", function (value) {
				// 			return value < new Date();
				// 		});
				// }

				// if (key.includes("SpecificContribution_189")) {
				// 	return Yup.string()
				// 		.required("Required")
				// 		.min(10, "Must be more than 10 characters")
				// 		.max(500, "Must not more than 500 characters");
				// }
				// if (key.includes("Outcome_194")) {
				// 	return Yup.string()
				// 		.required("Required")
				// 		.min(10, "Must be more than 10 characters")
				// 		.max(500, "Must not more than 500 characters");
				// }

				// if (key.includes("Refereedesignation_24")) {
				// 	return Yup.string().required("Required");
				// }
				// if (key.includes("OrganisationAddress_27")) {
				// 	return Yup.string().required("Required");
				// }
				// if (key.includes("Icertifythattheaboveinformation_93")) {
				// 	return Yup.string().required("Please certify");
				// }
				// if (key.includes("0Name_160")) {
				// 	return Yup.string().required("Required");
				// }
				// if (key.includes("Date_162")) {
				// 	return Yup.string().required("Required");
				// }
				// if (
				// 	key.includes("ref2Organization") ||
				// 	key.includes("OrganisationAddress") ||
				// 	key.includes("ref3Organization") ||
				// 	key.includes("ref1Organization")
				// ) {
				// 	return Yup.string().required("Required");
				// }

				// if (key.includes("Email") || key.includes("email")) {
				// 	return Yup.string().required("Required").email("Invalid email");
				// }

				// if (key.includes("1208GrandAmount_131")) {
				// 	return Yup.number()
				// 		.typeError("Must be a number")
				// 		.required("Required")
				// 		.test(
				// 			"maxLenght",
				// 			"Grand Amount must be greater than 1,00,000",
				// 			(val) => !isNaN(val) && `${val}`.length <= 6
				// 		);
				// }

				// if (key.includes("0GrandAmount_131")) {
				// 	return Yup.number()
				// 		.typeError("Must be a number")
				// 		.required("Required")
				// 		.test(
				// 			"maxLenght",
				// 			"Grand Amount must be less than  or equal to 2,00,000",
				// 			(val) => !isNaN(val) && `${val}`.length <= 6
				// 		);
				// }

				// if (key.includes("1213GrandAmount-2_145")) {
				// 	return Yup.string()
				// 		.matches(/^[0-9]+$/, "Must be only digits")
				// 		.min(100000, "Must be more than Rs. 1 lakh");
				// }
				// if (
				// 	key.includes("SWorldRanking_53") ||
				// 	key.includes("Nopublished") ||
				// 	key.includes("Pages") ||
				// 	key.includes("noofpublishedacc") ||
				// 	key.includes("Volume Numbers") ||
				// 	key.includes("noofpaperofindia") ||
				// 	key.includes("NumberifinternationalPatents") ||
				// 	key.includes("NumberifIndianPatents")
				// ) {
				// 	return Yup.number()
				// 		.typeError("Must be a number")
				// 		.required("Required")
				// 		.test(
				// 			"maxLenght",
				// 			"Max 6",
				// 			(val) => !isNaN(val) && `${val}`.length <= 6
				// 		);
				// }

				// if (key.includes("Start Date_363")) {
				// 	return Yup.date()
				// }

				// if (key.includes("End Date_364")) {
				// 	return Yup.date()
				// 		.min(Yup.ref('{Start Date_363}'), 'End date must be after start date')
				// 	// .test("enddate", "enddatemax", (v) =>)
				// }

				if (key.includes("WorldRanking") || key.includes("NIRFranking")) {
					return Yup.number()
						.typeError("Must be a number")
				}

				if (key.includes("Last Drawn CTC")) {
					return Yup.number()
						.typeError("Must be a number")
						.min(0, "Must be a positive number")

				}
				if (key.includes("Notice Period")) {
					return Yup.number()
						.typeError("Must be a number").min(0, "Must be a positive number")
				}

				if (key.includes("Variable Pay")) {
					return Yup.number()
						.typeError("Must be a number")
						.min(0, "Must be a positive number")

				}
				if (key.includes("Post PhD work experience in Years_389")) {
					return Yup.number()
						.typeError("Must be a number")
						.min(0, "Must be a positive number")

				}
				if (key.includes("Pre Phd work Experience in Years_390")) {
					return Yup.number()
						.typeError("Must be a number")
						.min(0, "Must be a positive number")

				}
				if (key.includes("Total work experience_391") || (key.includes("TotalWorkexperience_274"))) {
					return Yup.number()
						.typeError("Must be a number")
						.min(0, "Must be a positive number")
				}
				// if (
				// 	key.includes("YearofPassing") ||
				// 	key.includes("YearReceived") ||
				// 	key.includes("YearDeveloped") ||
				// 	key.includes("YearsuccessfullyDefended")
				// ) {
				// 	return Yup.number()
				// 		.typeError("Must be a number")
				// 		.required("Required")
				// 		.test(
				// 			"minLenght",
				// 			"Invalid year",
				// 			(val) => !isNaN(val) && `${val}`.length == 4
				// 		);
				// }

				// if (
				// 	key.includes("PhdThesis_146") ||
				// 	key.includes("PhdThesiswithOthers") ||
				// 	key.includes("0noofrandd-1_132")
				// ) {
				// 	return Yup.number()
				// 		.typeError("Must be a number")
				// 		.required("Required")
				// 		.test(
				// 			"maxLenght",
				// 			"Grand Amount must be greater than 1,00,000",
				// 			(val) => !isNaN(val) && `${val}`.length <= 6
				// 		);
				// }
			})
		)
	);

	function renderSubElement(obj, setFieldValue, setErrors, setFieldTouched) {
		//for References component
		if (obj.sub_stage_id == 5) {
			return (
				<References
					sub_stage_id={obj.sub_stage_id}
					stage_id={obj.stage_id}
					category_id={categoryId}
					setFieldValue={setFieldValue}
				/>
			);
		} else {
			return (
				<SubStage
					sub_stage_id={obj.sub_stage_id}
					stage_id={obj.stage_id}
					Objx={Objx}
					category_id={categoryId}
					setObjx={setObjx}
					setFieldValue={setFieldValue}
					setFieldTouched={setFieldTouched}
					setErrors={setErrors}
					sub_stage_name={obj.sub_stage_name}
				/>
			);
		}
	}


	return (
		<>
			<div className="main-container full_width">
				<section className="section-common-space pt-0">
					<div className="container">
						<div className="row">
							<div className="col-12">
								<Button
									onClick={() => {
										navigate(-1)
									}}
									className="btn text-white h6-02 font-medium py-2 my-2 px-5 ml-1  "
								>
									Back
								</Button>
								<h1 className="h1-02 font-medium mb-2 f-32">
									Position Applying for
									<span className="font-bold">
										{" "}
										{category_desc ? category_desc?.replaceAll('/', "/ ") : ""}
									</span>
								</h1>
								<h6 className="h6-06 font-black">
									Job ID: {Jobid ? Jobid : ""}
								</h6>
							</div>
							{/*revised template*/}
							<div className="col-12">
								<div className="counter button-md">
									Step {StepIndex + 1}/{stepscount}
									{/* Step {StepIndex ? StepIndex : StepIndex + 1}/{stepscount} */}
									{/* Step {StepIndex =0 ? StepIndex + 1 : 1}/{stepscount} */}
								</div>
								<div>
									<div className="horizontal-tab career-application-steps">
										<div className="tab-menu">
											{/* Nav tabs */}
											<ul className="nav nav-tabs subhead-xs-md  d-block">
												{/* {console.log("Satge-data", StageMasterData)} */}
												{StageMasterData?.map((jobStageData, index) => {
													var isdisabledactive = "active";

													if (isActive == jobStageData.stage_id) {
														isdisabledactive = "active";
													}
													if (isActive != jobStageData.stage_id) {
														isdisabledactive = "completed";
														areAllStepsCompleted.current.completed =
															!!areAllStepsCompleted.current.completed && false;
													}
													if (
														jobStageData.isdraft == 0 &&
														jobStageData.isdraft != null
													) {
														isdisabledactive = "completed";
														areAllStepsCompleted.current.completed =
															!!areAllStepsCompleted.current.completed && false;
														var _id = jobStageData.stage_id;
														diabledstage_id = parseInt(_id) + 1;
													} else {
														isdisabledactive = "";
													}
													if (
														diabledstage_id == jobStageData.stage_id &&
														!hasuserSelectedStage.current.selected
													) {
														isdisabledactive = "active";
														if (
															localStorage.getItem("stageID") != null &&
															localStorage.getItem("index") != null
														) {
															activeStageInfo.current = {
																stage_id: Number(
																	localStorage.getItem("stageID")
																),
																index: Number(localStorage.getItem("index")),
															};
														} else {
															activeStageInfo.current.stage_id =
																jobStageData.stage_id;
															activeStageInfo.current.index = index;
														}
													}
													return (
														<li
															id={jobStageData.stage_id}
															className={`${isdisabledactive} stage`}
															key={jobStageData.stage_id}
														>
															<a
																href="#jio-tab1"
																onClick={() => (
																	(hasuserSelectedStage.current.selected = true),
																	localStorage.setItem(
																		"stageID",
																		jobStageData.stage_id
																	),
																	localStorage.setItem("index", index),
																	ViewDetails(
																		jobStageData.stage_id,
																		index,
																		jobStageData.stage_name,
																		StageMasterData
																	)
																)}
																data-toggle="tab"
															>
																<div className="button-md ">
																	<span
																		className={
																			jobStageData.isdraft == 0 ? "tick" : ""
																		}
																	/>
																	<span className="bullet" />
																</div>
																{jobStageData.stage_name}
															</a>
														</li>
													);
												})}
											</ul>
										</div>
										{/* Tab panes */}
										<div
											className="tab-content ml-lg-5"
										// style={user_applied ? { pointerEvents: "none" } : {}}
										>
											<div className="tab-pane active" id="jio-tab1">
												<Formik
													enableReinitialize
													initialValues={Objx}
													validationSchema={
														savingAsDraft ? ValidateSchema : null
													}
													onSubmit={(values) => {
														let newTestArr = [];
														for (const [key, value] of Object.entries(localStorage)) {
															if (key.includes('subStage_2') || key.includes('subStage_6') || key.includes('subStage_7')) {
																newTestArr = [...JSON.parse(value), ...newTestArr]
															}
														};
														var arrData = [];
														const entries = Object.entries(values);
														for (let i = 0; i < entries.length; i++) {
															const uniqueId = parseInt(entries[i][0]);
															const findGroup = newTestArr.filter((f) => {
																return parseInt(f?.field_name) == uniqueId
															})
															let fieldIdsComm = entries[i][0];
															let fieldvalues = entries[i][1];
															let fieldIds = fieldIdsComm.substr(
																fieldIdsComm.indexOf("_") + 1
															);
															let fieldgroup_id = fieldIdsComm.substr(
																0,
																fieldIdsComm.indexOf("~")
															);
															if (fieldgroup_id === "") {
																fieldgroup_id = "0";
															}

															arrData.push({
																job_field_value_id: 0,
																field_id: parseInt(fieldIds),
																value_id: 0,
																fieldgroup_id: findGroup[0]?.group_id ? findGroup[0]?.group_id : parseInt(fieldgroup_id),
																value_name: String(fieldvalues),
																trans_hdr_id: Number(
																	localStorage.getItem("job_trans_id")
																),
																createdby: String(candidate_id),
															});
														}

														//#endregion
														//save draft

														if (submitAction.current === "draft") {
															SaveDraft(stage_id, 1);
															setHideBtn(true)
															FieldValue_crud(
																arrData,
																activeStageInfo.current.stage_id
																	? activeStageInfo.current.stage_id
																	: stage_id,
																true
															);
														} else {
															//saved as not a draft
															// SaveDraft(stage_id, 0);
															SaveDraft(activeStageInfo.current.stage_id, 0);
															// setshow(true);
															// console.log("Submit vaLUE", submit)
															setHideBtn(true)

															FieldValue_crud(
																arrData,
																activeStageInfo.current.stage_id
																	? activeStageInfo.current.stage_id
																	: stage_id,
																false
															);
															let currentStageID =
																StageMasterData[
																	Number(
																		StageMasterData?.map(
																			(e) => e.stage_id
																		)?.indexOf(stage_id)
																	) + 1
																]?.stage_id;

															activeStageInfo.current.stage_id = currentStageID
																? currentStageID
																: stage_id;
															activeStageInfo.current.index =
																activeStageInfo.current.index + 1;
															localStorage.setItem(
																"stageID",
																activeStageInfo.current.stage_id
															);
															localStorage.setItem(
																"index",
																activeStageInfo.current.index
															);
														}
													}}
												>
													{(props) => {
														const {
															values,
															touched,
															errors,
															setErrors,
															isSubmitting,
															handleChange,
															handleBlur,
															handleSubmit,
															setFieldValue,
															setFieldTouched,
														} = props;
														return (
															<Form style={{ wordWrap: "break-word" }}>
																<fieldset
																	disabled={user_applied ? true : false}
																>
																	{/* {!user_applied && (
																		<div className="row mt-3">
																			<div className="col-lg-4">
																				<button
																					onClick={() => {
																						submitAction.current = "save";
																					}}
																					type="submit"
																					className="arrow-white btn btn-primary center-v btn-verify"
																				>
																					{text}
																				</button>
																			</div>
																			<div className="col-lg-3">
																				<button
																					className=" btn btn-secondary center-v mt-2 mt-lg-0 text-nowrap"
																					onClick={() => {
																						setsavingAsDraft(false);
																						submitAction.current = "draft";
																						setTimeout(() => {
																							setsavingAsDraft(true);
																						}, 3000);
																					}}
																				>
																					Save as draft
																				</button>
																			</div>
																		</div>
																	)} */}
																	{/* {SubStageMasterData.sort(
																		(a, b) => a.priority - b.priority
																	)?.map((obj) => { */}
																	{_.orderBy(SubStageMasterData, [
																		"priority",
																	])?.filter((obj) => {
																		return !obj.sub_stage_name.includes('Attach Your Cover Letter')
																			&& !obj.sub_stage_name.includes('Attach Resume')
																			&& !obj.sub_stage_name.includes('Upload Research Statement')
																			&& !obj.sub_stage_name.includes('Research Papers')
																			&& !obj.sub_stage_name.includes('Additional Attachment')
																			&& !obj.sub_stage_name.includes('Upload Teaching Statement')
																			&& obj.group_id == 0
																	}).map((obj) => {
																		return (
																			<Fragment key={obj?.sub_stage_id}>
																				<section
																					className="mt-4"
																				//className="col-12 col-lg-12 box-grey mt-2 details-wrapper"
																				>
																					<div className="col-12">
																						<div className="h5-05 font-bold">
																							{obj.sub_stage_name}
																						</div>
																						<small>{obj.sub_stage_desc}</small>
																					</div>
																				</section>
																				{renderSubElement(
																					obj,
																					setFieldValue,
																					setErrors,
																					setFieldTouched
																				)}
																			</Fragment>
																		);
																	})}
																	<div className="row d-flex flex-wrap">
																		{_.orderBy(SubStageMasterData, [
																			"priority",
																		])?.filter((obj) => {
																			return obj.sub_stage_name.includes('Attach Your Cover Letter')
																				|| obj.sub_stage_name.includes('Attach Resume')
																				|| obj.sub_stage_name.includes('Upload Research Statement')
																				|| obj.sub_stage_name.includes('Research Papers')
																				|| obj.sub_stage_name.includes('Additional Attachment')
																				|| obj.sub_stage_name.includes('Upload Teaching Statement')
																		}).map((obj) => {
																			return (
																				<Fragment key={obj?.sub_stage_id}>
																					<div className="col-12 col-lg-6">
																						<section
																							className="mt-4"
																						//className="col-12 col-lg-12 box-grey mt-2 details-wrapper"
																						>
																							<div className="col-12">
																								<div className="h5-05 font-bold">
																									{obj.sub_stage_name}
																								</div>
																								<small>{obj.sub_stage_desc}</small>
																							</div>
																						</section>
																						{renderSubElement(
																							obj,
																							setFieldValue,
																							setErrors,
																							setFieldTouched
																						)}
																					</div>
																				</Fragment>
																			);
																		})}
																	</div>
																	
																<Offline>
																	{({ isOffline, isOnline }) => {
																		return isOffline ? <div className="text-center" style={{fontWeight:700}}>{'You are currently offline. Please check your internet connection.'}</div> : <div>{!user_applied && (
																			<div className="row mt-3 col d-flex justify-content-start align-items-center">
																				<div
																					className="mr-3"
																					onClick={() => {
																						submitAction.current = "save";
																					}}
																				>
																					<button
																						type="submit"
																						className="arrow-white btn btn-primary center-v btn-verify"
																						disabled={hideBtn}
																					>
																						{text}
																					</button>
																				</div>
																				<div className="">
																					<button
																						className="btn btn-secondary center-v mt-lg-0 text-nowrap"
																						onClick={() => {
																							setsavingAsDraft(false);
																							submitAction.current = "draft";
																							//setTimeout(() => {
																							//	window.location.reload();
																							//}, 2500);
																						}}
																					>
																						Save as draft
																					</button>
																				</div>
																			</div>
																		)}</div>;}}
																</Offline>
																	
																</fieldset>
															</Form>
														);
													}}
												</Formik>
											</div>
										</div>
										<div className="clearfix" />
									</div>
								</div>
							</div>
							{/*revised template end*/}
						</div>
					</div>
				</section>
				<section className="section-common-space" />
				{
					<Popup
						show={show}
						setshow={setshow}
						notifcationInfo={notificationInfo}
						setsubmit={setsubmit}
					/>
				}
				<ToastContainer autoClose={2500} theme="colored" />
				<SessionExpireyPopup
							show={showSessionpopup}
							setshow={setshowSessionpopup}
							
						/>
			</div>
		</>
	);
}

export default JobApplication;
