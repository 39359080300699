// /api/Notification/JobApplicationWithdraw

import axios from "axios";

export async function readUserNotification(candidateID, notificationID,emailId = "") {
	return axios
		.post("/api/Notification/Notifications", {
			UserID: candidateID,
			NotificationID: notificationID,
			userEmailID: emailId
		})
		.then((res) => res?.data);
}
