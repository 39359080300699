import "bootstrap/dist/js/bootstrap.bundle.min.js";

import React from "react";
// import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { unregister } from "./registerServiceWorker";
import { Provider } from "react-redux";
import { CookiesProvider } from "react-cookie";
import store from "./redux/store";
import "bootstrap-icons/font/bootstrap-icons.css";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import { createRoot } from "react-dom/client";

import CacheBuster from "react-cache-buster";
import packageJson from "../package.json";
import Loading from "./Loading";

const container = document.getElementById("root");
const root = createRoot(container);
const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const theme = createTheme({
	palette: {
		primary: {
			main: "#aa0023",
		},
	},
});
const isProduction = process.env.NODE_ENV === "production";
root.render(
	<BrowserRouter basename={baseUrl}>
		<Provider store={store}>
			<CookiesProvider>
				<ThemeProvider theme={theme}>
					<CacheBuster
						currentVersion={packageJson.version}
						isEnabled={isProduction} //If false, the library is disabled.
						isVerboseMode={false} //If true, the library writes verbose logs to console.
						loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
						metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
					>
						<App />
					</CacheBuster>
				</ThemeProvider>
			</CookiesProvider>
		</Provider>
	</BrowserRouter>
);

// registerServiceWorker();
unregister();
