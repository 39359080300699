export const initialState = {
	isLoggedIn: false,

	loading: false,
	user: { otpVerified: false, isRegistering: false },
	bookmarks: [],
	applied: [],
	areaDeptMaster: {},
	applicationStatusMaster: {},
	error: "",
	userpath: [],
	UserPrefs: [],
	refreshComponent: false,
};
