const clearAllCookies = () => {
	const cookies = document.cookie.split("; ");
	cookies.forEach((cookie) => {
		const name = cookie.split("=")[0];
		// console.log(`clearing cookie ${name}`);
		document.cookie =
			name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
	});
};

export default clearAllCookies;
