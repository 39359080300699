import React, { useState } from "react";
import _ from "lodash";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
function Popup(props) {
	const handleClose = () => {
		props.setshow(false);
	};

	return (
		<Modal
			show={props.show}
			onHide={handleClose}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header>
				<div className="text-center w-100">
					<h5
						className="modal-title font-medium h5-05"
						id="exampleModalLiveLabel"
					>
						{props?.notifcationInfo?.title}
					</h5>
				</div>
				<button
					type="button"
					className="close"
					data-dismiss="modal"
					aria-label="Close"
					onClick={handleClose}
				>
					<span aria-hidden="true">×</span>
				</button>
			</Modal.Header>
			<Modal.Body>
				<div className="modal-body text-center my-5 text-wrap">
					<h3>{props?.notifcationInfo?.msg}</h3>
				</div>
			</Modal.Body>
			<Modal.Footer className="footer_btns">
				<Button
					style={{ minWidth: "79px", justifyContent: "center" }}
					type="button"
					onClick={(e) => {
						e.preventDefault();
						props.setsubmit(true);
						handleClose();
					}}
					className=" btn center-v mt-2 mt-lg-0 text-nowrap mr-2"
					data-dismiss="modal"
				>
					OK
				</Button>
				<Button
					style={{ minWidth: "79px", justifyContent: "center" }}
					type="button"
					onClick={(e) => {
						e.preventDefault();
						handleClose();
					}}
					className=" btn center-v mt-2 mt-lg-0 text-nowrap mr-2"
					data-dismiss="modal"
				>
					Cancel
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

export default React.memo(Popup);
