import React from "react";
import $ from "jquery";
import { Link } from "react-router-dom";

function Faq() {
	$(document).ready(function () {
		$(".searchAbtJI").on("click", function () {
			$("body").css({ overflow: "hidden" });
			setTimeout(function () {
				$("input#topnav-search").focus();
			}, 100);
			setTimeout(function () {
				$(".pkv-searchview").addClass("slidedown");
			}, 500);
			setTimeout(function () {
				$(".pkv-searchview .search-backdrop.pkv-fade").addClass("show visible");
			}, 100);
			setTimeout(function () {
				$(".pkv-searchview .animate_search_result").addClass("animate");
				$(".pkv-searchview .animate_search_result").css({
					"box-sizing": "border-box",
					display: "block",
				});
			}, 700);
			setTimeout(function () {
				$(".pkv-searchview .pkv-searchresult-container").addClass("slidedown");
			}, 700);
		});

		$(".closeSearch, .search-backdrop").on("click", function () {
			$("body").css({ overflow: "auto" });
			setTimeout(function () {
				$(".pkv-searchview .pkv-searchresult-container").removeClass(
					"slidedown"
				);
			}, 100);
			setTimeout(function () {
				$("input#topnav-search").focus();
			}, 900);
			setTimeout(function () {
				$(".pkv-searchview").removeClass("slidedown");
			}, 100);
			setTimeout(function () {
				$(".pkv-searchview .search-backdrop.pkv-fade").removeClass(
					"show visible"
				);
			}, 500);
			setTimeout(function () {
				$(".pkv-searchview .animate_search_result").removeClass("animate");
				$(".pkv-searchview .animate_search_result").css({
					"box-sizing": "border-box",
					display: "none",
				});
			}, 100);
		});
	});

	//script for textbox close icon show and hide
	$(".form-group").each(function () {
		const $inp = $(this).find("input"),
			$cle = $(this).find(".clear-value");
		$inp.on("input", function () {
			$cle.toggle(!!this.value);
		});

		$cle.on("touchstart click", function (e) {
			e.preventDefault();
			$inp.val("").trigger("input");
		});
	});

	$(".form-group.input-container input")
		.on("focus", function () {
			if ($(this).val().length === 0) {
				$(this)
					.closest(".form-group.input-container")
					.find(".clear-value")
					.fadeOut();
			} else {
				$(this)
					.closest(".form-group.input-container")
					.find(".clear-value")
					.fadeIn();
			}
		})
		.blur(function () {
			$(".clear-value").fadeOut();
		});

	// $(".menu").click(function(){

	//    $(this).parents(".submenu-link").hide();
	//    $(this).parents(".submenu-link").siblings(".submenu").slideToggle();
	// });
	// $(".signout").click(function(){
	//    $(this).parents(".submenu").slideToggle();
	//    $(this).parents(".submenu").siblings(".submenu-link").show();
	// });

	$(".profile-frame .profile-toggle").click(function () {
		$(this)
			.parents(".profile-frame")
			.find(".profile-toggle-menu")
			.slideToggle();
		//profile-toggle-menu
	});
	// burger menu animation
	$(document).ready(function () {
		$("#nav-icon").click(function () {
			$(this).toggleClass("open");
		});
	});

	return (
		<>
			<div className="main-container full_width">
				<section className="section-masthead space-before-breadcrumb section-common-space masthead-mob-space overflow-hidden pt-0">
					<div className="container ">
						<div className="row d-md-block">
							<div className="col-lg-12">
								<nav aria-label="breadcrumb">
									<ol className="breadcrumb">
										<li className="breadcrumb-item">
										<a href={`/current-openings/Academics`}>Current Openings</a>
										</li>
										<li className="breadcrumb-item active" aria-current="page">
											FAQs
										</li>
									</ol>
								</nav>
							</div>
						</div>
					</div>{" "}
				</section>
			</div>
			<section class="section-common-space bg-white">
				<div class="container">
					<div class="row">
						<div class="col-lg-12">
							<div class="row">
								<div class="col-12">
									<div class="career-two-column-template-sm-right-col">
										<div class="left-column  flex-fill">
											<div>
												<h2 class="text-primary">
													Faculty Recruitment at Jio Institute (JI) Frequently
													Asked Questions (FAQs).
												</h2>
												<p>(As of 30th January 2023)</p>
												<br></br>
												<p>
													As a part of the academic program development, Jio
													Institute has commenced the process of recruitment for
													its core faculty members.The JI team has outlined
													certain aspects of the recruitment and appointment
													process, through a series of FAQs categorized as
													follows:
												</p>
												<p>
													<b>A. Application Process</b>
												</p>
												<p>
													<b>B. Terms of Employment </b>
												</p>
												<p>
													<b>C. Compensation & Benefits</b>
												</p>
												<p>
													<b>
														D. Research Grant and Personal Development
														Opportunities
													</b>
												</p>
												<p>
													<b>E. About Jio Institute</b>
												</p>
												{/* <p><b>F.	About Jio Institute</b></p> */}
											</div>
											<div class="faq">
												<div class="accordion" id="acccordionTab-1">
													<div class="card">
														<div class="card-header" id="heading1-1">
															<a
																href="javascript:void(0);"
																data-toggle="collapse"
																data-target="#collapse1-1"
																aria-expanded="true"
																aria-controls="collapse1-1"
															>
																<p>A. Application Process</p>
															</a>
														</div>
														<div
															id="collapse1-1"
															class="collapse show"
															aria-labelledby="heading1-1"
															data-parent="#acccordionTab-1"
														>
															<div class="card-body">
																<b>
																	1. What is the procedure to apply for a
																	faculty position at JI? What information and
																	documents are required while submitting the
																	application?
																</b>
																<br />
																<br />
																<p>
																	Interested applicants should apply online on{" "}
																	<a
																		href="https://www.jioinstitute.edu.in/work-with-us"
																		target="_blank"
																		rel="noopener noreferrer"
																	>
																		<span class="text-danger">
																			https://www.jioinstitute.edu.in/work-with-us
																		</span>
																	</a>
																	. Prospective faculty will have to attach the
																	following documents to complete their online
																	application form.
																</p>
																{/* <br></br> */}
																<p>
																	<span>
																		<b>i. </b>
																	</span>
																	A covering letters.
																</p>
																<p>
																	<span>
																		<b>ii. </b>
																	</span>
																	A full curriculum vitae.
																</p>
																<p>
																	<span>
																		<b>iii. </b>
																	</span>
																	Names and contact details of three referral.
																</p>
																<p>
																	<span>
																		<b>iv. </b>
																	</span>
																	Any supplemental material the applicant would
																	like to provide.
																</p>
																<p>
																	The applications shall be screened and
																	shortlisted by Jio Institute and the
																	applicants shall be informed on the decisions
																	taken in few weeks from submitting their
																	application.
																</p>
																<br></br>
																<p>
																	<b>
																		{" "}
																		2. What kinds of positions are available?
																	</b>
																</p>
																<p>
																	Jio Institute is recruiting full-time faculty
																	at all levels: Assistant Professor, Associate
																	Professor and Professor. The compensation and
																	other benefits vary depending on the level. JI
																	is also interested in engaging with highly
																	regarded academicians and industry
																	professional for Visiting Faculty positions to
																	bring in diverse perspectives and experiences.
																	Candidates applying for a faculty position
																	must demonstrate the ability to engage in
																	scholarly research and high-quality teaching
																	at the graduate and post graduate level.
																</p>
																<br></br>
																<p>
																	<b>
																		3. Does JI accept applications from fresh
																		PhD candidates?
																	</b>
																</p>
																<p>
																	Jio Institute welcomes applications from any
																	individual who holds an excellent academic
																	record, good communication skills and a strong
																	commitment to high quality education and
																	potential for exceptional research.
																</p>
															</div>
														</div>
													</div>
													<div class="card">
														<div class="card-header" id="heading1-4">
															<a
																href="javascript:void(0);"
																class="collapsed"
																data-toggle="collapse"
																data-target="#collapse1-4"
																aria-expanded="false"
																aria-controls="collapse1-4"
															>
																<p>B. Terms of Employment</p>
															</a>
														</div>
														<div
															id="collapse1-4"
															class="collapse"
															aria-labelledby="heading1-4"
															data-parent="#acccordionTab-1"
														>
															<div class="card-body">
																<p>
																	<b>
																		1. What qualifications are expected of the
																		faculty at JI?
																	</b>
																</p>
																<p>
																	JI will recruit individuals who show the
																	promise for or already have a record of
																	outstanding accomplishments and impact. In
																	addition, it is the intention to develop a
																	culture and practices that are typical of the
																	best research universities in the world.
																</p>
																<br></br>
																<p>
																	<b>
																		a. Appointment Criteria for an Assistant
																		Professor:
																	</b>
																</p>
																<p>
																	For appointment at the Assistant Professor
																	level, a doctorate in a relevant discipline
																	from top 500 global institutions with at least
																	2 years of postdoc experience will be
																	preferred. However, individuals with
																	doctorates from other institutions of repute
																	with excellent research and teaching potential
																	will also be considered based on merit.
																</p>
																<br></br>
																<p>
																	<b>
																		b. Appointment Criteria for an Associate
																		Professor:
																	</b>
																</p>
																<p>
																	At the Associate Professor level, it will be
																	expected that the candidates will have had at
																	least five years of prior experience at a
																	global institution or an institution of repute
																	at a level of Assistant Professor. Their
																	research track record and accomplishments will
																	be such that they show the promise for
																	outstanding and sustained contributions and
																	impact in the future.
																</p>
																<br></br>
																<p>
																	<b>
																		c. Appointment Criteria for a Professor:
																	</b>
																</p>
																<p>
																	At the full Professor level, prior experience
																	at a global institution and outstanding
																	accomplishments at the level of Associate
																	Professor for least 4 years will be expected.
																	In addition to the research track record, the
																	aptitude to be an institutional leader will be
																	a consideration.
																</p>
																<br></br>
																<p>
																	<b>
																		2. How soon will the faculty be expected to
																		join after getting the offer?
																	</b>
																</p>
																<p>
																	{" "}
																	Recruitment is ongoing at JI on a rolling
																	basis. With reference to that, each
																	application shall be unique and may be handled
																	differently for each candidate. However,
																	ideally academicians should be available as
																	per the academic calendar for the program
																	starting from July 2023.
																</p>
																<br></br>
																<p>
																	<b>
																		{" "}
																		3. What shall be the duration of
																		appointments at JI?
																	</b>
																</p>
																<p>
																	<span>
																		<b>
																			a. Appointment for an Assistant Professor:
																		</b>
																	</span>
																</p>
																<p>
																	The initial appointment for an assistant
																	professor shall be for three years. A
																	successful three-year review shall lead to
																	reappointment for another three to five years.
																</p>
																<br></br>
																<p>
																	<span>
																		<b>
																			b. Appointment for an Associate Professor:
																		</b>
																	</span>
																</p>
																<p>
																	The initial appointment for an Associate
																	Professor shall be for a fixed duration
																	ranging from three to five years. Based on a
																	periodic review the appointment shall be on a
																	rolling contract for three to five years.
																</p>
																<p>
																	<span>
																		<b>c. Appointment for a Professor:</b>
																	</span>
																</p>
																<p>
																	The initial appointment for a Professor shall
																	be for a fixed duration ranging from three to
																	five years. Based on a periodic review the
																	appointment shall be on a rolling contract for
																	three to five years.
																</p>
																<br></br>
																<p>
																	<b>
																		{" "}
																		4. Does JI have any opportunities available
																		for academicians looking to spend their
																		sabbatical?
																	</b>
																</p>
																<p>
																	{" "}
																	Academicians planning to spend their
																	sabbatical at Jio Institute (short-term and
																	long-term) are welcome to engage with the
																	institute as full-time faculty, visiting
																	faculty, research scholars or in any capacity
																	that contributes to the growth of the
																	Institution in its formative stages.
																</p>
															</div>
														</div>
													</div>

													<div class="card">
														<div class="card-header " id="heading1-8">
															<a
																href="javascript:void(0);"
																class="collapsed "
																data-toggle="collapse"
																data-target="#collapse1-8"
																aria-expanded="false"
																aria-controls="collapse1-4"
															>
																<p>C. Compensation & Benefits</p>
															</a>
														</div>
														<div
															id="collapse1-8"
															class="collapse"
															aria-labelledby="heading1-8"
															data-parent="#acccordionTab-1"
														>
															<div class="card-body">
																<p>
																	<b>
																		1. What is the compensation range across
																		levels at Jio Institute?
																	</b>
																</p>
																<p>
																	Jio Institute offers a competitive
																	compensation package to its faculty along with
																	attractive benefits.
																</p>
																<br></br>
																<p>
																	<b>
																		2. Does JI provide housing facilities for
																		faculty?
																	</b>
																</p>
																<p>
																	Jio Institute shall provide premier housing
																	facilities for our faculty member as per
																	institute norms. The details of the
																	accommodations shall be shared once the
																	appointment is confirmed.
																</p>
																<br></br>
																<p>
																	<b>
																		3. What kind of additional benefits does JI
																		provide?
																	</b>
																</p>
																<p>
																	<span>
																		<b>Insurance:</b>
																	</span>{" "}
																	Hospitalization cover of INR 5 Lakh with an
																	option to top up by another 5 Lakh, 7 Lakh or
																	10 Lakh for self and family.
																</p>
																<p>
																	{" "}
																	<span>
																		<b>Relocation Allowances & Benefits:</b>
																	</span>{" "}
																	Reimbursement of Flight Ticket and
																	transportation of household goods from the
																	current city to Navi Mumbai as per institute
																	norms.
																</p>
																<p>
																	{" "}
																	<span>
																		<b>Leave types:</b>
																	</span>{" "}
																	such as privilege (24), casual (6) and
																	optional (3). In addition to these, 11 public
																	holidays are declared in beginning of a
																	calendar year.
																</p>
																<p>
																	{" "}
																	<span>
																		<b>Sabbatical Leave:</b>
																	</span>{" "}
																	Can be availed for the duration of 6 months to
																	2 years as per norms.
																</p>
																<p>
																	{" "}
																	<span>
																		<b>Maternity Leave:</b>
																	</span>{" "}
																	Can be availed for the duration of 6 months to
																	2 years as per norms.
																</p>
																<p>
																	{" "}
																	<span>
																		<b>Paternity Leave:</b>
																	</span>{" "}
																	Can be availed for the duration of 6 months to
																	2 years as per norms.
																</p>
																<p>
																	<b>
																		4. What kind of rewards and awards are given
																		to faculty?
																	</b>
																</p>
																<p>
																	The scope for faculty recognition is not
																	limited to the following:
																</p>
																<span>
																	<b>Young Faculty Fellowship</b>
																</span>
																<br></br>
																<br></br>
																<p>
																	<span>
																		<b>•</b>
																	</span>
																	To be provided to highly talented young
																	academicians while joining as Assistant
																	Professors
																</p>
																<p>
																	<span>
																		<b>•</b>
																	</span>
																	Fellowships and research grant up to INR 1 Cr.
																</p>
																<br></br>
																<span>
																	<b>Faculty Awards</b>
																</span>
																<br></br>
																<br></br>
																<p>
																	<span>
																		<b>•</b>
																	</span>
																	Awards for Teaching Excellence: INR 2 Lakh.
																</p>
																<p>
																	<span>
																		<b>•</b>
																	</span>
																	Awards for Research Excellence:INR 2 Lakh
																</p>
																<br></br>
																<span>
																	<b>Faculty Awards</b>
																</span>
																<br></br>
																<br></br>
																<p>
																	<span>
																		<b>•</b>
																	</span>
																	Awards for Teaching Excellence: INR 2 Lakh.
																</p>
																<p>
																	<span>
																		<b>•</b>
																	</span>
																	Awards for Research Excellence:INR 2 Lakh.
																</p>
															</div>
														</div>
													</div>
													<div class="card">
														<div class="card-header" id="heading1-10">
															<a
																href="javascript:void(0);"
																class="collapsed"
																data-toggle="collapse"
																data-target="#collapse1-10"
																aria-expanded="false"
																aria-controls="collapse1-4"
															>
																<p>
																	D. Research Grant and Personal Development
																	Opportunities
																</p>
															</a>
														</div>
														<div
															id="collapse1-10"
															class="collapse"
															aria-labelledby="heading1-10"
															data-parent="#acccordionTab-1"
														>
															<div class="card-body">
																<p>
																	<b>
																		1. Does JI provide faculty with funding for
																		research and industry projects?
																	</b>
																</p>
																<p>
																	Jio Institute aims to be a research-focused
																	institute and encourages all its faculty
																	members to engage rigorously in research
																	activities or activities that advance
																	knowledge in their chosen areas.
																</p>
																<p>
																	For funding purposes, JI expects faculty to
																	submit well-planned proposals to the Institute
																	and start building up their research projects
																	at the earliest. The faculty are eligible for
																	the below grants:
																</p>
																<br></br>
																<span>
																	<b>• </b> Research Initiation Grant: INR 2
																	Lakh to 20 Lakh provided at the time of
																	joining; Utilization period of 2-3 years.
																</span>
																<span>
																	<br></br>
																	<b>•</b> Seed Grant: INR 20 Lakh funding of
																	INR 2 Cr to procure equipment, supplies for
																	setting up laboratories, Utilization period of
																	2-3 years.
																</span>
																<br></br>
																<span>
																	<b>•</b> Annual Grants: Up to INR 3 Lakh every
																	year.
																</span>
																<br></br>
																<br></br>
																<p>
																	<b>
																		2. Does JI provide professional development
																		allowance?
																	</b>
																</p>
																<p>
																	A professional development allowance ranging
																	between INR 3 Lakhs to 5 Lakhs per year is
																	available to all faculty members. These funds
																	can be used for attending international
																	conferences/symposiums, attending national
																	conferences/symposiums, purchase of books and
																	other contingent items and membership of
																	Professional Societies.Higher amount may be
																	granted for Leadership roles/ Star faculty as
																	approved by the competent authority of the
																	Institute.
																</p>
																<p>
																	<b>
																		3. Does JI provide any other opportunities
																		for faculty?
																	</b>
																</p>
																{/* <br></br> */}
																<p>Additional opportunities for faculty:</p>
																<span>
																	<b>•</b> Consultancy for Industries: A maximum
																	of 52 days in an academic year with 30% to 40%
																	of the fee to be charged as overheads by Jio
																	Institute
																</span>
																<br></br>
																<span>
																	<b>•</b> IP commercialization/ licensing: 50%
																	share of revenue to be given to the inventor/
																	faculty{" "}
																</span>
																<br></br>
																<span>
																	<b>•</b> Additional grant to setup prototype
																	of product under incubation cell.
																</span>
																<br></br>
																<br></br>
																<p>
																	<b>
																		4. How can a faculty member develop research
																		facilities and / or projects?
																	</b>
																</p>
																{/* <br></br> */}
																<p>
																	Faculty members are expected to develop a
																	detailed proposal that outlines their research
																	project, problem statement, approach, and
																	methodology, expected outcomes as well as
																	justifications for the funding requested
																	including research infrastructure and
																	equipment.These proposals shall be submitted
																	to their respective program chairs who shall
																	sanction these projects.
																</p>
															</div>
														</div>
													</div>
													<div class="card">
														<div class="card-header" id="heading1-11">
															<a
																href="javascript:void(0);"
																class="collapsed"
																data-toggle="collapse"
																data-target="#collapse1-11"
																aria-expanded="false"
																aria-controls="collapse1-4"
															>
																<p>E. About Jio Institute</p>
															</a>
														</div>
														<div
															id="collapse1-11"
															class="collapse"
															aria-labelledby="heading1-11"
															data-parent="#acccordionTab-1"
														>
															<div class="card-body">
																<p>
																	<b>
																		1. Where exactly is Jio Institute located?
																	</b>
																</p>
																<p>
																	Jio Institute is currently in the process of
																	developing a world class campus of learning,
																	which focuses on innovation and research-based
																	education at Ulwe in Navi Mumbai. The goal is
																	to create an international institution with
																	numerous facilities that set new global
																	standards. The Institute is being set-up close
																	to Mumbai which is the financial, commercial
																	and entertainment capital of India. Mumbai is
																	a metro city with top class residential,
																	educational and entertainment facilities and a
																	vibrant social and cultural life. The campus
																	site is well-connected by public transport and
																	is close to the upcoming airport in Navi
																	Mumbai. The Mumbai Trans Harbor Link will also
																	be operational by end of 2023 and then on the
																	commute time to South Mumbai is expected to be
																	less than 30 mins.
																</p>
																<br></br>
																<p>
																	<b>
																		2. What kind of educational institutions,
																		industries are near JI? ?{" "}
																	</b>
																</p>
																<p>
																	Navi Mumbai is home to various educational
																	institutions offering courses in several
																	streams including engineering, medical
																	sciences, interior design, and hotel
																	management. Various multinational corporations
																	have their head offices/branches across the
																	city, making it an active business hub.
																</p>
																<br></br>
																<p>
																	<b>
																		3. What kind of recreational and healthcare
																		facilities are near JI?
																	</b>
																</p>
																<p>
																	Navi Mumbai also has various recreational
																	facilities such as a Golf Course, Central Park
																	and Pandavkada Water Falls in Kharghar. Navi
																	Mumbai also has many quality restaurants and
																	luxury hotels for accommodation. There are
																	many shopping malls. Navi Mumbai is also a
																	host to many best health care centres and
																	hospitals like MITR Hospital in Sector 05,
																	Kharghar, Fortis Hiranandani Hospital near
																	Juhu Village, Juhu Nagar (Vashi), Apollo
																	Hospital in Belapur and SRL diagnostic centres
																	in Kharghar, Panvel, Kamothe, Kalamboli, Kopar
																	Khairane, Juhu Gaon to name a few.
																</p>
																<br></br>
																<p>
																	<b>
																		4. Does JI have any industry / academic
																		collaborations in place?
																	</b>
																</p>
																<p>To be updated shortly.</p>
															</div>
														</div>
													</div>
													{/* <div class="card">
													<div class="card-header" id="heading1-12">
														<a
															href="javascript:void(0);"
															class="collapsed"
															data-toggle="collapse"
															data-target="#collapse1-12"
															aria-expanded="false"
															aria-controls="collapse1-4"
														>
															<p class="text-primary">F.About Jio Institute</p>
														</a>
													</div>
													<div
														id="collapse1-12"
														class="collapse"
														aria-labelledby="heading1-12"
														data-parent="#acccordionTab-1"
													>
														<div class="card-body">
															<p>
																<b>
																	1. Where exactly is Jio Institute located?
																</b>
															</p>
															<p>
																Jio Institute is currently in the process of
																developing a world class campus of learning,
																which focuses on innovation and research based
																education at Ulwe in Navi Mumbai. The goal is to
																create an international institution with
																numerous facilities that set new global
																standards. The Institute is being set-up in
																Mumbai which is the financial, commercial and
																entertainment capital of India. It is a metro
																city with top class residential, educational and
																entertainment facilities and a vibrant social
																and cultural life. The campus site is
																well-connected by public transport and is close
																to the upcoming airport in Navi Mumbai.
															</p>
															<p>
																<b>
																	2. What kind of industries and educational
																	institutions are near JI?{" "}
																</b>
															</p>
															<p class="text-danger">
																To be updated – Need some information around our
																site to update this.
															</p>
															<p>
																<b>
																	3. Does JI have any industry / academic
																	collaborations in place?{" "}
																</b>
															</p>
															<p class="text-danger">
																To be updated – basis on the MOUs that get
																signed for collaborations
															</p>
														</div>
													</div>
												</div> */}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default Faq;
