import React from "react";
import { useField } from "formik";

const TextArea = ({ label, ...props }) => {

	const [field, meta] = useField(props);
	return (
		<>
			<textarea
				className="form-control"
				style={{ minHeight: "100px" }}
				{...field}
				{...props}
			></textarea>
			<label className="control-label" htmlFor={props.id || props.name}>
				{label}
			</label>
			{meta.touched && meta.error ? (
				<small className="massage error d-block">{meta.error}</small>
			) : null}
		</>
	);
};
export default TextArea;
