import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import {
	fetchUserSuccess,
	fetchUserJobPrefs,
	setUserLogout,
} from "../redux/UserDetails/UserActions";
import "./Common.css";
import NavDropdown from "react-bootstrap/NavDropdown";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import { fetchMasterData } from "./../redux/Masterdata/MasterdataActions";

import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import $ from "jquery";

function Header() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const [Departments, setDepartments] = useState(
		useSelector((state) => state.masterData.areaDeptMaster.departments)
	);

	const [Area, setArea] = useState(
		useSelector((state) => state.masterData.areaDeptMaster.area)
	);
	const isAuthenticated = useSelector((state) => state.user);

	const [activeState, SetactiveState] = useState(location.pathname);

	if (location.pathname != activeState) {
		SetactiveState(location.pathname);
	}

	const [showDropdown, setShowDropdown] = useState(false);
	const [showMobileProfileToggleMenu, setshowMobileProfileToggleMenu] =
		useState(false);

	function dropdown() {
		setShowDropdown(!showDropdown);
	}
	/* Title case function */
	String.prototype.toTitleCase = function () {
		return this.replace(/\w\S*/g, function (txt) {
			return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
		});
	};
	const UserInfo = useSelector((state) => state.user.user.userDetails);
	const userCategoryId = localStorage.getItem("userCategoryId");
	let defaultOpenings = "Academics";
	if (userCategoryId == 1) {
		defaultOpenings = "Academics";
	} else if (userCategoryId == 2) {
		defaultOpenings = "Jio-Digital-Library";
	} else if (userCategoryId == 3) {
		defaultOpenings = "Non-Academic";
	} else {
		defaultOpenings = "Academics";
	}

	function LogoutUser() {
		dispatch(setUserLogout());
		localStorage.clear(); //for localStorage
		sessionStorage.clear(); //for sessionStorage
		axios
			.post("/api/Login/Logout", null)
			.then((res) => { })
			.catch((err) => console.log("error is ", err.toString()));
		navigate("../login", { replace: false });
	}
	$(".profile-frame .profile-toggle").click(function () {
		$(this)
			.parents(".profile-frame")
			.find(".profile-toggle-menu")
			.slideToggle();
		//profile-toggle-menu
	});
	//const dispatch = useDispatch();
	useEffect(() => {
		if (Departments === undefined && Area === undefined) {
			dispatch(fetchMasterData());
		}
	}, []);
	return (
		<>
			<div className="jio_com">
				<div className="header-container bg-primary">
					<div className="container">
						<div className="sub-header" id="sub-header">
							<div className="sub-header-right-list">
								<ul className="sub-header-items">
									<li className="sub-header-img ">
										<a
											href="https://www.linkedin.com/school/jio-institute/?originalSubdomain=in"
											aria-label="Linkedin"
											id="linkedin"
											target="_blank"
											rel="noopener noreferrer"
										>
											<img
												className="social-media-icon pb-0"
												src="assets/images/header/Linkedin-White.svg"
												width="20px"
												height="20px"
												alt="Linkedin"
											/>
										</a>
									</li>

									<li className="sub-header-img">
										<a
											href="https://www.instagram.com/jio_institute/"
											id="instagram"
											aria-label="Instagram"
											target="_blank"
											rel="noopener noreferrer"
										>
											<img
												className="social-media-icon"
												src="assets/images/header/icon-instagram.svg"
												width="20px"
												height="20px"
												alt="Instagram"
											/>
										</a>
									</li>
									<li className="sub-header-img">
										<a
											href="https://www.youtube.com/channel/UC9X1xUUDHG61_czm7QzNnQg"
											aria-label="Youtube"
											id="youtube"
											target="_blank"
											rel="noopener noreferrer"
										>
											<img
												className="social-media-icon"
												src="assets/images/header/icon-youtube.svg"
												width="20px"
												height="20px"
												alt="youtube"
											/>
										</a>
									</li>
									<li className="sub-header-img mr-0">
										<a
											href="https://www.facebook.com/jioinstitute1"
											aria-label="Facebook"
											id="facebook"
											target="_blank"
											rel="noopener noreferrer"
										>
											<img
												className="social-media-icon last-child"
												src="assets/images/header/icon-facebook.svg"
												width="20px"
												height="20px"
												alt="Facebook"
											/>
										</a>
									</li>
								</ul>
							</div>
							<div className="clearfix" />
						</div>
						<div className="site-header-wrap1">
							<button
								id="nav-icon"
								className="header-mobile-menu js-nav-toggle"
								type="button"
								aria-expanded="false"
								aria-label="Toggle navigation"
								onClick={() => {
									let x = document.querySelector(".nav-wrapper");
									//show-menu
									x.classList.toggle("show-menu");
									let y = document.querySelector(".header-mobile-menu");
									y.classList.toggle("open");
								}}
							>
								<div className="bar1" />
								<div className="bar2" />
								<div className="bar3" />
							</button>

							<div className="header-logo ji-work-with-us">
								<a
									href={location.pathname === "/Register" ? "/Register" : "/"}
									aria-label="Jio Institute work with us"
									id="home"
								>
									<div className="header-logo-bg d-block">
										<div
											className="bg-color1"
											style={{
												backgroundImage:
													'url("assets/images/career-header/ji-work-with-us.png")',
											}}
										></div>
									</div>
								</a>
							</div>
							<div className="profile-pic-sm-device">
								<div className="profile-frame">
									<a
										className="profile-toggle p-0"
										onClick={() =>
											setshowMobileProfileToggleMenu((state) => !state)
										}
									>
										<div
											className="user-profile button-sm font-medium"
											data-toggle=""
										>
											<img
												src="assets/images/career/icon-profile.svg"
												alt="search"
												width=""
												height=""
												className="d-flex d-lg-none"
											/>
										</div>
									</a>

									{showMobileProfileToggleMenu && (
										<ul className="profile-toggle-menu d-block">
											{isAuthenticated["isLoggedIn"] && (
												<>
													{" "}
													<li
														onClick={() => {
															localStorage.removeItem("Category_id");
														}}
													>
														<a href="/Dashboard" id="Dashboard">
															Dashboard
														</a>
													</li>
													<li>
														<a
															onClick={(e) => (
																e.preventDefault(), LogoutUser(), window.location.reload()
															)}
															id="Signout"
														>
															Sign Out
														</a>
													</li>
												</>
											)}
											{!isAuthenticated["isLoggedIn"] && (
												<li>
													<a
														aria-label="Login"
														onClick={(e) => (
															e.preventDefault(),
															navigate("../login", { replace: false }),
															window.location.reload()
														)}
													>
														Sign In
													</a>
												</li>
											)}
										</ul>
									)}
								</div>
							</div>

							<div className="global-search-sm-device">
								<a href="#" className="searchAbtJI">
									{/*class for search popup*/}
									<img
										className="search-black-icon user-profile "
										src="assets/images/career/icon-profile.svg"
										width=""
										height=""
										alt="profile"
									/>
								</a>
							</div>
							<div className="desktop-nav-container d-none d-lg-block">
								<div className="d-flex pt-2">
									{location.pathname !== "/Register" && (
										<ul className="nav navbar-nav flex-row justify-content-end align-items-center w-100">
											<li>
												<a
													href="/"
													aria-label="Overview"
													onClick={() =>
														localStorage.setItem("redirect", false)
													}
													className={activeState === "/" ? "nav-active" : ""}
												>
													Home
												</a>
											</li>
											<li>
												{isAuthenticated["isLoggedIn"] && (
													<a
														href={`/current-openings/${defaultOpenings}`}
														aria-label="Overview"
														className={
															activeState ===
																`/current-openings/${defaultOpenings}`
																? "nav-active"
																: ""
														}
													>
														Current Openings
													</a>
												)}
												{!isAuthenticated["isLoggedIn"] && (
													<a
														href="/current-openings/Academics"
														aria-label="Overview"
														className={
															activeState === "/current-openings/Academics"
																? "nav-active"
																: ""
														}
													>
														Current Openings
													</a>
												)}
											</li>
											<li>
												<a
													href="/why-join-us"
													aria-label="Overview"
													className={
														activeState === "/why-join-us" ? "nav-active" : ""
													}
												>
													Why Join Us
												</a>
											</li>
											{/* <li 
											        className={
												         activeState === "/why-join-us" ? "nav-active" : ""
														 }
												>
												<NavDropdown title="Why Join Us" id="basic-nav-dropdown">
													<NavDropdown.Item
														href="/why-join-us"
														rel="noopener noreferrer"
													>
														Why Join US
													</NavDropdown.Item>
													<NavDropdown.Item href="/faqs">FAQs</NavDropdown.Item>
												</NavDropdown>
											</li> */}

											{!isAuthenticated["isLoggedIn"] && (
												<li>
													<a
														aria-label="Login"
														onClick={(e) => (
															e.preventDefault(),
															navigate("../login", { replace: false })
														)}
														className={
															activeState === "/login" ? "nav-active" : ""
														}
													>
														Sign In
													</a>
												</li>
											)}

											{isAuthenticated["isLoggedIn"] && (
												<>
													<li
														onClick={() => {
															localStorage.removeItem("Category_id");
														}}
													>
														<a
															href="/Dashboard"
															aria-label="Dashboard"
															className={
																activeState === "/Dashboard" ? "nav-active" : ""
															}
														>
															{"Welcome " + UserInfo?.first_name + " "}
														</a>
													</li>
													<li className="dropdown dropdownX submenu-right-align">
														<span className="dropdown-toggle p-0">
															<div className="user-profile " data-toggle="">
																<img
																	src="assets/images/career/icon-profile.svg"
																	alt="search"
																	width=""
																	height=""
																/>
															</div>
														</span>
														<ul className="dropdown-menuX dropdown-menu multi-level ">
															<li
																onClick={() => {
																	localStorage.removeItem("Category_id");
																}}
															>
																<a href="/Dashboard" id="Dashboard">
																	Dashboard
																</a>
															</li>
															{/* <li className="position-relative">
																<a href="/Dashboard" id="Notifications">
																	Notifications
																	 <span
																		className="notifications-badge font-medium"
																		id="cartCountNew"
																	>
																		0
																	</span> 
																</a>
															</li> */}

															<li>
																<a
																	onClick={(e) => (
																		e.preventDefault(), LogoutUser()
																	)}
																	id="Signout"
																>
																	Sign Out
																</a>
															</li>
														</ul>
													</li>
												</>
											)}
										</ul>
									)}

									<div className="social-icon-container d-block d-md-none">
										<ul className="d-none">
											<li>
												<a
													target="_blank"
													title="Linkedin"
													href="https://www.linkedin.com/company/jio-institute"
													rel="noopener noreferrer"
												>
													<span className="linkedIn-icon" />
												</a>
											</li>
											<li>
												<a
													title="Instagram"
													href="https://www.instagram.com/jio_institute/"
													target="_blank"
													rel="noopener noreferrer"
												>
													<span className="ista-icon" />
												</a>
											</li>
											<li>
												<a
													title="YouTube"
													href="https://www.youtube.com/channel/UC9X1xUUDHG61_czm7QzNnQg"
													target="_blank"
													rel="noopener noreferrer"
												>
													<span className="youtube-icon" />
												</a>
											</li>
											<li>
												<a
													target="_blank"
													rel="noopener noreferrer"
													title="Facebook"
													href="https://www.facebook.com/jioinstitute1"
												>
													<span className="fbookter-icon" />
												</a>
											</li>
										</ul>
										<Container>
											<Row>
												<Col>
													<a
														target="_blank"
														title="Linkedin"
														href="https://www.linkedin.com/company/jio-institute"
														rel="noopener noreferrer"
													>
														<span className="linkedIn-icon" />
													</a>
												</Col>
												<Col>
													<a
														title="Instagram"
														href="https://www.instagram.com/jio_institute/"
														target="_blank"
														rel="noopener noreferrer"
													>
														<span className="ista-icon" />
													</a>
												</Col>
												<Col>
													<a
														title="YouTube"
														href="https://www.youtube.com/channel/UC9X1xUUDHG61_czm7QzNnQg"
														target="_blank"
														rel="noopener noreferrer"
													>
														<span className="youtube-icon" />
													</a>
												</Col>
												<Col>
													<a
														target="_blank"
														rel="noopener noreferrer"
														title="Facebook"
														href="https://www.facebook.com/jioinstitute1"
													>
														<span className="fbookter-icon" />
													</a>
												</Col>
											</Row>
										</Container>
									</div>
								</div>
							</div>
							{location.pathname !== "/Register" && (
								<div className="mobile-nav-container d-block d-lg-none">
									<div className="nav-wrapper show">
										<nav role="navigation">
											<div className="nav-toggle">
												<span className="nav-back">
													<a href="/" aria-label="Back" />
												</span>
												<span className="nav-title">Menu</span>
												<span className="nav-close" />
											</div>
											<ul>
												<div className="scrollable-navigation">
													<li>
														<a
															href="/"
															aria-label="Overview"
															onClick={() =>
																localStorage.setItem("redirect", false)
															}
														>
															Home
														</a>
													</li>
													{isAuthenticated["isLoggedIn"] && (
														<li>
															<a
																href={`/current-openings/${defaultOpenings}`}
																aria-label="Overview"
															>
																Current Openings
															</a>
														</li>
													)}
													{!isAuthenticated["isLoggedIn"] && (
														<li>
															<a
																href={`/current-openings/Academics`}
																aria-label="Overview"
															>
																Current Openings
															</a>
														</li>
													)}
													<li>
														<a href="/why-join-us" aria-label="Overview">
															Why Join Us
														</a>
													</li>

													{!isAuthenticated["isLoggedIn"] && (
														<li>
															<a
																aria-label="Login"
																onClick={(e) => (
																	e.preventDefault(),
																	navigate("../login", { replace: false }),
																	window.location.reload()
																)}
															>
																Sign In
															</a>
														</li>
													)}

													{isAuthenticated["isLoggedIn"] && (
														<>
															<li
																onClick={() => {
																	localStorage.removeItem("Category_id");
																}}
															>
																<a href="/Dashboard" aria-label="Dashboard">
																	{"Welcome " + UserInfo?.first_name + " "}
																</a>
															</li>
															<li
																onClick={() => {
																	localStorage.removeItem("Category_id");
																}}
															>
																<a href="/Dashboard" aria-label="Dashboard">
																	Dashboard
																</a>
															</li>
															<li>
																<a
																	onClick={(e) => (
																		e.preventDefault(), LogoutUser()
																	)}
																	id="Signout"
																>
																	Sign Out
																</a>
															</li>
															{/* <li className="dropdown submenu-right-align d-none">
																<a
																	href="/Dashboard"
																	className="dropdown-toggle p-0"
																>
																	<div className="user-profile " data-toggle="">
																		<img
																			src="assets/images/career/icon-profile.svg"
																			alt="search"
																			width=""
																			height=""
																		/>
																	</div>
																</a>
																<ul className="dropdown-menu multi-level">
																	<li
																		onClick={() => {
																			localStorage.removeItem("Category_id");
																		}}
																	>
																		<a href="/Dashboard" id="Dashboard">
																			Dashboard
																		</a>
																	</li>

																	<li>
																		<a
																			onClick={(e) => {
																				e.preventDefault();
																				LogoutUser();
																				localStorage.removeItem(
																					"userCategoryId"
																				);
																			}}
																			id="Signout"
																		>
																			Sign Out
																		</a>
																	</li>
																</ul>
															</li> */}
														</>
													)}
													<div className="social-icons">
														<div className="d-flex justify-content-center">
															<div>
																<a
																	href="https://www.linkedin.com/school/jio-institute/?originalSubdomain=in"
																	aria-label="linkedin"
																	target="_blank"
																	rel="noopener noreferrer"
																>
																	<img
																		src="assets/images/career/icon-fill-linkin.svg"
																		width="23px"
																		height="23px"
																		alt="Linkedin"
																	/>
																</a>
															</div>
															<div>
																<a
																	href="https://www.instagram.com/jio_institute/"
																	aria-label="Instagram"
																	target="_blank"
																	rel="noopener noreferrer"
																>
																	<img
																		src="assets/images/career/icon-fill-insta.svg"
																		width="23px"
																		height="23px"
																		alt="Instagram"
																	/>
																</a>
															</div>
															<div>
																<a
																	href="https://www.youtube.com/channel/UC9X1xUUDHG61_czm7QzNnQg"
																	aria-label="Youtube"
																	id="youtube"
																	target="_blank"
																	rel="noopener noreferrer"
																>
																	<img
																		src="assets/images/career/icon-fill-youtube.svg"
																		width="23px"
																		height="23px"
																		alt="youtube"
																	/>
																</a>
															</div>
															<div>
																<a
																	href="https://www.facebook.com/jioinstitute1"
																	aria-label="Facebook"
																	target="_blank"
																	rel="noopener noreferrer"
																>
																	<img
																		src="assets/images/career/icon-fill-facebook.svg"
																		width="23px"
																		height="23px"
																		alt="Facebook"
																	/>
																</a>
															</div>
														</div>
													</div>
												</div>
											</ul>
										</nav>
									</div>
								</div>
							)}
							<div className="clearfix" />
						</div>
					</div>
				</div>
				<div className="header-top-space" />

				<aside
					id="searchMain"
					className="pkv-searchview  animate_block"
					role="search"
				>
					<div
						id="searchBar"
						className="pkv-searchview-content search_bar_animate"
					>
						<div className="pkv-searchview-bar">
							<div className="pkv-searchview-bar-wrapper">
								<form className="pkv-searchform with-reset">
									<a href="#" className="back_search">
										<img
											src="assets/images/header/icon-search.svg"
											width="20px"
											height="20px"
											alt="search"
										/>
									</a>
									<input
										id="topnav-search"
										type="text"
										className="pkv-searchform-input"
										placeholder="Search"
										autoFocus
									/>

									<a href="#" className="clear-search closeSearch">
										<img
											src="assets/images/search/close-icon.svg"
											width=""
											height=""
											alt="close"
										/>
									</a>
								</form>
							</div>
							<aside className="pkv-searchresult-container">
								<div className="animate_search_result">
									<section className="pkv-searchresult ">
										<div className="pkv-searchresult-section">
											<div className="pkv-searchresult-section-inner">
												<div className="pkv-suggested-list">
													<ul className="pkv-searchresult-list">
														<li className="pkv-searchresult-item">
															<div className="d-flex align-items-center">
																<a href="" className="item_text_link">
																	<div className="item-text">
																		Recent searches
																	</div>
																</a>
																<span className="arrow-suggest" />
															</div>
														</li>
														<li className="pkv-searchresult-item">
															<div className="d-flex align-items-center">
																<a href="" className="item_text_link">
																	<div className="item-text">
																		Under Graduate courses
																	</div>
																</a>
																<span className="arrow-suggest" />
															</div>
														</li>
														<li className="pkv-searchresult-item">
															<div className="d-flex align-items-center">
																<a href="" className="item_text_link">
																	<div className="item-text">
																		Digital Marketing
																	</div>
																</a>
																<span className="arrow-suggest" />
															</div>
														</li>
														<li className="pkv-searchresult-item">
															<div className="d-flex align-items-center">
																<a href="" className="item_text_link">
																	<div className="item-text">
																		Student teacher ratio
																	</div>
																</a>
																<span className="arrow-suggest" />
															</div>
														</li>
														<li className="pkv-searchresult-item">
															<div className="d-flex align-items-center">
																<a href="" className="item_text_link">
																	<div className="item-text">
																		Current openings
																	</div>
																</a>
																<span className="arrow-suggest" />
															</div>
														</li>
														<li className="pkv-searchresult-item">
															<div className="d-flex align-items-center">
																<a href="" className="item_text_link">
																	<div className="item-text">
																		Masters courses
																	</div>
																</a>
																<span className="arrow-suggest" />
															</div>
														</li>
														<li className="pkv-searchresult-item">
															<div className="d-flex align-items-center">
																<a href="" className="item_text_link">
																	<div className="item-text">Placement</div>
																</a>
																<span className="arrow-suggest" />
															</div>
														</li>
													</ul>
												</div>
											</div>
										</div>
									</section>
								</div>
							</aside>
						</div>
					</div>
					<div id="backDrop" className="search-backdrop pkv-fade" />
				</aside>
			</div>
		</>
	);
}

export default Header;
