import {
	FETCH_ACTIVEUNIT,
	SET_ACTIVEUNIT,
	UPDATE_ACTIVE_SKILL,
} from "./ActiveUnitActionTypes";
import { initialState } from "../initialState";

const CategoryActionReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_ACTIVEUNIT:
			const newState = { ...state };
			newState["activeUnit"] = action.payload;
			return newState;
		case UPDATE_ACTIVE_SKILL:
			const updatedState = { ...state };
			updatedState["activeUnit"] = action.payload;
			return updatedState;
		default:
			return state;
	}
};

export default CategoryActionReducer;
