// /api/JobApplication/JobApplicationWithdraw

import axios from "axios";

export async function withdrawJobApplication(candidateID, jobID) {
	// console.log(`x : ${candidateID} , y: ${jobID}`);
	return axios
		.post("/api/JobApplication/JobApplicationWithdraw", {
			candidate_id: candidateID,
			job_id: jobID,
		})
		.then((res) => res?.data);
}
