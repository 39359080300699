import {
	FETCH_USER_SUCCESS,
	FETCH_USER_JOB_PREFS,
	SET_OTP_VERIFICATION,
	SET_USER_LOGIN,
	SET_USER_LOGOUT,
	SET_USER_PROFILE_PHOTO,
	SET_USER_ID,
	SET_IS_REGISTERING,
} from "./UserActionTypes";
import { initialState } from "../initialState";
import rootReducer from "./../rootReducer";

const UserActionReducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_USER_SUCCESS:
			return {
				...state,
				user: {
					...state.user,
					userDetails: action.payload,
				},
			};
		case FETCH_USER_JOB_PREFS:
			return {
				...state,
				UserPrefs: action.payload,
			};

		case SET_USER_ID:
			return {
				...state,
				user: { ...state.user, userID: action.payload },
			};
		case SET_OTP_VERIFICATION:
			return {
				...state,
				user: { ...state.user, otpVerified: true },
			};
		case SET_IS_REGISTERING:
			return {
				...state,
				user: { ...state.user, isRegistering: action.payload },
			};
		case SET_USER_LOGIN:
			return {
				...state,
				isLoggedIn: true,
			};
		case SET_USER_LOGOUT:
			state = [];
			sessionStorage.clear();
			localStorage.clear();
			return rootReducer(undefined, "SET_USER_LOGOUT");
		default:
			return state;
	}
};

export default UserActionReducer;
