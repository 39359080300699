import axios from "axios";
import { useLocation, useParams } from "react-router-dom";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import $ from "jquery";
import "./CurrentOpenings.css";
import Bookmarked from "./../assets/images/career/bookmarked.svg";
import imagepath from "./../assets/images/career/bookmark.svg";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import "./JobDetails.css";
import { ToastContainer, toast } from "react-toastify";
import Offline from "react-offline"

function JobDetail() {
	// const location = useLocation();
	const params = useParams();
	const location = useLocation();
	// var Jobid = parseInt(params.id);
	var Jobid = parseInt(atob(params.id));
	// console.log(location.state.jobid);
	// console.log("Job id value", Jobid);
	var categoryId = parseInt(atob(params.cid));
	$(".searchAbtJI").on("click", function () {
		$("body").css({ overflow: "hidden" });
		setTimeout(function () {
			$("input#topnav-search").focus();
		}, 100);
		setTimeout(function () {
			$(".pkv-searchview").addClass("slidedown");
		}, 500);
		setTimeout(function () {
			$(".pkv-searchview .search-backdrop.pkv-fade").addClass("show visible");
		}, 100);
		setTimeout(function () {
			$(".pkv-searchview .animate_search_result").addClass("animate");
			$(".pkv-searchview .animate_search_result").css({
				"box-sizing": "border-box",
				display: "block",
			});
		}, 700);
		setTimeout(function () {
			$(".pkv-searchview .pkv-searchresult-container").addClass("slidedown");
		}, 700);
	});

	$(".closeSearch, .search-backdrop").on("click", function () {
		$("body").css({ overflow: "auto" });
		setTimeout(function () {
			$(".pkv-searchview .pkv-searchresult-container").removeClass("slidedown");
		}, 100);
		setTimeout(function () {
			$("input#topnav-search").focus();
		}, 900);
		setTimeout(function () {
			$(".pkv-searchview").removeClass("slidedown");
		}, 100);
		setTimeout(function () {
			$(".pkv-searchview .search-backdrop.pkv-fade").removeClass(
				"show visible"
			);
		}, 500);
		setTimeout(function () {
			$(".pkv-searchview .animate_search_result").removeClass("animate");
			$(".pkv-searchview .animate_search_result").css({
				"box-sizing": "border-box",
				display: "none",
			});
		}, 100);
	});

	const [data, setdata] = useState([]);
	const [copied, setCopied] = useState(false);
	const [similarjobs, setsimilarjobs] = useState([]);
	const [comingid, setcomingid] = useState(0);
	const userCategoryId = localStorage.getItem("userCategoryId");
	const BackUrlPath = localStorage.getItem("currentOpeningPath");
	// const [imagepath, setimagepath] = useState(
	// 	"assets/images/career/bookmark.svg"
	// );
	const isAuthenticated = useSelector((state) => state.user);
	const [category_desc, setcategory_desc] = useState("");
	const [copyimage, setcopyimage] = useState(
		"assets/images/career/copy-basicaction-functional-outline.svg"
	);
	const [refresh, setrefresh] = useState(false);
	const navigate = useNavigate();
	const Candidate_id = useSelector(
		(state) => state.user.user.userDetails?.candidate_id
	);

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	}, []);

	useEffect(() => {
		axios
			.get("api/JobDetails/JobDetail", {
				params: {
					// candidate_id: Candidate_id,
					job_id: Jobid,
					page_no: 0,
				},
			})
			.then((response) => {
				const contentdata = response.data;

				setdata(contentdata.data);
				// GetSimilarjobs(Jobid);
				// setcomingid(Jobid);
				//  Setapiloading(false);
				//console.log("Cotent_data", contentdata);
			})
			.catch((error) => {
				//  dispatch(fetchContentFailure(error.message));
				//console.log("error axios", error.message);
			});
		// console.log("refrshg caused");
	}, [refresh]);

	//console.log("Candiate id ", Candidate_id);

	const ApplyNow = (
		comingid,
		is_applied,
		is_bookmarked,
		job_title,
		category_id,
		isOffline
	) => {
		if(isOffline){
			toast.error(
				"You are currently offline. Please check your internet connection.",
				{
					position: toast.POSITION.TOP_CENTER,
				}
			);
		}
		setcategory_desc(job_title);
		localStorage.setItem("__jbt_crx__", JSON.stringify(job_title));
		if (isAuthenticated["isLoggedIn"]) {
			//console.log("inside apply", comingid);

			var parameter = {
				candidate_id: Number(Candidate_id),
				job_id: comingid,
				status_id: "1",
				modified_by: String(Candidate_id),
				is_bookmarked: is_bookmarked,
				is_applied: is_applied,
				remarks: "",
				feedback: "null",
			};
			axios
				.post("api/JobDetails/JobApplyDetails", parameter)
				.then((response) => {
					const contentdata = response.data.data;
					localStorage.setItem("job_trans_id", contentdata?.job_trans_id);
					localStorage.setItem("category_id", category_id);
					localStorage.setItem("stageID", 1);
					localStorage.setItem("index", 0);
					// console.log("Applied value", contentdata.data);
					// if (contentdata.userMsg == "Already applied") {
					// 	const contentdata = response.data;
					// 	console.log("ASDFDSA ");
					// 	alert(contentdata.userMsg);
					// } else {
					setTimeout(() => {
						navigate(`/JobApplication/${btoa(comingid)}/${btoa(categoryId)}`, {
							replace: false,
							state: {
								user_applied: false,
							},
						});
						// window.location.reload();
					}, 300);
				})

				.catch((error) => {
					//console.log("error axios", error.message);
				});
		} else {
			localStorage.setItem("jobid", comingid);
			localStorage.setItem("isRedirected", true);
			navigate("../login", { replace: false });
		}
	};

	// const BookmarkApply = (comingid, is_applied, is_bookmarked) =>
	// {
	// 	if (isAuthenticated["isLoggedIn"]) {
	// 		// setimagepath(
	// 		// 	"assets/images/career/bookmarked.svg"
	// 		// );
	// 		var parameter = {
	// 			candidate_id: Number(Candidate_id),
	// 			job_id: comingid,
	// 			status_id: "1",
	// 			modified_by:Candidate_id,
	// 			is_bookmarked: is_bookmarked,
	// 			is_applied: is_applied,
	// 			remarks: "",
	// 			feedback: "null",
	// 		};

	// 		axios
	// 			.post("api/JobDetails/JobApplyDetails", parameter)
	// 			.then((response) => {
	// 				const contentdata = response.data;
	// 				if (contentdata.statusCode)
	// 				{
	// 					setrefresh(!refresh);
	// 					// window.location.reload();
	// 				}
	// 			})
	// 			.catch((error) => {
	// 				console.log("error axios", error.message);
	// 			});
	// 	} else {
	// 		navigate("../Login", { replace: false });
	// 	}
	// };

	function copy() {
		setcopyimage("assets/images/career/copy-basicaction-functional-filled.svg");
		const el = document.createElement("input");
		el.value = window.location.href;
		document.body.appendChild(el);
		el.select();
		// console.log("Copied url",e1)
		document.execCommand("copy");
		document.body.removeChild(el);
		setCopied(true);
	}

	const GetSimilarjobs = (Jobid) => {
		axios
			.get("api/JobDetails/GetSimilarJob", {
				params: {
					jobid: Jobid,
				},
			})
			.then((response) => {
				const contentdata = response.data;
				setsimilarjobs(contentdata.data);

				//console.log("Cotent_data AAAA", contentdata);
			})
			.catch((error) => {
				//  dispatch(fetchContentFailure(error.message));
				//console.log("error axios", error.message);
			});
	};

	const Jobssimilar = (JobId, CategoryId) => {
		navigate(`/JobDetails/${btoa(JobId)}/${btoa(CategoryId)}`, {
			replace: false,
		});
		setTimeout(() => {
			window.location.reload();
		}, 100);
		axios
			.get("api/JobDetails/JobDetail", {
				params: {
					job_id: Jobid,
					page_no: 0,
				},
			})
			.then((response) => {
				const contentdata = response.data;

				setdata(contentdata.data);
				//  GetSimilarjobs(Jobid);
				//  Setapiloading(false);
				//console.log("Cotent_data", contentdata);
			})
			.catch((error) => {
				//  dispatch(fetchContentFailure(error.message));
				//console.log("error axios", error.message);
			});
	};

	const Msg = function () {
		return data?.length !== 0 ? (
			<p className="text-center display-6 fs-5 fw-100 mt-5">Loading ...</p>
		) : (
			<p className="text-center display-3 fs-5 fw-100 mt-5">No Job found</p>
		);
	};

	return (
		<>
			<div className="main-container full_width">
				<section className="section-common-space overflow-hidden pt-0">
					<div className="container">
						<div className="row d-block ">
							<div className="col-lg-12">
								{/*breadcrumb*/}
								<nav aria-label="breadcrumb">
									<ol className="breadcrumb">
										<li className="breadcrumb-item">
											<a href="/">Home</a>
										</li>
										{isAuthenticated["isLoggedIn"] && (
											<li className="breadcrumb-item">
												<a href={`/current-openings/${BackUrlPath}`}>
													Current Openings
												</a>
											</li>
										)}
										{!isAuthenticated["isLoggedIn"] && (
											<li className="breadcrumb-item">
												<a href={`/current-openings/${BackUrlPath}`}>
													Current Openings
												</a>
											</li>
										)}
										<li className="breadcrumb-item active" aria-current="page">
											Job Details
										</li>
									</ol>
								</nav>
								{/*breadcrumb end*/}
							</div>
						</div>
						<div className="row">
							{data?.length == 0 ? (
								<div
									style={{ height: "40vh", width: "100vw" }}
									className="d-flex justify-content-center"
								>
									{Msg}
									{
										<div className="d-flex justify-content-center align-items-center w-100">
											<Spinner
												animation="grow"
												variant="danger"
												className="mt-5 "
											/>
											<Spinner
												animation="grow"
												variant="danger"
												className="mt-5 "
											/>
											<Spinner
												animation="grow"
												variant="danger"
												className="mt-5 "
											/>
										</div>
									}
								</div>
							) : (
								data?.map((jobdata) => {
									return (
										<div className="col-12">
											<Container className="container1 p-0">
												<Row
													className="ml-1 mt-1 d-flex align-items-start"
													style={{ width: "1240px" }}
												>
													<Col className="h2-02 font-bold mb-2 p-0 col-lg-8 col-12">
														{jobdata.job_title}
													</Col>
													<div className="align-items-center justify-lg-content-center col-lg-4 col-md-12 col-12 d-flex p-0">
														<div className="mt-2 mb-2 p-0 ml-0 mr-3">
															{!!(
																jobdata?.status_id == 1 ||
																jobdata?.status_id == 0
															) && (
																<Offline>
																	{({ isOffline, isOnline }) => {
																		return <a
																		className={
																			jobdata?.status_id == 1
																				? " btn btn-primary mw-100 "
																				: "arrow-white btn btn-primary center-v mw-100"
																		}
																		style={{ minWidth: "120px" }}
																		onClick={() => {
																			ApplyNow(
																				Jobid,
																				true,
																				false,
																				jobdata.job_title,
																				jobdata.category_id,
																				isOffline
																			);
																		}}
																	>
																		{jobdata?.status_id === 1
																			? "Drafted"
																			: "Apply Now"}
																	</a>;
																		}}
																		</Offline>
																	
																)}

															{jobdata?.is_applied &&
																jobdata?.status_id !== 1 && (
																	<a
																		className=" btn btn-success text-white "
																		style={{ width: "120px" }}
																	>
																		Applied
																	</a>
																)}
														</div>
														<div
															className="copy d-flex p-0 mt-2 mb-2 "
															onClick={copy}
														>
															<div className="mr-1">
																<img src={copyimage} alt="share" />
															</div>
															<div className="primary-color">
																{" "}
																{!copied ? "Copy link" : "Copied!"}
															</div>
														</div>
													</div>
												</Row>
											</Container>
											<Container className="container1 p-0">
												<Col className="body-01 font-bold  my-2 p-0 col-8 ml-1">
													Job ID: {jobdata.job_id}
												</Col>
											</Container>
											<Container className="p-0 mb-0 mb-md-2">
												<Row className="row mt-2 job-categories-row">
													<Col className=" mr-5 mb-2">
														<div className="d-flex">
															<div className="mr-3">
																<img
																	src="assets/images/career/city-maps-places.svg"
																	alt="maps"
																/>
															</div>
															<div className="body-01">
																Department
																<div className="body-01 font-bold">
																	{jobdata?.sub_category_desc}
																</div>
															</div>
														</div>
													</Col>
													<Col className="mr-5 mb-2">
														<div className="d-flex">
															<div className="mr-3">
																<img
																	src="assets/images/career/time-timeline.svg"
																	alt="time"
																/>
															</div>
															<div className="body-01">
																Type
																<div className="body-01 font-bold">
																	{jobdata?.duration}
																</div>
															</div>
														</div>
													</Col>
													<Col className="mr-5">
														<div className="d-flex">
															<div className="mr-3">
																<img
																	src="assets/images/career/calendar.svg"
																	alt="calendar"
																/>
															</div>
															<div className="body-01">
																Posted on
																<div className="body-01 font-bold">
																	{" "}
																	{moment(jobdata.posted_on).format(
																		"Do MMM YYYY"
																	)}
																</div>
															</div>
														</div>
													</Col>
													<Col className="mb-2">
														<div className="d-flex">
															<div className="mr-3">
																<img
																	src="assets/images/career/location-maps-places.svg"
																	alt="maps"
																/>
															</div>
															<div className="body-01">
																Location
																<div className="body-01 font-bold">
																	{jobdata.location}
																</div>
															</div>
														</div>
													</Col>
												</Row>
											</Container>
										</div>
									);
								})
							)}
						</div>{" "}
						{/* / .row */}
					</div>{" "}
					{/* / .container */}
				</section>

				<section className="section-common-space overflow-hidden mt-0 mt-md-5 pt-5">
					<div className="container">
						<div className="row">
							<div className="col-12">
								<div className="row align-items-center">
									{/* <div className="col-lg-6 col-5">
											<a
												className="arrow-white btn btn-primary center-v"
												onClick={() => {ApplyNow(Jobid, true,false)}}
											>
												Apply Now
											</a>
										</div> */}
									<div className="col-lg-6 col-7 pl-0">
										<div className="d-flex ">
											{/* <div
													className="d-flex align-items-center mr-4"
													onClick={() => ApplyNow(Jobid, false, true)}
												>
													<div className="mr-1 active">
														<img
															src= {imagepath}
															alt="bookmark"
														/>
													</div>
													<div className="primary-color">Save</div>
												</div> */}
											{/* <div
													className="d-flex align-items-center"
													onClick={copy}
												>
													<div className="mr-1">
														<img
															src="assets/images/career/share.svg"
															alt="share"
														/>
													</div>
													<div className="primary-color">
														{" "}
														{!copied ? "Copy link" : "Copied!"}
													</div>
												</div> */}
										</div>
									</div>
								</div>
							</div>
						</div>{" "}
						{/* / .row */}
					</div>{" "}
					{/* / .container */}
				</section>
				{/* horizontal tabs */}
				<section className="section-common-space bg-white mt-sm-5 mt-md-0">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								{data?.map((jobdata) => {
									//console.log("jobdata", jobdata);
									return (
										<div className="job-description">
											<div className="right-column w-100">
												<p className="paragraph1 mt-0 mt-md-5 text-wrap w-80 box font-family">
													{/* <h5 className="text custom">Job Summary</h5>
													{jobdata.job_summary} */}
												</p>
												<br></br>
												<div className="faq">
													<div className="accordion" id="acccordionTab-1">
														<div className="card font-family">
															<div className="card-header " id="heading1-1">
																<a
																	href="/"
																	data-toggle="collapse"
																	data-target="#collapse1-1"
																	aria-expanded="true"
																	aria-controls="collapse1-1"
																>
																	Job Summary
																</a>
															</div>
															<div
																id="collapse1-1"
																className="collapse show"
																aria-labelledby="heading1-1"
																data-parent="#acccordionTab-1"
															>
																<div className="card-body">
																	<p className="paragraph1">
																		{jobdata.job_summary}
																	</p>
																</div>
															</div>
														</div>
														{
															<div className="card font-family">
																<div className="card-header" id="heading1-8">
																	<a
																		href="/"
																		className="collapsed"
																		data-toggle="collapse"
																		data-target="#collapse1-8"
																		aria-expanded="false"
																		aria-controls="collapse1-8"
																	>
																		Job Descriptions
																	</a>
																</div>
																<div
																	id="collapse1-8"
																	class="collapse"
																	aria-labelledby="heading1-8"
																	data-parent="#acccordionTab-8"
																>
																	<div class="card-body">
																		<p className="paragraph1">
																			{jobdata.job_desc
																				.split("Requirements:")[0]
																				.replace("Key Responsibilities:", "")}
																		</p>
																	</div>
																</div>
																<div
																	id="collapse1-8"
																	className="collapse"
																	aria-labelledby="heading1-8"
																	data-parent="#acccordionTab-1"
																>
																	<div className="card-body"></div>
																</div>
															</div>
														}
														{!!jobdata.functional_area && (
															<div className="card font-family">
																<div className="card-header" id="heading1-2">
																	<a
																		href="/"
																		className="collapsed"
																		data-toggle="collapse"
																		data-target="#collapse1-2"
																		aria-expanded="false"
																		aria-controls="collapse1-2"
																	>
																		Qualifications
																	</a>
																</div>
																<div
																	id="collapse1-2"
																	class="collapse"
																	aria-labelledby="heading1-2"
																	data-parent="#acccordionTab-1"
																>
																	<div class="card-body">
																		<p className="paragraph1">
																			{jobdata?.functional_area}
																		</p>
																	</div>
																</div>
																<div
																	id="collapse1-2"
																	className="collapse"
																	aria-labelledby="heading1-2"
																	data-parent="#acccordionTab-1"
																>
																	<div className="card-body"></div>
																</div>
															</div>
														)}
														{!!(jobdata.minExp || jobdata.maxExp) && (
															<div className="card font-family">
																<div className="card-header" id="heading1-3">
																	<a
																		href="/"
																		className="collapsed"
																		data-toggle="collapse"
																		data-target="#collapse1-3"
																		aria-expanded="false"
																		aria-controls="collapse1-3"
																	>
																		Work Experience
																	</a>
																</div>
																<div
																	id="collapse1-3"
																	class="collapse"
																	aria-labelledby="heading1-3"
																	data-parent="#acccordionTab-1"
																>
																	<div class="card-body">
																		<p className="paragraph1">
																			{jobdata.minExp +
																				"-" +
																				jobdata.maxExp +
																				" years"}
																		</p>
																	</div>
																</div>
																<div
																	id="collapse1-3"
																	className="collapse"
																	aria-labelledby="heading1-3"
																	data-parent="#acccordionTab-1"
																>
																	<div className="card-body"></div>
																</div>
															</div>
														)}

														{
															<div className="card font-family">
																<div className="card-header" id="heading1-6">
																	<a
																		href="/"
																		className="collapsed"
																		data-toggle="collapse"
																		data-target="#collapse1-4"
																		aria-expanded="false"
																		aria-controls="collapse1-4"
																	>
																		CTC
																	</a>
																</div>
																<div
																	id="collapse1-4"
																	className="collapse"
																	aria-labelledby="heading1-6"
																	data-parent="#acccordionTab-1"
																>
																	<div className="card-body">
																		<p className="paragraph1">
																			<p className="paragraph1">
																				{"₹ " +
																					jobdata.minCTC +
																					"-" +
																					jobdata.maxCTC}
																			</p>
																		</p>
																	</div>
																</div>
															</div>
														}

														{!!jobdata.haring_process && (
															<div className="card font-family">
																<div className="card-header" id="heading1-4">
																	<a
																		href="/"
																		className="collapsed"
																		data-toggle="collapse"
																		data-target="#collapse1-4"
																		aria-expanded="false"
																		aria-controls="collapse1-4"
																	>
																		Hiring Process
																	</a>
																</div>
																<div
																	id="collapse1-4"
																	className="collapse"
																	aria-labelledby="heading1-4"
																	data-parent="#acccordionTab-1"
																>
																	<div className="card-body">
																		<p className="paragraph1">
																			{jobdata.haring_process}
																		</p>
																	</div>
																</div>
															</div>
														)}
														{!!jobdata.industry && (
															<div className="card font-family">
																<div className="card-header" id="heading1-7">
																	<a
																		href="/"
																		className="collapsed"
																		data-toggle="collapse"
																		data-target="#collapse1-7"
																		aria-expanded="false"
																		aria-controls="collapse1-7"
																	>
																		Industry
																	</a>
																</div>
																<div
																	id="collapse1-7"
																	className="collapse"
																	aria-labelledby="heading1-7"
																	data-parent="#acccordionTab-1"
																>
																	<div className="card-body">
																		<p className="paragraph1">
																			{jobdata.industry}
																		</p>
																	</div>
																</div>
															</div>
														)}
														{!!jobdata.required_documents && (
															<div className="card font-family">
																<div className="card-header" id="heading1-5">
																	<a
																		href="/"
																		className="collapsed"
																		data-toggle="collapse"
																		data-target="#collapse1-5"
																		aria-expanded="false"
																		aria-controls="collapse1-4"
																	>
																		Required Documents
																	</a>
																</div>

																<div
																	id="collapse1-5"
																	className="collapse"
																	aria-labelledby="heading1-5"
																	data-parent="#acccordionTab-1"
																>
																	<div className="card-body"></div>
																	<div
																		id="collapse1-5"
																		class="collapse"
																		aria-labelledby="heading1-5"
																		data-parent="#acccordionTab-1"
																	>
																		<div class="card-body">
																			<p className="paragraph1">
																				{jobdata.required_documents}
																			</p>
																		</div>
																	</div>
																</div>
															</div>
														)}
													</div>
												</div>
											</div>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</section>
				<section className="section-common-space overflow-hidden">
					<div className="container">
						<div className="row">
							<div className="col-12">
								{/* <div className="row align-items-center">
										<div className="col-lg-6 col-5">
											<a
												className="arrow-white btn btn-primary center-v"
												onClick={() => ApplyNow(Jobid, true, false)}
											>
												Apply Now
											</a>
										</div> */}
								<div className="col-lg-6 col-7 pl-0">
									<div className="d-flex ">
										{/* <div
													className="d-flex align-items-center mr-4"
													onClick={() => ApplyNow(Jobid, false, true)}
												>
													<div className="mr-1">
														<img
															src={imagepath}
															alt="bookmark"
														/>
													</div>
													<div className="primary-color">Save</div>
												</div> */}
										{/* <div
													className="d-flex align-items-center"
													onClick={copy}
												>
													<div className="mr-1">
														<img
															src="assets/images/career/share.svg"
															alt="share"
														/>
													</div>
													<div className="primary-color">
														{!copied ? "Copy link" : "Copied!"}
													</div>
												</div>
											</div> */}
									</div>
								</div>
							</div>
						</div>{" "}
						{/* / .row */}
					</div>{" "}
					{/* / .container */}
				</section>

				{/* <SecFooter /> */}
			</div>
			<ToastContainer autoClose={10000} theme="colored" />
		</>
	);
}

export default JobDetail;
