import React, { useRef } from "react";
import { useField } from "formik";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import checkAllowedFileType from "../Common/Formik/checkAllowedFileType";
import { maxFileSize,maxFileSize10Mb } from "../Common/Constants/generalConstant";

const Attachment = ({ label, ...props }) => {
	// useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
	// which we can spread on <input>. We can use field meta to show an error
	// message if the field is invalid and it has been touched (i.e. visited)
	const [field, meta, helpers] = useField(props);
	const [multiplevalue, setmultiplevalue] = useState(false);
	const { setValue } = helpers;
	const resetErrors = () => {
		setTimeout(() => props?.setErrors({}), 3000);
	};
	const candidate = useSelector((state) => state.user.user.userDetails);
	var candidatename = candidate.first_name + candidate.last_name;
	var value = field.value;
	const fileRef = useRef(null)
	// console.log(field, "field")
	if (value !== "" && field.name.includes("AttachCoverLetter_347")) {
		var newvalue = value.split("_");
		props.setfileNameCover(newvalue[2]);
	}
	if (value !== "" && field.name.includes("AttachResume_348")) {
		var newvalue = value.split("_");
		props.setfilenameresume(newvalue[2]);
	}
	if (value !== "" && field.name.includes("UploadTeachingStatement_395")) {
		var newvalue = value.split("_");
		props.setTeachingStatement(newvalue[2]);
	}
	if (value !== "" && field.name.includes("UploadResearchStatement_397")) {
		var newvalue = value.split("_");
		props.setResearchStatement(newvalue[2]);
	}
	if (value !== "" && field.name.includes("ResearchPapers_398")) {
		var newvalue = value.split("_");
		props.setResearchPapers(newvalue[2]);
	}
	if (value !== "" && field.name.includes("AdditionalAttachment_399")) {
		var newvalue = value.split("_");
		props.setAdditionalAttachment(newvalue[2]);
	}
	if (value !== "" && field.name.includes("Attachyourtop3publications_349")) {
		props.settop3publication(value);
	}

	if (
		field.name.includes("AttachCoverLetter_347") ||
		field.name.includes("AttachResume_348") ||
		field.name.includes("UploadTeachingStatement_395") ||
		field.name.includes("UploadResearchStatement_397") ||
		field.name.includes("ResearchPapers_398") ||
		field.name.includes("AdditionalAttachment_399")

	) {
		return (
			<>
				<input
					className="form-control"
					{...field}
					{...props}
					value={""}
					// multiple
                    ref={fileRef}
					style={{ minWidth: "164px" }}
					onChange={(e) => {
						if (!checkAllowedFileType(e.currentTarget.files)) {
							props?.setErrors({
								field: "Invalid file type selected",
							});
							toast.error(
								"Please upload only pdf(.pdf),word(.doc,.docx) files.",
								{
									position: toast.POSITION.TOP_CENTER,
								}
							);
							return;
						}
						if (field.name.includes("AttachCoverLetter_347")) {
							if(e.currentTarget.files[0].size <= maxFileSize){
								//setValue(
								//	candidatename +
								//	"_" +
								//	localStorage.getItem("jobid") +
								//	"_" +
								//	e.currentTarget.files[0]?.name
								//);
								props.FileUpdateCover(e.currentTarget.files[0],field?.name);
								props.setfileNameCover(e.currentTarget.files[0]?.name);
							} else {
								props.setfileNameCover("Please Upload Cover Letter");
								toast.error(
									"Please upload only pdf(.pdf),word(.doc,.docx) and file size limit must be 5 MB.",
									{
										position: toast.POSITION.TOP_CENTER,
									}
								);
								return
							}

						}
						if (field.name.includes("AttachResume_348")) {
							if(e.currentTarget.files[0].size <= maxFileSize){
							//setValue(
							//	candidatename +
							//	"_" +
							//	localStorage.getItem("jobid") +
							//	"_" +
							//	e.currentTarget.files[0]?.name
							//);
							props.FileUpdateResume(e.currentTarget.files[0],field?.name);
							props.setfilenameresume(e.currentTarget.files[0]?.name);
						} else {
							props.setfilenameresume("Please Upload Resume");
							toast.error(
								"Please upload only pdf(.pdf),word(.doc,.docx) and file size limit must be 5 MB.",
								{
									position: toast.POSITION.TOP_CENTER,
								}
							);
							return
						}
						}
						if (field.name.includes("UploadTeachingStatement_395")) {
							if(e.currentTarget.files[0].size <= maxFileSize){
							//setValue(
							//	candidatename +
							//	"_" +
							//	localStorage.getItem("jobid") +
							//	"_" +
							//	e.currentTarget.files[0]?.name
							//);
							props.UploadDocument(e.currentTarget.files[0], "TeachingStatement",field?.name);
							props.setTeachingStatement(e.currentTarget.files[0]?.name);
						} else {
							props.setTeachingStatement("Please Upload Teaching Statement");
							toast.error(
								"Please upload only pdf(.pdf),word(.doc,.docx) and file size limit must be 5 MB.",
								{
									position: toast.POSITION.TOP_CENTER,
								}
							);
							return
						}
						}
						if (field.name.includes("UploadResearchStatement_397")) {
							if(e.currentTarget.files[0].size <= maxFileSize){
							//setValue(
							//	candidatename +
							//	"_" +
							//	localStorage.getItem("jobid") +
							//	"_" +
							//	e.currentTarget.files[0]?.name
							//);
							props.UploadDocument(e.currentTarget.files[0], "ResearchStatement",field?.name);
							props.setResearchStatement(e.currentTarget.files[0]?.name);
						} else {
							props.setResearchStatement("Please Upload Research Statement");
							toast.error(
								"Please upload only pdf(.pdf),word(.doc,.docx) and file size limit must be 5 MB.",
								{
									position: toast.POSITION.TOP_CENTER,
								}
							);
							return
						}
						}
						if (field.name.includes("ResearchPapers_398")) {
							if(e.currentTarget.files[0].size <= maxFileSize10Mb){
							//setValue(
							//	candidatename +
							//	"_" +
							//	localStorage.getItem("jobid") +
							//	"_" +
							//	e.currentTarget.files[0]?.name
							//);
							props.UploadDocument(e.currentTarget.files[0], "ResearchPapers",field?.name);
							props.setResearchPapers(e.currentTarget.files[0]?.name);
						} else {
							props.setResearchPapers("Please Upload Research Papers");
							toast.error(
								"Please upload only pdf(.pdf),word(.doc,.docx) and file size limit must be 5 MB.",
								{
									position: toast.POSITION.TOP_CENTER,
								}
							);
							return
						}
						}
						if (field.name.includes("AdditionalAttachment_399")) {
							if(e.currentTarget.files[0].size <= maxFileSize){
							//setValue(
							//	candidatename +
							//	"_" +
							//	localStorage.getItem("jobid") +
							//	"_" +
							//	e.currentTarget.files[0]?.name
							//);
							props.UploadDocument(e.currentTarget.files[0], "AdditionalAttachment",field?.name);
							props.setAdditionalAttachment(e.currentTarget.files[0]?.name);
						} else {
							props.setAdditionalAttachment("Please Upload Additional Attachments if any");
							toast.error(
								"Please upload only pdf(.pdf),word(.doc,.docx) and file size limit must be 5 MB.",
								{
									position: toast.POSITION.TOP_CENTER,
								}
							);
							return
						}
						}
					}}
				/>
				<label className="custom-file-label" htmlFor={props.id || props.name} onClick={()=> {fileRef.current.click()}}>
					{label}
				</label>
				{meta.touched && meta.error ? (
					<>
						<small
							className="massage error d-block"
							style={{ marginTop: "40px" }}
						>
							{meta.error}
						</small>
						{/* <>{resetErrors(props?.setErrors)}</> */}
					</>
				) : null}
			</>
		);
	}
	if (field.name.includes("Attachyourtop3publications_349")) {
		return (
			<>
				<input
					className="form-control"
					{...field}
					{...props}
					value={undefined}
					multiple
					style={{ minWidth: "164px" }}
					onChange={(e) => {
						if (field.name.includes("Attachyourtop3publications_349")) {
							if (!checkAllowedFileType(e.currentTarget.files)) {
								props?.setErrors({
									field: "Invalid file type selected",
								});
								toast.error(
									"Please upload only pdf(.pdf),word(.doc,.docx) files",
									{
										position: toast.POSITION.TOP_CENTER,
									}
								);
								return;
							}
							setmultiplevalue(true);
							let allFilesName = [];
							Array.from(e.currentTarget.files).forEach((file) => {
								allFilesName.push(file.name);
							});
							setValue(allFilesName.join(", "));
							props.toppublication(e.currentTarget.files);
							props.settop3publication(allFilesName.join(", "));
						}
					}}
				/>

				<label className="custom-file-label" htmlFor={props.id || props.name}>
					{label}
				</label>
				{meta.touched && meta.error ? (
					<small
						className="massage error d-block"
						style={{ marginTop: "40px" }}
					>
						{meta.error}
					</small>
				) : null}
			</>
		);
	}
};

export default Attachment;
