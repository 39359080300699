export const FETCH_USER_REQUEST = "FETCH_USER_REQUEST";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_JOB_PREFS = "FETCH_USER_JOB_PREFS";
export const FETCH_USER_FAILURE = "FETCH_USER_FAILURE";
export const SET_OTP_VERIFICATION = "SET_OTP_VERIFICATION";
export const SET_IS_REGISTERING = "SET_IS_REGISTERING";
export const SET_USER_LOGIN = "SET_USER_LOGIN";
export const SET_USER_ID = "SET_USER_ID";
export const SET_CANDIDATE_ID = "SET_CANDIDATE_ID";
export const SET_USER_LOGOUT = "SET_USER_LOGOUT";
