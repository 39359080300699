import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { makeStyles } from "@mui/styles";
import { useField } from "formik";
import countryISDCodes from "../Common/countryISDCodes";

const useStyles = makeStyles({
	root: {
		"& .MuiOutlinedInput-input": {
			color: "#000",
			fontSize: "1rem",
			fontWeight: 400,
			fontFamily: "JioTypeW04-Medium",
			minWidth:"40px !important" ,
			textOverflow:'initial !important'
		},
	},
});

const MuiCountryCode = ({
	setFieldValue = null,
	setFieldTouched = null,
	...props
}) => {
	const [field, meta] = useField(localStorage.getItem("countryCodeFieldName"));
	const classes = useStyles();

	useEffect(() => {
		if(field?.value == ""){
			setFieldValue(field?.name,countryISDCodes[0])
		}
	}, [])

  return (
	<div className=''>
      <Autocomplete
        id="free-solo-demo"
		disableClearable
		sx={{ '& fieldset': { borderTopRightRadius: 0,borderBottomRightRadius:0 }}}
		size="small"
		value={field?.value}
        options={countryISDCodes.map((v) => v)}
		onChange={( _, value )=>{
			setFieldValue( field.name, value );
				}}
		onBlur={() => {
			setFieldTouched(field.name, true);
				}}
		className={classes.root}
		renderInput={(params) => {
				return (
					<TextField
						{...params}
						placeholder="Select"
						sx={{
							input: {
								"&::placeholder": {
									opacity: 1,
								},
							},
						}}
					/>
					);
				}}
			/>
			{/*{meta.touched && meta.error ? (
				<small className="massage error d-block">{meta.error}</small>
			) : null}*/}
		</div>
	);
};

export default MuiCountryCode;
