import React, { Component } from "react";
import { Route, Navigate, useLocation } from "react-router-dom";

import { useSelector } from "react-redux";

const ProtectedRoute = ({ children }) => {
	const UserData = useSelector((state) => state.user);
	// console.log(`UserOTPVerified ${UserData}`);

	let location = useLocation();
	if (!UserData?.user["otpVerified"]) {
		return <Navigate to="/login" state={{ from: location }} replace={false} />;
	}
	return children;
};
export default ProtectedRoute;
