import React from "react";
import "./Common.css";

function Footer(props) {
	return (
		<div
			className="footer_container "
	
		>
			{/* <section className="section-common-space pb-0 pt-0 sub-footer">
        <div className="neutral-10-bg primary">
          <div className="container">
            <div className="row align-items-center text-center text-lg-left">
              <div className="col-12 col-lg-auto my-2 mr-4 ml-0  ji-logo-frame">
                <div className="d-flex align-items-center flex-column flex-lg-row list_animate">
                  <a href="/">
                    <img
                      src="assets/images/career/ji-work-with-us.svg"
                      alt="Jio institute work with us"
                      width="172px"
                      height="26px"
                    />
                  </a>
                </div>
              </div>
              <div className="col-12 col-lg-auto my-2 mr-4">
                <div className="d-flex align-items-center flex-column flex-lg-row list_animate">
                  <div className="col-12 col-lg-auto p-0">
                    <img src="assets/images/career/location.svg" alt="location" />
                  </div>
                  <div className="col-12 col-lg-auto my-2 mr-4">
                    Location: <br />
                    <a
                      href="contacts.html"
                      aria-label="Ulwe, Navi Mumbai, Maharashtra 410206"
                      className="font-medium primary"
                    >
                      Ulwe, Navi Mumbai, Maharashtra 410206
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-auto my-2 mr-4">
                <div className="d-flex align-items-center flex-column flex-lg-row list_animate">
                  <div className="col-12 col-lg-auto p-0">
                    <img src="assets/images/career/email.svg" alt="email" />
                  </div>
                  <div className="col-12 col-lg-auto my-2">
                    Write to us <br />
                    <span className="font-medium">
                      <a
                        href="mailto:contact@jioinstitute.edu.in"
                        className="primary"
                      >
                        contact@jioinstitute.edu.in
                      </a>
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-auto my-2">
                <div className="d-flex align-items-center flex-column flex-lg-row list_animate">
                  <div className="col-12 col-lg-auto p-0">
                    <img src="assets/images/career/call.svg" alt="call" />
                  </div>
                  <div className="col-12 col-lg-auto my-2">
                    Talk to us <br />
                    <span className="font-medium">9876543210</span>
                  </div>
                </div>
              </div>
            </div>{" "}
 
          </div>{" "}
          
        </div>
      </section> */}
			<section className="bg-primary white career-footer">
				<div className="container py-3">
					<div
						className="row align-items-center text-center text-lg-left caption-01"
						aria-label="Footer"
					>
						<div className="col-12 col-lg-auto my-2 mr-0 pr-0 ml-0 ji-logo-frame">
							<a href="/">
								<img
									src="assets/images/career-header/ji-work-with-us.png"
									alt="Jio institute work with us"
									width="136px"
									height="30px"
								/>
							</a>
						</div>
						{/* <div className="col-12 col-lg-auto my-2 mr-0"> */}
						<div className="col-12 col-lg-auto  my-2 mr-0">
							<div className="d-flex flex-grow-1 align-items-center flex-column flex-lg-row list_animate">
								<div className="col-12 col-lg-auto my-2 mx-0 mx-md-1 mx-lg-2 px-0 ">
									<a
										href="https://www.jioinstitute.edu.in/"
										aria-label="Jio Institute"
										className="text-white"
										target="_blank"
										rel="noopener noreferrer"
									>
										Jio Institute
									</a>
								</div>
								<div className="col-12 col-lg-auto my-2 mx-0 mx-md-1 mx-lg-2 px-0">
									<a
										href="https://www.jioinstitute.edu.in/about"
										aria-label="About Jio Institute"
										className="text-white"
										target="_blank"
										rel="noopener noreferrer"
									>
										About Jio Institute
									</a>
								</div>
								<div className="col-12 col-lg-auto my-2 mx-0 mx-md-1 mx-lg-2 px-0">
									<a
										href="https://www.jioinstitute.edu.in/academics"
										aria-label="Academics"
										className="text-white"
										target="_blank"
										rel="noopener noreferrer"
									>
										Academics
									</a>
								</div>
								<div className="col-12 col-lg-auto my-2 mx-0 mx-md-1 mx-lg-2 px-0">
									<a
										href="https://www.jioinstitute.edu.in/research-overview"
										aria-label="Research"
										className="text-white"
										target="_blank"
										rel="noopener noreferrer"
									>
										Research
									</a>
								</div>
								<div className="col-12 col-lg-auto my-2 mx-0 mx-md-1 mx-lg-2 px-0">
									<a
										href="https://www.jioinstitute.edu.in/contacts"
										aria-label="Contact"
										className="text-white"
										target="_blank"
										rel="noopener noreferrer"
									>
										Contact
									</a>
								</div>
								<div className="col-12 col-lg-auto my-2 mx-0 px-0">
									Write to us{" "}
									<a
										href="mailto:careers@jioinstitute.edu.in"
										aria-label="Contact"
										className="text-white"
										target="_blank"
										rel="noopener noreferrer"
									>
										careers@jioinstitute.edu.in
									</a>
								</div>
							</div>
						</div>
						<div className="col-12 col-lg-auto my-2 ml-auto">
							<div className="d-flex align-items-center flex-column flex-lg-row list_animate">
								<div className="col-12 col-lg-auto my-2  mx-0 mx-md-1 mx-lg-0 ps-sm-1">
									<a
										href="https://www.jioinstitute.edu.in/terms-conditions"
										aria-label="T&C"
										className="white"
										target="_blank"
										rel="noopener noreferrer"
									>
										T&amp;C
									</a>
								</div>
								<div className="col-12 col-lg-auto my-2  mx-0 mx-md-1 mx-lg-0 px-0">
									<a
										href="https://www.jioinstitute.edu.in/privacy-policy"
										aria-label="Privacy Policy"
										className="white"
										target="_blank"
										rel="noopener noreferrer"
									>
										Privacy Policy
									</a>
								</div>
							</div>
						</div>
					</div>{" "}
					{/* / .row */}
				</div>{" "}
				{/* / .container */}
			</section>
		</div>
	);
}

export default Footer;
