import React, { useState } from "react";
import { useField } from "formik";
import MuiCountryCode2 from "./MuiCountryCode2";

const TextInput = ({ setFieldValue = null, setFieldTouched = null, label, ...props }) => {
	const [field, meta] = useField(props);

	if (props.type == "date") {
		return (
			<>

				<input type="date" className="form-control " {...field} {...props} style={{ minWidth: '164px' }} />
				<label className="control-label" htmlFor={props.id || props.name}>
					{label}
				</label>
				{meta.touched && meta.error ? (
					<small className="massage error d-block">{meta.error}</small>
				) : null}
			</>
		);
	}
	else {
		return (
			<>
				<div>
					<label className="control-label" htmlFor={props.id || props.name}>
						{label}
					</label>
					<div className={field.name.includes("MobileNumber_259") ? "d-flex" : ""}>
						{field.name.includes("MobileNumber_259") && <>
							<MuiCountryCode2 setFieldValue={setFieldValue} setFieldTouched={setFieldTouched} />
						</>}
						<input className="form-control" {...field} {...props}
							style={{
								borderTopLeftRadius: field.name.includes("MobileNumber_259") ? "0px" : "0.25rem",
								borderBottomLeftRadius: field.name.includes("MobileNumber_259") ? "0px" : "0.25rem"
							}}
						/>
					</div>
					{meta.touched && meta.error ? (
						<small className="massage error d-block">{meta.error}</small>
					) : null}
				</div>
			</>
		);
	}

};
export default TextInput;
