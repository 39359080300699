import axios from "axios";
import React, {
	Fragment,
	useCallback,
	useEffect,
	useRef,
	useState,
} from "react";
import SecFooter from "../Common/SecFooter";
import jobApp from "../CurrentOpenings/jobApp.css";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import { Spinner } from "react-bootstrap";
import DropDownControl from "../JobApplications/DropDownControl";
import TextInput from "../JobApplications/TextInput";
import Attachment from "../JobApplications/Attachment";
import DropDown from "../JobApplications/DropDown";
import Checkbox from "../JobApplications/Checkbox";
import TextArea from "../JobApplications/TextArea";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Deleteicon from "../assets/images/career/delete-basicaction-functional-outline.svg";
import _ from "lodash";
import { useSelector } from "react-redux";
import MuiSelect from "../JobApplications/MuiSelect";

import { useFormik } from "formik";
import DatePicker from "../JobApplications/DatePicker";
import { maxFileSize, maxFileSize10Mb } from "../Common/Constants/generalConstant";
import MuiCountryCode from "../JobApplications/MuiCountryCode";

function SubStage({
	sub_stage_id,
	stage_id,
	category_id,
	Objx,
	setObjx,
	setFieldValue,
	setErrors,
	setFieldTouched,
	sub_stage_name,
}) {
	let count = useRef(0);
	const [toShowFirstAddBtn, settoShowFirstAddBtn] = useState(true);
	const [SubStageMasterData1, setSubStagedata1] = useState([]);
	const [mainListDB, setMainListDB] = useState([]);
	const [subcount, setsubcount] = useState(0);
	const [dataappend, setdataappend] = useState([]);
	const [removedata, setremovedata] = useState([]);
	const [removeid, setremoveid] = useState(0);
	// const [count, setCount] = useState(0);
	const [mapvalue, setmapvalue] = useState([{ field_id: "" }]);
	const [isUploading, setisUploading] = useState(false);
	const [isUploadingR, setisUploadingR] = useState(false);
	const [fileNameCover, setfileNameCover] = useState(
		"Please Upload Cover Letter"
	);
	const [filenameresume, setfilenameresume] = useState("Please Upload Resume");
	const [top3publication, settop3publication] = useState(
		"Attach your top 3 publication"
	);
	const [teachingStatement, setTeachingStatement] = useState(
		"Please Upload Teaching Statement"
	);
	const [researchStatement, setResearchStatement] = useState(
		"Please Upload Research Statement"
	);
	const [researchPapers, setResearchPapers] = useState(
		"Please Upload Research Papers"
	);
	const [additionalAttachment, setAdditionalAttachment] = useState(
		"Please Upload Additional Attachments if any"
	);
	const [refresh, setrefresh] = useState(false);
	const [aos, setAos] = useState(false);
	const candidate_id = useSelector(
		(state) => state.user.user.userDetails?.candidate_id
	);

	const handleAosChange = (value) => {
        setAos(value)
        localStorage.setItem("aos",value)
    }

	// var Jobid = parseInt(atob(params.id));
	const candidate = useSelector((state) => state.user.user.userDetails);
	var candidatename = candidate.first_name + candidate.last_name;
	useEffect(() => {
		axios
			.get("api/JobApplication/GetjobFieldMasterBySubStageid", {
				params: {
					stage_id: stage_id,
					// candidate_id: candidate_id,
					sub_stage_id: sub_stage_id,
					category_id: category_id,
					trans_hdr_id: Number(localStorage.getItem("job_trans_id")),
				},
			})
			.then((response) => {
				const contentdata = response.data;
				setSubStagedata1(contentdata.data);
				setMainListDB(JSON.parse(JSON.stringify(contentdata.data)));
				setmapvalue(contentdata.data);
				if (sub_stage_id == 2 || sub_stage_id == 6 || sub_stage_id == 7) {
					localStorage.setItem(`subStage_${sub_stage_id}`, JSON.stringify(contentdata.data))
				}
				//  console.log("SubSatgeData", contentdata.data);
			})
			.catch((error) => {
				//console.log("error axios", error.message);
			});
	}, [stage_id, sub_stage_id]);

	{
		// console.log("sunstagedata1", SubStageMasterData1);
	}
	function FileUpdateCover(file,fieldName) {
		// console.log("form data ", file.name);
		if (file.name.split(".").pop() !== "zip") {
			if (file.size <= maxFileSize) {
				setisUploading(true);
				const formData = new FormData();
				formData.append("file", file);
				formData.append("job_id", localStorage.getItem("jobid"));
				formData.append("candidatename", candidatename);
				// console.log(request);
				axios
					.post("/api/Login/CoverFile",formData)
					.then((response) => {
						// console.log("fileUpload response", response.data);

						if (
							response.data.data != null &&
							response.data.statusCode == 200 &&
							response.data.success == true
						) {
							setisUploading(false);
							setFieldValue(fieldName,candidatename +"_" +localStorage.getItem("jobid") +"_" +file.name)
						} else if (
							response.data.data == null &&
							response.data.statusCode == 400 &&
							response.data.success == false
						) {
							setisUploading(false);
							toast.error(
								"Please upload only pdf(.pdf),word(.doc,.docx) and file size limit must be 5 MB.",
								{
									position: toast.POSITION.TOP_CENTER,
								}
							);
							setFieldValue(fieldName,"")
							setfileNameCover("Please Upload Cover Letter")
						} else {
							setisUploading(false);
							toast.error(
								"Something went wrong. Please try after sometime.",
								{
									position: toast.POSITION.TOP_CENTER,
								}
							);
							setFieldValue(fieldName,"")
							setfileNameCover("Please Upload Cover Letter")
							// SetShowalert(true);
						}
					})
					.catch((error) => {
						setisUploading(false);
							toast.error("Something went wrong. Please try again after sometime.", {
								position: toast.POSITION.TOP_CENTER,
							});
							setFieldValue(fieldName,"")
							setfileNameCover("Please Upload Cover Letter")
							return;
					});
			} else {
				setisUploading(false);
				toast.error(
					"Please upload only pdf(.pdf),word(.doc,.docx) and file size limit must be 5 MB.",
					{
						position: toast.POSITION.TOP_CENTER,
					}
				);
				setFieldValue(fieldName,"")
				setfileNameCover("Please Upload Cover Letter")
			}
		} else {
			setisUploading(false);
			toast.error(
				"Please upload only pdf(.pdf),word(.doc,.docx) and file size limit must be 5 MB.",
				{
					position: toast.POSITION.TOP_CENTER,
				}
			);
			setFieldValue(fieldName,"")
			setfileNameCover("Please Upload Cover Letter")
		}
	}
	function FileUpdateResume(file,fieldName) {
		// console.log("form data ", file.name);
		if (file.name.split(".").pop() !== "zip") {
			if (file.size <= maxFileSize) {
				setisUploadingR(true);
				const formData = new FormData();
				formData.append("file", file);
				// formData.append("file", file);
				formData.append("job_id", localStorage.getItem("jobid"));
				formData.append("candidatename", candidatename);
				// console.log(request);
				axios
					.post("/api/Login/ResumeFile",formData)
					.then((response) => {
						// console.log("fileUpload response", response.data);

						if (  //upload success
							response.data.data != null &&
							response.data.statusCode == 200 &&
							response.data.success == true
						) {
							setisUploadingR(false);
							setFieldValue(fieldName,candidatename +"_" +localStorage.getItem("jobid") +"_" +file.name)
												
						} else if (
							response.data.data == null &&
							response.data.statusCode == 400 &&
							response.data.success == false
						) {
							setisUploadingR(false);
							toast.error(
								"Please upload only pdf(.pdf),word(.doc,.docx) and file size limit must be 5 MB.",
								{
									position: toast.POSITION.TOP_CENTER,
								}
							);
							setFieldValue(fieldName,"")
							setfilenameresume("Please Upload Resume")
						} else {
							setisUploadingR(false);
							// SetShowalert(true);
							toast.error(
								"something went wrong.Please try again after sometime.",
								{
									position: toast.POSITION.TOP_CENTER,
								}
							);
							setFieldValue(fieldName,"")
							setfilenameresume("Please Upload Resume")
						}
					})
					.catch((error) => {
						setisUploadingR(false);
							toast.error("Something went wrong. Please try again after sometime", {
								position: toast.POSITION.TOP_CENTER,
							});
							setFieldValue(fieldName,"")
							setfilenameresume("Please Upload Resume")
							return;
					});
			} else {
				setisUploadingR(false);
				toast.error(
					"Please upload only pdf(.pdf),word(.doc,.docx) and file size limit must be 5 MB.",
					{
						position: toast.POSITION.TOP_CENTER,
					}
				);
				setFieldValue(fieldName,"")
				setfilenameresume("Please Upload Resume")
			}
		} else {
			setisUploadingR(false);
			toast.error(
				"Please upload only pdf(.pdf),word(.doc,.docx) and file size limit must be 5 MB.",
				{
					position: toast.POSITION.TOP_CENTER,
				}
			);
			setFieldValue(fieldName,"")
			setfilenameresume("Please Upload Resume")
		}
	}
	// console.log(teachingStatement, "Teachingstate")

	function UploadDocument(file, name, fieldName) {
		// console.log("form data ", file.name);
		if (name == "ResearchPapers") {
			if (file.name.split(".").pop() !== "zip") {
				if (file.size <= maxFileSize10Mb) {
					setisUploadingR(true);
					const formData = new FormData();
					formData.append("file", file);
					// formData.append("file", file);
					formData.append("job_id", localStorage.getItem("jobid"));
					formData.append("candidatename", candidatename);
					// console.log(request);
					axios
						.post(`/api/Login/${name}`, formData)
						.then((response) => {
							// console.log("fileUpload response", response.data);

							if (
								response.data.data != null &&
								response.data.statusCode == 200 &&
								response.data.success == true
							) {
								setisUploadingR(false);
								setFieldValue(fieldName,candidatename +"_" +localStorage.getItem("jobid") +"_" +file.name)
							} else if (
								response.data.data == null &&
								response.data.statusCode == 400 &&
								response.data.success == false
							) {
								setisUploadingR(false);
								toast.error(
									"Please upload only pdf(.pdf),word(.doc,.docx) and file size limit must be 10 MB.",
									{
										position: toast.POSITION.TOP_CENTER,
									}
								);
								setFieldValue(fieldName,"")
								if (fieldName.includes("UploadTeachingStatement_395")){
									setTeachingStatement("Please Upload Teaching Statement")
								}
								else if (fieldName.includes("UploadResearchStatement_397")){
									setResearchStatement("Please Upload Research Statement")
								}
								else if (fieldName.includes("ResearchPapers_398")) {
									setResearchPapers("Please Upload Research Papers")
								}
								else if(fieldName.includes("AdditionalAttachment_399")){
									setAdditionalAttachment("Please Upload Additional Attachments if any")
								}
								
							} else {
								setisUploadingR(false);
								setFieldValue(fieldName,"")
								if (fieldName.includes("UploadTeachingStatement_395")){
									setTeachingStatement("Please Upload Teaching Statement")
								}
								else if (fieldName.includes("UploadResearchStatement_397")){
									setResearchStatement("Please Upload Research Statement")
								}
								else if (fieldName.includes("ResearchPapers_398")) {
									setResearchPapers("Please Upload Research Papers")
								}
								else if(fieldName.includes("AdditionalAttachment_399")){
									setAdditionalAttachment("Please Upload Additional Attachments if any")
								}
								// SetShowalert(true);
							}
						})
						.catch((error) => {
							setisUploadingR(false);
								toast.error("Something went wrong. Please try again after sometime", {
									position: toast.POSITION.TOP_CENTER,
								});
								setFieldValue(fieldName,"")
								if (fieldName.includes("UploadTeachingStatement_395")){
									setTeachingStatement("Please Upload Teaching Statement")
								}
								else if (fieldName.includes("UploadResearchStatement_397")){
									setResearchStatement("Please Upload Research Statement")
								}
								else if (fieldName.includes("ResearchPapers_398")) {
									setResearchPapers("Please Upload Research Papers")
								}
								else if(fieldName.includes("AdditionalAttachment_399")){
									setAdditionalAttachment("Please Upload Additional Attachments if any")
								}
								return;
						});
				} else {
					setisUploadingR(false);
					toast.error(
						"Please upload only pdf(.pdf),word(.doc,.docx) and file size limit must be 10 MB.",
						{
							position: toast.POSITION.TOP_CENTER,
						}
					);
					setFieldValue(fieldName,"")
					if (fieldName.includes("UploadTeachingStatement_395")){
						setTeachingStatement("Please Upload Teaching Statement")
					}
					else if (fieldName.includes("UploadResearchStatement_397")){
						setResearchStatement("Please Upload Research Statement")
					}
					else if (fieldName.includes("ResearchPapers_398")) {
						setResearchPapers("Please Upload Research Papers")
					}
					else if(fieldName.includes("AdditionalAttachment_399")){
						setAdditionalAttachment("Please Upload Additional Attachments if any")
					}
				}
			} else {
				setisUploadingR(false);
				toast.error(
					"Please upload only pdf(.pdf),word(.doc,.docx) and file size limit must be 10 MB.",
					{
						position: toast.POSITION.TOP_CENTER,
					}
				);
				setFieldValue(fieldName,"")
				if (fieldName.includes("UploadTeachingStatement_395")){
					setTeachingStatement("Please Upload Teaching Statement")
				}
				else if (fieldName.includes("UploadResearchStatement_397")){
					setResearchStatement("Please Upload Research Statement")
				}
				else if (fieldName.includes("ResearchPapers_398")) {
					setResearchPapers("Please Upload Research Papers")
				}
				else if(fieldName.includes("AdditionalAttachment_399")){
					setAdditionalAttachment("Please Upload Additional Attachments if any")
				}
			}
		}
		
		if (name != "ResearchPapers") {
			if (file.name.split(".").pop() !== "zip") {
				if (file.size <= maxFileSize) {
					setisUploadingR(true);
					const formData = new FormData();
					formData.append("file", file);
					// formData.append("file", file);
					formData.append("job_id", localStorage.getItem("jobid"));
					formData.append("candidatename", candidatename);
					// console.log(request);
					axios
						.post(`/api/Login/${name}`, formData)
						.then((response) => {
							// console.log("fileUpload response", response.data);

							if (
								response.data.data != null &&
								response.data.statusCode == 200 &&
								response.data.success == true
							) {
								setisUploadingR(false);
								setFieldValue(fieldName,candidatename +"_" +localStorage.getItem("jobid") +"_" +file.name)
							} else if (
								response.data.data == null &&
								response.data.statusCode == 400 &&
								response.data.success == false
							) {
								setisUploadingR(false);
								toast.error(
									"Please upload only pdf(.pdf),word(.doc,.docx) and file size limit must be 5 MB.",
									{
										position: toast.POSITION.TOP_CENTER,
									}
								);
								setFieldValue(fieldName,"")
								if (fieldName.includes("UploadTeachingStatement_395")){
									setTeachingStatement("Please Upload Teaching Statement")
								}
								else if (fieldName.includes("UploadResearchStatement_397")){
									setResearchStatement("Please Upload Research Statement")
								}
								else if (fieldName.includes("ResearchPapers_398")) {
									setResearchPapers("Please Upload Research Papers")
								}
								else if(fieldName.includes("AdditionalAttachment_399")){
									setAdditionalAttachment("Please Upload Additional Attachments if any")
								}
							} else {
								setisUploadingR(false);
								// SetShowalert(true);
								setFieldValue(fieldName,"")
								if (fieldName.includes("UploadTeachingStatement_395")){
									setTeachingStatement("Please Upload Teaching Statement")
								}
								else if (fieldName.includes("UploadResearchStatement_397")){
									setResearchStatement("Please Upload Research Statement")
								}
								else if (fieldName.includes("ResearchPapers_398")) {
									setResearchPapers("Please Upload Research Papers")
								}
								else if(fieldName.includes("AdditionalAttachment_399")){
									setAdditionalAttachment("Please Upload Additional Attachments if any")
								}
							}
						})
						.catch((error) => {
							setisUploadingR(false);
							
								toast.error("Something went wrong. Please try again after sometime", {
									position: toast.POSITION.TOP_CENTER,
								});
								setFieldValue(fieldName,"")
								if (fieldName.includes("UploadTeachingStatement_395")){
									setTeachingStatement("Please Upload Teaching Statement")
								}
								else if (fieldName.includes("UploadResearchStatement_397")){
									setResearchStatement("Please Upload Research Statement")
								}
								else if (fieldName.includes("ResearchPapers_398")) {
									setResearchPapers("Please Upload Research Papers")
								}
								else if(fieldName.includes("AdditionalAttachment_399")){
									setAdditionalAttachment("Please Upload Additional Attachments if any")
								}
								return;
						});
				} else {
					setisUploadingR(false);
					toast.error(
						"Please upload only pdf(.pdf),word(.doc,.docx) and file size limit must be 5 MB.",
						{
							position: toast.POSITION.TOP_CENTER,
						}
					);
					setFieldValue(fieldName,"")
					if (fieldName.includes("UploadTeachingStatement_395")){
						setTeachingStatement("Please Upload Teaching Statement")
					}
					else if (fieldName.includes("UploadResearchStatement_397")){
						setResearchStatement("Please Upload Research Statement")
					}
					else if (fieldName.includes("ResearchPapers_398")) {
						setResearchPapers("Please Upload Research Papers")
					}
					else if(fieldName.includes("AdditionalAttachment_399")){
						setAdditionalAttachment("Please Upload Additional Attachments if any")
					}
				}
			} else {
				setisUploadingR(false);
				toast.error(
					"Please upload only pdf(.pdf),word(.doc,.docx) and file size limit must be 5 MB.",
					{
						position: toast.POSITION.TOP_CENTER,
					}
				);
				setFieldValue(fieldName,"")
				if (fieldName.includes("UploadTeachingStatement_395")){
					setTeachingStatement("Please Upload Teaching Statement")
				}
				else if (fieldName.includes("UploadResearchStatement_397")){
					setResearchStatement("Please Upload Research Statement")
				}
				else if (fieldName.includes("ResearchPapers_398")) {
					setResearchPapers("Please Upload Research Papers")
				}
				else if(fieldName.includes("AdditionalAttachment_399")){
					setAdditionalAttachment("Please Upload Additional Attachments if any")
				}
			}
		}
	}

	function toppublication(file) {
		if (file.length > 3) {
			toast.error("Please upload only three files", {
				position: toast.POSITION.TOP_CENTER,
			});
		} else {
			axios
				.all(
					Array.from(file)?.map((file) => {
						if (file?.name?.split(".")?.pop() !== "zip") {
							if (file?.size <= maxFileSize) {
								setisUploadingR(true);
								const formData = new FormData();
								formData.append("file", file);

								return axios.post("/api/Login/PublicationFile", formData);
							} else {
								setisUploadingR(false);
								toast.error(
									"Please upload only pdf(.pdf),word(.doc,.docx) and file size limit must be 5 MB.",
									{
										position: toast.POSITION.TOP_CENTER,
									}
								);
								return null;
							}
						} else {
							setisUploadingR(false);
							toast.error(
								"Please upload only pdf(.pdf),word(.doc,.docx) and file size limit must be 5 MB.",
								{
									position: toast.POSITION.TOP_CENTER,
								}
							);
							return null;
						}
					})
				)
				.then(
					axios.spread(function (res1, res2, res3) {
						setisUploadingR(false);
						if (res1) {
							if (
								res1.data.data != null &&
								res1.data.statusCode == 200 &&
								res1.data.success == true
							) {
							} else if (
								res1.data.data == null &&
								res1.data.statusCode == 400 &&
								res1.data.success == false
							) {
								toast.error(
									"Please upload only pdf(.pdf),word(.doc,.docx) and file size limit must be 5 MB.",
									{
										position: toast.POSITION.TOP_CENTER,
									}
								);
							}
						}
						if (res2) {
							if (
								res2.data.data != null &&
								res2.data.statusCode == 200 &&
								res2.data.success == true
							) {
							} else if (
								res2.data.data == null &&
								res2.data.statusCode == 400 &&
								res2.data.success == false
							) {
								toast.error(
									"Please upload only pdf(.pdf),word(.doc,.docx) and file size limit must be 5 MB.",
									{
										position: toast.POSITION.TOP_CENTER,
									}
								);
							}
						}
						if (res3) {
							if (
								res3.data.data != null &&
								res3.data.statusCode == 200 &&
								res3.data.success == true
							) {
							} else if (
								res3.data.data == null &&
								res3.data.statusCode == 400 &&
								res3.data.success == false
							) {
								toast.error(
									"Please upload only pdf(.pdf),word(.doc,.docx) and file size limit must be 5 MB.",
									{
										position: toast.POSITION.TOP_CENTER,
									}
								);
							}
						}
					})
				)
				.catch(
					axios.spread(function () {
						setisUploadingR(false);
						toast.error("File upload failed, please try again...", {
							position: toast.POSITION.TOP_CENTER,
						});
					})
				);
		}
	}
	function changeDesc(projects, count) {
		// console.log("Set-count-increament",)
		var tempMainData = JSON.parse(JSON.stringify(projects));
		for (var i in tempMainData) {
			tempMainData[i].field_name = count + "~" + tempMainData[i].field_name;

			tempMainData[i]["Addedid"] = count;
		}

		// let addmoreEle = SubStageMasterData1[SubStageMasterData1.length-1];

		// setSubStagedata1(SubStageMasterData1.filter((ele)=>{
		//     return ele.field_type != "ismore";
		// }))

		setSubStagedata1((oldarr) => [...oldarr, ...tempMainData]);
		return SubStageMasterData1;

		// return tempMainData;
	}

	function AddMoreItem() {
		settoShowFirstAddBtn(false);
		setsubcount(subcount + 1);
		// setCount(count + 1);
		count.current = count.current + 1;
		let lengthOfObj = SubStageMasterData1.length + 1; // set prauriority to next of length
		for (let i = 0; i < mainListDB.length; i++) {
			//item of mainListDB) {
			let item = mainListDB[i];
			item.priority = lengthOfObj;
		}

		var data = changeDesc(
			mainListDB?.filter((ele) => ele.group_id == 0),
			count.current
		);
	}
	const formik = useFormik({
		initialValues: {
			allowed: false,
		},
		// validate,
		onSubmit: (values) => {
			alert(JSON.stringify(values, null, 2));
		},
	});
	const [refresher, setRefresher] = useState(0)
	function removeFormFields(event) {
		count.current = count.current - 1;
		let id = event.target.getAttribute("addedid");
		setremoveid(parseInt(id));
		setremovedata(
			SubStageMasterData1?.filter((ele) => ele.Addedid !== parseInt(id))
		);
		setRefresher((pre) => pre + 1)
	}
	useEffect(() => {
		setSubStagedata1(removedata);
	}, [removeid, refresher]);

	const [currentRadioValue, setcurrentRadioValue] = useState("yes");

	const handleRadioChange = (e) => {
		setcurrentRadioValue(e.target.value);
	};

	useEffect(() => {
		// console.log(currentRadioValue);
	}, [currentRadioValue]);

	// let Country = localStorage.getItem("Country");
	// let State = localStorage.getItem("State");
	// let City = localStorage.getItem("City");
	// let District = localStorage.getItem("District");

	// useEffect(() => {
	// 	console.log("Renredwer caused in Parent");
	// 	return () => {
	// 		localStorage.removeItem("Country");
	// 		localStorage.removeItem("State");
	// 		localStorage.removeItem("City");
	// 		localStorage.removeItem("District");
	// 	};
	// }, [Country, State, City, District]);
	let hideBit = 0
	function renderElement(objSub, idx, setErrors, ttl) {
		let isBreakLine = idx % mainListDB.length == 0 && idx != 0 ? false : false; //old breakline logic
		//Below logic is for filling educational details max 2
		let isBreakLinePhd = objSub.sub_stage_id == 2 && ttl.length > 7 && objSub.field_id == 99 ? true : false;
		let isBreakLineMtech = objSub.sub_stage_id == 6 && ttl.length > 5 && objSub.field_id == 354 ? true : false;
		let isBreakLineBtech = objSub.sub_stage_id == 7 && ttl.length > 5 && objSub.field_id == 73 ? true : false;
		let restrictAddMorePhd = objSub.sub_stage_id == 2 && ttl.length > 7 ? true : false;
		let restrictAddMoreMtech = objSub.sub_stage_id == 6 && ttl.length > 5 ? true : false;
		let restrictAddMoreBtech = objSub.sub_stage_id == 7 && ttl.length > 5 ? true : false;
		if (isBreakLinePhd) {
			hideBit = hideBit + 1;
		}
		if (isBreakLineMtech) {
			hideBit = hideBit + 1;
		}
		if (isBreakLineBtech) {
			hideBit = hideBit + 1;
		}
		let lineDiv = (
			<div className={`group-id-hide-${hideBit}`}
				style={{
					width: "100%",
					height: "1px",
					margin: "auto",
					marginBottom: "20px",
					border: "solid #c4c9d9 1px",
				}}
			/>
		);
		if (objSub.field_type == "othertextbox"){
			if(aos == '3505' || aos == '3506' || aos == '3507'){
			return <div className="col-lg-4 col-12">
			<div className="form-group input-container">
				<TextInput
					className="form-control"
					label={objSub.field_desc}
					name={objSub.field_name}
					type="text"
					placeholder={objSub.field_desc}
					setFieldValue={setFieldValue}
					setFieldTouched={setFieldTouched}
				/>
				<div
					className="right-icon clear-value"
					style={{ display: "none" }}
				>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
						<g transform="translate(3.498 3.498)">
							<path
								d="M1648.295,1400.784a10.5,10.5,0,1,0,10.5,10.5A10.5,10.5,0,0,0,1648.295,1400.784Zm0,19.254a8.753,8.753,0,1,1,8.753-8.753A8.762,8.762,0,0,1,1648.295,1420.038Z"
								transform="translate(-1637.793 -1400.784)"
							/>
							<path
								d="M1652.651,1408.881a.876.876,0,0,0-1.237,0l-2.147,2.147-2.146-2.147a.875.875,0,0,0-1.238,1.237l2.148,2.147-2.148,2.147a.875.875,0,0,0,1.238,1.237l2.146-2.147,2.147,2.147a.875.875,0,0,0,1.237-1.237l-2.147-2.147,2.147-2.147A.877.877,0,0,0,1652.651,1408.881Z"
								transform="translate(-1638.772 -1401.764)"
							/>
						</g>
					</svg>
				</div>
			</div>
		</div>
			} else {
				return <></>
			}

		}
		if (objSub.field_type == "textbox") {
			if (
				// objSub.field_desc == "First Name*" ||
				// objSub.field_desc == "Middle Name" ||
				// objSub.field_desc == "Last Name*" ||
				// objSub.field_desc == "Primary Email Address*" ||
				// objSub.field_desc == "Mobile Number*"
				objSub.field_desc == "Address Line 1*" ||
				objSub.field_desc == "Address Line 2*"
			) {
				return (
					<>
						{isBreakLine && lineDiv}
						<div
							// className={objSub.fieldDesc == 'Address Line1*' ? "col-lg-6" : "col-lg-4"}
							className="col-lg-6 col-12"
						>
							<div className="form-group input-container">
								<TextInput
									className="form-control"
									label={objSub.field_desc}
									name={objSub.field_name}
									type="text"
									placeholder={objSub.field_desc}
									setFieldValue={setFieldValue}
									setFieldTouched={setFieldTouched}
								/>
								<div className="right-icon clear-value">
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
										<g transform="translate(3.498 3.498)">
											<path
												d="M1648.295,1400.784a10.5,10.5,0,1,0,10.5,10.5A10.5,10.5,0,0,0,1648.295,1400.784Zm0,19.254a8.753,8.753,0,1,1,8.753-8.753A8.762,8.762,0,0,1,1648.295,1420.038Z"
												transform="translate(-1637.793 -1400.784)"
											/>
											<path
												d="M1652.651,1408.881a.876.876,0,0,0-1.237,0l-2.147,2.147-2.146-2.147a.875.875,0,0,0-1.238,1.237l2.148,2.147-2.148,2.147a.875.875,0,0,0,1.238,1.237l2.146-2.147,2.147,2.147a.875.875,0,0,0,1.237-1.237l-2.147-2.147,2.147-2.147A.877.877,0,0,0,1652.651,1408.881Z"
												transform="translate(-1638.772 -1401.764)"
											/>
										</g>
									</svg>
								</div>
							</div>
						</div>
					</>
				);

			}
			else if (objSub.field_desc == "Percentage") {
				return <>{isBreakLine && lineDiv}</>;
			} else if (
				objSub.field_type == "date" &&
				objSub.field_name.includes("DateofBirth_22")
			) {
				return (
					<>
						{isBreakLine && lineDiv}
						<div className="col-lg-4 col-12">
							<DatePicker
								fieldDesc={objSub.field_desc}
								setFieldValue={setFieldValue}
								name={objSub.field_name}
								setFieldTouched={setFieldTouched}
							/>
						</div>
					</>
				);
			} else
			if(objSub.field_type == "othertextbox"){
				return <></>
			}
				return (
					<>
						{isBreakLine && lineDiv}
						<div className="col-lg-4 col-12">
							<div className="form-group input-container">
								<TextInput
									className="form-control"
									label={objSub.field_desc}
									name={objSub.field_name}
									type="text"
									placeholder={objSub.field_desc}
									setFieldValue={setFieldValue}
									setFieldTouched={setFieldTouched}
								/>
								<div
									className="right-icon clear-value"
									style={{ display: "none" }}
								>
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
										<g transform="translate(3.498 3.498)">
											<path
												d="M1648.295,1400.784a10.5,10.5,0,1,0,10.5,10.5A10.5,10.5,0,0,0,1648.295,1400.784Zm0,19.254a8.753,8.753,0,1,1,8.753-8.753A8.762,8.762,0,0,1,1648.295,1420.038Z"
												transform="translate(-1637.793 -1400.784)"
											/>
											<path
												d="M1652.651,1408.881a.876.876,0,0,0-1.237,0l-2.147,2.147-2.146-2.147a.875.875,0,0,0-1.238,1.237l2.148,2.147-2.148,2.147a.875.875,0,0,0,1.238,1.237l2.146-2.147,2.147,2.147a.875.875,0,0,0,1.237-1.237l-2.147-2.147,2.147-2.147A.877.877,0,0,0,1652.651,1408.881Z"
												transform="translate(-1638.772 -1401.764)"
											/>
										</g>
									</svg>
								</div>
							</div>
						</div>
						{isBreakLinePhd && lineDiv}
					</>
				);
		} else if (
			objSub.field_type == "date" &&
			objSub.field_name.includes("DateofBirth_22")
		) {
			return (
				<>
					{isBreakLine && lineDiv}
					<div className="col-lg-4 col-12" style={{ marginTop: "-5px" }}>
						<DatePicker
							fieldDesc={objSub.field_desc}
							setFieldValue={setFieldValue}
							name={objSub.field_name}
							setFieldTouched={setFieldTouched}
						/>
					</div>
				</>
			);
		} else if (objSub.field_type == "date") {
			return (
				<>
					{isBreakLine && lineDiv}
					<div className="col-lg-4 col-12">
						<DatePicker
							fieldDesc={objSub.field_desc}
							setFieldValue={setFieldValue}
							name={objSub.field_name}
							setFieldTouched={setFieldTouched}
						/>
					</div>
					{isBreakLineMtech && lineDiv}
					{isBreakLineBtech && lineDiv}
				</>
			);
		} else if (objSub.field_type == "file") {
			return (
				<>
					{isBreakLine && lineDiv}
					<div className="col-12">
						<div className="input-file-frame">
							<label
								name
								htmlFor="user-name font-medium button-md neutral-70"
								className="control-label"
							>
								{objSub.field_desc}
							</label>
							<div className="form-group input-container with-left-icon mb-2 custom-file mb-3">
								<Attachment
									className="custom-file-input"
									label={fileNameCover}
									name={objSub.field_name}
									type="file"
									FileUpdateCover={FileUpdateCover}
									setfileNameCover={setfileNameCover}
									// accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
									setErrors={setErrors}
								/>
								<div className="left-icon">
									<svg
										id="Left_Icon"
										data-name="Left Icon"
										xmlns="http://www.w3.org/2000/svg"
										width="20"
										height="20"
										viewBox="0 0 20 20"
									>
										<g
											id="Group_1173"
											data-name="Group 1173"
											transform="translate(0 1.871)"
										>
											<path
												id="Path_1112"
												data-name="Path 1112"
												d="M1725.562,3857.3v-1.515a1.6,1.6,0,0,0-1.578-1.612h-8.4s-1.188-1.114-2.55-2.5h-5.89a1.6,1.6,0,0,0-1.577,1.612v4.018Z"
												transform="translate(-1705.562 -3851.669)"
												fill="#767b87"
											></path>
											<path
												id="Path_1113"
												data-name="Path 1113"
												d="M1705.562,3859.924v7.666a1.767,1.767,0,0,0,1.577,1.715h16.844a1.767,1.767,0,0,0,1.578-1.715v-7.666Z"
												transform="translate(-1705.562 -3853.045)"
												fill="#767b87"
											></path>
										</g>
										<rect
											id="Rectangle_642"
											data-name="Rectangle 642"
											width="20"
											height="20"
											fill="none"
										></rect>
									</svg>
								</div>
							</div>
						</div>
						{isUploading && (
							<Spinner
								animation="border"
								role="status"
								variant="danger"
							></Spinner>
						)}
						<small className="font-medium button-sm neutral-70">
							Max. file size allowed: 5 MB (.pdf, .doc, .docx)
						</small>
					</div>

				</>
			);
		} else if (objSub.field_type == "file1") {
			return (
				<>
					{isBreakLine && lineDiv}
					<div className="col-12">
						<div className="input-file-frame">
							<label
								name
								htmlFor="user-name font-medium button-md neutral-70"
								className="control-label"
							>
								{objSub.field_desc}
							</label>
							<div className="form-group input-container with-left-icon mb-2 custom-file mb-3">
								<Attachment
									className="custom-file-input"
									label={filenameresume}
									name={objSub.field_name}
									type="file"
									// accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
									FileUpdateResume={FileUpdateResume}
									setfilenameresume={setfilenameresume}
									setErrors={setErrors}
								/>
								<div className="left-icon">
									<svg
										id="Left_Icon"
										data-name="Left Icon"
										xmlns="http://www.w3.org/2000/svg"
										width="20"
										height="20"
										viewBox="0 0 20 20"
									>
										<g
											id="Group_1173"
											data-name="Group 1173"
											transform="translate(0 1.871)"
										>
											<path
												id="Path_1112"
												data-name="Path 1112"
												d="M1725.562,3857.3v-1.515a1.6,1.6,0,0,0-1.578-1.612h-8.4s-1.188-1.114-2.55-2.5h-5.89a1.6,1.6,0,0,0-1.577,1.612v4.018Z"
												transform="translate(-1705.562 -3851.669)"
												fill="#767b87"
											></path>
											<path
												id="Path_1113"
												data-name="Path 1113"
												d="M1705.562,3859.924v7.666a1.767,1.767,0,0,0,1.577,1.715h16.844a1.767,1.767,0,0,0,1.578-1.715v-7.666Z"
												transform="translate(-1705.562 -3853.045)"
												fill="#767b87"
											></path>
										</g>
										<rect
											id="Rectangle_642"
											data-name="Rectangle 642"
											width="20"
											height="20"
											fill="none"
										></rect>
									</svg>
								</div>
							</div>
						</div>
						{isUploadingR && (
							<Spinner
								animation="border"
								role="status"
								variant="danger"
							></Spinner>
						)}
						<small className="font-medium button-sm neutral-70">
							Max. file size allowed: 5 MB (.pdf, .doc, .docx)
						</small>
					</div>
				</>
			);
		} else if (objSub.field_type == "file2") {
			return (
				<>
					{isBreakLine && lineDiv}
					<div className="col-12">
						<div className="input-file-frame">
							<label
								name
								htmlFor="user-name font-medium button-md neutral-70"
								className="control-label"
							>
								{objSub.field_desc}
							</label>
							<div className="form-group input-container with-left-icon mb-2 custom-file mb-3">
								<Attachment
									className="custom-file-input"
									label={top3publication}
									name={objSub.field_name}
									type="file"
									// multiple
									// accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
									toppublication={toppublication}
									settop3publication={settop3publication}
									setErrors={setErrors}
								/>
								<div className="left-icon">
									<svg
										id="Left_Icon"
										data-name="Left Icon"
										xmlns="http://www.w3.org/2000/svg"
										width="20"
										height="20"
										viewBox="0 0 20 20"
									>
										<g
											id="Group_1173"
											data-name="Group 1173"
											transform="translate(0 1.871)"
										>
											<path
												id="Path_1112"
												data-name="Path 1112"
												d="M1725.562,3857.3v-1.515a1.6,1.6,0,0,0-1.578-1.612h-8.4s-1.188-1.114-2.55-2.5h-5.89a1.6,1.6,0,0,0-1.577,1.612v4.018Z"
												transform="translate(-1705.562 -3851.669)"
												fill="#767b87"
											></path>
											<path
												id="Path_1113"
												data-name="Path 1113"
												d="M1705.562,3859.924v7.666a1.767,1.767,0,0,0,1.577,1.715h16.844a1.767,1.767,0,0,0,1.578-1.715v-7.666Z"
												transform="translate(-1705.562 -3853.045)"
												fill="#767b87"
											></path>
										</g>
										<rect
											id="Rectangle_642"
											data-name="Rectangle 642"
											width="20"
											height="20"
											fill="none"
										></rect>
									</svg>
								</div>
							</div>
						</div>
						{isUploadingR && (
							<Spinner animation="border" role="status" variant="danger">
								{/* <span className="visually-hidden">Loading...</span> */}
							</Spinner>
						)}
						<small className="font-medium button-sm neutral-70">
							Max. file size allowed: 5 MB (.pdf, .doc, .docx)
						</small>
					</div>
				</>
			);
		} else if (objSub.field_type == "file4") { /*For uploading Teaching Interest */
			return (
				<>
					{isBreakLine && lineDiv}
					<div className="col-12">
						<div className="input-file-frame">
							<label
								name
								htmlFor="user-name font-medium button-md neutral-70"
								className="control-label"
							>
								{objSub.field_desc}
							</label>
							<div className="form-group input-container with-left-icon mb-2 custom-file mb-3">
								<Attachment
									className="custom-file-input"
									label={teachingStatement}
									name={objSub.field_name}
									type="file"
									// multiple
									// accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
									UploadDocument={UploadDocument}
									setTeachingStatement={setTeachingStatement}
									setErrors={setErrors}
								/>
								<div className="left-icon">
									<svg
										id="Left_Icon"
										data-name="Left Icon"
										xmlns="http://www.w3.org/2000/svg"
										width="20"
										height="20"
										viewBox="0 0 20 20"
									>
										<g
											id="Group_1173"
											data-name="Group 1173"
											transform="translate(0 1.871)"
										>
											<path
												id="Path_1112"
												data-name="Path 1112"
												d="M1725.562,3857.3v-1.515a1.6,1.6,0,0,0-1.578-1.612h-8.4s-1.188-1.114-2.55-2.5h-5.89a1.6,1.6,0,0,0-1.577,1.612v4.018Z"
												transform="translate(-1705.562 -3851.669)"
												fill="#767b87"
											></path>
											<path
												id="Path_1113"
												data-name="Path 1113"
												d="M1705.562,3859.924v7.666a1.767,1.767,0,0,0,1.577,1.715h16.844a1.767,1.767,0,0,0,1.578-1.715v-7.666Z"
												transform="translate(-1705.562 -3853.045)"
												fill="#767b87"
											></path>
										</g>
										<rect
											id="Rectangle_642"
											data-name="Rectangle 642"
											width="20"
											height="20"
											fill="none"
										></rect>
									</svg>
								</div>
							</div>
						</div>
						{isUploadingR && (
							<Spinner animation="border" role="status" variant="danger">
								{/* <span className="visually-hidden">Loading...</span> */}
							</Spinner>
						)}
						<small className="font-medium button-sm neutral-70">
							Max. file size allowed: 5 MB (.pdf, .doc, .docx)
						</small>
					</div>
				</>
			);
		}
		else if (objSub.field_type == "file5") { /*For uploading UploadResearchStatement */
			return (
				<>
					{isBreakLine && lineDiv}
					<div className="col-12">
						<div className="input-file-frame">
							<label
								name
								htmlFor="user-name font-medium button-md neutral-70"
								className="control-label"
							>
								{objSub.field_desc}
							</label>
							<div className="form-group input-container with-left-icon mb-2 custom-file mb-3">
								<Attachment
									className="custom-file-input"
									label={researchStatement}
									name={objSub.field_name}
									type="file"
									// multiple
									// accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
									UploadDocument={UploadDocument}
									setResearchStatement={setResearchStatement}
									setErrors={setErrors}
								/>
								<div className="left-icon">
									<svg
										id="Left_Icon"
										data-name="Left Icon"
										xmlns="http://www.w3.org/2000/svg"
										width="20"
										height="20"
										viewBox="0 0 20 20"
									>
										<g
											id="Group_1173"
											data-name="Group 1173"
											transform="translate(0 1.871)"
										>
											<path
												id="Path_1112"
												data-name="Path 1112"
												d="M1725.562,3857.3v-1.515a1.6,1.6,0,0,0-1.578-1.612h-8.4s-1.188-1.114-2.55-2.5h-5.89a1.6,1.6,0,0,0-1.577,1.612v4.018Z"
												transform="translate(-1705.562 -3851.669)"
												fill="#767b87"
											></path>
											<path
												id="Path_1113"
												data-name="Path 1113"
												d="M1705.562,3859.924v7.666a1.767,1.767,0,0,0,1.577,1.715h16.844a1.767,1.767,0,0,0,1.578-1.715v-7.666Z"
												transform="translate(-1705.562 -3853.045)"
												fill="#767b87"
											></path>
										</g>
										<rect
											id="Rectangle_642"
											data-name="Rectangle 642"
											width="20"
											height="20"
											fill="none"
										></rect>
									</svg>
								</div>
							</div>
						</div>
						{isUploadingR && (
							<Spinner animation="border" role="status" variant="danger">
								{/* <span className="visually-hidden">Loading...</span> */}
							</Spinner>
						)}
						<small className="font-medium button-sm neutral-70">
							Max. file size allowed: 5 MB (.pdf, .doc, .docx)
						</small>
					</div>
				</>
			);
		}
		else if (objSub.field_type == "file6") { /*For uploading ResearchPapers */
			return (
				<>
					{isBreakLine && lineDiv}
					<div className="col-12">
						<div className="input-file-frame">
							<label
								name
								htmlFor="user-name font-medium button-md neutral-70"
								className="control-label"
							>
								{objSub.field_desc}
							</label>
							<div className="form-group input-container with-left-icon mb-2 custom-file mb-3">
								<Attachment
									className="custom-file-input"
									label={researchPapers}
									name={objSub.field_name}
									type="file"
									// multiple
									// accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
									UploadDocument={UploadDocument}
									setResearchPapers={setResearchPapers}
									setErrors={setErrors}
								/>
								<div className="left-icon">
									<svg
										id="Left_Icon"
										data-name="Left Icon"
										xmlns="http://www.w3.org/2000/svg"
										width="20"
										height="20"
										viewBox="0 0 20 20"
									>
										<g
											id="Group_1173"
											data-name="Group 1173"
											transform="translate(0 1.871)"
										>
											<path
												id="Path_1112"
												data-name="Path 1112"
												d="M1725.562,3857.3v-1.515a1.6,1.6,0,0,0-1.578-1.612h-8.4s-1.188-1.114-2.55-2.5h-5.89a1.6,1.6,0,0,0-1.577,1.612v4.018Z"
												transform="translate(-1705.562 -3851.669)"
												fill="#767b87"
											></path>
											<path
												id="Path_1113"
												data-name="Path 1113"
												d="M1705.562,3859.924v7.666a1.767,1.767,0,0,0,1.577,1.715h16.844a1.767,1.767,0,0,0,1.578-1.715v-7.666Z"
												transform="translate(-1705.562 -3853.045)"
												fill="#767b87"
											></path>
										</g>
										<rect
											id="Rectangle_642"
											data-name="Rectangle 642"
											width="20"
											height="20"
											fill="none"
										></rect>
									</svg>
								</div>
							</div>
						</div>
						{isUploadingR && (
							<Spinner animation="border" role="status" variant="danger">
								{/* <span className="visually-hidden">Loading...</span> */}
							</Spinner>
						)}
						<small className="font-medium button-sm neutral-70">
							Max. file size allowed: 10 MB (.pdf, .doc, .docx)
						</small>
					</div>
				</>
			);
		}
		else if (objSub.field_type == "file7") { /*For uploading AdditionalAttachment */
			return (
				<>
					{isBreakLine && lineDiv}
					<div className="col-12">
						<div className="input-file-frame">
							<label
								name
								htmlFor="user-name font-medium button-md neutral-70"
								className="control-label"
							>
								{objSub.field_desc}
							</label>
							<div className="form-group input-container with-left-icon mb-2 custom-file mb-3">
								<Attachment
									className="custom-file-input"
									label={additionalAttachment}
									name={objSub.field_name}
									type="file"
									// multiple
									// accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
									UploadDocument={UploadDocument}
									setAdditionalAttachment={setAdditionalAttachment}
									setErrors={setErrors}
								/>
								<div className="left-icon">
									<svg
										id="Left_Icon"
										data-name="Left Icon"
										xmlns="http://www.w3.org/2000/svg"
										width="20"
										height="20"
										viewBox="0 0 20 20"
									>
										<g
											id="Group_1173"
											data-name="Group 1173"
											transform="translate(0 1.871)"
										>
											<path
												id="Path_1112"
												data-name="Path 1112"
												d="M1725.562,3857.3v-1.515a1.6,1.6,0,0,0-1.578-1.612h-8.4s-1.188-1.114-2.55-2.5h-5.89a1.6,1.6,0,0,0-1.577,1.612v4.018Z"
												transform="translate(-1705.562 -3851.669)"
												fill="#767b87"
											></path>
											<path
												id="Path_1113"
												data-name="Path 1113"
												d="M1705.562,3859.924v7.666a1.767,1.767,0,0,0,1.577,1.715h16.844a1.767,1.767,0,0,0,1.578-1.715v-7.666Z"
												transform="translate(-1705.562 -3853.045)"
												fill="#767b87"
											></path>
										</g>
										<rect
											id="Rectangle_642"
											data-name="Rectangle 642"
											width="20"
											height="20"
											fill="none"
										></rect>
									</svg>
								</div>
							</div>
						</div>
						{isUploadingR && (
							<Spinner animation="border" role="status" variant="danger">
								{/* <span className="visually-hidden">Loading...</span> */}
							</Spinner>
						)}
						<small className="font-medium button-sm neutral-70">
							Max. file size allowed: 5 MB (.pdf, .doc, .docx)
						</small>
					</div>
				</>
			);
		}
		else if (objSub.field_type == "checkbox") {
			return (
				<>
					{isBreakLine && lineDiv}
					<div className="col-12">
						<div className="input-file-frame">
							<div className="form-group input-container with-left-icon mb-2">
								<div className="form-check">
									<Checkbox
										className="form-check-input"
										id="flexCheckDefault"
										name={objSub.field_name}
									/>
									<label
										className="form-check-label"
										htmlFor="flexCheckDefault"
									>
										{objSub.field_desc}
									</label>

									{objSub.field_name.includes(
										"Declaration_401"
									) ? (
										<div className="mt-3">
											<a
												href="https://www.jioinstitute.edu.in/terms-conditions"
												className="mr-5"
												target="_blank"
												rel="noopener noreferrer"
											>
												Terms and Conditions
											</a>
											<br />
											<a
												href="https://www.jioinstitute.edu.in/privacy-policy"
												target="_blank"
												rel="noopener noreferrer"
											>
												Privacy Policy
											</a>
										</div>
									) : null}
								</div>
							</div>
						</div>
					</div>
				</>
			);
		} else if (objSub.field_type == "txtarea") {
			return (
				<>
					{isBreakLine && lineDiv}
					<div className="col-lg-12 col-12">
						<div className="form-group input-container">
							<TextArea
								//  required data-errormessage-value-missing="required *"
								//   className="form-control" rows="5"
								maxLength="350"
								label={objSub.field_desc}
								name={objSub.field_name}
							// placeholder={objSub.field_desc}
							/>
							{/* <label htmlFor="user-name font-medium button-md neutral-70" className="control-label">{objSub.field_desc}</label>
                <input type="text" name={objSub.field_name + '_' + objSub.field_id} id={objSub.field_name + '_' + objSub.field_id} value={objSub.value_name} className="form-control" placeholder /> */}
							<div className="right-icon clear-value">
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
									<g transform="translate(3.498 3.498)">
										<path
											d="M1648.295,1400.784a10.5,10.5,0,1,0,10.5,10.5A10.5,10.5,0,0,0,1648.295,1400.784Zm0,19.254a8.753,8.753,0,1,1,8.753-8.753A8.762,8.762,0,0,1,1648.295,1420.038Z"
											transform="translate(-1637.793 -1400.784)"
										/>
										<path
											d="M1652.651,1408.881a.876.876,0,0,0-1.237,0l-2.147,2.147-2.146-2.147a.875.875,0,0,0-1.238,1.237l2.148,2.147-2.148,2.147a.875.875,0,0,0,1.238,1.237l2.146-2.147,2.147,2.147a.875.875,0,0,0,1.237-1.237l-2.147-2.147,2.147-2.147A.877.877,0,0,0,1652.651,1408.881Z"
											transform="translate(-1638.772 -1401.764)"
										/>
									</g>
								</svg>
							</div>
						</div>
					</div>
				</>
			);
		} else if (objSub.field_type == "radio") {
			return (
				<>
					{isBreakLine && lineDiv}
					<div className="col-lg-12 col-12">
						<div className="d-flex">
							<div className="mr-4">{objSub.field_desc}</div>
							<div className="horizontal-align">
								{/* <div className="radiobutton-wrapper radiobutton-medium">
									<input
										id="yes"
										name={objSub.field_name + "_" + objSub.field_id}
										type="radio"
										value="Radio button one"
										checked="checked"
									></input>
									<label htmlFor="yes">Yes</label>
								</div>
								<div className="radiobutton-wrapper radiobutton-medium">
									&nbsp;
									<input
										id="no"
										name={objSub.field_name + "_" + objSub.field_id}
										type="radio"
										value="Radio button two"
									></input>
									<label htmlForfor="no">No</label>
								</div> */}

								<div className="radiobutton-wrapper radiobutton-medium">
									<input
										id="yes"
										name={objSub.field_name + "_" + objSub.field_id}
										type="radio"
										value="yes"
										onChange={handleRadioChange}
										checked={currentRadioValue == "yes"}
									></input>
									<label htmlFor="yes">Yes</label>
								</div>
								<div className="radiobutton-wrapper radiobutton-medium">
									&nbsp;
									<input
										id="no"
										name={objSub.field_name + "_" + objSub.field_id}
										type="radio"
										value="no"
										onChange={handleRadioChange}
										checked={currentRadioValue == "no"}
									></input>
									<label htmlForfor="no">No</label>
								</div>

								{/* <div aria-labelledby="my-radio-group d-flex align-items-center">
									<label className="d-inline-flex">
										<Field
											type="radio"
											name={objSub.field_name + "_" + objSub.field_id}
											value={true}
											checked="checked"
											style={{
												accentColor: "#bd2130",
												height: "20px",
												width: "20px",
											}}
											onClick={handleRadioChange}
										/>
										&nbsp;Yes
									</label>
									&nbsp; &nbsp;
									<label className="d-inline-flex">
										<Field
											type="radio"
											name={objSub.field_name + "_" + objSub.field_id}
											value={false}
											style={{
												accentColor: "#bd2130",
												height: "20px",
												width: "20px",
											}}
											onClick={handleRadioChange}
										/>
										&nbsp;No
									</label>
								</div> */}
							</div>
						</div>
					</div>
				</>
			);
		} else if (objSub.field_type == "ismore") {
			let obj = JSON.parse(JSON.stringify(SubStageMasterData1));
			let idx1 =
				obj[obj.length - 1].Addedid == undefined
					? 0
					: obj[obj.length - 1].Addedid;
			if (restrictAddMorePhd) {
				return <>
					{objSub.Addedid != undefined && (
						<img
							addedid={objSub.Addedid}
							onClick={(e) => {
								removeFormFields(e);
							}}
							className={objSub.Addedid >= 1 ? "dltIconPhd" : ""}
							// style={{ left: '95%' }}
							src={Deleteicon}
						/>
					)}</>
			}
			if (restrictAddMoreMtech || restrictAddMoreBtech) {
				return <>
					{objSub.Addedid != undefined && (
						<img
							addedid={objSub.Addedid}
							onClick={(e) => {
								removeFormFields(e);
							}}
							className={objSub.Addedid >= 1 ? "dltIcon " : ""}
							// style={{ left: '62%', alignSelf: 'flex-end' }}
							src={Deleteicon}
						/>
					)}</>
			}
			return (
				<>
					{isBreakLine && lineDiv}
					<div className="col-lg-12 col-12">
						<div className="form-group input-container">
							<div className="Maindiv">
								{(SubStageMasterData1.length == mainListDB.length ||
									objSub.Addedid == count.current) && (
										<div
											onClick={AddMoreItem}
											className="font-weight-bold  text-danger"
											style={
												count.current == process.env.REACT_APP_MAX_ADD_MORE_COUNT
													? { pointerEvents: "none", opacity: 0.4 }
													: {}
											}
										>
											+ {objSub.field_desc}
										</div>
									)}
								{objSub.Addedid != undefined && (
									<img
										addedid={objSub.Addedid}
										onClick={(e) => {
											removeFormFields(e);
										}}
										className={objSub.Addedid >= 1 ? "icon" : ""}
										src={Deleteicon}
									/>
								)}
							</div>
						</div>
					</div>
				</>
			);
		} else {
			if (objSub.field_desc == "Country*" ||
				objSub.field_desc == "State*"
				// objSub.field_desc == "University/Institute" ||
				// objSub.field_desc == "Country" ||
				// objSub.field_desc == "Nature of Work"
			) {
				return (
					<>
						{isBreakLine && lineDiv}
						{

							<div className="col-lg-6 col-12" style={{ marginTop: "-5px" }}>
								<MuiSelect
									label={objSub.field_desc}
									name={objSub.field_name}
									refresh={refresh}
									setFieldValue={setFieldValue}
									setrefresh={setrefresh}
									field_id={objSub.field_id}
									setFieldTouched={setFieldTouched}
									handleAosChange={handleAosChange}
								/>
							</div>
						}

					</>
				);
			} else if (objSub.field_type == "textboxnotrequired"
				// || objSub.field_desc == "Country Code"
			) {
				localStorage.setItem("countryCodeFieldName", objSub?.field_name)
				return (
					//<div className="col-lg-4 col-12" style={{ marginTop: "-5px" }}>
					//	<MuiCountryCode
					//		label={objSub.field_desc}
					//		name={objSub.field_name}
					//		setFieldValue={setFieldValue}
					//		setFieldTouched={setFieldTouched}
					//	/>
					//</div>
					<div></div>
				);
			} else {
				return (
					<>
						{isBreakLine && lineDiv}
						{

							<div className="col-lg-4 col-12">
								<MuiSelect
									label={objSub.field_desc}
									name={objSub.field_name}
									refresh={refresh}
									setFieldValue={setFieldValue}
									setrefresh={setrefresh}
									field_id={objSub.field_id}
									setFieldTouched={setFieldTouched}
									handleAosChange={handleAosChange}
								/>
							</div>
						}

					</>
				);
			}
		}
		return null;
	}

	return (
		<>
			<section
				// className={sub_stage_name.includes('Attach Your Cover Letter') || sub_stage_name.includes('Attach Resume') ? "col-12 col-lg-6 box-grey mt-2 details-wrapper" : "col-12 col-lg-12 box-grey mt-2 details-wrapper"}
				className="col-12 col-lg-12 box-grey mt-2 details-wrapper"
			>
				<div className="conatiner">
					<div className="row">
						{_.sortBy(SubStageMasterData1, ["align_id", "group_id", "priority"])?.map(
							(objSub, idx, ttl) => {
								//  {setmapvalue(objSub)}
								return (
									<Fragment key={idx}>
										{renderElement(objSub, idx, setErrors, ttl)}
									</Fragment>
								);
							}
						)}
					</div>
					<div className="Maindiv">
						{/* <div onClick={removeFormFields}  className="font-weight-bold  text-warning  ">- Remove</div> */}
					</div>
				</div>
			</section>
		</>
	);
}
export default React.memo(SubStage);
