import axios from "axios";
//import "./Captcha.css";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Formik, Form, useField } from "formik";
import TextInput from "../Dashboard/TextInput";
import jobApp from "../CurrentOpenings/jobApp.css";
import { useNavigate } from "react-router-dom";
import CheckBox from "./../Common/Formik/CheckBox";
import DropDown from "./../Common/Formik/DropDown";
import OtpInput from "react-otp-input";
import sendOTP from "./../APICaller/Login/sendOTP";

import _ from "lodash";

import * as Yup from "yup";
import "yup-phone";
import SecFooter from "../Common/SecFooter";
import Attachment from "./Attachment";
import { Modal, Button } from "react-bootstrap";

import { Spinner } from "react-bootstrap";
// import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
	setUserLogin,
	setUserLogout,
	setUserID,
} from "../redux/UserDetails/UserActions";

import $ from "jquery";
import getDeviceType from "../Common/getDeviceType";
import countryISDCodes from "../Common/countryISDCodes";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OtpTimer from "otp-timer";

export default function Profile() {
	// //#region jQuery
	// $(".searchAbtJI").on("click", function () {
	// 	$("body").css({ overflow: "hidden" });
	// 	setTimeout(function () {
	// 		$("input#topnav-search").focus();
	// 	}, 100);
	// 	setTimeout(function () {
	// 		$(".pkv-searchview").addClass("slidedown");
	// 	}, 500);
	// 	setTimeout(function () {
	// 		$(".pkv-searchview .search-backdrop.pkv-fade").addClass("show visible");
	// 	}, 100);
	// 	setTimeout(function () {
	// 		$(".pkv-searchview .animate_search_result").addClass("animate");
	// 		$(".pkv-searchview .animate_search_result").css({
	// 			"box-sizing": "border-box",
	// 			display: "block",
	// 		});
	// 	}, 700);
	// 	setTimeout(function () {
	// 		$(".pkv-searchview .pkv-searchresult-container").addClass("slidedown");
	// 	}, 700);
	// });

	// $(".closeSearch, .search-backdrop").on("click", function () {
	// 	$("body").css({ overflow: "auto" });
	// 	setTimeout(function () {
	// 		$(".pkv-searchview .pkv-searchresult-container").removeClass("slidedown");
	// 	}, 100);
	// 	setTimeout(function () {
	// 		$("input#topnav-search").focus();
	// 	}, 900);
	// 	setTimeout(function () {
	// 		$(".pkv-searchview").removeClass("slidedown");
	// 	}, 100);
	// 	setTimeout(function () {
	// 		$(".pkv-searchview .search-backdrop.pkv-fade").removeClass(
	// 			"show visible"
	// 		);
	// 	}, 500);
	// 	setTimeout(function () {
	// 		$(".pkv-searchview .animate_search_result").removeClass("animate");
	// 		$(".pkv-searchview .animate_search_result").css({
	// 			"box-sizing": "border-box",
	// 			display: "none",
	// 		});
	// 	}, 100);
	// });

	// $(".form-group").each(function () {
	// 	const $inp = $(this).find("input"),
	// 		$cle = $(this).find(".clear-value");
	// 	$inp.on("input", function () {
	// 		$cle.toggle(!!this.value);
	// 	});

	// 	$cle.on("touchstart click", function (e) {
	// 		e.preventDefault();
	// 		$inp.val("").trigger("input");
	// 	});
	// });

	// $(".form-group.input-container input")
	// 	.on("focus", function () {
	// 		if ($(this).val().length == 0) {
	// 			$(this)
	// 				.closest(".form-group.input-container")
	// 				.find(".clear-value")
	// 				.fadeOut();
	// 		} else {
	// 			$(this)
	// 				.closest(".form-group.input-container")
	// 				.find(".clear-value")
	// 				.fadeIn();
	// 		}
	// 	})
	// 	.blur(function () {
	// 		$(".clear-value").fadeOut();
	// 	});

	// $(".form-group").each(function () {
	// 	const $inp = $(this).find("input"),
	// 		$cle = $(this).find(".clear-value");
	// 	$inp.on("input", function () {
	// 		$cle.toggle(!!this.value);
	// 	});
	// 	$cle.on("touchstart click", function (e) {
	// 		e.preventDefault();
	// 		$inp.val("").trigger("input");
	// 	});
	// });

	// $(".btn-edit").on("click", function () {
	// 	$(this).parents(".non-edit-mode").hide();
	// 	$(this).parents(".non-edit-mode").siblings(".edited-mode").show();
	// });
	// $("#btnUpdate").on("click", function () {
	// 	$(".non-edit-mode").show();
	// 	$(".edited-mode").hide();
	// 	$(this).parent(".section-common-space").hide();
	// });
	// //#endregion jQuery ends here

	const phoneSchema = Yup.string().phone("IN").required();

	const [profileData, setprofileData] = useState([]);

	const [validateotp, setvalidateotp] = useState(false);
	const [validEmail, setValidEmail] = useState(false);
	const [validPhone, setValidPhone] = useState(false);

	const [resendbtnStatus, setresendbtnStatus] = useState(true);
	const [show, setShow] = useState(false);
	const [otp, setotp] = useState("");
	const [userLoginID, setuserLoginID] = useState("");
	const isAuthenticated = useSelector((state) => state.user);
	const navigate = useNavigate();
	const candidatID = useSelector(
		(state) => state.user.user.userDetails?.candidate_id
	);
	const [showDraftToast, setshowDraftToast] = useState(true);

	const dispatch = useDispatch();
	const [captchaImgURL, setcaptchaImgURL] = useState(
		`/api/Login/captcha?${Math.floor(Math.random() * 100)}`
	);

	const [enableProfileEdit, setenableProfileEdit] = useState(
		localStorage.getItem("ep_ux_op") == "true" ? true : false
	);

	const [fileName, setfileName] = useState("Upload your CV from storage");
	const [isUploading, setisUploading] = useState(false);

	const [areaData, setareaData] = useState(
		useSelector((state) => state.masterData.areaDeptMaster.area)
	);
	const SubDEpartment = useSelector(
		(state) => state.masterData.areaDeptMaster.area
	);
	const departmentData = useSelector(
		(state) => state.masterData.areaDeptMaster.departments
	);

	const handleClose = () => {
		setShow(false);
		setValidEmail(false);
		setValidPhone(false);
		setotp("");
	};

	useEffect(() => {
		if (isAuthenticated["isLoggedIn"]) {
			axios
				.get("api/Profile/GetCandidateProfile", {
					params: {
						candidate_id: candidatID,
					},
				})
				.then((response) => {
					// response.data is the users
					const contentdata = response.data;
					// SubStageMasterData=contentdata.data;
					setprofileData([contentdata.data]);
					// console.log("profileData", contentdata.data);
				})
				.catch((error) => {
					//console.log("error axios", error.message);
				});
		} else {
			navigate("../login", { replace: false });
		}
	}, [fileName]);

	// Renderer callback with condition
	const renderer = ({ hours, minutes, seconds, completed }) => {
		if (completed) {
			setresendbtnStatus(false);
			return (
				<span>
					{minutes}:{seconds}
				</span>
			);
		} else {
			return (
				<span>
					{minutes}:{seconds}
				</span>
			);
		}
	};
	function submitOTP(otpNo) {
		const url = "/OTP/api/ValidateOTP";
		const requestBody = {
			otp: otpNo,
		};
		axios
			.post(url, requestBody)
			.then((res) => {
				if (res.data.statusCode == 200) {
					setShow(false);

					dispatch(setUserLogin());
					setvalidateotp(true);
					window.location.reload(false);
					// history.replace({
					// 	pathname: "/",
					// 	state: { userID: userLoginID },
					// });
					//history.replace({ pathname: "/", stae: { userID: userLoginID } });
					// alert("Login succesfull");
				} else {
					alert("login failed");
				}
			})
			.catch((err) => {
				//console.log({ err })
			});
	}

	function FileUpdate(file) {
		// console.log("File-size", file.size);
		if (file.name.split(".").pop() !== "zip") {
			if (file.size <= 5242880) {
				setisUploading(true);
				const formData = new FormData();
				formData.append("file", file);
				axios
					.post("/api/Login/File", formData)
					.then((response) => {
						// console.log("fileUpload response", response.data);

						if (
							response.data.data != null &&
							response.data.statusCode == 200 &&
							response.data.success == true
						) {
							setisUploading(false);
						} else if (
							response.data.data == null &&
							response.data.statusCode == 400 &&
							response.data.success == false
						) {
							setisUploading(false);
							toast.error(
								"Please upload file pdf,word,image and file size limit must be 5 MB.",
								{
									position: toast.POSITION.TOP_CENTER,
								}
							);
						} else {
							setisUploading(false);
							// SetShowalert(true);
						}
					})
					.catch((error) => {
						setisUploading(false);
						//console.log("error axios", error.message);
						//alert("CV Upload failed");
						// toast.error("Please upload file PDF,WORD,IMG",
						// 	{
						// 		position: toast.POSITION.TOP_CENTER,
						// 	});
						//console.log(error);
					});
			} else {
				setisUploading(false);
				toast.error(
					"Please upload file pdf,word,image and file size limit must be 5 MB.",
					{
						position: toast.POSITION.TOP_CENTER,
					}
				);
			}
		} else {
			setisUploading(false);
			toast.error(
				"Please upload file pdf,word,image and file size limit must be 5 MB.",
				{
					position: toast.POSITION.TOP_CENTER,
				}
			);
		}
	}

	function AreaSelection(e) {
		// debugger;
		// e.preventDefault();
		// console.log("catetory id ", e.target.value);
		if (e.target.value !== "") {
			var filtervalue = SubDEpartment?.filter(
				(ele) => ele?.category_id.toString() === e.target.value
			);
			setareaData(filtervalue);
		}
	}
	function handleProfilePicEdit(state) {
		//console.log({ state });
		setenableProfileEdit(state);
		localStorage.setItem("ep_ux_op", state);
	}

	return (
		<>
			<div
				className="tab-pane fade show active"
				id="profile"
				role="tabpanel"
				aria-labelledby="profile-tab"
			>
				<div className="profile-tab-panel">
					{profileData?.map((obj) => {
						return (
							<Formik
								enableReinitialize
								initialValues={{
									firstName: obj.first_name,
									middleName: "",
									lastName: obj.last_name,
									salutation: obj.salutation,
									mobile: obj.mobile,
									email: obj.email,
									isd_code: obj.isd_code,
									linkedin_url: obj.linkedin_url,
									cvFile: obj.cv_file,
									device: getDeviceType(),
									chanelType: "",
									category_id: departmentData
										?.filter((x) => x?.category_name == obj.category_desc)[0]
										?.category_id.toString(),
									sub_category_id: areaData
										?.filter(
											(x) =>
												x.sub_category_name ==
												obj.sub_category_desc?.replace("\r\n", "")
										)[0]
										?.sub_category_id?.toString(),
									captcha: "",
								}}
								validationSchema={Yup.object({
									salutation: Yup.string().required("Required"),
									// 	.oneOf(
									// 		["Mr.", "Ms", "Mrs", "other"],
									// 		"One of Mr. , Ms or Mrs must be selected"
									// 	)
									//
									firstName: Yup.string()
										.max(50, "Must be less than 50 characters")
										.required("Required"),
									lastName: Yup.string()
										.max(50, "Must be less than 50 characters")
										.required("Required"),
									email: Yup.string()
										.email("Enter Valid Email")
										.required("Required"),

									isd_code: Yup.string().required("Required"),
									mobile: Yup.string()
										.required("Required")
										.test(
											"phone validate user input",
											"Enter Valid Mobile Number",
											async function (value) {
												phoneSchema
													.isValid(value)
													.then((r) => {
														if (r) {
															setValidPhone(true);
														} else {
															setValidPhone(false);
														}
													})
													.catch((e) => {
														//console.log(e);
													});

												if (
													!isNaN(value) &&
													!(await phoneSchema.isValid(value))
												) {
													return false;
												}
												return true;
											}
										),
									linkedin_url: Yup.string(),

									category_id: Yup.string().required("Required"),
									sub_category_id: Yup.string().required("Required"),
									// 						cvFile: Yup.string().required("Required").test(
									// 							'oneOfRequired',
									// 							'One of FileName',

									//      function(item) {

									//    return (this.cvFile=fileName)
									//  }
									// 						),
									captcha: Yup.string().required("Required"),
								})}
								onSubmit={(values, { setSubmitting }) => {
									values = { ...values, cvFile: fileName, cvFileName:"",candidateProfilePicName:"" };

									setSubmitting(false);
									axios
										.post("/api/Login/user-signup", values)
										.then((res) => {
											if (
												res.data.success == true &&
												res.data.statusCode == 200
											) {
												//alert("Updated successfull");
												toast.success("User Details updated successfully", {
													position: toast.POSITION.TOP_CENTER,
												});
												setTimeout(() => {
													handleProfilePicEdit(false);
													window.location.reload(true);
												}, 3000);
											} else {
												toast.error(res.data.SystemMsg, {
													position: toast.POSITION.TOP_CENTER,
												});
											}
										})
										.catch((err) => {
											if (
												err.response.data.statusCode == 406 &&
												err.response.data.success == false
											) {
												toast.error(`${err.response.data.systemMsg}`, {
													position: toast.POSITION.TOP_CENTER,
													toastId: "error-toast",
												});

												return;
											}
											//console.log({ err });
											toast.error("Something went wrong", {
												position: toast.POSITION.TOP_CENTER,
												toastId: "error-toast-1",
											});
										});
								}}
							>
								{(props) => {
									const {
										values,
										touched,
										errors,
										isSubmitting,
										handleChange,
										handleBlur,
										handleSubmit,
										setFieldValue,
									} = props;
									return (
										<Form noValidate>
											<>
												{!!!enableProfileEdit && (
													<section className="section-common-space overflow-hidden details-wrapper non-edit-mode">
														<div>
															<div className="d-flex justify-content-between ">
																<div className="h5-05 font-bold">
																	My Profile
																</div>
																<div
																	className="body-02 mr-2"
																	onClick={() => handleProfilePicEdit(true)}
																>
																	<span>
																		<a className="btn-edit">
																			<img
																				className="img-fluid"
																				src="assets/images/career/icon-edit.svg"
																				width="20px"
																				height="20px"
																				alt="edit"
																			/>
																		</a>
																	</span>
																	<span>
																		<a className="primary btn-edit">Edit</a>
																	</span>
																</div>
															</div>
															<div className="divider" />
															<div>
																<div className="d-flex flex-sm-row flex-column body-01 font-medium mb-3">
																	<div className="neutral-80 lable-frame">
																		Name
																	</div>
																	<div className="mr-auto">
																		{/*:{" "}*/}
																		{obj.first_name +
																			" " +
																			obj.middle_name +
																			" " +
																			obj.last_name}
																	</div>
																</div>
																<div className="d-flex flex-sm-row flex-column body-01 font-medium mb-3">
																	<div className="neutral-80 lable-frame">
																		Email
																	</div>
																	<div className="mr-auto">
																		 {obj.email}
																		<span
																			className={
																				obj.email_verify_flag
																					? "DivHide ml-5"
																					: undefined
																			}
																		>
																			{/* <a
																		onClick={() => ValidateInput(obj.mobile)}
																		className="ml-5 arrow-red btn btn-secondary center-v"
																	>
																		Validate
																	</a> */}
																			{/* {validateotp &&
							                                        (
																	<a disabled
																		onClick={() => ValidateInput(obj.mobile)}
																		className="ml-5  btn  btn-success center-v"
																	>
																		Varified
																	</a>
						                                          )} */}
																		</span>
																	</div>
																	<div
																		className={
																			!obj.email_verify_flag
																				? "DivHide ml-5"
																				: undefined
																		}
																	>
																		<span className="ml-5 mr-1">
																			<svg
																				xmlns="http://www.w3.org/2000/svg"
																				id="download-finished-file-tick-workflow-system-filled"
																				width={24}
																				height={24}
																				viewBox="0 0 20 20"
																			>
																				<path
																					id="Path_1081"
																					data-name="Path 1081"
																					d="M947,3658.929a8.746,8.746,0,1,0,8.746,8.747A8.746,8.746,0,0,0,947,3658.929Zm3.894,6.793-4.79,4.789a.624.624,0,0,1-.883,0l-2.115-2.114a.625.625,0,0,1,.883-.883l1.673,1.673,4.348-4.348a.625.625,0,1,1,.883.884Z"
																					transform="translate(-937.004 -3657.675)"
																					fill="#fff"
																					style={{ fill: "#1A873C" }}
																				/>
																				<rect
																					id="Rectangle_627"
																					data-name="Rectangle 627"
																					width={20}
																					height={20}
																					fill="none"
																				/>
																			</svg>
																		</span>
																		<span className="button-md font-medium">
																			Validated
																		</span>
																	</div>
																</div>
																<div className="d-flex flex-sm-row flex-column body-01 font-medium mb-3">
																	<div className="neutral-80 lable-frame">
																		Phone Number
																	</div>
																	<div className="mr-auto">
																		 {obj.mobile}
																		<span
																			className={
																				obj.mobile_verify_flag
																					? "DivHide ml-5"
																					: undefined
																			}
																		>
																			{/* <a
																		onClick={() => ValidateInput(obj.mobile)}
																		className="ml-5 arrow-red btn btn-secondary center-v"
																	>
																		Validate
																	</a> */}
																		</span>
																	</div>
																	<div
																		className={
																			!obj.mobile_verify_flag
																				? "DivHide"
																				: undefined
																		}
																	>
																		<span className="ml-5 mr-1">
																			<svg
																				xmlns="http://www.w3.org/2000/svg"
																				id="download-finished-file-tick-workflow-system-filled"
																				width={24}
																				height={24}
																				viewBox="0 0 20 20"
																			>
																				<path
																					id="Path_1081"
																					data-name="Path 1081"
																					d="M947,3658.929a8.746,8.746,0,1,0,8.746,8.747A8.746,8.746,0,0,0,947,3658.929Zm3.894,6.793-4.79,4.789a.624.624,0,0,1-.883,0l-2.115-2.114a.625.625,0,0,1,.883-.883l1.673,1.673,4.348-4.348a.625.625,0,1,1,.883.884Z"
																					transform="translate(-937.004 -3657.675)"
																					fill="#fff"
																					style={{ fill: "#1A873C" }}
																				/>
																				<rect
																					id="Rectangle_627"
																					data-name="Rectangle 627"
																					width={20}
																					height={20}
																					fill="none"
																				/>
																			</svg>
																		</span>
																		<span className="button-md font-medium">
																			Validated
																		</span>
																	</div>
																</div>
															</div>
															<div className="divider" />
															<div>
																<div className="d-flex flex-sm-row flex-column body-01 font-medium mb-3">
																	<div className="neutral-80 lable-frame">
																		Department
																	</div>
																	<div className="mr-auto">
																		 {obj.category_desc}
																	</div>
																</div>
																<div className="d-flex flex-sm-row flex-column body-01 font-medium mb-3">
																	<div className="neutral-80 lable-frame">
																		Area
																	</div>
																	<div className="mr-auto">
																		 {obj.sub_category_desc}
																	</div>
																	{/* <div>
                                                            <span className="mr-1">
                                                                <svg xmlns="http://www.w3.org/2000/svg" id="download-finished-file-tick-workflow-system-filled" width={24} height={24} viewBox="0 0 20 20">
                                                                    <path id="Path_1081" data-name="Path 1081" d="M947,3658.929a8.746,8.746,0,1,0,8.746,8.747A8.746,8.746,0,0,0,947,3658.929Zm3.894,6.793-4.79,4.789a.624.624,0,0,1-.883,0l-2.115-2.114a.625.625,0,0,1,.883-.883l1.673,1.673,4.348-4.348a.625.625,0,1,1,.883.884Z" transform="translate(-937.004 -3657.675)" fill="#fff" style={{ fill: '#1A873C' }} />
                                                                    <rect id="Rectangle_627" data-name="Rectangle 627" width={20} height={20} fill="none" />
                                                                </svg>
                                                            </span>
                                                            <span className="button-md font-medium">Validated</span>
                                                        </div> */}
																</div>
															</div>
															<div className="divider" />
															<div className="d-none">
																{/* <div className="d-flex flex-sm-row flex-column body-01 font-medium mb-3">
                                                        <div className="neutral-80 lable-frame">Department</div>
                                                        <div className="mr-auto">: Academics</div>
                                                    </div> */}
																<div className="d-flex flex-sm-row flex-column body-01 font-medium mb-3">
																	<div className="neutral-80 lable-frame">
																		Curriculum Vitae
																	</div>
																	<div className="mr-auto">
																		:{" "}
																		<a href="/" className="primary">
																			{obj.cv_file}
																		</a>
																	</div>
																</div>
															</div>
														</div>
													</section>
												)}

												{!!enableProfileEdit && (
													<section
														className="section-common-space overflow-hidden details-wrapper edited-mode"
														// style={{ display: "none" }}
													>
														<div className>
															<div className="row">
																<div className="col-lg-2">
																	<div className="form-wrapper">
																		<div className="form-group input-container">
																			<DropDown
																				label="Prefix"
																				name="salutation"
																				setFieldValue={setFieldValue}
																			>
																				<option value="" key={1} disabled>
																					Select
																				</option>
																				<option value="Dr." key={2}>
																					Dr.
																				</option>
																				<option value="Mr." key={3}>
																					Mr.
																				</option>
																				<option value="Ms" key={4}>
																					Ms.
																				</option>
																				<option value="Mrs" key={5}>
																					Mrs.
																				</option>
																				<option value="other" key={6}>
																					Other
																				</option>
																			</DropDown>
																		</div>
																	</div>
																</div>
															</div>
															<div className="row">
																<div className="col-lg-6 col-12">
																	<div className="form-group input-container">
																		<label
																			htmlFor="user-name font-medium button-md neutral-70"
																			className="control-label"
																		>
																			First Name
																		</label>
																		<TextInput
																			className="form-control"
																			name="firstName"
																			type="text"
																			placeholder="First Name"
																		/>

																		<div className="right-icon clear-value">
																			<svg
																				xmlns="http://www.w3.org/2000/svg"
																				viewBox="0 0 28 28"
																			>
																				<g transform="translate(3.498 3.498)">
																					<path
																						d="M1648.295,1400.784a10.5,10.5,0,1,0,10.5,10.5A10.5,10.5,0,0,0,1648.295,1400.784Zm0,19.254a8.753,8.753,0,1,1,8.753-8.753A8.762,8.762,0,0,1,1648.295,1420.038Z"
																						transform="translate(-1637.793 -1400.784)"
																					/>
																					<path
																						d="M1652.651,1408.881a.876.876,0,0,0-1.237,0l-2.147,2.147-2.146-2.147a.875.875,0,0,0-1.238,1.237l2.148,2.147-2.148,2.147a.875.875,0,0,0,1.238,1.237l2.146-2.147,2.147,2.147a.875.875,0,0,0,1.237-1.237l-2.147-2.147,2.147-2.147A.877.877,0,0,0,1652.651,1408.881Z"
																						transform="translate(-1638.772 -1401.764)"
																					/>
																				</g>
																			</svg>
																		</div>
																	</div>
																</div>
																<div className="col-lg-6 col-12">
																	<div className="form-group input-container">
																		<label
																			htmlFor="last_name font-medium button-md neutral-70"
																			className="control-label"
																		>
																			Last Name
																		</label>
																		<TextInput
																			className="form-control"
																			name="lastName"
																			type="text"
																			placeholder="Last Name"
																			// value={(obj.last_name)}
																		/>
																		<div className="right-icon clear-value">
																			<svg
																				xmlns="http://www.w3.org/2000/svg"
																				viewBox="0 0 28 28"
																			>
																				<g transform="translate(3.498 3.498)">
																					<path
																						d="M1648.295,1400.784a10.5,10.5,0,1,0,10.5,10.5A10.5,10.5,0,0,0,1648.295,1400.784Zm0,19.254a8.753,8.753,0,1,1,8.753-8.753A8.762,8.762,0,0,1,1648.295,1420.038Z"
																						transform="translate(-1637.793 -1400.784)"
																					/>
																					<path
																						d="M1652.651,1408.881a.876.876,0,0,0-1.237,0l-2.147,2.147-2.146-2.147a.875.875,0,0,0-1.238,1.237l2.148,2.147-2.148,2.147a.875.875,0,0,0,1.238,1.237l2.146-2.147,2.147,2.147a.875.875,0,0,0,1.237-1.237l-2.147-2.147,2.147-2.147A.877.877,0,0,0,1652.651,1408.881Z"
																						transform="translate(-1638.772 -1401.764)"
																					/>
																				</g>
																			</svg>
																		</div>
																	</div>
																</div>
															</div>
															<div className="row">
																<div className="col-sm-12">
																	<div className="form-group input-container with-left-icon">
																		<label
																			htmlFor="user-name font-medium button-md neutral-70"
																			className="control-label"
																		>
																			Email
																		</label>
																		<TextInput
																			className="form-control"
																			name="email"
																			type="text"
																			placeholder="abc@xyz.com"
																			// value={(obj.email)}
																		/>

																		<div className="left-icon">
																			<svg
																				id="Left_Icon"
																				data-name="Left Icon"
																				xmlns="http://www.w3.org/2000/svg"
																				width={20}
																				height={20}
																				viewBox="0 0 20 20"
																			>
																				<g
																					id="Group_868"
																					data-name="Group 868"
																					transform="translate(0 2.552)"
																				>
																					<path
																						id="Path_856"
																						data-name="Path 856"
																						d="M372.777,1486.522a.491.491,0,0,0,.706,0l8.742-7.542a.21.21,0,0,0-.1-.366.938.938,0,0,0-.146-.011H364.284a1,1,0,0,0-.167.014.21.21,0,0,0-.1.366Z"
																						transform="translate(-363.131 -1478.604)"
																						fill="#767b87"
																					/>
																					<path
																						id="Path_857"
																						data-name="Path 857"
																						d="M374.131,1487.775a1.729,1.729,0,0,1-2.327,0l-8.372-7.2a.28.28,0,0,0-.462.211c0,2.481,0,11.716,0,11.716a1.242,1.242,0,0,0,1.153,1.314h17.692a1.242,1.242,0,0,0,1.154-1.314s0-9.251,0-11.736a.279.279,0,0,0-.462-.21Z"
																						transform="translate(-362.968 -1478.917)"
																						fill="#767b87"
																					/>
																				</g>
																				<rect
																					id="Rectangle_523"
																					data-name="Rectangle 523"
																					width={20}
																					height={20}
																					fill="none"
																				/>
																			</svg>
																		</div>
																		<div
																			className="right-icon clear-value"
																			style={{ display: "none" }}
																		>
																			<svg
																				xmlns="http://www.w3.org/2000/svg"
																				viewBox="0 0 28 28"
																			>
																				<g transform="translate(3.498 3.498)">
																					<path
																						d="M1648.295,1400.784a10.5,10.5,0,1,0,10.5,10.5A10.5,10.5,0,0,0,1648.295,1400.784Zm0,19.254a8.753,8.753,0,1,1,8.753-8.753A8.762,8.762,0,0,1,1648.295,1420.038Z"
																						transform="translate(-1637.793 -1400.784)"
																					/>
																					<path
																						d="M1652.651,1408.881a.876.876,0,0,0-1.237,0l-2.147,2.147-2.146-2.147a.875.875,0,0,0-1.238,1.237l2.148,2.147-2.148,2.147a.875.875,0,0,0,1.238,1.237l2.146-2.147,2.147,2.147a.875.875,0,0,0,1.237-1.237l-2.147-2.147,2.147-2.147A.877.877,0,0,0,1652.651,1408.881Z"
																						transform="translate(-1638.772 -1401.764)"
																					/>
																				</g>
																			</svg>
																		</div>
																	</div>
																</div>

																<div className="col-sm-3 d-flex align-items-center">
																	<span
																		className={
																			obj.email_verify_flag
																				? "DivHide ml-5"
																				: undefined
																		}
																	>
																		{/* <a
																onClick={() => ValidateInput(obj.email)}
																className="arrow-red btn btn-secondary center-v"
																
															>
																Validate
															</a> */}
																	</span>
																	<span
																		className={
																			!obj.email_verify_flag
																				? "DivHide"
																				: undefined
																		}
																	>
																		<span className="mr-1">
																			<svg
																				xmlns="http://www.w3.org/2000/svg"
																				id="download-finished-file-tick-workflow-system-filled"
																				width={24}
																				height={24}
																				viewBox="0 0 20 20"
																			>
																				<path
																					id="Path_1081"
																					data-name="Path 1081"
																					d="M947,3658.929a8.746,8.746,0,1,0,8.746,8.747A8.746,8.746,0,0,0,947,3658.929Zm3.894,6.793-4.79,4.789a.624.624,0,0,1-.883,0l-2.115-2.114a.625.625,0,0,1,.883-.883l1.673,1.673,4.348-4.348a.625.625,0,1,1,.883.884Z"
																					transform="translate(-937.004 -3657.675)"
																					fill="#fff"
																					style={{ fill: "#1A873C" }}
																				/>
																				<rect
																					id="Rectangle_627"
																					data-name="Rectangle 627"
																					width={20}
																					height={20}
																					fill="none"
																				/>
																			</svg>
																		</span>
																		<span className="button-md font-medium">
																			Validated
																		</span>
																	</span>
																</div>
															</div>
															<div className="row">
																<div className="col-sm-12">
																	<div className="d-flex">
																		<div>
																			<div className="mr-2">
																				<div className="form-group input-container">
																					{/* <label htmlFor="state" className="control-label">ISD Code</label> */}
																					<DropDown
																						label="ISD Code"
																						name="isd_code"
																						disabled
																						setFieldValue={setFieldValue}
																					>
																						<option
																							key={"k1"}
																							value=""
																							disabled
																						>
																							Select
																						</option>
																						{countryISDCodes?.map((isdCode) => (
																							<option
																								value={isdCode}
																								key={isdCode}
																							>
																								{isdCode}
																							</option>
																						))}
																					</DropDown>

																					<div className="left-icon">
																						<svg
																							xmlns="http://www.w3.org/2000/svg"
																							viewBox="0 0 16 16"
																						>
																							<defs>
																								<style
																									dangerouslySetInnerHTML={{
																										__html:
																											".a {fill: ;}.b {fill: #767b87;}.c {fill: #fff;}",
																									}}
																								/>
																							</defs>
																							<rect
																								className="a"
																								width={16}
																								height={16}
																							/>
																							<g transform="translate(0.818 1.447)">
																								<circle
																									className="b"
																									cx={7}
																									cy={7}
																									r={7}
																									transform="translate(0.182 -0.447)"
																								/>
																								<path
																									className="c"
																									d="M242.263,314.6a2.976,2.976,0,0,0,1.15-2.069l1.207,2.069Zm-4.783-2.069-1.207,2.069h2.356A2.976,2.976,0,0,1,237.48,312.536Zm2.966-3.274a2.968,2.968,0,0,1,1.2.254l-1.2-2.065-1.2,2.065A2.967,2.967,0,0,1,240.446,309.261Zm0,.873a2.108,2.108,0,1,0,2.108,2.108A2.108,2.108,0,0,0,240.446,310.134Z"
																									transform="translate(-233.093 -304.898)"
																								/>
																							</g>
																						</svg>
																					</div>
																				</div>
																			</div>
																		</div>
																		<div className="flex-fill">
																			<div className="form-group input-container with-left-icon">
																				{/* <input type="number" id="user-name" required value={obj.mobile} className="form-control" placeholder="Textfields" /> */}
																				<label
																					htmlFor="user-name font-medium button-md neutral-70"
																					className="control-label"
																				>
																					Phone Number
																				</label>
																				<TextInput
																					className="form-control"
																					name="mobile"
																					type="text"
																					placeholder="998*******"
																					maxLength={15}
																					disabled
																				/>
																				<small
																					id="errorhelp"
																					className="massage error"
																				>
																					Invalid text
																				</small>
																				<div className="left-icon">
																					<svg
																						id="mobile-device-hardware-functional-filled"
																						xmlns="http://www.w3.org/2000/svg"
																						width={20}
																						height={20}
																						viewBox="0 0 20 20"
																					>
																						<path
																							id="Path_2259"
																							data-name="Path 2259"
																							d="M564.656,10650.936h-6.719a1.951,1.951,0,0,0-1.846,2.037v13.419a1.95,1.95,0,0,0,1.846,2.036h6.719a1.95,1.95,0,0,0,1.847-2.036v-13.419A1.951,1.951,0,0,0,564.656,10650.936Zm-3.328,15.323a.833.833,0,1,1,.833-.833A.833.833,0,0,1,561.328,10666.259Z"
																							transform="translate(-551.297 -10649.682)"
																							fill="#767b87"
																						/>
																						<rect
																							id="Rectangle_1162"
																							data-name="Rectangle 1162"
																							width={20}
																							height={20}
																							fill="none"
																						/>
																					</svg>
																				</div>
																				<div
																					className="right-icon clear-value"
																					style={{ display: "none" }}
																				>
																					<svg
																						xmlns="http://www.w3.org/2000/svg"
																						viewBox="0 0 28 28"
																					>
																						<g transform="translate(3.498 3.498)">
																							<path
																								d="M1648.295,1400.784a10.5,10.5,0,1,0,10.5,10.5A10.5,10.5,0,0,0,1648.295,1400.784Zm0,19.254a8.753,8.753,0,1,1,8.753-8.753A8.762,8.762,0,0,1,1648.295,1420.038Z"
																								transform="translate(-1637.793 -1400.784)"
																							/>
																							<path
																								d="M1652.651,1408.881a.876.876,0,0,0-1.237,0l-2.147,2.147-2.146-2.147a.875.875,0,0,0-1.238,1.237l2.148,2.147-2.148,2.147a.875.875,0,0,0,1.238,1.237l2.146-2.147,2.147,2.147a.875.875,0,0,0,1.237-1.237l-2.147-2.147,2.147-2.147A.877.877,0,0,0,1652.651,1408.881Z"
																								transform="translate(-1638.772 -1401.764)"
																							/>
																						</g>
																					</svg>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>

																{/* edit validation for mobile */}

																<div className="col-sm-3 d-flex align-items-center">
																	<span
																		className={
																			obj.mobile_verify_flag
																				? "DivHide ml-5"
																				: undefined
																		}
																	>
																		{/* <a
																onClick={() => ValidateInput(obj.mobile)}
																className="arrow-red btn btn-secondary center-v"
															>
																Validate
															</a> */}
																	</span>
																	<span
																		className={
																			!obj.mobile_verify_flag
																				? "DivHide"
																				: undefined
																		}
																	>
																		<span className="mr-1">
																			<svg
																				xmlns="http://www.w3.org/2000/svg"
																				id="download-finished-file-tick-workflow-system-filled"
																				width={24}
																				height={24}
																				viewBox="0 0 20 20"
																			>
																				<path
																					id="Path_1081"
																					data-name="Path 1081"
																					d="M947,3658.929a8.746,8.746,0,1,0,8.746,8.747A8.746,8.746,0,0,0,947,3658.929Zm3.894,6.793-4.79,4.789a.624.624,0,0,1-.883,0l-2.115-2.114a.625.625,0,0,1,.883-.883l1.673,1.673,4.348-4.348a.625.625,0,1,1,.883.884Z"
																					transform="translate(-937.004 -3657.675)"
																					fill="#fff"
																					style={{ fill: "#1A873C" }}
																				/>
																				<rect
																					id="Rectangle_627"
																					data-name="Rectangle 627"
																					width={20}
																					height={20}
																					fill="none"
																				/>
																			</svg>
																		</span>
																		{/* <span className="button-md font-medium">
																Validated
															</span> */}
																	</span>
																</div>
															</div>
															<div className="row">
																<div className="col-12 pb-1">
																	<div className="form-group input-container with-left-icon">
																		{/* <input type="text" id="user-name" required value={obj.linkedin_url} className="form-control" placeholder="Textfields" /> */}
																		<TextInput
																			className="form-control"
																			name="linkedin_url"
																			type="text"
																			placeholder="linkedin.com/in/xyz"
																			// value={(obj.linkedin_url)}
																		/>
																		<label
																			htmlFor="user-name font-medium button-md neutral-70"
																			className="control-label"
																		>
																			LinkedIn URL / Website URL
																		</label>
																		<small
																			id="errorhelp"
																			className="massage error"
																		>
																			Invalid text
																		</small>
																		<div className="left-icon">
																			<svg
																				id="Left_Icon"
																				data-name="Left Icon"
																				xmlns="http://www.w3.org/2000/svg"
																				width={20}
																				height={20}
																				viewBox="0 0 20 20"
																			>
																				<g
																					id="Group_2263"
																					data-name="Group 2263"
																					transform="translate(2.51 1.242)"
																				>
																					<path
																						id="Path_2051"
																						data-name="Path 2051"
																						d="M370.174,8672.918a3.856,3.856,0,1,0-3.857-3.856A3.86,3.86,0,0,0,370.174,8672.918Z"
																						transform="translate(-362.683 -8665.205)"
																						fill="#767b87"
																					/>
																					<path
																						id="Path_2052"
																						data-name="Path 2052"
																						d="M369.447,8675.482a7.5,7.5,0,0,0-7.491,7.489,1.461,1.461,0,0,0,1.462,1.462h12.057a1.461,1.461,0,0,0,1.462-1.462A7.5,7.5,0,0,0,369.447,8675.482Z"
																						transform="translate(-361.956 -8666.918)"
																						fill="#767b87"
																					/>
																				</g>
																				<rect
																					id="Rectangle_1059"
																					data-name="Rectangle 1059"
																					width={20}
																					height={20}
																					fill="none"
																				/>
																			</svg>
																		</div>
																		<div
																			className="right-icon clear-value"
																			style={{ display: "none" }}
																		>
																			<svg
																				xmlns="http://www.w3.org/2000/svg"
																				viewBox="0 0 28 28"
																			>
																				<g transform="translate(3.498 3.498)">
																					<path
																						d="M1648.295,1400.784a10.5,10.5,0,1,0,10.5,10.5A10.5,10.5,0,0,0,1648.295,1400.784Zm0,19.254a8.753,8.753,0,1,1,8.753-8.753A8.762,8.762,0,0,1,1648.295,1420.038Z"
																						transform="translate(-1637.793 -1400.784)"
																					/>
																					<path
																						d="M1652.651,1408.881a.876.876,0,0,0-1.237,0l-2.147,2.147-2.146-2.147a.875.875,0,0,0-1.238,1.237l2.148,2.147-2.148,2.147a.875.875,0,0,0,1.238,1.237l2.146-2.147,2.147,2.147a.875.875,0,0,0,1.237-1.237l-2.147-2.147,2.147-2.147A.877.877,0,0,0,1652.651,1408.881Z"
																						transform="translate(-1638.772 -1401.764)"
																					/>
																				</g>
																			</svg>
																		</div>
																	</div>
																</div>
															</div>
															<div className="row mt-4">
																<div className="col-lg-6 col-12">
																	<div className="form-group input-container">
																		<DropDown
																			label="Area Of Interest"
																			name="category_id"
																			AreaSelection={AreaSelection}
																			setFieldValue={setFieldValue}
																		>
																			<option key={"k2"} value="" disabled>
																				Select Area of Interest
																			</option>
																			{departmentData?.sort()?.map((area) => (
																				<option
																					key={area.category_id}
																					value={area.category_id}
																				>
																					{area.category_name}
																				</option>
																			))}
																		</DropDown>
																	</div>
																</div>
																<div className="col-lg-6 col-12">
																	<div className="form-group input-container">
																		<DropDown
																			label="Department"
																			name="sub_category_id"
																			setFieldValue={setFieldValue}
																		>
																			<option key={"k3"} value="" disabled>
																				Select Department
																			</option>
																			{areaData?.map((area) => (
																				<option
																					key={area.sub_category_id}
																					value={area.sub_category_id}
																				>
																					{area.sub_category_name}
																				</option>
																			))}
																		</DropDown>
																	</div>
																</div>
															</div>
															<div className="row d-none">
																<div className="col-12 border-bottom pb-1">
																	<div className="input-file-frame">
																		{/* <label htmlFor="user-name font-medium button-md neutral-70" className="control-label">Curriculum Vitae</label> */}
																		<div className="form-group input-container with-left-icon mb-2 custom-file">
																			{/* <input type="file" className="custom-file-input" id="customFile" name="filename" /> */}
																			{/* <Attachment
																		className="custom-file-input"
																		label={fileName}
																		name="cvFile"
																		onChange={(e) => {
																			e.preventDefault();
																			setfileName(e.target.files[0].name);
																			// cvFile = e.target.files[0].name;
																			FileUpdate(e.target.files[0]);
																		}}
																	/> */}
																			<input
																				className="custom-file-input"
																				type="file"
																				onChange={(e) => {
																					e.preventDefault();
																					setfileName(e.target.files[0].name);
																					// cvFile = e.target.files[0].name;
																					FileUpdate(e.target.files[0]);
																				}}
																				// value={fileName}
																			/>
																			<label
																				className="custom-file-label"
																				htmlFor="customFile"
																			>
																				{fileName}
																			</label>
																			{/* <Attachment
																	className="form-control custom-file-input"
																	// label="Upload your CV from storage"
																	label={fileName}
																	name="cvFile"
																	type="file"
																	// value={fileName}
																	onChange={(e) => {
																		e.preventDefault();
																		setfileName(e.target.files[0].name);
																		//  cvFile = e.target.files[0].name;
																		FileUpdate(e.target.files[0]);
																	}}
																/> */}
																			<div className="left-icon">
																				<svg
																					id="Left_Icon"
																					data-name="Left Icon"
																					xmlns="http://www.w3.org/2000/svg"
																					width={20}
																					height={20}
																					viewBox="0 0 20 20"
																				>
																					<g
																						id="Group_1173"
																						data-name="Group 1173"
																						transform="translate(0 1.871)"
																					>
																						<path
																							id="Path_1112"
																							data-name="Path 1112"
																							d="M1725.562,3857.3v-1.515a1.6,1.6,0,0,0-1.578-1.612h-8.4s-1.188-1.114-2.55-2.5h-5.89a1.6,1.6,0,0,0-1.577,1.612v4.018Z"
																							transform="translate(-1705.562 -3851.669)"
																							fill="#767b87"
																						/>
																						<path
																							id="Path_1113"
																							data-name="Path 1113"
																							d="M1705.562,3859.924v7.666a1.767,1.767,0,0,0,1.577,1.715h16.844a1.767,1.767,0,0,0,1.578-1.715v-7.666Z"
																							transform="translate(-1705.562 -3853.045)"
																							fill="#767b87"
																						/>
																					</g>
																					<rect
																						id="Rectangle_642"
																						data-name="Rectangle 642"
																						width={20}
																						height={20}
																						fill="none"
																					/>
																				</svg>
																			</div>
																		</div>
																	</div>
																	{isUploading && (
																		<>
																			<Spinner
																				animation="border"
																				role="status"
																				variant="danger"
																			></Spinner>
																			<br />
																			<span className="visually-hidden">
																				Loading...
																			</span>
																		</>
																	)}
																	<br />
																	<small className="font-medium button-sm neutral-70">
																		One file only.5 MB limit.Allowed types: pdf,
																		doc, png docx.
																	</small>
																</div>
															</div>
															<div className="row mt-lg-4">
																<div
																	className="col-lg-6 col-12"
																	style={{ padding: "0px 17px" }}
																>
																	<div className="form-group input-container">
																		{/* <label htmlFor="user-name font-medium button-md neutral-70" className="control-label">Enter Captcha</label> */}
																		{/* <input type="text" id="number" required className="form-control" placeholder /> */}
																		<TextInput
																			className="form-control"
																			label="Enter Captcha"
																			name="captcha"
																			type="text"
																			placeholder="Captcha text"
																			// value={(obj.cvFile)}
																		/>
																		<div
																			className="right-icon clear-value "
																			style={{ top: "10px" }}
																		>
																			<svg
																				xmlns="http://www.w3.org/2000/svg"
																				viewBox="0 0 28 28"
																			>
																				<g transform="translate(3.498 3.498)">
																					<path
																						d="M1648.295,1400.784a10.5,10.5,0,1,0,10.5,10.5A10.5,10.5,0,0,0,1648.295,1400.784Zm0,19.254a8.753,8.753,0,1,1,8.753-8.753A8.762,8.762,0,0,1,1648.295,1420.038Z"
																						transform="translate(-1637.793 -1400.784)"
																					/>
																					<path
																						d="M1652.651,1408.881a.876.876,0,0,0-1.237,0l-2.147,2.147-2.146-2.147a.875.875,0,0,0-1.238,1.237l2.148,2.147-2.148,2.147a.875.875,0,0,0,1.238,1.237l2.146-2.147,2.147,2.147a.875.875,0,0,0,1.237-1.237l-2.147-2.147,2.147-2.147A.877.877,0,0,0,1652.651,1408.881Z"
																						transform="translate(-1638.772 -1401.764)"
																					/>
																				</g>
																			</svg>
																		</div>
																	</div>
																</div>
																<div className="col-lg-6 col-12">
																	<div className="captcha mb-4">
																		<img
																			className="img-fluid captcha-img rounded mr-2"
																			src={captchaImgURL}
																			// src="/api/Login/captcha"
																			alt="Captcha"
																			lazy="true"
																		/>
																		<Button
																			className="ml-1"
																			onClick={() =>
																				setcaptchaImgURL(
																					`/api/Login/captcha?${Math.floor(
																						Math.random() * 100
																					)}`
																				)
																			}
																		>
																			<i
																				className="bi bi-arrow-clockwise"
																				style={{ fontSize: "1.1rem" }}
																			></i>
																		</Button>
																	</div>
																</div>
															</div>
															{/* <div className="row mt-5">
														<div className="col-12">
															<div className="body-02 primary"><i>Log in to your account to apply/save the job</i></div>
														</div>
													</div> */}
															<div className="row mt-3">
																<div className="col-12">
																	<button
																		className="btn btn-primary center-v arrow-white"
																		type="submit"
																		disabled={isUploading}
																	>
																		Update
																	</button>

																	<span
																		className="btn center-v"
																		onClick={() => handleProfilePicEdit(false)}
																	>
																		Cancel
																	</span>
																</div>
															</div>
														</div>
													</section>
												)}
											</>
										</Form>
									);
								}}
							</Formik>
						);
					})}
				</div>
			</div>

			<Modal
				show={show}
				onHide={handleClose}
				size="md"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header>
					<div className="text-center w-100">
						<h5
							className="modal-title font-medium h5-05"
							id="exampleModalLiveLabel"
						>
							Please validate again
						</h5>
					</div>
					<button
						type="button"
						className="close"
						data-dismiss="modal"
						aria-label="Close"
						onClick={handleClose}
					>
						<span aria-hidden="true">×</span>
					</button>
				</Modal.Header>
				<Modal.Body>
					<div className="modal-body text-center my-5">
						<p className="overline-01 font-medium neutral-80 mb-3">
							Enter the OTP
						</p>
						<div className="otpfield">
							<div className=" mb-2 center_align_elmnt ">
								<div>
									<OtpInput
										value={otp}
										onChange={(e) => setotp(e)}
										autoComplete="on"
										shouldAutoFocus={true}
										pattern="^[1-9]\d*$"
										numInputs={6}
										isInputNum={true}
										containerStyle={"otp_input"}
										inputStyle={"otp_fields"}
										autocomplete="one-time-code"
									/>
								</div>
							</div>
							<span className="sp--m pd--top j-Space__vertical j-Space" />
							<OtpTimer
								className="my-5"
								minutes={0}
								seconds={59}
								text={<p className="mt-5 d-inline-flex fh-10">Resend OTP in</p>}
								ButtonText={<p className="mt-5 text-danger">Resend OTP </p>}
								resend={() => sendOTP(userLoginID)}
								textColor="dark"
								buttonColor="primary"
								background="white"
								style={{ width: "100%" }}
							/>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer className="footer_btns">
					<a
						href="#"
						onClick={(e) => {
							e.preventDefault();
							setShow(false);
							setotp("");
						}}
						className=" btn center-v mt-2 mt-lg-0 text-nowrap mr-2"
						data-dismiss="modal"
					>
						Cancel
					</a>
					<a
						onClick={_.throttle((e) => {
							e.preventDefault();
							submitOTP(otp);
						})}
						className="arrow-white btn btn-primary center-v"
					>
						Proceed
					</a>
				</Modal.Footer>
			</Modal>
			<ToastContainer autoClose={2500} theme="colored" />
		</>
	);
}
