import {
	FETCH_MASTER_DATA,
	SET_MASTER_DATA,
	UPDATE_MASTER_DATA,
	SET_APPLICATION_STATUS_MASTER,
} from "./MasterdataActionTypes";
import axios from "axios";

export const fetchMasterData = () => {
	return (dispatch) => {
		axios
			.get("/api/JobDetails/DepartmentAreaMaster")
			.then((response) => {
				// response.data is the master data
				const masterData = response?.data?.data;
				// console.log("MSRT DATA", masterData);
				dispatch(SetMasterdata(masterData));
			})
			.catch((error) => {
				//console.log(error);
				// error.message is the error message
				// dispatch(fetchVideosFailure(error.message));
			});
	};
};
export const fetchApplicationStatusMasterData = () => {
	return (dispatch) => {
		axios
			.get(`/api/JobDetails/ApplicationStatusMaster`)
			.then((response) => {
				// response.data is the master data
				const masterData = response?.data?.data?.application_Job_Status_Master;
				dispatch(SetApplicationStatusMasterdata(masterData));
			})
			.catch((error) => {
				//console.log(error);
				// error.message is the error message
			});
	};
};

export const fetchMasterdataRequest = () => {
	return {
		type: FETCH_MASTER_DATA,
	};
};

export const SetMasterdata = (Unit) => {
	return {
		type: SET_MASTER_DATA,
		payload: Unit,
	};
};
export const SetApplicationStatusMasterdata = (data) => {
	return {
		type: SET_APPLICATION_STATUS_MASTER,
		payload: data,
	};
};
export const updateMasterdata = (data) => {
	return {
		type: UPDATE_MASTER_DATA,
		payload: data,
	};
};
