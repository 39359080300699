import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { makeStyles } from "@mui/styles";
import { useField } from "formik";
import axios from "axios";

const useStyles = makeStyles({
	root: {
		"& .MuiOutlinedInput-input": {
			color: "#000",
			fontSize: "1rem",
			fontWeight: 400,
			fontFamily: "JioTypeW04-Medium",
		},
	},
});

const MuiSelect = ({
	label,
	refresh,
	setFieldValue = null,
	setrefresh = null,
	field_id,
	setFieldTouched = null,
	name,
	handleAosChange = null,
	...props
}) => {
	const [DropDownData, setDropDown] = useState([]);
	const [field, meta] = useField(name);
	const classes = useStyles();

	//console.log(field,'field')
	//console.log(DropDownData,'DropDownData')

	let Country = localStorage.getItem("Country");
	let State = localStorage.getItem("State");
	let City = localStorage.getItem("City");
	let District = localStorage.getItem("District");
	let valueX = DropDownData.filter((v)=>{
		if(field.value === ""){
			//console.log('if')
			return null
		} else{
			//console.log('else')
			return v.value_id == Number(field.value)
		}
	});
	//console.log(valueX,'valueX')
		
		//console.log(DropDownData,'DropData')

	useEffect(() => {
		if(field?.name.includes("AreaofSpecialisation")){
			handleAosChange(field?.value)
		}
	}, [field?.value]);

	useEffect(() => {
		return () => {};
	}, [Country, State, City, District, refresh]);

	useEffect(() => {
		axios
			.get("api/JobApplication/GetFieldControlMaster", {
				params: {
					field_id: field_id,
				},
			})
			.then((response) => {
				const contentdata = response.data;

				if (contentdata.data[0]?.category_id == 0) {
					setDropDown(contentdata.data);
					if (name.includes("State")) {
						if (
							localStorage.getItem("Country") !== null ||
							localStorage.getItem("Country") !== undefined
						) {
							setDropDown(
								[...contentdata.data?.filter(
									(x) => x.parent_field_id == localStorage.getItem("Country")
								),{category_id:contentdata.data[0]?.category_id,field_id:field_id,parent_field_id:contentdata.data[0]?.parent_field_id,value_id:0,value_name:'Other'}]
							);
						}
					}
					if (name.includes("District")) {
						if (
							localStorage.getItem("State") !== null ||
							localStorage.getItem("State") !== undefined
						) {
							setDropDown(
								contentdata.data?.filter(
									(x) => x.parent_field_id == localStorage.getItem("State")
								)
							);
						}
					}
					if (name.includes("City")) {
						if (
							localStorage.getItem("State") !== null ||
							localStorage.getItem("State") !== undefined
						) {
							setDropDown(
								contentdata.data?.filter(
									(x) => x.parent_field_id == localStorage.getItem("State")
								)
							);
						}
					}
				} else if (contentdata.data[0].category_id != 0) {
					setDropDown(
						contentdata.data?.filter(
							(x) => x.category_id == localStorage.getItem("category_id")
						)
					);
				}
			})
			.catch((error) => {
				//console.log("error axios", error.message);
			});
	}, [field_id, Country, State, City, District]);

  return (
	<div className='mb-3'>
	 <label
		htmlFor="user-name font-medium button-md neutral-70"
		className="control-label"
		style={{fontSize:"0.8rem",marginBottom:"-0.5rem"}}
		>
		{label}
		</label>
      <Autocomplete
        id="free-solo-demo"
		disableClearable
		size="small"
		//defaultValue={ valueX[0]?.value_name || ""}
		 value={ valueX[0]?.value_name  ? valueX[0]?.value_name : ""}
        options={DropDownData?.length == 0 ? ['Other'] : DropDownData.map((v) => v.value_name)}
		onChange={( _, value )=>{
			setFieldValue( field.name, value );
			const filterX = DropDownData?.length > 0 ? DropDownData?.filter((v)=>v.value_name == value) : [
				{category_id:0,field_id:267,parent_field_id:Country,value_id:0,value_name:'Other'}
			]
			//console.log(filterX,'filterX')


					if (field.name.includes("Country")) {
						localStorage.setItem("Country", Number(filterX[0]?.value_id));
					}
					if (field.name.includes("State")) {
						localStorage.setItem("State", Number(filterX[0]?.value_id));
					}
					if (field.name.includes("City")) {
						localStorage.setItem("City", Number(filterX[0]?.value_id));
					}
					if (field.name.includes("District")) {
						localStorage.setItem("District", Number(filterX[0]?.value_id));
					}
					// console.log("Value ffff", field.name);
					if (setrefresh != null || setrefresh != undefined) {
						setrefresh(!refresh);
					}
					if (setFieldValue != null || setFieldValue != undefined) {
						setFieldValue(name, filterX[0]?.value_id);
					}
				}}
				onBlur={() => {
					setFieldTouched(field.name, true);
				}}
				className={classes.root}
				renderInput={(params) => {
					return (
						<TextField
							{...params}
							placeholder="Select"
							sx={{
								input: {
									"&::placeholder": {
										opacity: 1,
									},
								},
							}}
						/>
					);
				}}
			/>
			{meta.touched && meta.error ? (
				<small className="massage error d-block">{meta.error}</small>
			) : null}
		</div>
	);
};

export default MuiSelect;