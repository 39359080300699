import axios from "axios";
import { GetCookie } from "../Common/cookie";
let timeoutRecall = 0
let maxTimeoutRecall = 2

export function AxiosRequestInterceptors(token){
	axios.interceptors.request.use(function (config) {
		// Do something before request is sent
		//if (token){
		//	//const newToken = token.slice(0,-3).slice(3)
		//	Object.assign(config.headers,{Authorization:`Bearer ${token}`})
		//}
		const sessionId = GetCookie("session-id");
		Object.assign(config.headers,{sessionId:sessionId})
		//console.log(config)
		return config;
	});
}

export function AxiosResponseInterceptors(token, handleSessionRefresh, handleInvalidSession){
	axios.interceptors.response.use(response => {
		//console.log(response,'response')
		return response
	}, error => {
		//console.log(error?.response,'error')
		const status = error?.response ? error?.response?.data?.statusCode : null
	
		if (status === 425) {
			timeoutRecall++
			if(timeoutRecall >= maxTimeoutRecall){
				handleSessionRefresh()
			}
		}
		if (status === 421) {
            handleInvalidSession()
        }
	
		return Promise.reject(error);
	});
}