import React from "react";

import Header from "../Common/Header";
import Footer from "../Common/Footer";
import { Button } from "react-bootstrap";
import "./ErrorFallback.css";
import PageNotFound from "./PageNotFound";

function ErrorFallback() {
	return (
		<>
			<Header />

			{/* <div style={{ marginTop: "5rem" }}>
				<h1 className="page_heading text-bold">Oops! Something went wrong.</h1>
				<p className="page_para">
					<br />
					Try &nbsp;<kbd>Ctrl</kbd> + <kbd>F5</kbd>
					<br />
					<br />
					OR
					<br />
					<br />
					<kbd>Ctrl</kbd> + <kbd>Shift</kbd> + <kbd>R</kbd>
					<br />
					<br />
					<br />
					<br />
					<Button
						className="btn-primary mw-100"
						onClick={() => window.history.back(-1)}
					>
						Go Back
					</Button>
					<br />
				</p>
			</div>*/}

			<div className="main-container full_width">
				<section className="section-masthead space-before-breadcrumb">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								{/*breadcrumb*/}
								<nav aria-label="breadcrumb">
									<ol className="breadcrumb">
										<li className="breadcrumb-item">
											<a href="#">Jio Institute</a>
										</li>
										<li className="breadcrumb-item active" aria-current="page">
											Page not Found
										</li>
									</ol>
								</nav>
								{/*breadcrumb end*/}
							</div>
						</div>
					</div>{" "}
					{/* / .container */}
				</section>
				{/* horizontal tabs */}
				<section className="section-common-space mb-5 pb-lg-5">
					<div className="container">
						<div className="row">
							<div className="col-lg-12 text-center">
								<div className="error-404-container">
									<h2 className="subhead font-medium">
										Sorry, this page isn’t available
									</h2>
									<p>
										The link you followed may be broken, or the page may have
										been removed.
									</p>
									<div className="mt-2 mb-3 mb-lg-5">
										<img
											src="assets/images/page-not-found/icon-not-found.png"
											width=""
											height=""
											alt=""
										/>
									</div>

									<div className="row">
										<div className="col-md-2" />
										<div className="col-md-8">
											<Button
												className="btn-primary w-50 px-5"
												onClick={() => window.history.back(-1)}
											>
												Go Back
											</Button>
										</div>
										<div className="col-md-2" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>

			<Footer className="position-absolute w-100 fixed-bottom" />
		</>
	);
}

export default ErrorFallback;
