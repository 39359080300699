import axios from "axios";

function getAllUserNotifications(candidate_id) 
{
	return axios
		.get(`/api/Notification/Notification?userID=${candidate_id}`)
		.then((response) => response?.data?.data)
		
		.catch((error) => {
			//console.log("error axios", error.message);
		});
}

export default getAllUserNotifications;
