import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import { persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import BackgroundLoader from "./BackgroundLoader";
import ErrorFallback from "./ErrorBoundry/ErrorFallback";

import PrivateRoute from "./PrivateRoute";
import ProtectedRoute from "./ProtectedRoute";

import Login from "./Login/Login";
import SignUp from "./Login/SignUp";
import CurrentOpenings from "./CurrentOpenings/CurrentOpenings";
import CurrentOpenings2 from "./CurrentOpenings/CurrentOpening2";
import Layout from "./Common/Layout";

import Home from "./Home/Home";
import JobDetail from "./CurrentOpenings/JobDetail";
import JobApplication from "./CurrentOpenings/JobApplication";
import Dashboard from "./Dashboard/Dashboard";
import PageNotFound from "./ErrorBoundry/PageNotFound";
import WhyJoinUs from "./About/WhyJoinUs";
import HiringProcess from "./About/HiringProcess";
import Faq from "./About/Faq";
import $ from "jquery";
import "./App.css";
import { useDispatch, useSelector } from "react-redux";
import { unregister } from "./registerServiceWorker";
import { GetCookie } from "./Common/cookie";
import { AxiosRequestInterceptors, AxiosResponseInterceptors } from "./APICaller/interceptors";
import IdleSessionContainer from "./Common/IdleSessionContainer";
import { setUserLogout } from "./redux/UserDetails/UserActions";
import axios from "axios";

export default function App() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const isAuthenticated = useSelector((state) => state.user);
	const category_id = localStorage.getItem("category_id");
	const [sessPopup,setSessPopup] = useState(false);
	

	const handleSessionRefresh = () => {
		setSessPopup(true);
	}
	const handleInvalidSession = () => {
		dispatch(setUserLogout());
		localStorage.clear(); //for localStorage
		sessionStorage.clear(); //for sessionStorage
		axios
		.post("/api/Login/Logout", null)
		.then((res) => { })
		.catch((err) => console.log("error is ", err.toString()));
		navigate("/login", { replace: false });
		window.location.reload();
	}

	//axios request interceptors for jwt
	AxiosRequestInterceptors(GetCookie("__jic__uc__ck_aix"))

	//axios response interceptors for jwt
	AxiosResponseInterceptors(GetCookie("__jic__uc__ck_aix"),handleSessionRefresh,handleInvalidSession)

	$.fn.dataImg = function (options) {
		var settings = $.extend(
			{
				sml: 400,

				med: 800,

				lrg: 1000,

				resize: false,
			},
			options
		);

		var elements = $(this);

		function getSrc(element) {
			var screen = $(window).width();

			if (screen > settings.med) {
				return element.data("lrg");
			} else if (screen <= settings.med && screen > settings.sml) {
				return element.data("med");
			}

			return element.data("sml");
		}

		function breakpoints() {
			elements.each(function () {
				var e = $(this);

				var src = getSrc(e);

				if (src != undefined) {
					if (e.is("img")) {
						e.attr("src", src);
					} else {
						e.css("background-image", "url(" + src + ")");
					}
				}
			});
		}
		breakpoints();

		if (settings.resize === true) {
			$(window).resize(function () {
				breakpoints();
			});
		}
	};
	$(function () {
		$(".data-img").dataImg({
			sml: 600,

			med: 800,

			lrg: 1000,

			resize: true,

			bgImg: true,
		});
	});
	useEffect(() => {
		unregister();
	}, []);

	useEffect(() => {
		if (category_id) {
			if (category_id == 1) {
				localStorage.setItem("currentOpeningPath", "Academics");
			}
			if (category_id == 2) {
				localStorage.setItem("currentOpeningPath", "Jio-Digital-Library");
			}
			if (category_id == 3) {
				localStorage.setItem("currentOpeningPath", "Non-Academic");
			}
		}
		if (!category_id) {
			localStorage.setItem("category_id", 1);
		}
	}, [category_id]);

	return (
		<ErrorBoundary FallbackComponent={ErrorFallback}>
			<React.Suspense fallback={<BackgroundLoader />}>
				<PersistGate loading={null} persistor={persistor}>
					<Routes>
						<Route element={<Layout show={sessPopup} setshow={setSessPopup}/>}>
							<Route exact path="/login" element={<Login />} />
							<Route
								exact
								path="/Register"
								element={
									<ProtectedRoute>
										<SignUp />
									</ProtectedRoute>
								}
							/>
							<Route exact path="/" element={<Home />}>
								<Route exact path="/home" element={<Home />}></Route>
							</Route>
							{!isAuthenticated["isLoggedIn"] && 
							<Route
								exact
								path="/current-openings/:category"
								element={<CurrentOpenings2 />}
							/>}
							{!isAuthenticated["isLoggedIn"] && 
							<Route
								exact
								path="/JobDetails/:id/:cid"
								element={<JobDetail />}
							/>}
							<Route exact path="/why-join-us" element={<WhyJoinUs />} />
							<Route exact path="/hiring-process" element={<HiringProcess />} />
							<Route exact path="/faqs" element={<Faq />} />

							<Route
								exact
								path="/JobApplication/:id/:cid"
								element={
									<IdleSessionContainer>
									<PrivateRoute>
										<JobApplication />
									</PrivateRoute>
									</IdleSessionContainer>
								}
							/>
							<Route
								exact
								path="/Dashboard"
								element={
									<IdleSessionContainer>
									<PrivateRoute>
										<Dashboard />
									</PrivateRoute>
									</IdleSessionContainer>
								}
							/>
							{isAuthenticated["isLoggedIn"] && 
							<Route
								exact
								path="/current-openings/:category"
								element={<IdleSessionContainer>
									<PrivateRoute>
										<CurrentOpenings2 />
									</PrivateRoute>
									</IdleSessionContainer>}
							/>}
							{isAuthenticated["isLoggedIn"] && 
							<Route
								exact
								path="/JobDetails/:id/:cid"
								element={<IdleSessionContainer>
									<PrivateRoute>
										<JobDetail />
									</PrivateRoute>
									</IdleSessionContainer>}
							/>}

							<Route path="*" element={<PageNotFound />} />
						</Route>
					</Routes>
				</PersistGate>
			</React.Suspense>
		</ErrorBoundary>
	);
}
