import React, { useEffect, useState } from "react";
import $ from "jquery";
import Profile from "./Profile";
import { useSelector } from "react-redux";
import { getUserAppliedJobs } from "../APICaller/getUserAppliedJobs";
import AppliedJobs from "./AppliedJobs";
import NotificationCard from "./NotificationCard";
import { getCandidateProfile } from "../APICaller/getCandidateProfile";
import getAllUserNotifications from "../APICaller/getAllUserNotifications";
import { useNavigate } from "react-router-dom";
import "./Dashboard.css";
import { Button, Modal } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import _ from "lodash";
import { GetCookie } from "../Common/cookie";
import { setUserLogout } from "../redux/UserDetails/UserActions";
import { useDispatch } from "react-redux";
import SessionExpireyPopup from "../Common/SessionExpireyPopup";

export default function Dashboard() {
	const dispatch = useDispatch()
	const userCategoryId = localStorage.getItem('userCategoryId')
	//#region jQUery
	$(".searchAbtJI").on("click", function () {
		$("body").css({ overflow: "hidden" });
		setTimeout(function () {
			$("input#topnav-search").focus();
		}, 100);
		setTimeout(function () {
			$(".pkv-searchview").addClass("slidedown");
		}, 500);
		setTimeout(function () {
			$(".pkv-searchview .search-backdrop.pkv-fade").addClass("show visible");
		}, 100);
		setTimeout(function () {
			$(".pkv-searchview .animate_search_result").addClass("animate");
			$(".pkv-searchview .animate_search_result").css({
				"box-sizing": "border-box",
				display: "block",
			});
		}, 700);
		setTimeout(function () {
			$(".pkv-searchview .pkv-searchresult-container").addClass("slidedown");
		}, 700);
	});

	$(".closeSearch, .search-backdrop").on("click", function () {
		$("body").css({ overflow: "auto" });
		setTimeout(function () {
			$(".pkv-searchview .pkv-searchresult-container").removeClass("slidedown");
		}, 100);
		setTimeout(function () {
			$("input#topnav-search").focus();
		}, 900);
		setTimeout(function () {
			$(".pkv-searchview").removeClass("slidedown");
		}, 100);
		setTimeout(function () {
			$(".pkv-searchview .search-backdrop.pkv-fade").removeClass(
				"show visible"
			);
		}, 500);
		setTimeout(function () {
			$(".pkv-searchview .animate_search_result").removeClass("animate");
			$(".pkv-searchview .animate_search_result").css({
				"box-sizing": "border-box",
				display: "none",
			});
		}, 100);
	});

	$(".form-group").each(function () {
		const $inp = $(this).find("input"),
			$cle = $(this).find(".clear-value");
		$inp.on("input", function () {
			$cle.toggle(!!this.value);
		});

		$cle.on("touchstart click", function (e) {
			e.preventDefault();
			$inp.val("").trigger("input");
		});
	});

	$(".form-group.input-container input")
		.on("focus", function () {
			if ($(this).val().length == 0) {
				$(this)
					.closest(".form-group.input-container")
					.find(".clear-value")
					.fadeOut();
			} else {
				$(this)
					.closest(".form-group.input-container")
					.find(".clear-value")
					.fadeIn();
			}
		})
		.blur(function () {
			$(".clear-value").fadeOut();
		});

	$(".form-group").each(function () {
		const $inp = $(this).find("input"),
			$cle = $(this).find(".clear-value");
		$inp.on("input", function () {
			$cle.toggle(!!this.value);
		});
		$cle.on("touchstart click", function (e) {
			e.preventDefault();
			$inp.val("").trigger("input");
		});
	});

	$(".btn-edit").on("click", function () {
		$(this).parents(".non-edit-mode").hide();
		$(this).parents(".non-edit-mode").siblings(".edited-mode").show();
	});
	$("#btnUpdate").on("click", function () {
		$(".non-edit-mode").show();
		$(".edited-mode").hide();
		$(this).parent(".section-common-space").hide();
	});
	//#endregion

	// #region
	$(".form-group").each(function () {
		const $inp = $(this).find("input"),
			$cle = $(this).find(".clear-value");
		$inp.on("input", function () {
			$cle.toggle(!!this.value);
		});
		$cle.on("touchstart click", function (e) {
			e.preventDefault();
			$inp.val("").trigger("input");
		});
	});

	$(document).ready(function () {
		$(".btn-edit").click(function () {
			$(this).parents(".non-edit-mode").hide();
			$(this).parents(".non-edit-mode").siblings(".edited-mode").show();
		});
		$("#btnUpdate").click(function () {
			$(".non-edit-mode").show();
			$(".edited-mode").hide();
			$(this).parent(".section-common-space").hide();
		});
	});

	// #endRegion
	const [key, setKey] = useState(
		localStorage.getItem("dk_cat")
			? localStorage.getItem("dk_cat")
			: "applied-jobs"
	);
	const [fileSelected, setFileSelected] = useState();

	const [SubStageMasterData1, setSubStagedata1] = useState([]);
	const [UserEmail, setemail] = useState([]);
	const [Userfirstname, setfirstname] = useState([]);
	const [appliedJobs, setappliedJobs] = useState({});
	const [notificationCount, setnotificationCount] = useState(0);
	const [notificationList, setnotificationList] = useState([]);
	const [refreshPage, setrefreshPage] = useState(false);
	const [showFileUploadModal, setshowFileUploadModal] = useState(false);
	const [profile_icon, Setprofile_icon] = useState(null);
	const [uploading, setuploading] = useState(false);
	const [profilePicUrl, setprofilePicUrl] = useState(
		"assets/images/career/icon-admin-user-profile.svg"
	);

	
	const [show, setshow] = useState(false);
	

	const navigate = useNavigate();

	const candidate_id = useSelector(
		(state) => state.user.user.userDetails?.candidate_id
	);
	const ApplStatus = useSelector(
		(state) => state.masterData.applicationStatusMaster
	);
	const isAuthenticated = useSelector((state) => state.user);

	useEffect(() => {
		getCandidateProfile(candidate_id).then((response) => {
			const contentdata = response.data;
			localStorage.setItem('userCategoryId', contentdata?.data?.category_id)
			setprofilePicUrl((state) =>
				contentdata?.data?.candidateProfilePicName
					? contentdata?.data?.candidateProfilePicName
					: state
			);
			setSubStagedata1(contentdata?.data);
			setemail(contentdata?.data?.email);
			setfirstname(contentdata?.data?.first_name);
		});
	}, [uploading, showFileUploadModal]);
	useEffect(() => {
		getUserAppliedJobs(candidate_id).then((res) => {
			setappliedJobs(_.orderBy(res, ["applied_on"], ["desc"]));
		});
	}, []);
	useEffect(() => {
		getAllUserNotifications(candidate_id).then((res) => {
			setnotificationCount(res?.notificationCount || 0);

			setnotificationList(
				_.orderBy(res?.notificationList, ["notificationCreatedOn"], ["desc"])
			);
		});
	}, [refreshPage]);

	useEffect(() => {
		if (isAuthenticated["isLoggedIn"]) {
			const isActiveSession = GetCookie("jic_auth_");
		// console.log(isActiveSession,'isActiveSession')
		if(!isActiveSession){
		setshow(true)
	}
		}
	}, []);

	function handleTabChange(key) {
		localStorage.setItem("dk_cat", key);
		setKey(key);
	}
	const saveFileSelected = (e) => {
		setFileSelected(e.target.files[0]);
		Setprofile_icon(URL.createObjectURL(e.target.files[0]));
	};

	const uploadFile = async (e) => {
		const formData = new FormData();
		formData.append("file", fileSelected);
		setuploading(true);
		try {
			//debugger;
			axios
				.post("/api/Profile/ProfilePhoto/", formData)
				.then((res) => {
					//console.log({ res });
					if (res.data.statusCode == 200 && res.success == true) {
						toast.success("Profile picture updated ", {
							position: toast.POSITION.TOP_CENTER,
						});
						window.location.reload();
					}
					setshowFileUploadModal(false);
				})
				.catch((err) => {
					//debugger;
					//console.log({ err });
					toast.error("Try again.", {
						position: toast.POSITION.TOP_CENTER,
					});
				})
				.finally(() => {
					setuploading(false);
				});
		} catch (ex) {
			toast.error("Profile picture update failed.. Try again.", {
				position: toast.POSITION.TOP_CENTER,
			});
			setuploading(false);
			//console.log({ ex });
		}
	};

	return (
		<>
			<div className="main-container full_width">
				<section className="section-masthead space-before-breadcrumb section-common-space masthead-mob-space overflow-hidden pt-0">
					<div className="container ">
						<div className="row d-flex ">
							<div className="col-lg-12">
								<nav aria-label="breadcrumb">
									<ol className="breadcrumb">
										<li className="breadcrumb-item">
											<a href="/">Home</a>
										</li>
										<li className="breadcrumb-item active" aria-current="page">
											Dashboard
										</li>
									</ol>
								</nav>
							</div>
						</div>
					</div>{" "}
					<section className="section-masthead">
						<div className="container">
							<div className="row align-items-center">
								<div className="col-12 p-0 p-md-2 space">
									<div className="position-relative overflow-hidden masthead-rounded">
										<img
											src="assets/images/career-dashboard/banner-desktop.png"
											className="w-100 data-img dashboard-banner-image"
											alt=""
											data-sml="assets/images/career-dashboard/banner-mobile.png"
											data-med="assets/images/career-dashboard/banner-tablet.png"
											data-lrg="assets/images/career-dashboard/banner-desktop.png"
										></img>
									</div>
									<div className="white position-absolute user-profile-masthead justify-content-center align-items-center d-flex d-lg-block d-md-block">
										<div className="d-flex flex-sm-row flex-column justify-content-between py-4">
											<div className="user-profile-unit">
												<div
													className="dashboard-user-profile m-auto profile-container"
													data-toggle="modal"
													data-target="#profile-pic-upload"
													onClick={() => setshowFileUploadModal(true)}
												>
													<img
														src={profilePicUrl == "assets/images/career/icon-admin-user-profile.svg" ?
															profilePicUrl : process.env.REACT_APP_USER_PROFILE_PIC_LINK + profilePicUrl
														}
														alt={Userfirstname}
														width={"100%"}
														height={"100%"}
													/>
													<div className="middle">
														<i className="text ml-0 bi bi-camera"></i>
													</div>
												</div>

												<div className="mt-2">
													<span className="white ">{UserEmail}</span>
												</div>
											</div>
											<div className="user-profile-details text-center">
												<div className="h3-03 font-bold title">Dashboard</div>
												<div className="h4-04">
													Hello{" "}
													<span className="font-medium">{Userfirstname}</span>
												</div>

												<div
													className="spacer mt-2"
													onClick={() => {
														localStorage.setItem('category_id', userCategoryId)
														if (userCategoryId == 1) {
															navigate(`/current-openings/Academics`)
														} else if (userCategoryId == 2) {
															navigate(`/current-openings/Jio-Digital-Library`)
														} else if (userCategoryId == 3) {
															navigate(`/current-openings/Non-Academic`)
														} else {
															navigate(`/current-openings/Academics`)
														}
													}
													}
												>
													<a
														href="/current-openings/"
														className="arrow-white btn white-btn-secondary center-v mt-2 mt-lg-0 text-nowrap"
														data-toggle="modal"
														data-target="#dashboardPopup"
														id="Dashboard"
													>
														Job Search
													</a>
												</div>
											</div>
											<div />
										</div>
									</div>
								</div>
							</div>{" "}
						</div>{" "}
					</section>
				</section>
				<section
					className="section-masthead space-before-breadcrumb section-common-space masthead-mob-space overflow-hidden"
					style={{ minHeight: "65vh" }}
				>
					<div className="container">
						<div className="row">
							<div className="col-12">
								<div className="custom_tab">
									<Tabs
										// defaultActiveKey="applied-jobs"
										activeKey={key}
										onSelect={handleTabChange}
										id="uncontrolled-tab-example"
										className="mb-3 jds-tab-scroll-div justify-content-center"
									>
										<Tab eventKey="applied-jobs" title="Applied Jobs">
											<div className="myjob-tab-panel">
												<section className="section-common-space overflow-hidden">
													<AppliedJobs
														jobs={appliedJobs}
														candidateID={candidate_id}
														refreshPage={setrefreshPage}
													/>
												</section>
											</div>
										</Tab>
										<Tab
											eventKey="notifications"
											title={`
											Notifications (${notificationCount})  `}
										>
											<div className="notification-tab-panel">
												<section className="section-common-space overflow-hidden">
													<NotificationCard
														notificationList={notificationList}
														candidateID={candidate_id}
														candidateName={Userfirstname}
														candidateEmail={UserEmail}
														setrefreshPage={setrefreshPage}
													/>
												</section>
											</div>
										</Tab>
										<Tab eventKey="profile" title="Profile">
											<Profile />
										</Tab>
									</Tabs>
								</div>
							</div>
						</div>
					</div>{" "}
				</section>
			</div>
			<ToastContainer autoClose={3000} theme="colored" />
			<Modal
				size="md"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				show={showFileUploadModal}
			>
				<Modal.Header>
					<Modal.Title id="contained-modal-title-vcenter ">
						Upload Profile Picture
					</Modal.Title>
					<button
						type="button"
						className="close"
						data-dismiss="modal"
						aria-label="Close"
					>
						<span
							aria-hidden="true"
							onClick={() => {
								setshowFileUploadModal(false);
							}}
						>
							×
						</span>
					</button>
				</Modal.Header>
				<Modal.Body>
					<div className="task-cont-popup2 ml-0 pl-0 ml-sm-5 pl-sm-5">
						<div className="field ml-0 ml-sm-5 my-5">
							{profile_icon == null ? (
								<></>
							) : (
								<span className="preview-img ">
									<img className="profile_icon" src={profile_icon} />
									<br />
								</span>
							)}
							<input
								type="file"
								accept="image/*"
								onChange={(e) => {
									e.preventDefault();
									saveFileSelected(e);
								}}
							/>

							{uploading && (
								<Spinner
									className=" upload-spinner mt-5"
									animation="border"
									variant="danger"
								/>
							)}
						</div>

						<small className="ml-0 ml-sm-3 mt-5 pt-3">
							Please note - Max file size allowed is 5MB
						</small>
						<br />
						<br />

						{/* <br />
						<br /> */}
					</div>
				</Modal.Body>
				<Modal.Footer className="justify-content-center">
					<Button className="mw-100 px-5" onClick={uploadFile}>
						Upload
					</Button>
					<Button
						className=" mw-100 px-5"
						variant="outline-light"
						onClick={() => setshowFileUploadModal(false)}
					>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
			<SessionExpireyPopup
							show={show}
							setshow={setshow}
						/>
		</>
	);
}
