import React, { Component } from "react";
import { Route, Navigate, useLocation } from "react-router-dom";

import { useSelector } from "react-redux";

const PrivateRoute = ({ children }) => {
	const isAuthenticated = useSelector((state) => state.user);
	// console.log(`authx ${isAuthenticated}`);
	let location = useLocation();
	if (!isAuthenticated["isLoggedIn"]) {
		return <Navigate to="/login" state={{ from: location }} replace={false} />;
	}
	return children;
};
export default PrivateRoute;

