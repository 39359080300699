import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "./Dashboard.css";
import Modal from "react-bootstrap/Modal";

function ApplicationProgress({ show, handleClose, progress }) {
	const [primarySteps, setprimarySteps] = useState([]);
	const [finalSteps, setfinalSteps] = useState([]);
	let ApplicationStatus = useSelector(
		(state) => state.masterData.applicationStatusMaster
	);

	useEffect(() => {
		setprimarySteps(ApplicationStatus?.filter((x) => x.status_id < 4));
		setfinalSteps(ApplicationStatus?.filter((x) => x.status_id >= 4));
	}, []);

	return (
		<div>
			<Modal
				show={show}
				onHide={handleClose}
				className="application-progress-container text-center"
				size="xl"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header>
					<div className="text-center w-100">
						<h5
							className="modal-title font-medium h5-05"
							id="exampleModalLiveLabel"
						>
							Application Status
						</h5>
					</div>
					<button
						type="button"
						className="close"
						data-dismiss="modal"
						aria-label="Close"
						onClick={handleClose}
					>
						<span aria-hidden="true">×</span>
					</button>
				</Modal.Header>
				<Modal.Body>
					<div className="container">
						<div className="stepper-wrapper">
							{primarySteps?.map((status, index) => (
								<div
									className={
										status.status_id <= progress
											? "stepper-item completed"
											: "stepper-item active"
									}
								>
									<div className="step-counter">{index + 1}</div>
									<div className="step-name">{status.status_name}</div>
								</div>
							))}
							{finalSteps?.map((status) => (
								<>
									{status?.status_id == progress && (
										<div
											className={
												status.status_id == progress
													? "stepper-item completed"
													: "stepper-item active"
											}
										>
											<div className="step-counter">
												{primarySteps.length + 1}
											</div>
											<div className="step-name">{status.status_name}</div>
										</div>
									)}
								</>
							))}
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
}

export default React.memo(ApplicationProgress);
