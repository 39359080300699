import axios from "axios";

export async function getCandidateProfile(candidate_id) {
	return axios
		.get("api/Profile/GetCandidateProfile", {
			params: {
				candidate_id: candidate_id,
			},
		})
		.then((response) => response)
		.catch((error) => {
			//console.log("error axios", error.message);
			return error;
		});
}
