import React from "react";
import "./PageNotFound.css";

function PageNotFound() {
	document.addEventListener("DOMContentLoaded", function () {
		let footerheight = document.querySelector("footer").offsetHeight;
		document.querySelector("body").style.paddingBottom = footerheight;
	});
	return (
		<>
			<div className="main-container full_width ">
				<section className="section-masthead space-before-breadcrumb">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								{/*breadcrumb*/}
								<nav aria-label="breadcrumb">
									<ol className="breadcrumb">
										<li className="breadcrumb-item">
											<a href="#">Jio Institute</a>
										</li>
										<li className="breadcrumb-item active" aria-current="page">
											Page not Found
										</li>
									</ol>
								</nav>
								{/*breadcrumb end*/}
							</div>
						</div>
					</div>{" "}
					{/* / .container */}
				</section>
				{/* horizontal tabs */}
				<section className="section-common-space mb-5 pb-lg-5 pg-nt-fnd">
					<div className="container">
						<div className="row">
							<div className="col-lg-12 text-center">
								<div className="error-404-container">
									<h2 className="subhead font-medium">
										Sorry, this page isn’t available
									</h2>
									<p>
										The link you followed may be broken, or the page may have
										been removed.
									</p>
									<div className="mt-2 mb-3 mb-lg-5">
										<img
											src="assets/images/page-not-found/icon-not-found.png"
											width=""
											height=""
											alt=""
										/>
									</div>
									<div className="d-none">
										<h6 className="font-medium body-copy-20 black mb-2 h6">
											Never mind! You may search for whatever you are looking
											for here
										</h6>
										<div className="row">
											<div className="col-md-2" />
											<div className="col-md-8">
												{/* <div class="jds-form-group input-container invalid-field-value with-left-icon with-right-icon  mb-3 text-left">
                <input type="text" id="user-name" required class="jds-form-control" placeholder="Search">
                <label for="user-name" class="jds-control-label invisible">Search</label>
                <small id="errorhelp" class="massage error">error message</small>
                  <div class="left-icon">
                  <img src = "assets/images/search/search-icon.svg" width="" height="" alt=""/>
                </div>
                <div class="right-icon clear-value">
                  <img src = "assets/images/search/close-circle.svg" alt=""/>
                </div>
            </div> */}
												<div className="form-group  w-75 m-auto input-container invalid-field-value with-left-icon with-right-icon  text-left">
													<div className="form-wrapper">
														<input
															type="text"
															id="search"
															required=""
															className="form-control"
															placeholder="search"
														/>
														{/* <label for="name" class="control-label">Name</label> */}
														<div className="left-icon">
															<img
																src="assets/images/search/search-icon.svg"
																width=""
																height=""
																alt=""
															/>
														</div>
														<div className="right-icon clear-value">
															<svg
																xmlns="http://www.w3.org/2000/svg"
																viewBox="0 0 28 28"
															>
																<g transform="translate(3.498 3.498)">
																	<path
																		d="M1648.295,1400.784a10.5,10.5,0,1,0,10.5,10.5A10.5,10.5,0,0,0,1648.295,1400.784Zm0,19.254a8.753,8.753,0,1,1,8.753-8.753A8.762,8.762,0,0,1,1648.295,1420.038Z"
																		transform="translate(-1637.793 -1400.784)"
																	/>
																	<path
																		d="M1652.651,1408.881a.876.876,0,0,0-1.237,0l-2.147,2.147-2.146-2.147a.875.875,0,0,0-1.238,1.237l2.148,2.147-2.148,2.147a.875.875,0,0,0,1.238,1.237l2.146-2.147,2.147,2.147a.875.875,0,0,0,1.237-1.237l-2.147-2.147,2.147-2.147A.877.877,0,0,0,1652.651,1408.881Z"
																		transform="translate(-1638.772 -1401.764)"
																	/>
																</g>
															</svg>
														</div>
													</div>
													<small id="errorhelp" className="massage error">
														Invalid text
													</small>
												</div>
											</div>
											<div className="col-md-2" />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</>
	);
}

export default PageNotFound;
