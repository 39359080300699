import axios from "axios";
import { setUserLogout } from "../redux/UserDetails/UserActions";

export default function checkSession(response, dispatch, navigate) {
	// console.log("cs ", response);
	if (
		response?.data?.statusCode == 444 ||
		response.message.includes("code 444")
	) {
		// alert("Session Expired,Please Login again !!!");
		axios.post("/api/Login/Logout");
		dispatch(setUserLogout());
		localStorage.clear(); //for localStorage
		sessionStorage.clear();
		// history.replace("/Login");
		navigate("../Login", { replace: false });
	}
}
