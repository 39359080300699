const clearAllCache = () => {
	if ("caches" in window) {
		caches.keys().then((cacheNames) => {
			cacheNames.forEach((cacheName) => {
				caches.delete(cacheName);
			});
		});
	}
	clearCurrentSiteCache();
};

const clearCurrentSiteCache = () => {
	if ("caches" in window) {
		const currentSiteUrl = window.location.origin;
		caches.keys().then((cacheNames) => {
			cacheNames.forEach((cacheName) => {
				if (cacheName.startsWith(currentSiteUrl)) {
					caches.delete(cacheName);
				}
			});
		});
	}
};

export default clearAllCache;
