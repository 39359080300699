import axios from "axios";
import CryptoJS from "crypto-js"

async function sendOTP(userId) {
	let url = "/OTP/api/AuthRequest/";
	const userHex = CryptoJS.MD5((userId + process.env.REACT_APP_MD5_PASSCODE)?.toLowerCase())?.toString();
	const requestBody = {
		userLoginID: userId,
		CheckUser:userHex
	};
	const result = await axios.post(url, requestBody);
	// console.log('result ++', result);
	return result;
}
export default sendOTP;
