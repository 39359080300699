import {
	FETCH_MASTER_DATA,
	SET_MASTER_DATA,
	UPDATE_MASTER_DATA,
	SET_APPLICATION_STATUS_MASTER,
} from "./MasterdataActionTypes";
import { initialState } from "../initialState";

const MasterdataReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_MASTER_DATA:
			return { ...state, areaDeptMaster: action.payload };
		case UPDATE_MASTER_DATA:
			return { ...state, areaDeptMaster: action.payload };
		case SET_APPLICATION_STATUS_MASTER:
			return { ...state, applicationStatusMaster: action.payload };
		default:
			return state;
	}
};

export default MasterdataReducer;
