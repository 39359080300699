import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import "./Home.css";
import axios from "axios";
import checkSession from "../Common/checkSession";
import {
	fetchApplicationStatusMasterData,
	fetchMasterData,
} from "./../redux/Masterdata/MasterdataActions";
import { fetchUserSuccess } from "./../redux/UserDetails/UserActions";

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import $ from "jquery";
import "slick-carousel";
import { refresh } from "../APICaller/refreshPage";
import clearAllCache from "../Common/clearAllCache";
import clearAllCookies from "../Common/clearAllCookies";
import CryptoJS from "crypto-js"

function Home() {
	// var $jq = jquery.noConflict();
	const dispatch = useDispatch();
	const isAuthenticated = useSelector((state) => state.user);
	dispatch(fetchMasterData());
	if (isAuthenticated["isLoggedIn"]){
		dispatch(fetchApplicationStatusMasterData());
	}

	const usrID = useSelector((state) => state.user.user.userID);
	// console.log("USER ID ", usrID);
	const navigate = useNavigate();
	// const category_id = useRef(0);
	const [isUploading, setisUploading] = useState(false);
	const [data, setdata] = useState([]);
	const [searchterm, setsearchterm] = useState();
	const [usersearch, setusersearch] = useState("");
	let timeout;
	// console.log("is Auth ",isAuthenticated["isLoggedIn"]);
	const location = useLocation();
	useEffect(() => {
		const cancelTokenSource = axios.CancelToken.source();
		// localStorage.removeItem("Category_id");
		// debugger;
		if (localStorage.getItem("redirect") == "true") {
			navigate(localStorage.getItem("current_job_path"), {
				replace: false,
				state: {
					user_applied: false,
				},
			});
		}
		// debugger;
		// Clear all cookies and cache if user is not logged in
		if (!isAuthenticated["isLoggedIn"]) {
			clearAllCookies();
			clearAllCache();
		}
		if (isAuthenticated["isLoggedIn"]) {
			setisUploading(true);
			const userHex = CryptoJS.MD5((usrID + process.env.REACT_APP_MD5_PASSCODE)?.toLowerCase())?.toString();
			const requestBody = {
				userName: usrID,
				CheckUser:userHex
			};
			// userDetails();
			axios
				.post("/api/Login/user-status", requestBody)
				.then((resp) => {
					if (resp.data.statusCode == 200 && resp.data.success) {
						dispatch(fetchUserSuccess(resp?.data?.data));
					}
				})
				.catch((error) => {
					//console.log(`error ${error}`);
					// if (error.message.includes("code 404")) {
					// 	navigate("../SignUp", { replace: false });
					// }
					//else
					if (error.message.includes("code 444")) {
						checkSession(error, dispatch, navigate);
					}
					//console.log("error ", error);
				});
		}
		return () => {
			setdata({});
			setsearchterm({});
			cancelTokenSource.cancel();
			clearTimeout(timeout);
		};
	}, []);
	// const [ref, inView] = useInView();
	// const animation = useAnimation();
	// useEffect(() => {}, [inView]);
	const candidate_id = useSelector(
		(state) => state.user.user.userDetails?.candidate_id
	);

	const searchItems = (searchValue, e) => {
		// setsearchterm(searchValue);
		setusersearch(searchValue);
	};
	const SearchResult = () => {
		setTimeout(() => {
			navigate(
				"/current-openings",
				{ state: { usersearch: usersearch } },
				{ replace: false }
			);
		}, 300);
	};

	var settings = {
		slidesToShow: 3,
		slidesToScroll: 1,
		infinite: true,
		dots: false,
		arrows: true,
		adaptiveHeight: true,
		responsive: [
			{
				breakpoint: 1025,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};

	var plannerSliderSettings = {
		slidesToShow: 3,
		slidesToScroll: 1,
		infinite: true,
		dots: true,
		arrows: false,
		adaptiveHeight: true,
		responsive: [
			{
				breakpoint: 1025,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};

	useEffect(() => {
		if (!isAuthenticated["isLoggedIn"]) {
			refresh();
		}
	}, []);

	//#region jQuery
	$(document).ready(function () {
		$(".searchAbtJI").on("click", function () {
			$("body").css({ overflow: "hidden" });
			setTimeout(function () {
				$("input#topnav-search").focus();
			}, 100);
			setTimeout(function () {
				$(".pkv-searchview").addClass("slidedown");
			}, 500);
			setTimeout(function () {
				$(".pkv-searchview .search-backdrop.pkv-fade").addClass("show visible");
			}, 100);
			setTimeout(function () {
				$(".pkv-searchview .animate_search_result").addClass("animate");
				$(".pkv-searchview .animate_search_result").css({
					"box-sizing": "border-box",
					display: "block",
				});
			}, 700);
			setTimeout(function () {
				$(".pkv-searchview .pkv-searchresult-container").addClass("slidedown");
			}, 700);
		});

		$(".closeSearch, .search-backdrop").on("click", function () {
			$("body").css({ overflow: "auto" });
			setTimeout(function () {
				$(".pkv-searchview .pkv-searchresult-container").removeClass(
					"slidedown"
				);
			}, 100);
			setTimeout(function () {
				$("input#topnav-search").focus();
			}, 900);
			setTimeout(function () {
				$(".pkv-searchview").removeClass("slidedown");
			}, 100);
			setTimeout(function () {
				$(".pkv-searchview .search-backdrop.pkv-fade").removeClass(
					"show visible"
				);
			}, 500);
			setTimeout(function () {
				$(".pkv-searchview .animate_search_result").removeClass("animate");
				$(".pkv-searchview .animate_search_result").css({
					"box-sizing": "border-box",
					display: "none",
				});
			}, 100);
		});
	});

	//script for textbox close icon show and hide
	$(".form-group").each(function () {
		const $inp = $(this).find("input"),
			$cle = $(this).find(".clear-value");
		$inp.on("input", function () {
			$cle.toggle(!!this.value);
		});

		$cle.on("touchstart click", function (e) {
			e.preventDefault();
			$inp.val("").trigger("input");
		});
	});

	$(".form-group.input-container input")
		.on("focus", function () {
			if ($(this).val().length == 0) {
				$(this)
					.closest(".form-group.input-container")
					.find(".clear-value")
					.fadeOut();
			} else {
				$(this)
					.closest(".form-group.input-container")
					.find(".clear-value")
					.fadeIn();
			}
		})
		.blur(function () {
			$(".clear-value").fadeOut();
		});
	// album carosel
	$(document).ready(function () {
		// $(".header-mobile-menu").click(function () {
		// 	$(".nav-wrapper").toggleClass("show-menu");
		// });

		setTimeout(function () {
			$(".academy2").css("display", "none");
			$(".academy3").css("display", "none");
			$(".academy4").css("display", "none");
			$(".academy5").css("display", "none");
			$(".academy6").css("display", "none");
			$(".academy7").css("display", "none");
			$(".academy8").css("display", "none");
			$(".academy9").css("display", "none");
		}, 1000);

		$(".academybtncntr li").click(function () {
			$(".academybtncntr li").removeClass("active");
			$(this).addClass("active");

			if ($("#academy1").hasClass("active")) {
				$(".facilitiesslider [class^=academy]").css("display", "none");
				$(".academy1").css("display", "block");
			}
			if ($("#academy2").hasClass("active")) {
				$(".facilitiesslider [class^=academy]").css("display", "none");
				$(".academy2").css("display", "block");
			}
			if ($("#academy3").hasClass("active")) {
				$(".facilitiesslider [class^=academy]").css("display", "none");
				$(".academy3").css("display", "block");
			}
			if ($("#academy4").hasClass("active")) {
				$(".facilitiesslider [class^=academy]").css("display", "none");
				$(".academy4").css("display", "block");
			}
			if ($("#academy5").hasClass("active")) {
				$(".facilitiesslider [class^=academy]").css("display", "none");
				$(".academy5").css("display", "block");
			}
			if ($("#academy6").hasClass("active")) {
				$(".facilitiesslider [class^=academy]").css("display", "none");
				$(".academy6").css("display", "block");
			}
			if ($("#academy7").hasClass("active")) {
				$(".facilitiesslider [class^=academy]").css("display", "none");
				$(".academy7").css("display", "block");
			}
			if ($("#academy8").hasClass("active")) {
				$(".facilitiesslider [class^=academy]").css("display", "none");
				$(".academy8").css("display", "block");
			}
			if ($("#academy9").hasClass("active")) {
				$(".facilitiesslider [class^=academy]").css("display", "none");
				$(".academy9").css("display", "block");
			}
		});
	});

	$(document).ready(function () {
		$(".facilities-view1").slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: true,
			fade: true,
			// //lazyLoad: "ondemand",
		});
	});

	$(document).ready(function () {
		$(".facilities-view2").slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: true,
			fade: true,
			//lazyLoad: "ondemand",
		});
	});

	$(document).ready(function () {
		$(".facilities-view3").slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: true,
			fade: true,
			//lazyLoad: "ondemand",
		});
	});

	$(document).ready(function () {
		$(".facilities-view4").slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: true,
			fade: true,
			//lazyLoad: "ondemand",
		});
	});

	$(document).ready(function () {
		$(".facilities-view5").slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: true,
			fade: true,
			//lazyLoad: "ondemand",
		});
	});

	$(document).ready(function () {
		$(".facilities-view6").slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: true,
			fade: true,
			//lazyLoad: "ondemand",
		});
	});

	$(document).ready(function () {
		$(".facilities-view7").slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: true,
			fade: true,
			//lazyLoad: "ondemand",
		});
	});

	$(document).ready(function () {
		$(".facilities-view8").slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: true,
			fade: true,
			//lazyLoad: "ondemand",
		});
	});

	$(document).ready(function () {
		$(".facilities-view9").slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: true,
			fade: true,
		});
	});

	$(document).ready(function () {
		var liMaxHeight = -1;
		var node;
		$(".hm-section-4 .col-lg-4 p").each(function (index) {
			if ($(this).outerHeight() > liMaxHeight) {
				liMaxHeight = $(this).outerHeight();
				node = index;
			}
		});
		$(".hm-section-4 .col-lg-4 p").css("min-height", liMaxHeight + 1);
	});
	let isMobile = window.innerWidth < 768;
	//

	return (
		<div className="main-container full_width homepage">
			<section className="section-common-space overflow-hidden pt-0 pb-0 d-none">
				<div>
					<div className="jumbotron-heading box">
						<h1 className="text-white display-02 font-black">
							Join us in preparing the next generation of global citizens
						</h1>
					</div>
					<img
						src="assets/images/career/landing/career-landing-jumbotron-bg-desktop.png"
						className="img-fluid w-100 data-img"
						alt=""
						data-sml="assets/images/career/landing/career-landing-jumbotron-bg-mobile.png"
						data-med="assets/images/career/landing/career-landing-jumbotron-bg-tab.png"
						data-lrg="assets/images/career/landing/career-landing-jumbotron-bg-desktop.png"
					/>
				</div>
			</section>
			<section className="section-common-space overflow-hidden pt-0 pb-0 hm-section-1 blacklayer justify-content-center align-items-center">
				<div className="d-none  d-md-block jumbotron-heading text-center">
					<h1 className="text-white h2-02 font-black">
						Be a part of building a vibrant ecosystem for research, innovation
						and entrepreneurship.
					</h1>
				</div>

				<img
					src="assets/images/career/landing/career-landing-jumbotron-bg-desktop.jpeg"
					className="w-100 "
					alt=""
				/>
			</section>

			{/*Visible  Mobile View only */}

			<div className="d-block d-md-none text-container bg-primary">
				<div className="container">
					<div className="text-center">
						<h6 className="text-white font-black py-3">
							Be a part of building a vibrant ecosystem for research, innovation
							and entrepreneurship.
						</h6>
					</div>
				</div>
			</div>

			{/* End Mobiel view only text */}
			<section className="section-common-space overflow-hidden">
				<div className="container">
					<div className="row">
						<div className="col-12 text-center">
							<h2 className="h2-02 font-black mb-2 heading-container-sm m-auto">
								Work with global scholars and thought leaders
							</h2>
						</div>
					</div>{" "}
					<div className="row slider-circle-panel pb-2 pt-4">
						<div className="col-12 text-center">
							<div className="provos-section-slider equal_height_cards  justify-content-center text-center ">
								{/* <Slider {...settings}> */}
								<div className="row justify-content-around">
									<div className="col-12 col-sm-4">
										<div className="card h-100 border-0 radius-16 overflow-hidden">
											<div className="rounded-circle img-dimension  overflow-hidden">
												<img
													src="assets/images/leader-image/Dr-Mashelkar_0.png.webp"
													// src="https://www.jioinstitute.edu.in/sites/default/files/styles/webp/public/leader_image/Dr-Mashelkar_0.png.webp?itok=1FJQJYfw"
													alt="about"
													className="w-50 rounded-circle m-auto leaders-img"
												/>
											</div>
											<div className="card-body leader-card-body">
												<div className="font-bold mb-2 primary text-uppercase body-02"></div>
												<h4 className="font-bold f-24 leader-title ">
													Dr. Raghunath Mashelkar
												</h4>
												<span className="profile-text overline-01 text-uppercase font-bold">
													Chancellor
												</span>

												<p className="font-small ml-auto mr-auto mt-2 w-65">
													Padma Vibhushan | Former Director General, CSIR,
													Government of India
												</p>
											</div>
											<div className="card-footer pt-0 bg-white border-0">
												<a
													href="https://www.jioinstitute.edu.in/about/chancellor"
													className="btn btn-secondary"
													target="_blank"
													rel="noopener noreferrer"
												>
													Know more
												</a>
											</div>
										</div>
									</div>
									<div className="col-12 col-sm-4"><div className="card h-100 border-0 radius-16 overflow-hidden">
										<div className="rounded-circle img-dimension  overflow-hidden">
											<img
												src="assets/images/leader-image/Dipak-Jain_1.png.webp"
												// src="https://www.jioinstitute.edu.in/sites/default/files/styles/webp/public/leader_image/Dipak-Jain_1.png.webp?itok=MXpYvydw"
												alt="about"
												className="w-50 rounded-circle m-auto leaders-img"
											/>
										</div>
										<div className="card-body leader-card-body">
											<div className="font-bold mb-2 primary text-uppercase body-02"></div>
											<h4 className="font-bold f-24 leader-title">
												Dr. Dipak Jain
											</h4>
											<span className="profile-text overline-01 text-uppercase font-bold">
												VICE-CHANCELLOR
											</span>

											<p className="font-small ml-auto mr-auto mt-2 w-75">
												Former Dean, Kellogg School of Management, USA | Former
												Dean, INSEAD, France
											</p>
										</div>
										<div className="card-footer pt-0 bg-white border-0 ">
											<a
												href="https://www.jioinstitute.edu.in/about/vice-chancellor"
												className="btn btn-secondary"
												target="_blank"
												rel="noopener noreferrer"
											>
												Know more
											</a>
										</div>
									</div></div>

								</div>
									

									

									
								{/* </Slider> */}
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Video section */}
			<section className="section-common-space overflow-hidden">
				<div className="container">
					<div className="row">
						<div className="col-12 text-center">
							<iframe
								className="iframe-temp-wrapper "
								width="790"
								height="445"
								src={process.env.REACT_APP_INTRO_VIDEO_LINK}
								title="YouTube video player"
								frameBorder="0"
								marginHeight="0"
								marginWidth="0"
								scrolling="no"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
								allowFullScreen
								style={
									isMobile
										? { borderRadius: "15px", width: "calc(100% - 5vw)" }
										: { borderRadius: "15px" }
								}
							></iframe>
						</div>
					</div>
				</div>
			</section>

			{/* Video section  end s */}

			<section className="section-common-space overflow-hidden sectioncolorbg hm-section-4">
				<div className="container">
					<div className="row">
						<div className="col-12">
							<div className="">
								<div>
									<h2 className="h2-02 font-black text-center">
										A stimulating environment for thinkers,
										<br /> planners, and creators
									</h2>
								</div>
								<div
									className="font-medium body-01 mt-2 mb-3 text-center"
								// initial={{ opacity: 0, scale: 1 }}
								>
									Find opportunities in various departments across roles for you
									to perform, excel, and lead.
								</div>
								<div className="section-slider equal_height_cards  justify-content-center text-center slider-circle-panel pt-0 ">
									<Slider {...plannerSliderSettings}>
										<div className="card rounded-0 border-0 bg-transparent text-left">
											<div className="card-body deps-body p-0">
												<h5 className="h5-05 font-bold">Academics</h5>
												<p className="body-02 font-small">
													We are looking for potential candidates, holding PhD
													degrees and having exceptional research footprint with
													excellent pedigrees in academics, for the position of
													Assistant Professor, Associate Professor and
													Professor.
												</p>
											</div>
											<div
												className="card-footer p-0 border-0 bg-transparent mb-5"
												onClick={() => {
													localStorage.setItem("category_id", 1);
													if (isAuthenticated["isLoggedIn"]) {
														setTimeout(() => {
															navigate("/current-openings/Academics");
														}, 300);
													}
													if (!isAuthenticated["isLoggedIn"]) {
														setTimeout(() => {
															navigate("/current-openings/Academics");
														}, 300);
													}
												}}
											>
												<a
													className="btn btn-secondary arrow-red center-v"
													tabIndex={0}
												>
													Check Academic openings
												</a>
											</div>
										</div>

										<div className="card rounded-0 border-0 bg-transparent text-left">
											<div className="card-body deps-body p-0">
												<h5 className="h5-05 font-bold">Jio Digital Library</h5>
												<p className="body-02 font-small">
													We are looking for Library professionals to provide
													professional research, reference and consultation
													services for the Institute and users, to ensure
													efficient library operations and administration and to
													effectively maintain the library and its materials.
												</p>
											</div>
											<div
												className="card-footer p-0 border-0 bg-transparent"
												onClick={() => {
													localStorage.setItem("category_id", 2);
													if (isAuthenticated["isLoggedIn"]) {
														setTimeout(() => {
															navigate("/current-openings/Jio-Digital-Library");
														}, 300);
													}
													if (!isAuthenticated["isLoggedIn"]) {
														setTimeout(() => {
															navigate("/current-openings/Jio-Digital-Library");
														}, 300);
													}
												}}
											>
												<a
													className="btn btn-secondary arrow-red center-v"
													tabIndex={0}
												>
													Check JDL openings
												</a>
											</div>
										</div>

										<div className="card rounded-0 border-0 bg-transparent text-left">
											<div className="card-body deps-body p-0">
												<h5 className="h5-05 font-bold">Non-Academic</h5>
												<p className="body-02 font-small">
													We welcome exceptional applicants from all
													backgrounds. Jio Institute is a home for curious minds
													and joining our team means you’ll work with a creative
													and socially conscious set of individuals. We thrive
													on diversity—in life experiences, fields of study, and
													ways of thinking (to name just a few).
												</p>
											</div>
											<div
												className="card-footer p-0 border-0 bg-transparent"
												onClick={() => {
													localStorage.setItem("category_id", 3);
													if (isAuthenticated["isLoggedIn"]) {
														setTimeout(() => {
															navigate("/current-openings/Non-Academic");
														}, 300);
													}
													if (!isAuthenticated["isLoggedIn"]) {
														setTimeout(() => {
															navigate("/current-openings/Non-Academic");
														}, 300);
													}
												}}
											>
												<a
													className="btn btn-secondary arrow-red center-v"
													tabIndex={0}
												>
													Check Non-Academic openings
												</a>
											</div>
										</div>
									</Slider>
								</div>
							</div>
						</div>
					</div>{" "}
				</div>{" "}
			</section>
			<section className="section-common-space overflow-hidden mx-3">
				<div className="card p-3">
					{/* <p>
						Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil ea
						quod, sint vel inventore fugit autem adipisci. Adipisci, illo eaque
						praesentium recusandae alias soluta repudiandae tempora qui
						voluptatum veniam tempore.
					</p> */}
					{/* <p class="text-center ">
						Applications are invited for Postdoctoral Fellowships in Artificial
						Intelligence and Data Science (AI&amp;DS) at Jio Institute. View
						more details by{" "}
						<a href="https://www.jioinstitute.edu.in/work-with-us/postdoctoral-fellowships-ai-and-ds">
							<strong>clicking here</strong>
						</a>
						.&nbsp;
					</p> */}
					<p class="text-center ">
						{/* Applications are invited for&nbsp;Computer Vision in Medical Imaging
						(CVMI) . Click respective position to view more details :&nbsp; */}
						Applications are invited for the Research Project - Computer Vision
						in Medical Imaging (CVMI). Kindly click on respective positions to
						view more details:
						<br />
						<strong>
							<a href="https://www.jioinstitute.edu.in/work-with-us/postdoctoral-fellowships-cvmi">
								Postdoctoral Fellowship (CVMI Lab)
							</a>
						</strong>
						&nbsp;and&nbsp;
						<a href="https://www.jioinstitute.edu.in/work-with-us/research-assistant-associate-cvmi">
							<strong>Research Assistant/Associate (CVMI Lab)</strong>
						</a>
						&nbsp;
					</p>
				</div>
			</section>
			<section className="section-common-space overflow-hidden d-none">
				<div className="container">
					<div className="row ">
						<div className="col-12 text-center">
							<div className="heading-container-sm m-auto">
								<h2 className="h2-02 font-black mb-2">
									Come, be part of our <br />
									dynamic community
								</h2>
								<p className="body-01 font-medium">
									At Jio Institute, we are always looking forward to working
									with like-minded people who can fuel our vision with their
									exceptional flair and collaborative mindset.
								</p>
							</div>
						</div>
					</div>

					<div className="row ">
						<div className="col-12 text-center h5-05 font-bold section-spacer">
							Here are the kind of people we are always excited to welcome
							aboard.
						</div>
					</div>

					<div className="row ">
						<div className="col-12">
							<div className="row">
								<div className="col-12 mt-4">
									<div className="container-sm m-auto">
										<div className="d-flex flex-wrap">
											<div className="card-with-thumbnail align-items-center shadow-com radius-24 d-flex">
												<div className="thumbnail">
													<img
														src="assets/images/career/career-community.png"
														width="240px"
														alt=""
														height="180px"
														className="img-fluid w-100"
													/>
												</div>
												<div className="font-medium body-01 community-text">
													Curious minds exploring global challenges
												</div>
											</div>
											<div className="card-with-thumbnail align-items-center shadow-com radius-24 d-flex">
												<div className="thumbnail">
													<img
														src="assets/images/career/career-community.png"
														width="240px"
														alt=""
														height="180px"
														className="img-fluid w-100"
													/>
												</div>
												<div className="font-medium body-01 community-text">
													Lifelong learners committed to excellence
												</div>
											</div>
											<div className="card-with-thumbnail align-items-center shadow-com radius-24 d-flex">
												<div className="thumbnail">
													<img
														src="assets/images/career/career-community.png"
														width="240px"
														alt=""
														height="180px"
														className="img-fluid w-100"
													/>
												</div>
												<div className="font-medium body-01 community-text">
													Forward-thinkers envisioning change
												</div>
											</div>
											<div className="card-with-thumbnail align-items-center shadow-com radius-24 d-flex">
												<div className="thumbnail">
													<img
														src="assets/images/career/career-community.png"
														width="240px"
														alt=""
														height="180px"
														className="img-fluid w-100"
													/>
												</div>
												<div className="font-medium body-01 community-text">
													Hands-on people solving real-world problems
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="row ">
						<div className="col-12 text-center">
							<a
								href="/why-join-us"
								className="arrow-white btn btn-primary center-v mr-3"
							>
								Why join US
							</a>
							<a
								href="/hiring-process"
								className="btn btn-secondary arrow-red center-v"
							>
								Hiring Process
							</a>
						</div>
					</div>
				</div>
				{/* / .container */}
			</section>
			<section className="slider-circle-panel section-common-space overflow-hidden sectionnocolorbg hm-section-5">
				<div className="container">
					<div className="view-header">
						<h2 className="h4-04 font-bold f-28" data-aos="fade-down">
							Campus Experience @ JI
						</h2>
					</div>
					<div className="facilities-slider mt-5">
						<div className="facilitiesslider">
							<div className="academy1">
								<div className="slick--view facilities-view1">
									<div>
										<img src="assets/images/campus-experience/academic-research/raj-sethuraman-class.jpg" />
									</div>
									<div>
										<img src="assets/images/campus-experience/academic-research/amy-banner.jpg" />
									</div>
									<div>
										<img src="assets/images/campus-experience/academic-research/dr-larry.jpg" />
									</div>
									<div>
										<img src="assets/images/campus-experience/academic-research/drfrank.jpg" />
									</div>
									<div>
										<img src="assets/images/campus-experience/academic-research/faculty-1.jpg" />
									</div>
									<div>
										<img src="assets/images/campus-experience/academic-research/faculty-2.jpg" />
									</div>
									<div>
										<img src="assets/images/campus-experience/academic-research/faculty-team.jpg" />
									</div>

									<div>
										<img src="assets/images/campus-experience/academic-research/research.jpg" />
									</div>
								</div>
							</div>
							<div className="academy2">
								<div className="slick--view facilities-view2">
									<div>
										<img src="assets/images/campus-experience/guest-lectures/dr-jlc.jpg" />
									</div>
								</div>
							</div>
							<div className="academy3">
								<div className="slick--view facilities-view3">
									<div>
										<img src="assets/images/campus-experience/labs/labs-studio.jpg" />
									</div>
									<div>
										<img src="assets/images/campus-experience/labs/aids-lab.jpg" />
									</div>
									<div>
										<img src="assets/images/campus-experience/labs/computer-lab-1.jpg" />
									</div>
									<div>
										<img src="assets/images/campus-experience/labs/lab-1.jpg" />
									</div>
								</div>
							</div>
							<div className="academy4">
								<div className="slick--view facilities-view4">
									<div>
										<img src="assets/images/campus-experience/library/library-3.jpg" />
									</div>
									<div>
										<img src="assets/images/campus-experience/library/library-1.jpg" />
									</div>
									<div>
										<img src="assets/images/campus-experience/library/library-2.jpg" />
									</div>
								</div>
							</div>
							<div className="academy5">
								<div className="slick--view facilities-view5">
									<div>
										<img src="assets/images/campus-experience/dining-collaborations/faculty-dining-1.jpg" />
									</div>
									<div>
										<img src="assets/images/campus-experience/dining-collaborations/faculty-dining-2.jpg" />
									</div>
									<div>
										<img src="assets/images/campus-experience/dining-collaborations/faculty-discussion.jpg" />
									</div>
									<div>
										<img src="assets/images/campus-experience/dining-collaborations/faculty-lunch.jpg" />
									</div>
								</div>
							</div>
							<div className="academy6">
								<div className="slick--view facilities-view6">
									<div>
										<img src="assets/images/campus-experience/gym/gym-Banner-1.jpg" />
									</div>
									<div>
										<img src="assets/images/campus-experience/gym/gym-Banner-2.jpg" />
									</div>
								</div>
							</div>
							<div className="academy7">
								<div className="slick--view facilities-view7">
									<div>
										<img src="assets/images/campus-experience/celbrations-at-ji/christmas-banner.jpg" />
									</div>
									<div>
										<img src="assets/images/campus-experience/celbrations-at-ji/diwali-banner.jpg" />
									</div>
									<div>
										<img src="assets/images/campus-experience/celbrations-at-ji/festivals-1.jpg" />
									</div>
									<div>
										<img src="assets/images/campus-experience/celbrations-at-ji/festivals-2.jpg" />
									</div>
									<div>
										<img src="assets/images/campus-experience/celbrations-at-ji/ganpati-banner.jpg" />
									</div>
									<div>
										<img src="assets/images/campus-experience/celbrations-at-ji/independence-day-banner.jpg" />
									</div>
									<div>
										<img src="assets/images/campus-experience/gym/gym-Banner-1.jpg" />
									</div>
									<div>
										<img src="assets/images/campus-experience/gym/gym-Banner-2.jpg" />
									</div>
									<div>
										<img src="assets/images/campus-experience/dining-collaborations/faculty-dining-1.jpg" />
									</div>
									<div>
										<img src="assets/images/campus-experience/dining-collaborations/faculty-dining-2.jpg" />
									</div>
									<div>
										<img src="assets/images/campus-experience/dining-collaborations/faculty-discussion.jpg" />
									</div>
									<div>
										<img src="assets/images/campus-experience/dining-collaborations/faculty-lunch.jpg" />
									</div>
								</div>
							</div>
						</div>
						<ul className="academybtncntr">
							<li className="active" id="academy1">
								<img src="assets/images/campus-experience/academic-research/Raj-Sethuraman-Class thumbnail.jpg" />

								<span>Academics &amp; Research</span>
							</li>
							<li className="" id="academy2">
								<img src="assets/images/campus-experience/guest-lectures/dr-jlc.jpg" />
								<span>Guest Lecture</span>
							</li>
							<li className="" id="academy3">
								<img src="assets/images/campus-experience/labs/Labs--STudio-Thumbnail.jpg" />
								<span>Labs</span>
							</li>
							<li className="" id="academy4">
								<img src="assets/images/campus-experience/library/Library---Thumbnail.jpg" />
								<span>Library</span>
							</li>
							{/* <li className="" id="academy5">
								<img src="assets/images/campus-experience/dining-collaborations/faculty-dining-1.jpg" />
								<span>Dining | Collaboration</span>
							</li>
							<li className="" id="academy6">
								<img src="assets/images/campus-experience/gym/gym-Banner-1.jpg" />
								<span>Gym</span>
							</li>
							<li className="" id="academy7">
								<img src="assets/images/campus-experience/celbrations-at-ji/christmas-banner.jpg" />
								<span>Celebrations at JI</span>
							</li> */}
							<li className="" id="academy7">
								<img src="assets/images/campus-experience/dining-collaborations/faculty-dining-1.jpg" />
								<span>Life @ JI</span>
							</li>
						</ul>
					</div>
				</div>
			</section>

			{/* <section className="section-common-space overflow-hidden pb-0">
				<div className="container text-white">
					<div className="row">
						<div className="col-12">
							<div className="values-container">
								<div className="values-mini-container">
									<h2 className=" h2-02 font-black">
										Our culture &amp; values
									</h2>
									<div className="body-01 font-medium">
										Before you embark on a transformative journey with us, know
										what we are built on and what it means to work here.
									</div>
								</div>
								<div className="values">
									<ul className="h5-05 font-bold">
										<li>Inclusiveness</li>
										<li>Freedom of Inquiry</li>
										<li>Integrity</li>
										<li>Growth Mindset</li>
										<li>Excellence</li>
										<li>Courage</li>
									</ul>
								</div>
								<div className="d-none">
									<a
										href="#;"
										className="btn btn-white center-v arrow-red font-bold"
									>
										Know More
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
				<img
					src="assets/images/career/landing/ji-values-desktop.png"
					className="img-fluid w-100 data-img"
					alt=""
					data-sml="assets/images/career/landing/ji-values-mobile.png"
					data-med="assets/images/career/landing/ji-values-tablet.png"
					data-lrg="assets/images/career/landing/ji-values-desktop.png"
				/>
			</section> */}
		</div>
	);
}

export default Home;
