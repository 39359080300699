import React from "react";
import { useField } from "formik";

const DropDown = ({
	label,
	AreaSelection = null,
	setFieldValue = null,
	...props
}) => {
	const [field, meta] = useField(props);
	//   debugger;
	return (
		<div key={field} className="form-group input-container">
			<select
				{...field}
				{...props}
				className="form-control px-2 "
				autoComplete="true"
				onChange={(e) => {
					if (AreaSelection != null || AreaSelection != undefined) {
						AreaSelection(e);
					}
					if (setFieldValue != null || setFieldValue != undefined) {
						setFieldValue(props.name, e.target.value);
					}
				}}
			/>
			<label
				htmlFor={props.id || props.name}
				className="control-label font-medium overline-01"
			>
				{label}
			</label>
			{meta.touched && meta.error ? (
				<small className="massage error d-block">{meta.error}</small>
			) : null}
		</div>
	);
};
export default DropDown;
