import React, { useState, useEffect, useMemo, useCallback } from "react";
import "./Captcha.css";
import { Formik, Form, useField } from "formik";

import TextInput from "./../Common/Formik/TextInput";
import CheckBox from "./../Common/Formik/CheckBox";
import { Button } from "react-bootstrap";
import DropDown from "./../Common/Formik/DropDown";
import * as Yup from "yup";
import "yup-phone";
import axios from "axios";
import { Spinner, Row, Col } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import getDeviceType from "../Common/getDeviceType";
import countryISDCodes from "../Common/countryISDCodes";
import Popup from "../Common/Popup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import fetchUserData from "../Common/fetchUserData";
import "../App.css"
// import { setRegisterationState } from "../redux/actions/userActions";

import _ from "lodash";

import { emailRegex, phoneRegex } from "../Common/Constants/Regex";
import { PersistFormikValues } from "formik-persist-values";
import getCandidateProfileInfo from "../APICaller/getCandidateProfileInfo";
import { getCandidateProfile } from "../APICaller/getCandidateProfile";

function SignUp() {
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [fileName, setfileName] = useState("Upload your CV from storage");
	const [isUploading, setisUploading] = useState(false);
	const [formData, setformData] = useState({});
	const [captchaImgURL, setcaptchaImgURL] = useState(
		`/api/Login/captcha?${Math.floor(Math.random() * 100)}`
	);

	const [show, setshow] = useState(false);
	const [ip, setIP] = useState("");
	const [showGoBackConfirmationPopup, setshowGoBackConfirmationPopup] =
		useState(false);
	const [notificationInfo, setnotificationInfo] = useState({
		title: "",
		msg: "Please ensure that all the filled details are correct. ",
	});
	const [showpopup, setshowpopup] = useState({
		title: "",
		msg: "Are you sure, you want to logout?",
	});
	const departmentData = useSelector(
		(state) => state.masterData.areaDeptMaster.departments
	);
	const [submit, setsubmit] = useState(false);
	const [areaData, setareaData] = useState(
		useSelector((state) => state.masterData.areaDeptMaster.area)
	);
	const SubDEpartment = useSelector(
		(state) => state.masterData.areaDeptMaster.area
	);
	const userLoginID = useSelector((state) => state.user.user.userID);
	const isAuthenticated = useSelector((state) => state.user);
	const ALREADY_LOGGED_IN = "ALREADY_LOGGED_IN";
	const candidatID = useSelector(
		(state) => state.user.user.userDetails?.candidate_id
	);
	const isRedirected = localStorage.getItem('isRedirected')
	const jobId = localStorage.getItem('jobid');
	let useremail;
	let phone;
	let phonevalue = false;
	let emailvalue = false;

	//check if userID is Email
	if (emailRegex.test(userLoginID)) {
		useremail = userLoginID;
		emailvalue = true;
	} else {
		phone = userLoginID;
		phonevalue = true;
	}
	// function FileUpdate(file) {
	// 	// console.log("form data ", file.name);
	// 	if (file.name.split(".").pop() !== "zip") {
	// 		if (file.size <= 5242880) {
	// 			setisUploading(true);
	// 			const formData = new FormData();
	// 			formData.append("file", file);
	// 			// console.log(request);
	// 			axios
	// 				.post("/api/Login/File", formData)
	// 				.then((response) => {
	// 					// console.log("fileUpload response", response.data);

	// 					if (
	// 						response.data.data != null &&
	// 						response.data.statusCode == 200 &&
	// 						response.data.success == true
	// 					) {
	// 						setisUploading(false);
	// 					} else if (
	// 						response.data.data == null &&
	// 						response.data.statusCode == 400 &&
	// 						response.data.success == false
	// 					) {
	// 						setisUploading(false);
	// 						toast.error(
	// 							"Please upload file pdf,word,image and file size limit must be 5 MB.",
	// 							{
	// 								position: toast.POSITION.TOP_CENTER,
	// 							}
	// 						);
	// 					} else {
	// 						setisUploading(false);
	// 						// SetShowalert(true);
	// 					}
	// 				})
	// 				.catch((error) => {
	// 					setisUploading(false);
	// 					// alert("CV Upload failed");
	// 					console.log(error);
	// 				});
	// 		} else {
	// 			setisUploading(false);
	// 			toast.error(
	// 				"Please upload file pdf,word,image and file size limit must be 5 MB.",
	// 				{
	// 					position: toast.POSITION.TOP_CENTER,
	// 				}
	// 			);
	// 		}
	// 	} else {
	// 		setisUploading(false);
	// 		toast.error(
	// 			"Please upload file pdf,word,image and file size limit must be 5 MB.",
	// 			{
	// 				position: toast.POSITION.TOP_CENTER,
	// 			}
	// 		);
	// 	}
	// }

	// if (submit) {

	// }
	function submitSignUpForm(formData, dispatch, navigate, userLoginID) {
		axios
			.post("/api/Login/user-signup", formData)
			.then((res) => {
				if (res.data.success == true && res.data.statusCode == 200) {
					toast.success("Thank you for registering  with us", {
						position: toast.POSITION.TOP_CENTER,
						toastId: "success-toast",
					});

					fetchUserData(userLoginID, dispatch, navigate, "register");

					//setTimeout(() => {
					//	navigate("../current-openings", {
					//		replace: false,
					//	});
					//}, 3000);
				}
			})
			.catch((err) => {
				if (
					err.response.data.statusCode == 406 &&
					err.response.data.success == false
				) {
					toast.error(`${err.response.data.systemMsg}`, {
						position: toast.POSITION.TOP_CENTER,
						toastId: "error-toast",
					});
					setTimeout(() => {
						window.location.reload();
					}, 4000);

					return;
				}
				//console.log({ err });
				toast.error("Something went wrong", {
					position: toast.POSITION.TOP_CENTER,
					toastId: "error-toast-1",
				});
			});
	}
	if (submit) {
		//console.log({ submit });
		submitSignUpForm(formData, dispatch, navigate, userLoginID);
	}

	function AreaSelection(e) {
		if (e.target.value !== "") {
			var filtervalue = SubDEpartment?.filter(
				(ele) => ele?.category_id.toString() === e.target.value
			);
			setareaData(filtervalue);
		}

		// const select = document.getElementById('category_id');
	}
	const [initialFormValues, setinitialFormValues] = useState({
		firstName: "",
		middleName: "",
		lastName: "",
		salutation: "",
		mobile: phone,
		email: useremail,
		isd_code: "",
		linkedin_url: "",
		cvFile: "No file",
		device: getDeviceType(),
		chanelType: "",
		category_id: "",
		sub_category_id: "",
		captcha: "",
		cvFileName: "",
		candidateProfilePicName: ""
	});

	function checkExistingUser(secUserLoginID) {
		let validPhone = phoneRegex.test(secUserLoginID);
		let validEmail = emailRegex.test(secUserLoginID);

		if (validEmail || validPhone) {
			//debugger;
			// console.log({ candidatID });
			getCandidateProfileInfo(secUserLoginID, "register")
				.then((response) => {
					if(!response){
						return
					}
					const candidatID = response?.data?.candidate_id;
					getCandidateProfile(candidatID)
						.then((res) => {
							return res?.data?.data;
						})
						.then((response) => {
							//console.log(response );
							if(response){
								setinitialFormValues({
									...initialFormValues,
									firstName: response?.first_name,
									middleName: response?.middle_name,
									lastName: response?.last_name,
									salutation: response?.salutation,
									mobile: phonevalue
										? initialFormValues.mobile
										: response?.mobile,
									email: emailvalue
										? initialFormValues.email
										: response?.email,
									isd_code: response?.isd_code.toString(),
									linkedin_url: response?.linkedin_url,
									category_id: response?.category_id.toString(),
									sub_category_id: response?.sub_category_id.toString(),
								});
							}
						})
						.catch((err) => {
							//console.log("invalid user");
						});
				})
				.catch((error) => {
					//console.log(error);
				});
		}
	}

	const ipFinderURL = process.env.REACT_APP_IPFinder_URL;
	const getIPData = useCallback(async () => {
		const res = await axios.get(ipFinderURL);
		//console.log(res);
		setIP(res.data.IPv4);
	}, [ip]);

	useEffect(() => {
		getIPData();
	}, [getIPData.apply, ip]);

	//const getDeviceType = () => {
	//	const ua = navigator.userAgent;
	//	if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
	//		return "tablet";
	//	}
	//	if (
	//		/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
	//			ua
	//		)
	//	) {
	//		return "mobile";
	//	}
	//	return "desktop";
	//};

	const logUserData = () => {
		const request = {
			user_email: " ",
			sessionID: " ",
			loginTime: null,
			client_IP: " ",
			serverIP: " ",
			deviceType: " "
		}
		request.deviceType = getDeviceType();
		request.client_IP = ip;
		axios
			.post("api/Login/User", request)
			.then((res) => {
			})
			.catch((error) => {
			});
	}

	useEffect(() => {
		if (isAuthenticated["isLoggedIn"] && isRedirected) {
			axios
				.get("api/Profile/GetCandidateProfile", {
					params: {
						candidate_id: candidatID,
					},
				})
				.then((response) => {
					const contentdata = response.data;
					if (contentdata.success == true && contentdata.statusCode == 200) {
						localStorage.setItem('userCategoryId', contentdata?.data?.category_id)
					}
				})
				.catch((error) => {
					navigate('/')
					//console.log("error axios", error.message);
				});
			setTimeout(() => {
				navigate(`/JobDetails/${btoa(jobId)}/${btoa(localStorage.getItem('category_id'))}`, {
					replace: false,
				});
			}, 300);
		}
		if (isAuthenticated["isLoggedIn"] && !isRedirected) {
			logUserData()
			axios
				.get("api/Profile/GetCandidateProfile", {
					params: {
						candidate_id: candidatID,
					},
				})
				.then((response) => {
					const contentdata = response.data;
					if (contentdata.success == true && contentdata.statusCode == 200) {
						localStorage.setItem('userCategoryId', contentdata?.data?.category_id)
						localStorage.setItem('category_id', contentdata?.data?.category_id)
						if (contentdata?.data?.category_id == 1) {
							navigate(`/current-openings/Academics`)
						}
						if (contentdata?.data?.category_id == 2) {
							navigate(`/current-openings/Jio-Digital-Library`)
						}
						if (contentdata?.data?.category_id == 3) {
							navigate(`/current-openings/Non-Academic`)
						}
					} else {
						navigate('/')
					}
				})
				.catch((error) => {
					navigate('/')
					//console.log("error axios", error.message);
				});
		}
	}, [isAuthenticated]);

	useEffect(() => {
		if (isAuthenticated["isLoggedIn"]) {
			navigate("/", {
				replace: false,
				state: {
					login_state: ALREADY_LOGGED_IN,
				},
			});
		}
	}, []);

	const [root, setRoot] = useState(false);
	useEffect(() => {
		if (root) {
			navigate("/");
			localStorage.removeItem("signup-form")
		}
	}, [root]);

	const handlePopup = () => {
		setshow(true);
	};
	const handleGoBackPopup = () => {
		setshowGoBackConfirmationPopup(true);
	};

	return (
		<>
			<div className="main-container full_width">
				<section className="section-masthead space-before-breadcrumb section-common-space masthead-mob-space overflow-hidden mt-0">
					<div className="container">
						<div className="row d-none d-lg-block">
							<div className="col-lg-12">
								{/*breadcrumb*/}
								<nav aria-label="breadcrumb">
									<ol className="breadcrumb">
										<li className="breadcrumb-item">
											<span
												onClick={handleGoBackPopup}
												style={{ cursor: "pointer" }}
											>
												Home
											</span>
										</li>
										<li className="breadcrumb-item active" aria-current="page">
											Register
										</li>
									</ol>
								</nav>
							</div>
						</div>
						<div className="row signup-row mt-sm-5 mt-md-0">
							{/* <Row className="signup-row"> */}
							<div className="col-5 d-none d-md-block">
								{/* <div className="signup-img-wrap"> */}
								<div className="rounded-circle masthead-rounded-circle">
									<img
										src="assets/images/career/log-in.png"
										alt=""
										className="w-100 row signup-img "
										width=""
										height=""
									/>
								</div>
								{/* </div> */}
							</div>
							<div className="col-lg-7 col-12">
								{/* <Col> */}
								<div
									className="register-form-screen"
									style={{ display: "block" }}
								>
									<div className="row">
										<div className="col-12">
											{/* <h1 className="h1-01 font-black mb-3">Register</h1> */}
											<div className="h5-05 font-medium">
												Kindly register to apply for the job
											</div>
										</div>
									</div>
									<Formik
										enableReinitialize
										initialValues={initialFormValues}
										validationSchema={Yup.object({
											salutation: Yup.string().required("Required"),
											firstName: Yup.string()
												.max(50, "Must be 50 characters or less")
												.required("Required")
												.matches(
													/^[[a-zA-Z][a-zA-Z ]+$/,
													"Enter valid details"
												),
											lastName: Yup.string()
												.max(50, "Must be 50 characters or less")
												.required("Required")
												.matches(
													/^[[a-zA-Z][a-zA-Z ]+$/,
													"Enter valid details"
												),
											email: Yup.string()
												.email("Invalid email address")
												.required("Required"),

											isd_code: Yup.string().required("Required"),
											mobile: Yup.string()
												.required("Required")
												.max(10, "Enter Valid Phone Number")
												.test(
													"Validate user input",
													"Enter Valid Phone Number",
													function (value) {
														const phoneRegex =
															/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
														// /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
														// Change this regex based on requirement
														let isValidPhone = phoneRegex.test(value);
														if (isValidPhone) {
															return true;
														}
														return false;
													}
												),
											// linkedin_url: Yup.string().required("Required"),
											category_id: Yup.string().required("Required"),
											sub_category_id: Yup.string().required("Required"),
											// 						cvFile: Yup.string().required("Required").test(
											// 							'oneOfRequired',
											// 							'One of FileName',
											//      function(item) {
											//    return (this.cvFile=fileName)
											//  }
											// 						),
											captcha: Yup.string().required("Required"),
										})}
										onSubmit={(values, { setSubmitting }) => {
											// values = { ...values, cvFile: fileName };
											setsubmit(false);
											setformData(values);
											setshow(true);

											setSubmitting(false);
										}}
									>
										{(props) => {
											const {
												values,
												touched,
												errors,
												isSubmitting,
												handleChange,
												handleBlur,
												handleSubmit,
												setFieldValue,
											} = props;
											return (
												<Form className="section-common-space overflow-hidden details-wrapper">
													<div className="row d-flex justify-content-between">
														<div className="col-lg-2 pr-lg-1">
															<div className="form-wrapper">
																<DropDown
																	label="Prefix*"
																	name="salutation"
																	setFieldValue={setFieldValue}
																>
																	<option value="">Select</option>
																	<option value="Dr.">Dr.</option>
																	<option value="Mr.">Mr.</option>
																	<option value="Ms">Ms.</option>
																	<option value="Mrs">Mrs.</option>
																	<option value="other">Other</option>
																</DropDown>
															</div>
														</div>
														{/* </div> */}
														{/* <div className="row"> */}
														<div className="col-md-5 col-sm-12 ">
															<div className="form-group input-container">
																<TextInput
																	className="form-control"
																	label="First Name*"
																	name="firstName"
																	type="text"
																	placeholder="First Name"
																/>
																<div className="right-icon clear-value">
																	<svg
																		xmlns="http://www.w3.org/2000/svg"
																		viewBox="0 0 28 28"
																	>
																		<g transform="translate(3.498 3.498)">
																			<path
																				d="M1648.295,1400.784a10.5,10.5,0,1,0,10.5,10.5A10.5,10.5,0,0,0,1648.295,1400.784Zm0,19.254a8.753,8.753,0,1,1,8.753-8.753A8.762,8.762,0,0,1,1648.295,1420.038Z"
																				transform="translate(-1637.793 -1400.784)"
																			/>
																			<path
																				d="M1652.651,1408.881a.876.876,0,0,0-1.237,0l-2.147,2.147-2.146-2.147a.875.875,0,0,0-1.238,1.237l2.148,2.147-2.148,2.147a.875.875,0,0,0,1.238,1.237l2.146-2.147,2.147,2.147a.875.875,0,0,0,1.237-1.237l-2.147-2.147,2.147-2.147A.877.877,0,0,0,1652.651,1408.881Z"
																				transform="translate(-1638.772 -1401.764)"
																			/>
																		</g>
																	</svg>
																</div>
															</div>
														</div>
														<div className="col-md-5 col-sm-12 pl-md-0">
															<div className="form-group input-container">
																<TextInput
																	className="form-control"
																	label="Last Name*"
																	name="lastName"
																	type="text"
																	placeholder="Last Name"
																/>
																<div className="right-icon clear-value">
																	<svg
																		xmlns="http://www.w3.org/2000/svg"
																		viewBox="0 0 28 28"
																	>
																		<g transform="translate(3.498 3.498)">
																			<path
																				d="M1648.295,1400.784a10.5,10.5,0,1,0,10.5,10.5A10.5,10.5,0,0,0,1648.295,1400.784Zm0,19.254a8.753,8.753,0,1,1,8.753-8.753A8.762,8.762,0,0,1,1648.295,1420.038Z"
																				transform="translate(-1637.793 -1400.784)"
																			/>
																			<path
																				d="M1652.651,1408.881a.876.876,0,0,0-1.237,0l-2.147,2.147-2.146-2.147a.875.875,0,0,0-1.238,1.237l2.148,2.147-2.148,2.147a.875.875,0,0,0,1.238,1.237l2.146-2.147,2.147,2.147a.875.875,0,0,0,1.237-1.237l-2.147-2.147,2.147-2.147A.877.877,0,0,0,1652.651,1408.881Z"
																				transform="translate(-1638.772 -1401.764)"
																			/>
																		</g>
																	</svg>
																</div>
															</div>
														</div>
													</div>

													<div className="row">
														{/* removed border-bottom  */}
														<div className="col-12 pb-1">
															<div className="form-group input-container with-left-icon">
																<TextInput
																	className="form-control"
																	label="Email*"
																	name="email"
																	type="text"
																	placeholder="abc@xyz.com"
																	disabled={emailvalue}
																	onKeyUp={() => {
																		if (!errors?.email) {
																			checkExistingUser(values.email);
																		}
																	}}
																/>
																<div className="left-icon">
																	<svg
																		id="Left_Icon"
																		data-name="Left Icon"
																		xmlns="http://www.w3.org/2000/svg"
																		width={20}
																		height={20}
																		viewBox="0 0 20 20"
																	>
																		<g
																			id="Group_868"
																			data-name="Group 868"
																			transform="translate(0 2.552)"
																		>
																			<path
																				id="Path_856"
																				data-name="Path 856"
																				d="M372.777,1486.522a.491.491,0,0,0,.706,0l8.742-7.542a.21.21,0,0,0-.1-.366.938.938,0,0,0-.146-.011H364.284a1,1,0,0,0-.167.014.21.21,0,0,0-.1.366Z"
																				transform="translate(-363.131 -1478.604)"
																				fill="#767b87"
																			/>
																			<path
																				id="Path_857"
																				data-name="Path 857"
																				d="M374.131,1487.775a1.729,1.729,0,0,1-2.327,0l-8.372-7.2a.28.28,0,0,0-.462.211c0,2.481,0,11.716,0,11.716a1.242,1.242,0,0,0,1.153,1.314h17.692a1.242,1.242,0,0,0,1.154-1.314s0-9.251,0-11.736a.279.279,0,0,0-.462-.21Z"
																				transform="translate(-362.968 -1478.917)"
																				fill="#767b87"
																			/>
																		</g>
																		<rect
																			id="Rectangle_523"
																			data-name="Rectangle 523"
																			width={20}
																			height={20}
																			fill="none"
																		/>
																	</svg>
																</div>
																<div
																	className="right-icon clear-value"
																	style={{ display: "none" }}
																>
																	<svg
																		xmlns="http://www.w3.org/2000/svg"
																		viewBox="0 0 28 28"
																	>
																		<g transform="translate(3.498 3.498)">
																			<path
																				d="M1648.295,1400.784a10.5,10.5,0,1,0,10.5,10.5A10.5,10.5,0,0,0,1648.295,1400.784Zm0,19.254a8.753,8.753,0,1,1,8.753-8.753A8.762,8.762,0,0,1,1648.295,1420.038Z"
																				transform="translate(-1637.793 -1400.784)"
																			/>
																			<path
																				d="M1652.651,1408.881a.876.876,0,0,0-1.237,0l-2.147,2.147-2.146-2.147a.875.875,0,0,0-1.238,1.237l2.148,2.147-2.148,2.147a.875.875,0,0,0,1.238,1.237l2.146-2.147,2.147,2.147a.875.875,0,0,0,1.237-1.237l-2.147-2.147,2.147-2.147A.877.877,0,0,0,1652.651,1408.881Z"
																				transform="translate(-1638.772 -1401.764)"
																			/>
																		</g>
																	</svg>
																</div>
															</div>
														</div>
													</div>
													<div className="row ">
														<div className="col-lg-12 col-12">
															<div className="d-md-flex justify-content-sm-between">
																{/* removed border-bottom */}
																<div className="pl-0 col-lg-4 pb-1">
																	<div className="form-group input-container mb-0	">
																		<DropDown
																			label="ISD Code*"
																			name="isd_code"
																			setFieldValue={setFieldValue}
																		>
																			<option value="">Select</option>
																			{countryISDCodes?.map((isdCode) => (
																				<option value={isdCode} key={isdCode}>
																					{isdCode}
																				</option>
																			))}
																		</DropDown>

																		<div className="left-icon">
																			<svg
																				xmlns="http://www.w3.org/2000/svg"
																				viewBox="0 0 16 16"
																			>
																				<defs>
																					<style
																						dangerouslySetInnerHTML={{
																							__html:
																								".a {fill: ;}.b {fill: #767b87;}.c {fill: #fff;}",
																						}}
																					/>
																				</defs>
																				<rect
																					className="a"
																					width={16}
																					height={16}
																				/>
																				<g transform="translate(0.818 1.447)">
																					<circle
																						className="b"
																						cx={7}
																						cy={7}
																						r={7}
																						transform="translate(0.182 -0.447)"
																					/>
																					<path
																						className="c"
																						d="M242.263,314.6a2.976,2.976,0,0,0,1.15-2.069l1.207,2.069Zm-4.783-2.069-1.207,2.069h2.356A2.976,2.976,0,0,1,237.48,312.536Zm2.966-3.274a2.968,2.968,0,0,1,1.2.254l-1.2-2.065-1.2,2.065A2.967,2.967,0,0,1,240.446,309.261Zm0,.873a2.108,2.108,0,1,0,2.108,2.108A2.108,2.108,0,0,0,240.446,310.134Z"
																						transform="translate(-233.093 -304.898)"
																					/>
																				</g>
																			</svg>
																		</div>
																	</div>
																</div>
																{/* removed border-bottom */}
																<div className="col-lg-8 px-0 pb-1">
																	<div className="form-group input-container with-left-icon flex-fill">
																		<TextInput
																			className="form-control "
																			label="Phone Number*"
																			name="mobile"
																			type="text"
																			maxLength={10}
																			placeholder="998*******"
																			disabled={phonevalue}
																			onKeyUp={() => {
																				if (!errors?.mobile) {
																					checkExistingUser(values.mobile);
																				}
																			}}
																		/>
																		<div className="left-icon">
																			<svg
																				id="mobile-device-hardware-functional-filled"
																				xmlns="http://www.w3.org/2000/svg"
																				width={20}
																				height={20}
																				viewBox="0 0 20 20"
																			>
																				<path
																					id="Path_2259"
																					data-name="Path 2259"
																					d="M564.656,10650.936h-6.719a1.951,1.951,0,0,0-1.846,2.037v13.419a1.95,1.95,0,0,0,1.846,2.036h6.719a1.95,1.95,0,0,0,1.847-2.036v-13.419A1.951,1.951,0,0,0,564.656,10650.936Zm-3.328,15.323a.833.833,0,1,1,.833-.833A.833.833,0,0,1,561.328,10666.259Z"
																					transform="translate(-551.297 -10649.682)"
																					fill="#767b87"
																				/>
																				<rect
																					id="Rectangle_1162"
																					data-name="Rectangle 1162"
																					width={20}
																					height={20}
																					fill="none"
																				/>
																			</svg>
																		</div>
																		<div
																			className="right-icon clear-value"
																			style={{ display: "none" }}
																		>
																			<svg
																				xmlns="http://www.w3.org/2000/svg"
																				viewBox="0 0 28 28"
																			>
																				<g transform="translate(3.498 3.498)">
																					<path
																						d="M1648.295,1400.784a10.5,10.5,0,1,0,10.5,10.5A10.5,10.5,0,0,0,1648.295,1400.784Zm0,19.254a8.753,8.753,0,1,1,8.753-8.753A8.762,8.762,0,0,1,1648.295,1420.038Z"
																						transform="translate(-1637.793 -1400.784)"
																					/>
																					<path
																						d="M1652.651,1408.881a.876.876,0,0,0-1.237,0l-2.147,2.147-2.146-2.147a.875.875,0,0,0-1.238,1.237l2.148,2.147-2.148,2.147a.875.875,0,0,0,1.238,1.237l2.146-2.147,2.147,2.147a.875.875,0,0,0,1.237-1.237l-2.147-2.147,2.147-2.147A.877.877,0,0,0,1652.651,1408.881Z"
																						transform="translate(-1638.772 -1401.764)"
																					/>
																				</g>
																			</svg>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
													{/* <br></br> */}
													<div className="row">
														{/* removed border-bottom */}
														<div className="col-12  pb-1">
															<div className="form-group input-container with-left-icon">
																<TextInput
																	className="form-control"
																	label="LinkedIn URL or Website URL"
																	name="linkedin_url"
																	type="text"
																	placeholder="linkedin.com/in/xyz"
																	maxLength={128}
																/>

																<div className="left-icon">
																	<svg
																		id="Left_Icon"
																		data-name="Left Icon"
																		xmlns="http://www.w3.org/2000/svg"
																		width={20}
																		height={20}
																		viewBox="0 0 20 20"
																	>
																		<g
																			id="Group_2263"
																			data-name="Group 2263"
																			transform="translate(2.51 1.242)"
																		>
																			<path
																				id="Path_2051"
																				data-name="Path 2051"
																				d="M370.174,8672.918a3.856,3.856,0,1,0-3.857-3.856A3.86,3.86,0,0,0,370.174,8672.918Z"
																				transform="translate(-362.683 -8665.205)"
																				fill="#767b87"
																			/>
																			<path
																				id="Path_2052"
																				data-name="Path 2052"
																				d="M369.447,8675.482a7.5,7.5,0,0,0-7.491,7.489,1.461,1.461,0,0,0,1.462,1.462h12.057a1.461,1.461,0,0,0,1.462-1.462A7.5,7.5,0,0,0,369.447,8675.482Z"
																				transform="translate(-361.956 -8666.918)"
																				fill="#767b87"
																			/>
																		</g>
																		<rect
																			id="Rectangle_1059"
																			data-name="Rectangle 1059"
																			width={20}
																			height={20}
																			fill="none"
																		/>
																	</svg>
																</div>
																<div
																	className="right-icon clear-value"
																	style={{ display: "none" }}
																>
																	<svg
																		xmlns="http://www.w3.org/2000/svg"
																		viewBox="0 0 28 28"
																	>
																		<g transform="translate(3.498 3.498)">
																			<path
																				d="M1648.295,1400.784a10.5,10.5,0,1,0,10.5,10.5A10.5,10.5,0,0,0,1648.295,1400.784Zm0,19.254a8.753,8.753,0,1,1,8.753-8.753A8.762,8.762,0,0,1,1648.295,1420.038Z"
																				transform="translate(-1637.793 -1400.784)"
																			/>
																			<path
																				d="M1652.651,1408.881a.876.876,0,0,0-1.237,0l-2.147,2.147-2.146-2.147a.875.875,0,0,0-1.238,1.237l2.148,2.147-2.148,2.147a.875.875,0,0,0,1.238,1.237l2.146-2.147,2.147,2.147a.875.875,0,0,0,1.237-1.237l-2.147-2.147,2.147-2.147A.877.877,0,0,0,1652.651,1408.881Z"
																				transform="translate(-1638.772 -1401.764)"
																			/>
																		</g>
																	</svg>
																</div>
															</div>
														</div>
													</div>
													<div className="row">
														<div className="col-md-6 col-12">
															<div className=" input-container">
																<DropDown
																	label="Area of Interest*"
																	name="category_id"
																	AreaSelection={AreaSelection}
																	setFieldValue={setFieldValue}
																>
																	<option key={"k1"} value="">
																		Select
																	</option>
																	{departmentData?.sort()?.map((department) => (
																		<option
																			key={department.category_id}
																			value={Number(department.category_id)}
																			name={department.category_name}
																		>
																			{department.category_name}
																		</option>
																	))}
																</DropDown>
															</div>
														</div>
														<div className="col-md-6 col-12">
															<div className="input-container">
																<DropDown
																	label="Department*"
																	name="sub_category_id"
																	setFieldValue={setFieldValue}
																>
																	<option key={"k2"} value="">
																		Select
																	</option>
																	{areaData?.sort()?.map((area) => (
																		<option
																			key={area.sub_category_id}
																			value={area.sub_category_id}
																		>
																			{area.sub_category_name}
																		</option>
																	))}
																</DropDown>
															</div>
														</div>
													</div>

													{/* <div className="row d-none">
                    <div className="col-10 border-bottom pb-1">
                        <div className="input-file-frame">
                            <label
                                htmlFor="user-name font-medium button-md neutral-70"
                                className="control-label"
                            >
                                Curriculum Vitae
                            </label>
                            <div className="form-group input-container with-left-icon mb-3 custom-file  d-block">
                                <Attachment
                                    className="form-control custom-file-input"
                                
                                    label={fileName}
                                    name="cvFile"
                                    type="file"
                                    onChange={(e) => {
                                        e.preventDefault();
                                        setfileName(e.target.files[0].name);
                                        //  cvFile = e.target.files[0].name;
                                        FileUpdate(e.target.files[0]);
                                    }}
                                />
                                <div className="left-icon">
                                    <svg
                                        id="Left_Icon"
                                        data-name="Left Icon"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={20}
                                        height={20}
                                        viewBox="0 0 20 20"
                                    >
                                        <g
                                            id="Group_1173"
                                            data-name="Group 1173"
                                            transform="translate(0 1.871)"
                                        >
                                            <path
                                                id="Path_1112"
                                                data-name="Path 1112"
                                                d="M1725.562,3857.3v-1.515a1.6,1.6,0,0,0-1.578-1.612h-8.4s-1.188-1.114-2.55-2.5h-5.89a1.6,1.6,0,0,0-1.577,1.612v4.018Z"
                                                transform="translate(-1705.562 -3851.669)"
                                                fill="#767b87"
                                            />
                                            <path
                                                id="Path_1113"
                                                data-name="Path 1113"
                                                d="M1705.562,3859.924v7.666a1.767,1.767,0,0,0,1.577,1.715h16.844a1.767,1.767,0,0,0,1.578-1.715v-7.666Z"
                                                transform="translate(-1705.562 -3853.045)"
                                                fill="#767b87"
                                            />
                                        </g>
                                        <rect
                                            id="Rectangle_642"
                                            data-name="Rectangle 642"
                                            width={20}
                                            height={20}
                                            fill="none"
                                        />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        {isUploading && (
                            <Spinner
                                animation="border"
                                role="status"
                                variant="danger"
                            >
                                <span className="visually-hidden"></span>
                            </Spinner>
                        )}
                        <p className="font-medium button-sm neutral-70">
                            One file only.5 MB limit.Allowed types: pdf,
                            doc,png, docx.
                        </p>
                    </div>
                </div> */}

													<div className="row ">
														<div className="col-md-6 col-sm-6">
															<div className="form-group input-container">
																<TextInput
																	className="form-control"
																	label="Enter Captcha*"
																	name="captcha"
																	type="text"
																	placeholder="Captcha text"
																/>
																<div className="right-icon clear-value">
																	<svg
																		xmlns="http://www.w3.org/2000/svg"
																		viewBox="0 0 28 28"
																	>
																		<g transform="translate(3.498 3.498)">
																			<path
																				d="M1648.295,1400.784a10.5,10.5,0,1,0,10.5,10.5A10.5,10.5,0,0,0,1648.295,1400.784Zm0,19.254a8.753,8.753,0,1,1,8.753-8.753A8.762,8.762,0,0,1,1648.295,1420.038Z"
																				transform="translate(-1637.793 -1400.784)"
																			/>
																			<path
																				d="M1652.651,1408.881a.876.876,0,0,0-1.237,0l-2.147,2.147-2.146-2.147a.875.875,0,0,0-1.238,1.237l2.148,2.147-2.148,2.147a.875.875,0,0,0,1.238,1.237l2.146-2.147,2.147,2.147a.875.875,0,0,0,1.237-1.237l-2.147-2.147,2.147-2.147A.877.877,0,0,0,1652.651,1408.881Z"
																				transform="translate(-1638.772 -1401.764)"
																			/>
																		</g>
																	</svg>
																</div>
															</div>
														</div>
														<div className="col-md-6 col-sm-6">
															<div className="captcha mt-2 mb-4">
																<img
																	className="img-fluid captcha-img rounded mr-3"
																	src={captchaImgURL}
																	// src="/api/Login/captcha"
																	alt="Captcha"
																	lazy="true"
																/>
																<Button
																	className="ml-1 mt-2"
																	onClick={() =>
																		setcaptchaImgURL(
																			`/api/Login/captcha?${Math.floor(
																				Math.random() * 100
																			)}`
																		)
																	}
																>
																	<i
																		className="bi bi-arrow-clockwise"
																		style={{ fontSize: "1.1rem" }}
																	></i>
																</Button>
															</div>
														</div>
													</div>
													<div className="row">
														<div className="col-12">
															<button
																className="btn btn-primary center-v arrow-white"
																type="submit"
																disabled={isUploading}
															>
																Submit
															</button>
														</div>
													</div>
													<PersistFormikValues
														name="signup-form"
														debounce={300}
														storage="localStorage"
														persistInvalid={true}
													/>
												</Form>
											);
										}}
									</Formik>
								</div>
								{/* </Col> */}
							</div>
							{/* </Row> */}
						</div>{" "}
					</div>{" "}
					{
						<Popup
							show={show}
							setshow={setshow}
							notifcationInfo={notificationInfo}
							setsubmit={(e) => {
								setsubmit(true);
							}}
						/>
					}
					{
						<Popup
							show={showGoBackConfirmationPopup}
							setshow={setshowGoBackConfirmationPopup}
							notifcationInfo={showpopup}
							setsubmit={(e) => {
								setRoot(true);
							}}
						/>
					}
				</section>
				<ToastContainer autoClose={4000} theme="colored" />
			</div>
		</>
	);
}

export default SignUp;
