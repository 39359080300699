import axios from "axios";
import CryptoJS from "crypto-js"

export default function getCandidateProfileInfo(candidate_id, from_page) {
	const userHex = CryptoJS.MD5((candidate_id + process.env.REACT_APP_MD5_PASSCODE)?.toLowerCase())?.toString();
	const requestBody = {
		userName: candidate_id,
		fromRegisterPage: from_page == "register" ? true : false,
		CheckUser:userHex
	};
	return axios
		.post("/api/Login/user-status", requestBody)
		.then((response) => {
			return response?.data
		})
		.catch((error) => {
			//console.log("error axios", error.message);
			return null;
		});
}
