import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { makeStyles } from "@mui/styles";
import { useField } from "formik";
import countryISDCodes from "../Common/countryISDCodes";

const useStyles = makeStyles({
	root: {
		"& .MuiOutlinedInput-input": {
			color: "#000",
			fontSize: "1rem",
			fontWeight: 400,
			fontFamily: "JioTypeW04-Medium",
			minWidth:40 
		},
	},
});

const MuiCountryCode = ({
	label,
	setFieldValue = null,
	setFieldTouched = null,
	name,
	...props
}) => {
	const [field, meta] = useField(name);
	const classes = useStyles();

  return (
	<div className=''>
		 <label
		htmlFor="user-name font-medium button-md neutral-70"
		className="control-label"
		style={{fontSize:"0.8rem",marginBottom:"-0.5rem"}}
		>
		{label}
		</label>
      <Autocomplete
        id="free-solo-demo"
		disableClearable
		sx={{ '& fieldset': { borderTopRightRadius: 0,borderBottomRightRadius:0 }}}
		size="small"
		value={field.value}
        options={countryISDCodes.map((v) => v)}
		onChange={( _, value )=>{
			setFieldValue( field.name, value );
				}}
				onBlur={() => {
					setFieldTouched(field.name, true);
				}}
				className={classes.root}
				renderInput={(params) => {
					return (
						<TextField
							{...params}
							placeholder="Select"
							sx={{
								input: {
									"&::placeholder": {
										opacity: 1,
									},
								},
							}}
						/>
					);
				}}
			/>
			{/*{meta.touched && meta.error ? (
				<small className="massage error d-block">{meta.error}</small>
			) : null}*/}
		</div>
	);
};

export default MuiCountryCode;
