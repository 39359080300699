import React, { useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import IdleTimer from "react-idle-timer";
import { useDispatch } from "react-redux";
import axios from "axios";
import { setUserLogout } from "../redux/UserDetails/UserActions";
import { useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";

function IdleSessionContainer(props) {
	const idelTimeRef = useRef(null);
	const timeoutDuaration = 21600 * 1000;
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const onIdle = () => {
		setShow(true);
		setTimeout(() => {
			dispatch(setUserLogout());
			localStorage.clear(); //for localStorage
			sessionStorage.clear(); //for sessionStorage
			axios
			.post("/api/Login/Logout", null)
			.then((res) => { })
			.catch((err) => console.log("error is ", err.toString()));
			window.location.reload()
			//navigate("../login", { replace: false });
		}, 5000);
	};

	return (
		<>
			<IdleTimer ref={idelTimeRef} timeout={timeoutDuaration} onIdle={onIdle}>
				{props.children}
			</IdleTimer>

			<Modal
				show={show}
				onHide={handleClose}
				style={{ color: "black", backgroundColor: "transparent" }}
			>
				<Modal.Header  style={{display:"flex", justifyContent:"center"}} closeVariant="white">
					<Modal.Title >Warning!</Modal.Title>
				</Modal.Header>
				<Modal.Body style={{display:"flex", justifyContent:"center"}}>
					<div className="task-cont-popup2">
						<div className="d-flex justify-content-center">
						<img
							src={
								"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyhA6QlAXwBmXP0YCZEXzjbNNjuqvKkiUzS6eNEHJTNFRI0CfMZpBUmTBOpzr25wgetoU&usqp=CAU"
							}
							height="50px"
							width="50px"
						/>
						</div>
						<br />
						<br />
						<strong>You will be logged out soon due to inactivity! </strong>
					</div>
				</Modal.Body>
			</Modal>
			<Outlet />
		</>
	);
}

export default IdleSessionContainer;
