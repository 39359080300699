import React, { useState, useEffect } from "react";
import { useField } from "formik";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { TextField } from "@mui/material";
import moment from "moment";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-input": {
      color: "#000",
      fontSize: "1rem",
      fontWeight:400,
      fontFamily:"JioTypeW04-Medium"
    }
  }
});
const DatePicker = ({ fieldDesc,setFieldValue,setFieldTouched, ...props  }) => {
	const [field, meta] = useField(props);
	const [maxDate,setMaxDate] = useState(null)
	const [minDate,setMinDate] = useState(null)

	useEffect(() => {
		if(field?.name.includes('Start Date_363') && field?.value){
			localStorage.setItem('Start Date_363',field?.value)
		}
		if(field?.name.includes('End Date_364') && field?.value){
			localStorage.setItem('End Date_364',field?.value)
		}
		if(field?.name.includes('Start Date_383') && field?.value){
			localStorage.setItem('Start Date_383',field?.value)
		}
		if(field?.name.includes('End Date_384') && field?.value){
			localStorage.setItem('End Date_384',field?.value)
		}
	}, [field?.value])
	const startDateCurrent = localStorage.getItem('Start Date_363') || null;
	const endDateCurrent = localStorage.getItem('End Date_364') || null;
	const startDatePrevious = localStorage.getItem('Start Date_383') || null;
	const endDatePrevious = localStorage.getItem('End Date_384') || null;
	useEffect(() => {
		if(field?.name.includes('End Date_364') && startDateCurrent){
			setMinDate(startDateCurrent)
		}
		if(field?.name.includes('Start Date_363') && endDateCurrent){
			setMaxDate(endDateCurrent)
		}
		if(field?.name.includes('End Date_384') && startDatePrevious){
			setMinDate(startDatePrevious)
		}
		if(field?.name.includes('Start Date_383') && endDatePrevious){
			setMaxDate(endDatePrevious)
		}
	}, [startDateCurrent,endDateCurrent,startDatePrevious,endDatePrevious])

    const classes = useStyles();
return(
    <div>
                <label
								htmlFor="user-name font-medium button-md neutral-70"
								className="control-label"
								style={{fontSize:"0.8rem",marginBottom:"-0.5rem"}}
							>
								{fieldDesc}
							</label>
					<div className="form-group input-container">
				     <LocalizationProvider dateAdapter={AdapterMoment}>
                    <MobileDatePicker
                    inputFormat="DD/MM/YYYY"
                    disableFuture
                    onChange={(value) => {
                      setFieldValue(field.name,moment(value));
                    }}
                    onClose={()=>{setFieldTouched(field.name,true)}}
                    value={field.value ? field.value : null}
                    name={field.name}
					maxDate={maxDate ? moment(maxDate) : ""}
					minDate={minDate ? moment(minDate) : ""}
                    className={classes.root}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        {...params}
                        InputProps={{
                          endAdornment: <CalendarTodayIcon style={{transform:"scale(0.65)"}} />,
                        }}
                        placeholder="dd-mm-yyyy"
                        sx={{
                          width: "100%",
                          input: {
                            "&::placeholder": {    
                                opacity: 1,
                                fontWeight:1200,
                             },
                          },
                       }}
                      />
                    )}
                  />
                </LocalizationProvider>
            {meta?.error && meta?.touched ? (
					<small className="massage error d-block">{meta?.error}</small>
				) : null}
						</div>
            
    </div>
)
};
export default DatePicker;
