// import { fileTypeFromFile } from "file-type";
import {
	allowedDocExtensions,
	allowedDoctyeps,
} from "../../Common/Constants/AllowedDoctypes";

function checkAllowedFileType(fileData) {
	for (let i = 0; i < fileData.length; i++) {
		if (
			fileData[i]?.name.endsWith(allowedDocExtensions[0]) ||
			fileData[i]?.name.endsWith(allowedDocExtensions[1]) ||
			fileData[i]?.name.endsWith(allowedDocExtensions[2]) ||
			allowedDoctyeps.includes(fileData[i]?.type)
		) {
			return true;
		}
		return false;
	}
}

export default checkAllowedFileType;
