import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";

function DropDownControl({ field_id, name }) {
	const [DropDownData, setDropDown] = useState([]);

	// console.log("X =>> ", name);

	let Country = localStorage.getItem("Country");
	let State = localStorage.getItem("State");
	let City = localStorage.getItem("City");
	let District = localStorage.getItem("District");

	// useEffect(() => {
	// 	console.log("Renredwer caused in child");
	// 	return () => {
	// 		localStorage.removeItem("Country");
	// 		localStorage.removeItem("State");
	// 		localStorage.removeItem("City");
	// 		localStorage.removeItem("District");
	// 	};
	// }, [Country, State, City, District]);

	useEffect(() => {
	
		axios
			.get("api/JobApplication/GetFieldControlMaster", {
				params: {
					field_id: field_id,
				},
			})
			.then((response) => {
				const contentdata = response.data;

				if (contentdata.data[0]?.category_id == 0) {
					setDropDown(contentdata.data);
					if (name.includes("State")) {
						if (
							localStorage.getItem("Country") !== null ||
							localStorage.getItem("Country") !== undefined
						) {
							setDropDown(
								contentdata.data?.filter(
									(x) => x.parent_field_id == localStorage.getItem("Country")
								)
							);
						}
					}
					if (name.includes("District")) {
						if (
							localStorage.getItem("State") !== null ||
							localStorage.getItem("State") !== undefined
						) {
							setDropDown(
								contentdata.data?.filter(
									(x) => x.parent_field_id == localStorage.getItem("State")
								)
							);
						}
					}
					if (name.includes("City")) {
						if (
							localStorage.getItem("State") !== null ||
							localStorage.getItem("State") !== undefined
						) {
							setDropDown(
								contentdata.data?.filter(
									(x) => x.parent_field_id == localStorage.getItem("State")
								)
							);
						}
					}
				} else if (contentdata.data[0].category_id != 0) {
					setDropDown(
						contentdata.data?.filter(
							(x) => x.category_id == localStorage.getItem("category_id")
						)
					);
				}
			})
			.catch((error) => {
				console.log("error axios", error.message);
			});
	}, [field_id, Country, State, City, District]);
	return (
		<>
			{/* {name.includes("State") ? (
				<option value={0} lkey={0} key={0} className="dropdown-item rounded">
					Other
				</option>
			) : null} */}
			{DropDownData?.length == 0 ? (
				<option value={0} lkey={0} key={0} className="dropdown-item rounded">
					Other
				</option>
			) : (
				DropDownData?.map((obj, index) => {
					return (
						<option
							value={obj.value_id}
							lkey={obj.value_id}
							key={index}
							className="dropdown-item rounded"
						>
							{obj.value_name}
						</option>
					);
				})
			)}
		</>
	);
}
export default DropDownControl;
