import React from "react";
import Header from "./Header";
import "./Common.css";
import Footer from "./Footer";
import { Routes, Route, Outlet, Link } from "react-router-dom";
import SessionExpireyPopup from "./SessionExpireyPopup";

const Layout = ({ children, show, setshow }) => {
	return (
		<React.Fragment>
			<Header />
			{children}
			<Outlet />
			<Footer className="position-absolute w-100  fixed-md-bottom "/>
			<SessionExpireyPopup
				show={show}
				setshow={setshow}
			/>
		</React.Fragment>
	);
};

export default Layout;
