import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import _ from "lodash";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import axios from "axios";
import { setUserLogout } from "../redux/UserDetails/UserActions";
function Popup(props) {
	const dispatch=useDispatch()
	const handleClose = () => {
		props.setshow(false);
	};

	const [showpopup, setshowpopup] = useState({
		title: "Session Expired. Please sign in.",
		msg: "Your session has expired. Click OK to return to login page.",
	});

	const sessionExpire=()=>{
			dispatch(setUserLogout());
			localStorage.clear(); //for localStorage
			sessionStorage.clear(); //for sessionStorage
			axios
				.post("/api/Login/Logout", null)
				.then((res) => { })
				.catch((err) => console.log("error is ", err.toString()));
				// setTimeout(() => {
				// 	window.location.reload()
				// }, 100);
				
		}
const navigate=useNavigate()
	return (
		<Modal
			show={props?.show}
			//onHide={handleClose}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header>
				<div className="text-center w-100">
					<h3
						className="modal-title font-medium h5-05"
						id="exampleModalLiveLabel"
					>
						{showpopup?.title}
					</h3>
				</div>
				<button
					type="button"
					className="close"
					data-dismiss="modal"
					aria-label="Close"
					onClick={(e) => {
						e.preventDefault();
						handleClose();
						sessionExpire()
						setTimeout(() => {
							window.location.reload()
						}, 100);
					}}
				>
					<span aria-hidden="true">×</span>
				</button>
			</Modal.Header>
			<Modal.Body>
				<div className="modal-body text-center my-0 text-wrap">
					<h5>{showpopup?.msg}</h5>
				</div>
			</Modal.Body>
			<Modal.Footer className="footer_btns">
				<Button
					style={{ minWidth: "79px", justifyContent: "center" }}
					type="button"
					onClick={(e) => {
						e.preventDefault();
						// props.setsubmit(true);
						handleClose();
						sessionExpire()
						setTimeout(()=>
							navigate("/login", { replace: false }),100
						)						
					}}
					className=" btn center-v mt-2 mt-lg-0 text-nowrap mr-2"
					data-dismiss="modal"
				>
					OK
				</Button>
				<Button
					style={{ minWidth: "79px", justifyContent: "center" }}
					type="button"
					onClick={(e) => {
						e.preventDefault();
						handleClose();
						sessionExpire()
						setTimeout(() => {
							window.location.reload()
						}, 100);
					}}
					className=" btn center-v mt-2 mt-lg-0 text-nowrap mr-2"
					data-dismiss="modal"
				>
					Cancel
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

export default React.memo(Popup);
