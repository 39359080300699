import React, { useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { withdrawJobApplication } from "../APICaller/withdrawJobApplication";
import ApplicationProgress from "./ApplicationProgress";
import "./Dashboard.css";

function AppliedJobs({ jobs, candidateID, setrefreshPage }) {
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const ApplicationStatus = useSelector(
		(state) => state.masterData.applicationStatusMaster
	);
	let Departments = useSelector(
		(state) => state.masterData.areaDeptMaster.area
	);
	// console.log({ Departments });

	const [ActivePopUp, setActivePopUp] = useState(0);
	const navigate = useNavigate();

	return (
		<div >
			{jobs?.map?.((job) => (
				<div className="job-discription" key={job.job_id}>
					<div className="h5-05 font-medium mb-2 mt-2 primary">
						{job.job_title}
					</div>
					<div className="divider" />
					<div className="d-flex flex-sm-row flex-column justify-content-between align-items-center flex-grow-0">
						<div className="d-flex applied-jobs-list">
							<div className="mr-5 col-3">
								<div className="body-01">Department</div>
								<div className="body-01 font-bold dept-name">
									{
										Departments?.filter(
											(x) => x.sub_category_id == job.sub_category_id
										)[0]?.sub_category_name
									}
								</div>
							</div>
							<div className="mr-5 col-3">
								<div className="body-01">Type</div>
								<div className="body-01 font-bold">{job?.duration}</div>
							</div>
							<div className="col-3">
								{job?.status_id == 1 ? (
									<div className="body-01">Drafted on</div>
								) : (
									<div className="body-01">Applied on</div>
								)}
								<div className="body-01 font-bold">
									{moment(job?.applied_on).format("Do MMM YYYY")}
									{/* {moment(job?.applied_on).fromNow()} */}
								</div>
								{/* <div className="body-01 font-bold">5 days ago</div> */}
							</div>
						</div>
						<div>
							{job?.status_id == 1 && job?.is_active == 0 && (
								<span>
									<a
										href={`/JobApplication/${btoa(job?.job_id)}/`}
										className="btn btn-secondary center-v mt-2 mt-lg-0 text-wrap disabled text-dark"
									>
										<i className="bi bi-slash-circle-fill ban-icon" />
										&nbsp; No longer accepting applications
									</a>
								</span>
							)}
							{job?.status_id == 1 && job?.is_active == 1 && (
								<span>
									<a
										// href={`/JobApplication/${btoa(job?.job_id)}/${btoa(
										// 	job?.category_id
										// )}`}
										onClick={(e) => (
											e.preventDefault(),
											localStorage.setItem("job_trans_id", job.tran_hdr_id),
											localStorage.setItem("stageID", 1),
											localStorage.setItem("index", 0),
											localStorage.setItem(
												"__jbt_crx__",
												JSON.stringify(job?.job_title)
											),
											localStorage.setItem("category_id", job?.category_id),
											localStorage.setItem("jobid", job?.job_id),
											navigate(
												`/JobApplication/${btoa(job?.job_id)}/${btoa(
													job?.category_id
												)}`,
												{
													replace: false,
													state: {
														user_applied: false,
													},
												}
											)
										)}
										className="arrow-red btn btn-secondary center-v mt-2 mt-lg-0 text-nowrap"
									>
										Edit Application
									</a>
								</span>
							)}
							{job?.status_id >= 2 &&
								!moment(jobs?.posted_on).fromNow().includes("ago") && (
									<span>
										<a
											// href=""
											className=" btn center-v mt-2 mt-lg-0 text-nowrap mr-2"
											onClick={(e) =>
												withdrawJobApplication(candidateID, job?.job_id)
											}
										>
											<span className="mr-2">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													version="1.2"
													viewBox="0 0 22 23"
													width={22}
													height={23}
												>
													<title>
														button-tertiary-fluid-icon-medium-11-svg
													</title>
													<style
														dangerouslySetInnerHTML={{
															__html:
																"\n                        tspan { white-space:pre } \n                        .s0 { fill: #bb0027 } \n                        .s1 { fill: none } \n                      ",
														}}
													/>
													<g id="Icon_and_Text">
														<g id="delete-basicaction-functional-outline">
															<g id="Group_241">
																<path
																	id="Path_223"
																	fillRule="evenodd"
																	className="s0"
																	d="m20.8 5.2q0.2 0.2 0.2 0.5 0 0.2-0.2 0.4-0.2 0.2-0.4 0.2h-1.9v12.6q0 0.6-0.2 1.2-0.3 0.5-0.7 1-0.4 0.4-1 0.7-0.6 0.2-1.2 0.2h-8.8q-0.6 0-1.2-0.2-0.5-0.3-1-0.7-0.4-0.5-0.7-1-0.2-0.6-0.2-1.2v-12.6h-1.9q-0.2 0-0.4-0.2-0.2-0.2-0.2-0.4 0-0.3 0.2-0.5 0.2-0.2 0.4-0.1h3.9v-0.1q0-0.1 0-0.2c0-1.5 1-2.8 2.2-2.8h6.6c1.2 0 2.3 1.3 2.3 3v0.1h3.8q0.2-0.1 0.4 0.1zm-14.1-0.2h8.6v-0.1c0-0.9-0.5-1.7-1-1.7h-6.6c-0.4 0-1 0.7-1 1.7q0 0 0 0.1zm10.6 1.3h-0.7-11.1-0.7v12.5q0 0.4 0.1 0.7 0.1 0.4 0.4 0.6 0.3 0.3 0.6 0.5 0.4 0.1 0.7 0.1h8.8q0.4 0 0.7-0.1 0.3-0.2 0.6-0.5 0.3-0.2 0.4-0.6 0.2-0.3 0.2-0.7z"
																/>
																<path
																	id="Path_224"
																	className="s0"
																	d="m8 9.1q0.1 0 0.2 0.1 0.1 0.1 0.1 0.2 0.1 0.1 0.1 0.3v7.7q0 0.3-0.2 0.4-0.2 0.2-0.4 0.2-0.3 0-0.5-0.2-0.1-0.1-0.2-0.4v-7.7q0-0.2 0.1-0.3 0-0.1 0.1-0.2 0.1-0.1 0.2-0.1 0.1-0.1 0.3-0.1 0.1 0 0.2 0.1z"
																/>
																<path
																	id="Path_225"
																	className="s0"
																	d="m11.2 9.1q0.2 0 0.2 0.1 0.1 0.1 0.2 0.2 0 0.1 0 0.3v7.7q0 0.3-0.2 0.4-0.1 0.2-0.4 0.2-0.2 0-0.4-0.2-0.2-0.1-0.2-0.4v-7.7q0-0.2 0-0.3 0.1-0.1 0.2-0.2 0.1-0.1 0.2-0.1 0.1-0.1 0.2-0.1 0.1 0 0.2 0.1z"
																/>
																<path
																	id="Path_226"
																	className="s0"
																	d="m14.5 9.1q0.1 0 0.2 0.1 0.1 0.1 0.1 0.2 0.1 0.1 0.1 0.3v7.7q0 0.3-0.2 0.4-0.2 0.2-0.5 0.2-0.2 0-0.4-0.2-0.2-0.1-0.2-0.4v-7.7q0-0.2 0.1-0.3 0-0.1 0.1-0.2 0.1-0.1 0.2-0.1 0.1-0.1 0.2-0.1 0.2 0 0.3 0.1z"
																/>
															</g>
															<path
																id="Rectangle_204"
																className="s1"
																d="m1 2h20v20h-20z"
															/>
														</g>
													</g>
												</svg>
											</span>
											Withdraw
										</a>
									</span>
								)}
							{job?.status_id >= 2 && (
								<span>
									<a
										className="arrow-white btn btn-primary center-v btn-verify px-4"
										onClick={(e) => {
											setActivePopUp(job?.job_id);
											return handleShow();
										}}
										style={{minWidth:'151px'}}
									>
										View Status
									</a>
									{ActivePopUp == job?.job_id && (
										<ApplicationProgress
											jobID={job?.job_id}
											show={show}
											handleClose={handleClose}
											progress={job?.status_id}
										/>
									)}
								</span>
							)}
						</div>
					</div>
				</div>
			))}
		</div>
	);
	function getApplicationStatusCSS(statusID) {
		const applicationStatusCSS = [
			"labal blue-bg",
			"labal green-bg",
			"labal bg-orange",
			"labal blue-bg",
		];
		return applicationStatusCSS[statusID];
	}
}

export default React.memo(AppliedJobs);
