import React, { useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { readUserNotification } from "../APICaller/readUserNotification";
import CustomModal from "../Common/CustomModal";
import DOMPurify from "dompurify";
import "./Dashboard.css";

function NotificationCard({
	notificationList,
	candidateID,
	candidateName,
	candidateEmail,
	setrefreshPage,
}) {
	const [show, setshow] = useState(false);
	const [notificationInfo, setnotificationInfo] = useState({
		title: "",
		msg: "",
	});
	let ApplicationStatus = useSelector(
		(state) => state.masterData.applicationStatusMaster
	);
	const UserInfo = useSelector((state) => state.user.user.userDetails);
	return (
		<>
			<div >
				{notificationList?.map((element) => (
					<div
						key={element.id}
						className="notices cursor-pointer"
						data-toggle="modal"
						data-target="#document-rejected"
						onClick={(e) => {
							e.preventDefault();
							readUserNotification(candidateID, element.id,UserInfo?.email);
							setrefreshPage(true);
							setshow(true);
							setnotificationInfo({});
							setnotificationInfo({
								title: element?.notificationTitle,
								msg: element?.notificationBody
									?.replace("#user", candidateName)
									?.replace(
										"#status",
										ApplicationStatus?.filter(
											(x) => x.status_id == Number(element.status)
										)[0]?.status_name
									)?.replace("#email", candidateEmail)
								,
							});
						}}
					>
						<span className="labal blue-bg">New</span>
						<div className="h6-06 font-medium mb-2 mt-2">
							{element?.notificationTitle}
						</div>

						<div
							className="body-01 mb-2 notification-body "
							dangerouslySetInnerHTML={{
								__html: DOMPurify.sanitize(element?.notificationBody),
							}}
						/>
						{/* <div className="body-01 mb-2">{element?.notificationBody}</div> */}
						<div className="neutral-50 button-md font-medium">
							{moment(element?.notificationCreatedOn).format("DD/MM/YYYY")}
						</div>
					</div>
				))}
			</div>
			<CustomModal
				show={show}
				setshow={setshow}
				notifcationInfo={notificationInfo}
				setrefreshPage={setrefreshPage}
			/>
		</>
	);
}

export default NotificationCard;
// export default React.memo(NotificationCard);
