import axios from "axios";
import { fetchUserSuccess } from "./../redux/UserDetails/UserActions";
import checkSession from "./checkSession";
import { setUserLogin } from "./../redux/UserDetails/UserActions";
import CryptoJS from "crypto-js"
import { GetCookie } from "./cookie";

export default function fetchUserData(
	usrID,
	dispatch,
	navigate,
	from_page = "home"
) {
	const userHex = CryptoJS.MD5((usrID + process.env.REACT_APP_MD5_PASSCODE)?.toLowerCase())?.toString();
	const requestBody = {
		userName: usrID,
		fromRegisterPage: from_page == "register" ? true : false,
		CheckUser:userHex
	};
	//
	axios
		.post("/api/Login/user-status", requestBody)
		.then((resp) => {
			const resHex = CryptoJS.MD5((usrID + GetCookie("session-id") + process.env.REACT_APP_MD5_PASSCODE)?.toLowerCase())?.toString();
			if (resp?.data?.statusCode == 200 && resp?.data?.success && resp?.data?.status == resHex) {
				dispatch(setUserLogin());
				dispatch(fetchUserSuccess(resp?.data?.data));
			} else {
				alert("Something went wrong")
				window.location.reload()
			}
		})
		.catch((error) => {
			//debugger;
			//console.log({ error });
			if (error.message.includes("code 404")) {
				// history.push("/SignUp");
				navigate("../Register", { replace: false });
			} else if (error.message.includes("code 444")) {
				checkSession(error, dispatch, navigate);
			}
			//console.log("error ", error);
		});
}
