import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Modal } from "react-bootstrap";
import DOMPurify from "dompurify";

function CustomModal(props) {
	const handleClose = () => {
		props.setshow(false);

		props?.setrefreshPage((state) => {
			//console.log("refresh State", { state });
			return !state;
		});
	};
	useEffect(() => {
		return () => {
			props?.setrefreshPage((state) => !state);
		};
	}, []);

	// console.log(props.notifcationInfo);
	const NotifcationBodyHTML = `${props?.notifcationInfo?.msg}`;

	const sanitizedMsg = DOMPurify.sanitize(NotifcationBodyHTML);

	return (
		<Modal
			show={props.show}
			onHide={handleClose}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			backdrop="static"
		// style={{ maxHeight: "670px" }}

		>
			<Modal.Header>
				<div className="text-center w-100">
					<h5
						className="modal-title font-medium h5-05"
						id="exampleModalLiveLabel"
					>
						Notification
					</h5>
					<small>{props?.notifcationInfo?.title}</small>
				</div>
				<button
					type="button"
					className="close"
					data-dismiss="modal"
					aria-label="Close"
					onClick={handleClose}
				>
					<span aria-hidden="true">×</span>
				</button>
			</Modal.Header>
			<Modal.Body style={{ overflowY: "scroll" }}>
				<div className="text-center my-0 py-0 px-3 text-wrap" >
					{/* <stromg>{props?.notifcationInfo?.msg}</stromg> */}
					<strong dangerouslySetInnerHTML={{ __html: sanitizedMsg }}></strong>
				</div>
			</Modal.Body>
			<Modal.Footer className="footer_btns">
				<a
					href="#"
					onClick={(e) => {
						e.preventDefault();
						props?.setshow(false);
						props?.setrefreshPage((state) => !state);
					}}
					className=" btn center-v mt-2 mt-lg-0 text-nowrap mr-2"
					data-dismiss="modal"
				>
					OK
				</a>
			</Modal.Footer>
		</Modal>
	);
}

export default React.memo(CustomModal);
