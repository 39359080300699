import React, { useState, useEffect, useRef, useCallback } from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import "./Login.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import "yup-phone";
import { Modal, Button, Spinner } from "react-bootstrap";
import OtpInput from "react-otp-input";
import "./otp-verify.css";
import sendOTP from "./../APICaller/Login/sendOTP";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import {
	setUserLogin,
	setUserLogout,
	setUserOTPVerification,
	setUserID,
} from "../redux/UserDetails/UserActions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { useCookies } from "react-cookie"; // for cookies
import OtpTimer from "otp-timer";
import fetchUserData from "../Common/fetchUserData";
import { emailRegex, phoneRegex } from "../Common/Constants/Regex";
import clearAllCache from "../Common/clearAllCache";
import clearAllCookies from "../Common/clearAllCookies";
import { setCookie } from "../Common/cookie";
import { v4 as uuidv4 } from 'uuid';
import CryptoJS from "crypto-js"

function Login() {
	// const dispatch = useDispatch();
	const [userLoginID, setuserLoginID] = useState("");
	const [maxLength, setMaxLength] = useState(51);
	const [validEmail, setValidEmail] = useState(false);
	const [validPhone, setValidPhone] = useState(false);
	const [show, setShow] = useState(false);

	const [loading, setloading] = useState(false);
	const [countDownTimer, setcountDownTimer] = useState({
		minutes: 0,
		seconds: 30,
	});
	const [verifyOTPError, setverifyOTPError] = useState(false);
	const [ip, setIP] = useState("");

	const handleClose = () => {
		setShow(false);
		setValidEmail(false);
		setValidPhone(false);
		setotp("");
		setloading(false);
		setcountDownTimer((state) => ({
			...state,
			seconds: 30,
		}));
	};
	const [otp, setotp] = useState("");
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const isAuthenticated = useSelector((state) => state.user);
	const ALREADY_LOGGED_IN = "ALREADY_LOGGED_IN";
	const candidatID = useSelector(
		(state) => state.user.user.userDetails?.candidate_id
	);
	const isRedirected = localStorage.getItem("isRedirected");
	const jobId = localStorage.getItem("jobid");

	useEffect(() => {
		// Clear all cookies and cache if user is not logged in
		if (!isAuthenticated["isLoggedIn"]) {
			clearAllCookies();
			clearAllCache();
		}
	}, []);

	const ipFinderURL = process.env.REACT_APP_IPFinder_URL;
	const getIPData = useCallback(async () => {
		const res = await axios.get(ipFinderURL);
		//console.log(res);
		setIP(res.data.IPv4);
	}, [ip]);

	useEffect(() => {
		getIPData();
	}, [getIPData.apply, ip]);

	const getDeviceType = () => {
		const ua = navigator.userAgent;
		if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
			return "tablet";
		}
		if (
			/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
				ua
			)
		) {
			return "mobile";
		}
		return "desktop";
	};

	const logUserData = () => {
		const request = {
			user_email: " ",
			sessionID: " ",
			loginTime: null,
			client_IP: " ",
			serverIP: " ",
			deviceType: " "
		}
		request.deviceType = getDeviceType();
		request.client_IP = ip;
		axios
			.post("api/Login/User", request)
			.then((res) => {
			})
			.catch((error) => {
			});
	}

	useEffect(() => {
		if (isAuthenticated["isLoggedIn"] && isRedirected) {
			axios
				.get("api/Profile/GetCandidateProfile", {
					params: {
						candidate_id: candidatID,
					},
				})
				.then((response) => {
					const contentdata = response.data;
					if (contentdata.success == true && contentdata.statusCode == 200) {
						localStorage.setItem(
							"userCategoryId",
							contentdata?.data?.category_id
						);
					}
				})
				.catch((error) => {
					navigate("/");
					//console.log("error axios", error.message);
				});
			setTimeout(() => {
				navigate(
					`/JobDetails/${btoa(jobId)}/${btoa(
						localStorage.getItem("category_id")
					)}`,
					{
						replace: false,
					}
				);
			}, 300);
		}
		if (isAuthenticated["isLoggedIn"] && !isRedirected) {
			logUserData()
			axios
				.get("api/Profile/GetCandidateProfile", {
					params: {
						candidate_id: candidatID,
					},
				})
				.then((response) => {
					const contentdata = response.data;
					if (contentdata.success == true && contentdata.statusCode == 200) {
						localStorage.setItem(
							"userCategoryId",
							contentdata?.data?.category_id
						);
						localStorage.setItem(
							"category_id",
							contentdata?.data?.category_id
						);
						if (contentdata?.data?.category_id == 1) {
							navigate(`/current-openings/Academics`);
						}
						if (contentdata?.data?.category_id == 2) {
							navigate(`/current-openings/Jio-Digital-Library`);
						}
						if (contentdata?.data?.category_id == 3) {
							navigate(`/current-openings/Non-Academic`);
						}
					} else {
						navigate("/");
					}
				})
				.catch((error) => {
					navigate("/");
					//console.log("error axios", error.message);
				});
		}
	}, [isAuthenticated]);

	const phoneSchema = Yup.string().phone("IN").required();
	const emailSchema = Yup.string().email().required();

	const formik = useFormik({
		initialValues: {
			userEmailORPhone: "",
		},
		validationSchema: Yup.object({
			userEmailORPhone: Yup.string("Enter your Email ID")
				.required("Email ID is required")
				.test(
					"Email validate user input",
					"Enter Valid Email ID",
					async function (value) {
						if (isNaN(value)) {
							setMaxLength(51);
						}
						emailSchema
							.isValid(value)
							.then((r) => {
								if (r) {
									setValidEmail(true);
								} else {
									setValidEmail(false);
								}
							})
							.catch((e) => {
								//console.log(e);
							});

						if (isNaN(value) && !(await emailSchema.isValid(value))) {
							return false;
						}
						return true;
					}
				)
				.test(
					"phone validate user input",
					"Enter Valid Mobile Number",
					async function (value) {
						if (!isNaN(value)) {
							setMaxLength(15);
						}
						phoneSchema
							.isValid(value)
							.then((r) => {
								if (r) {
									setValidPhone(true);
								} else {
									setValidPhone(false);
								}
							})
							.catch((e) => {
								//console.log(e);
							});

						if (!isNaN(value) && !(await phoneSchema.isValid(value))) {
							return false;
						}
						return true;
					}
				)
				.test("maxEmail", "Maximum Length Exceeded", (value) => {
					if (isNaN(value) && value && value.length > 50) {
						return false;
					} else {
						return true;
					}
				})
				.test("maxPhone", "Maximum Limit Exceeded", (value) => {
					if (!isNaN(value) && value.length > 10) {
						return false;
					} else {
						return true;
					}
				}),
		}),
		onSubmit: (values) => {
			submitUserLoginForm(values);
		},
	});

	function submitUserLoginForm(values) {
		dispatch(setUserID(values.userEmailORPhone));
		setuserLoginID(values.userEmailORPhone);

		const uuid = uuidv4();
		setCookie('session-id', uuid)

		const resp = sendOTP(values.userEmailORPhone);
		toast.promise(
			resp,
			{
				// pending: "Sending OTP",
				success: "OTP has been sent",
				error: "Something went wrong.. Try again.",
			},
			{
				position: toast.POSITION.TOP_CENTER,
			}
		);
		setShow(true);
	}

	function submitOTP(otpNo) {
		if (
			otpNo === undefined ||
			otpNo === null ||
			otpNo === "" ||
			otpNo.length !== 6
		) {
			toast.error(" Please Enter Valid OTP ", {
				position: toast.POSITION.TOP_CENTER,
			});
			return;
		}
		setloading(true);
		const url = "/OTP/api/ValidateOTP/";
		const otpHex = CryptoJS.MD5((otpNo + process.env.REACT_APP_MD5_PASSCODE)?.toLowerCase())?.toString();
		const requestBody = {
			otp: otpNo,
			otpCheck: otpHex
		};
		const verifyOTPResponse = async () =>
			await axios
				.post(url, requestBody)
				.then((res) => {
					const userHex = CryptoJS.MD5((formik?.values?.userEmailORPhone + process.env.REACT_APP_MD5_PASSCODE)?.toLowerCase())?.toString();
					if (res?.data?.statusCode == 200 && userHex == res?.data?.status) {
						setCookie('jic_auth_', "session-active", process.env.REACT_APP_SESSION_TIMEOUT_IN_HOURS)
						dispatch(setUserOTPVerification());
						setShow(false);
						fetchUserData(userLoginID, dispatch, navigate);
					}

					else {
						toast.error("Login failed, something went wrong", {
							position: toast.POSITION.TOP_CENTER,
						});

						setcountDownTimer((state) => ({
							...state,
							seconds: 2,
						}));
						//throw new Error("Login failed, something went wrong");
					}
				})
				.catch(
					(err) => (
						setcountDownTimer((state) => ({
							...state,
							seconds: 2,
						})),
						setverifyOTPError((state) => !state),
						toast.error("Invalid OTP. Please try again", {
							position: toast.POSITION.TOP_CENTER,
						})
					)
				)
				.finally(
					() => (
						setcountDownTimer((state) => ({
							...state,
							seconds: 2,
						})),
						setloading(false)
					)
				);

		verifyOTPResponse();
	}

	return (
		<>
			<Header />
			<div className="main-container full_width">
				<section
					className="section-masthead space-before-breadcrumb masthead-mob-space overflow-hidden"
					style={{ minHeight: "72vh", marginTop: "-10px" }}
				>
					<div className="container">
						<div className="row signup-row">
							<div className="col-auto ">
								<div className="rounded-circle ">
									<img
										src="assets/images/career/log-in.png"
										alt=""
										className="w-100 d-none d-sm-block row signup-img"
										style={{ maxHeight: "510px" }}
									/>
								</div>
							</div>

							<div className="login-landing-screen m-lg-5 pt-5 col-lg-3 col-sm-12 p-0 m-lg-5 pt-lg-5 ">
								<span className="font-bold mx-3 ">Sign in or Sign up</span>

								<section className="section-common-space overflow-hidden .login-container ">
									<form onSubmit={formik.handleSubmit}>
										<div className="col-md-12 col-12 col-lg-12 ">
											<div className="form-group input-container">
												<input
													id="user-name"
													type="text"
													className="form-control"
													maxLength={maxLength}
													// placeholder="Phone or Email"
													{...formik.getFieldProps("userEmailORPhone")}
												/>
												<label htmlFor="user-name" className="control-label">
													<section>
														{/* Email ID/ */}
														Please enter your valid Email ID
													</section>
													{/* <small>(For Indian Candidates Only)</small>{" "} */}
												</label>{" "}
												{formik.touched.userEmailORPhone &&
													formik.errors.userEmailORPhone ? (
													<small
														id="errorhelp"
														className="massage error d-block"
													>
														{formik.errors.userEmailORPhone}
													</small>
												) : null}
												<div className="left-icon">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 16 16"
													>
														<defs>
															<style
																dangerouslySetInnerHTML={{
																	__html:
																		".a {fill: ;}.b {fill: #767b87;}.c {fill: #fff;}",
																}}
															/>
														</defs>
														<rect className="a" width={16} height={16} />
														<g transform="translate(0.818 1.447)">
															<circle
																className="b"
																cx={7}
																cy={7}
																r={7}
																transform="translate(0.182 -0.447)"
															/>
															<path
																className="c"
																d="M242.263,314.6a2.976,2.976,0,0,0,1.15-2.069l1.207,2.069Zm-4.783-2.069-1.207,2.069h2.356A2.976,2.976,0,0,1,237.48,312.536Zm2.966-3.274a2.968,2.968,0,0,1,1.2.254l-1.2-2.065-1.2,2.065A2.967,2.967,0,0,1,240.446,309.261Zm0,.873a2.108,2.108,0,1,0,2.108,2.108A2.108,2.108,0,0,0,240.446,310.134Z"
																transform="translate(-233.093 -304.898)"
															/>
														</g>
													</svg>
												</div>
												<div className="right-icon clear-value">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 28 28"
													>
														<g transform="translate(3.498 3.498)">
															<path
																d="M1648.295,1400.784a10.5,10.5,0,1,0,10.5,10.5A10.5,10.5,0,0,0,1648.295,1400.784Zm0,19.254a8.753,8.753,0,1,1,8.753-8.753A8.762,8.762,0,0,1,1648.295,1420.038Z"
																transform="translate(-1637.793 -1400.784)"
															/>
															<path
																d="M1652.651,1408.881a.876.876,0,0,0-1.237,0l-2.147,2.147-2.146-2.147a.875.875,0,0,0-1.238,1.237l2.148,2.147-2.148,2.147a.875.875,0,0,0,1.238,1.237l2.146-2.147,2.147,2.147a.875.875,0,0,0,1.237-1.237l-2.147-2.147,2.147-2.147A.877.877,0,0,0,1652.651,1408.881Z"
																transform="translate(-1638.772 -1401.764)"
															/>
														</g>
													</svg>
												</div>
											</div>
										</div>
										<div className="col-12 text-lg-left text-center">
											<button
												type="submit"
												href="#"
												className="btn btn-primary center-v px-5 py-2"
												tabIndex={0}
												data-toggle="modal"
												data-target="#exampleModalLive"
											>
												Submit
											</button>
										</div>
									</form>
								</section>
							</div>
							{/* </div> */}
						</div>{" "}
					</div>{" "}
				</section>
				<Modal
					show={show}
					onHide={handleClose}
					size="md"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					keyboard
					backdrop="static"
				>
					<Modal.Header>
						<div className="text-center w-100">
							<h5
								className="modal-title font-medium h5-05 ml-5"
								id="exampleModalLiveLabel"
							>
								Validate and Login
							</h5>
						</div>
						<button
							type="button"
							className="close"
							data-dismiss="modal"
							aria-label="Close"
							onClick={handleClose}
						>
							<span aria-hidden="true">×</span>
						</button>
					</Modal.Header>
					<Modal.Body>
						<div className="text-center my-5">
							<p className="overline-01 font-medium neutral-80 mb-0">
								Enter the OTP sent to your {validEmail && "mail"}{" "}
								{validPhone && "phone"} {userLoginID}
							</p>
							<div className="otpfield">
								<div className=" mb-2 center_align_elmnt ">
									<div
										onKeyDown={(e) => {
											if (e.key == "Enter") {
												submitOTP(otp);
											}
										}}
									>
										<OtpInput
											value={otp}
											onChange={(e) => setotp(e)}
											autoComplete="on"
											shouldAutoFocus={true}
											pattern="^[1-9]\d*$"
											numInputs={6}
											isInputNum={true}
											containerStyle={"otp_input"}
											inputStyle={"otp_fields"}
											autocomplete="one-time-code"
										/>
									</div>
								</div>
								<span className="sp--m pd--top j-Space__vertical j-Space" />

								<OtpTimer
									className="my-5"
									minutes={countDownTimer.minutes}
									seconds={countDownTimer.seconds}
									text={
										<p className="mt-5 d-inline-flex fh-10">Resend OTP in</p>
									}
									ButtonText={<p className="mt-5 text-danger">Resend OTP </p>}
									resend={() => (
										sendOTP(userLoginID),
										setcountDownTimer((state) => ({
											minutes: 0,
											seconds: 30,
										}))
									)}
									textColor="dark"
									buttonColor="primary"
									background="white"
									style={{ width: "100%" }}
								/>
							</div>

							{loading && (
								<>
									<div className="d-flex justify-content-center mt-2 pt-0">
										<b>Verifying OTP</b>
									</div>
									<div className="d-flex justify-content-center mt-3">
										<Spinner animation="grow" variant="danger" />
										<Spinner animation="grow" variant="danger" />
										<Spinner animation="grow" variant="danger" />
									</div>
								</>
							)}
						</div>
					</Modal.Body>
					<Modal.Footer className="footer_btns">
						<a
							onClick={(e) => {
								e.preventDefault();
								setShow(false);
								setotp("");
							}}
							className=" btn center-v mt-2 mt-lg-0 text-nowrap mr-2"
							data-dismiss="modal"
						>
							Cancel
						</a>
						<Button
							onClick={_.throttle((e) => {
								e.preventDefault();
								submitOTP(otp);
							}, 4000)}
							className="arrow-white btn btn-primary center-v"
							disabled={loading}
						>
							Proceed
						</Button>
					</Modal.Footer>
				</Modal>
				<ToastContainer autoClose={3000} theme="colored" />
			</div>
			{/*<Footer className="position-absolute w-100  fixed-md-bottom " />*/}
		</>
	);
}

export default Login;
