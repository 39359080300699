import React, { Fragment, useEffect } from "react";
import "./WhyJoinUs.css";
import "slick-carousel";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import $ from "jquery";

function WhyJoinUs() {
	var settings = {
		slidesToShow: 1,
		slidesToScroll: 1,
		infinite: true,
		dots: false,
		arrows: true,
		adaptiveHeight: true,
	};
	//
	$.fn.dataImg = function (options) {
		var settings = $.extend(
			{
				sml: 400,

				med: 800,

				lrg: 1000,

				resize: false,
			},
			options
		);

		var elements = $(this);

		function getSrc(element) {
			var screen = $(window).width();

			if (screen > settings.med) {
				return element.data("lrg");
			} else if (screen <= settings.med && screen > settings.sml) {
				return element.data("med");
			}

			return element.data("sml");
		}

		function breakpoints() {
			elements.each(function () {
				var e = $(this);

				var src = getSrc(e);

				if (src != undefined) {
					if (e.is("img")) {
						e.attr("src", src);
					} else {
						e.css("background-image", "url(" + src + ")");
					}
				}
			});
		}
		breakpoints();

		if (settings.resize === true) {
			$(window).resize(function () {
				breakpoints();
			});
		}
	};

	//
	// $(document).ready(function () {
	// 	$(".header-mobile-menu").click(function () {
	// 		$(".nav-wrapper").toggleClass("show-menu");
	// 	});
	// });
	$(document).ready(function () {
		$(".section-slider").slick({
			dots: false,
			arrows: true,
			speed: 400,
			slidesToShow: 1,
			slidesToScroll: 1,
		});
	});

	return (
		<>
			<div className="main-container full_width why-join-us">
				<section className="section-masthead space-before-breadcrumb section-common-space masthead-mob-space overflow-hidden sectionnocolorbg  wju-section-1  my-0 py-0 section-container">
					<div className="container">
						<div className="row d-flex">
							<div className="col-lg-12">
								<nav aria-label="breadcrumb">
									<ol className="breadcrumb">
										<li className="breadcrumb-item">
											<a href="/">Home</a>
										</li>
										<li className="breadcrumb-item active" aria-current="page">
											Why Join Us
										</li>
									</ol>
								</nav>
							</div>
						</div>
						<div className="row align-items-lg-center">
							<div className="col-lg-7" data-aos="fade-right">
								<h1 className="h1-01 font-black mb-3 text-lg-left text-center">Why Join Us</h1>
								<div className="d-lg-none mt-lg-0 mt-5">
									<div className="rounded-circle masthead-rounded-circle-mob overflow-hidden">
										<img
											src="assets/images/career/about.jpg"
											alt="about"
											className="w-100"
										/>
									</div>
								</div>
								<div className="h4-04">
									Jio Institute is a multi-disciplinary education institute,
									dedicated to the pursuit of excellence by bringing together
									global scholars as well as providing an enriching student
									experience through world-class education, relevant research
									platforms and a culture of innovation.
								</div>
							</div>
							<div
								className="col-lg-5 col-auto d-lg-block d-none"
								data-aos="fade-left"
							>
								<div className="rounded-circle masthead-rounded-circle overflow-hidden">
									<img
										src="assets/images/career/about.jpg"
										alt="about"
										className="w-100"
									/>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="section-common-space sectioncolorbg-why-join-us wju-section-2 section-container">
					<div className="container">
						<div className="card-group d-flex flex-wrap eq-h-tile">
							<div className="card" data-aos="fade-in" data-aos-duration={200}>
								<div className="leadership-quality d-flex align-items-center mb-lg-5 m-0">
									<div className="icon d-flex align-items-center justify-content-around">
										<img
											src="assets/images/career/icon-leadership.svg"
											alt=""
											className="img-fluid"
											width="100%"
											height="auto"
										/>
									</div>
									<div className="details font-medium">
										<div className="body-02">
											Benefit from Founders’ transformative precedents in the
											business world and philanthropy via Reliance ecosystem
										</div>
									</div>
								</div>
							</div>
							<div className="card" data-aos="fade-in" data-aos-duration={1200}>
								<div className="leadership-quality d-flex align-items-center mb-lg-5 m-0">
									<div className="icon d-flex align-items-center justify-content-around">
										<img
											src="assets/images/career/icon-integrated.svg"
											alt=""
											className="img-fluid"
											width="64px"
											height="auto"
										/>
									</div>
									<div className="details font-medium">
										<div className="body-02">
											Be an integral part of the core team that can take pride
											in laying a solid foundation for institution building in
											the long run
										</div>
									</div>
								</div>
							</div>
							<div className="card" data-aos="fade-in" data-aos-duration={2200}>
								<div className="leadership-quality d-flex align-items-center mb-lg-5 m-0">
									<div className="icon d-flex align-items-center justify-content-around">
										<img
											src="assets/images/career/icon-digital-thinking.svg"
											alt=""
											className="img-fluid"
											width="100%"
											height="auto"
										/>
									</div>
									<div className="details font-medium">
										<div className="body-02">
											A plethora of prospects to explore other than your core
											competencies
										</div>
									</div>
								</div>
							</div>
							<div
								className="card mb-lg-0"
								data-aos="fade-in"
								data-aos-duration={2200}
							>
								<div className="leadership-quality d-flex align-items-center m-0">
									<div className="icon d-flex align-items-center justify-content-around">
										<img
											src="assets/images/career/icon-goal.svg"
											alt=""
											className="img-fluid"
											width="100%"
											height="auto"
										/>
									</div>
									<div className="details font-medium">
										<div className="body-02">
											Once-in-a-lifetime opportunity to implement a clearly
											charted out growth plan
										</div>
									</div>
								</div>
							</div>
							<div
								className="card mb-lg-0"
								data-aos="fade-in"
								data-aos-duration={1200}
							>
								<div className="leadership-quality d-flex align-items-center m-0">
									<div className="icon d-flex align-items-center justify-content-around">
										<img
											src="assets/images/career/icon-communication-skill.svg"
											alt=""
											className="img-fluid"
											width="100%"
											height="auto"
										/>
									</div>
									<div className="details font-medium">
										<div className="body-02">
											Be a part of a community where passion and ownership are
											contagious
										</div>
									</div>
								</div>
							</div>
							<div
								className="card mb-lg-0"
								data-aos="fade-in"
								data-aos-duration={200}
							>
								<div className="leadership-quality d-flex align-items-center m-0">
									<div className="icon d-flex align-items-center justify-content-around">
										<img
											src="assets/images/career/icon-entrepreneurial-virtues.svg"
											alt=""
											className="img-fluid"
											width="100%"
											height="auto"
										/>
									</div>
									<div className="details font-medium">
										<div>
											Be part of a digitally driven culture of innovation,
											collaboration and entrepreneurship.
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<div className="individual-who-wrapper layout layout--onecol sectionnocolorbg wju-section-3 section-container-3">
					<div className="layout__region layout__region--content">
						<div className="views-element-container container block block-views block-views-blockwork-with-us-block-1">
							<div className="content">
								<div>
									<div className="row view-inner-wrapper">
										<div className="view-header col-lg-4" data-aos="fade-right">
											<h2 className="font-medium">
												We seek <br /> individuals who…
											</h2>
										</div>
										<div
											className="view-content row col-lg-8"
											data-aos="fade-left"
										>
											<div className="views-row">
												<div className="views-field views-field-field-slider-image">
													<div className="field-content">
														{" "}
														<img
															src="assets/images/files//Collaborate.svg"
															alt="Collaborate"
															loading="lazy"
															typeof="foaf:Image"
														/>
													</div>
												</div>
												<div className="views-field views-field-field-slider-description">
													<div className="field-content">
														<p>
															<strong>Collaborate </strong>with likeminded
															persons to address complex problems.
														</p>
													</div>
												</div>
											</div>
											<div className="views-row">
												<div className="views-field views-field-field-slider-image">
													<div className="field-content">
														{" "}
														<img
															src="assets/images/files//Excellence.svg"
															alt="Excellence"
															loading="lazy"
															typeof="foaf:Image"
														/>
													</div>
												</div>
												<div className="views-field views-field-field-slider-description">
													<div className="field-content">
														<p>
															Are committed to <strong>excellence</strong>
														</p>
													</div>
												</div>
											</div>
											<div className="views-row">
												<div className="views-field views-field-field-slider-image">
													<div className="field-content">
														{" "}
														<img
															src="assets/images/files//Digital-Thinking.svg"
															alt="Digital Thinking"
															loading="lazy"
															typeof="foaf:Image"
														/>
													</div>
												</div>
												<div className="views-field views-field-field-slider-description">
													<div className="field-content">
														<p>
															Are excited about the benefits, opportunities and
															challenges offered by technology and{" "}
															<strong>digital thinking</strong>.
														</p>
													</div>
												</div>
											</div>
											<div className="views-row">
												<div className="views-field views-field-field-slider-image">
													<div className="field-content">
														{" "}
														<img
															src="assets/images/files/Lifelong-Learners.svg"
															alt="Lifelong Learners"
															loading="lazy"
															typeof="foaf:Image"
														/>
													</div>
												</div>
												<div className="views-field views-field-field-slider-description">
													<div className="field-content">
														<p>
															Are <strong>lifelong learners</strong>.
														</p>
													</div>
												</div>
											</div>
											<div className="views-row m-lg-0">
												<div className="views-field views-field-field-slider-image">
													<div className="field-content">
														{" "}
														<img
															src="assets/images/files/Innovation.svg"
															alt="Innovation"
															loading="lazy"
															typeof="foaf:Image"
															style={{width:'28px'}}
														/>
													</div>
												</div>
												<div className="views-field views-field-field-slider-description">
													<div className="field-content">
														<p className="m-0">
															Are curious to explore globally significant
															questions through <strong>education</strong>,{" "}
															<strong>research </strong>and{" "}
															<strong>innovation</strong>
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<section className="section-common-space bg-value overflow-hidden section-bg-red text-white wju-section-4 m-0 p-0 section-container">
					<div className="container">
						<div className={`row align-items-lg-center my-5 sec-4`}>
							<div className="col">
								<h3 className="h3-03 font-black text-lg-left text-center" data-aos="fade-down">
									Our Values
								</h3>
								<div className="d-lg-none mt-lg-0 mt-5">
									<div className="rounded-circle masthead-rounded-circle-mob overflow-hidden">
										<img
											src="assets/images/career/our-values.png"
											alt="about"
											className="w-100"
											width=""
											height=""
										/>
									</div>
								</div>
								<div className="row mb-5 mt-5">
									<div
										className="col-md-6"
										data-aos="fade-in"
										data-aos-duration={500}
									>
										<h5 className="h5-05 font-bold">Inclusiveness</h5>
										<div className="body-02">
											We foster an inclusive and caring environment that
											embraces diverse backgrounds, identities and perspectives.
											We treat all people with respect and dignity. We listen to
											and encourage each other’s input and opinion.
										</div>
									</div>
									<div
										className="col-md-6 mt-lg-0 mt-5"
										data-aos="fade-in"
										data-aos-duration={1000}
									>
										<h5 className="h5-05 font-bold">Freedom of inquiry</h5>
										<div className="body-02">
											We provide an environment where bright, curious and
											critical minds work together to advance new ideas and
											technologies. We collaborate to explore, persevere and
											ultimately resolve challenges. All research upholds the
											highest standards of academic rigour.
										</div>
									</div>
								</div>
								<div className="row mb-5">
									<div
										className="col-md-6"
										data-aos="fade-in"
										data-aos-duration={1500}
									>
										<h5 className="h5-05 font-bold">Integrity</h5>
										<div className="body-02">
											We take accountability and responsibility for our actions,
											decisions and behaviours. We are honest and do the right
											thing in order to preserve both our personal and the
											Institute’s reputations. We work together in a
											collaborative and collegiate manner.
										</div>
									</div>
									<div
										className="col-md-6 mt-lg-0 mt-5"
										data-aos="fade-in"
										data-aos-duration={2000}
									>
										<h5 className="h5-05 font-bold">Growth Mindset</h5>
										<div className="body-02">
											We possess a love of learning and continually strive to
											develop and improve ourselves and support others to do the
											same. We are resilient and accept challenges and failures
											as opportunities to grow.
										</div>
									</div>
								</div>
								<div className="row">
									<div
										className="col-md-6"
										data-aos="fade-in"
										data-aos-duration={2500}
									>
										<h5 className="h5-05 font-bold">Excellence</h5>
										<div className="body-02">
											We have a mind-set of giving our best, seeking new and
											better ways to approach challenges, utilising a digital
											first approach and continually work to improve ourselves.
											We hold ourselves and our colleagues accountable to this
											value
										</div>
									</div>
									<div
										className="col-md-6 mt-lg-0 mt-5"
										data-aos="fade-in"
										data-aos-duration={3000}
									>
										<h5 className="h5-05 font-bold">Courage</h5>
										<div className="body-02">
											We are resilient, patient and tenacious. We embrace new
											(and old) challenges with passion and conviction. We stand
											up for what is right
										</div>
									</div>
								</div>
							</div>
							<div className="col-auto d-lg-block d-none">
								<div className="rounded-circle masthead-rounded-circle overflow-hidden">
									<img
										src="assets/images/career/our-values.png"
										width=""
										height=""
										alt="about"
										className="w-100"
									/>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="section-masthead space-before-breadcrumb section-common-space masthead-mob-space overflow-hidden sectionnocolorbg wju-section-5 my-0 py-0 sectionnocolorbg section-container">
					<div className="container">
						<div className="row align-items-lg-center">
							<div
								className="col-auto d-lg-block d-none col-lg-5"
								data-aos="fade-right"
							>
								<div className="rounded-circle masthead-rounded-circle overflow-hidden">
									<img
										src="assets/images/At-the-institute-you-will.jpg"
										className="w-100"
									/>
								</div>
							</div>
							<div className="col col-lg-6 offset-lg-1" data-aos="fade-left">
								<div className="d-lg-none">
									<div className="rounded-circle masthead-rounded-circle-mob overflow-hidden">
										<img
											src="assets/images/At-the-institute-you-will.jpg"
											alt=""
											className="w-100"
										/>
									</div>
								</div>
								<div className="h4-04">
									<h2 className="font-medium mb-4 f-42">
										At the Institute, you will:
									</h2>
									<p>
										Be a part of our eclectic community of experts,
										thought-leaders and scholars from diverse fields.
									</p>
									<p>Play an important role in Institution building.</p>
									<p>
										Be presented with several opportunities to innovate and
										excel.
									</p>
									<p>
										Undertake interdisciplinary as well as multidisciplinary
										research projects focused towards solving India’s
										developmental issues quickly and effectively.
									</p>
									<p>
										Be able to leverage the Institute’s infrastructure and
										facilities.
									</p>
									<p>
										Be part of a technology-focused and digitally driven culture
										of innovation and entrepreneurship.
									</p>
									<p>
										Be part of a diverse ecosystem that promotes different
										cultures and nationalities for cohesive collaborations.
									</p>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section
					className="slider-circle-panel neutral-10-bg section-common-space pt-0 pb-0 window-height  d-flex align-items-center justify-content-center wju-section-6"
					style={{
						backgroundImage: "url(assets/images/career/navi-mumbai-jic.jpg)",
						backgroundPosition: "center",
						backgroundRepeat: "no-repeat",
						backgroundSize: "cover",
					}}
					data-aos="fade-in"
				></section>

				{/* React slick slider  */}
				<section
					className="section-common-space pt-0 pb-0 window-height slick-section-container d-none"
					id="trigger6"
				>
					<div className="location-slider locationSlider">
						<Slider {...settings}>
							<div className="position-relative cmp-slick">
								<div className="container">
									<div className="row">
										<div className="col-12">
											<div className="d-flex justify-content-end">
												<div className="containt">
													<h3 className="h3-03 font-black">
														A Campus of Opportunities
													</h3>
													<div className="body-01 font-medium">
														Jio Institute campus is strategically located at
														Ulwe in Navi Mumbai, near the upcoming Navi Mumbai
														International Airport and the Mumbai Trans Harbour
														Link.
													</div>
													<div className="pt-3" />
													<div className="body-01 font-medium">
														Also, as the financial capital of India and one most
														prominent cities in the world, Mumbai welcomes
														millions of people every year who come here to
														achieve their dreams. The city is teeming with the
														world’s top MNCs and bustling with people from every
														culture, ethnicity, and strata of society. This
														makes our campus an ideal place for learners to
														explore careers post-completing their courses.
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="campus_bg">
									<img
										// src="assets/images/career/navi-mumbai-jic.jpg"
										src="assets/images/career/navi-mumbai-dekstop.png"
										className="w-100  data-img"
										alt=""
										data-sml="assets/images/career/nav-mumbai-mobile.png"
										data-med="assets/images/career/navi-mumbai-tab.png"
										data-lrg="assets/images/career/navi-mumbai-dekstop.png"
									/>
								</div>
							</div>
						</Slider>
					</div>
				</section>
			</div>
		</>
	);
}

export default WhyJoinUs;
