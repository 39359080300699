import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import DropDownControl from "./DropDownControl";
import TextInput from "../JobApplications/TextInput";
import DropDown from "../JobApplications/DropDown";
import { useSelector } from "react-redux";
export default function References({
	sub_stage_id,
	stage_id,
	category_id,
	setFieldValue = null,
}) {
	const [ReferencesData, setReferences] = useState([]);

	//console.log('substageid', sub_stage_id + 'substageid', stage_id);
	const candidate_id = useSelector(
		(state) => state.user.user.userDetails?.candidate_id
	);
	useEffect(() => {
		axios
			.get("api/JobApplication/GetjobFieldMasterBySubStageid", {
				params: {
					stage_id: stage_id,
					// candidate_id: candidate_id,
					category_id: category_id,
					sub_stage_id: sub_stage_id,
					trans_hdr_id: Number(localStorage.getItem("job_trans_id")),
				},
			})
			.then((response) => {
				// response.data is the users
				const contentdata = response.data;
				//console.log("Response-data", contentdata.data);
				setReferences(contentdata.data);
			})
			.catch((error) => {
				//console.log("error axios", error.message);
			});
	}, [stage_id, sub_stage_id]);

	function ReturnHtml(data) {
		// for (let i = 0; i < data.length; i++) {

		if (data.field_type == "dropdown") {
			if (data.field_desc == "Prefix") {
				return (
					<div className="col-lg-4 col-12">
						<div className="form-group input-container">
							<DropDown
								label={data.field_desc}
								name={data.field_name}
								setFieldValue={setFieldValue}
							>
								<option value="">Select</option>
								<DropDownControl
									field_id={data.field_id}
									name={data.field_name}
								/>
							</DropDown>
							{/* <select name={data.field_name} id={data.field_name} className="form-control ">
                            <DropDownControl field_id={data.field_id} />
                        </select>
                        <label htmlFor={data.field_name} className="control-label font-medium overline-01">{data.field_desc}</label> */}
						</div>
					</div>
				);
			} else
				return (
					<div className="col-lg-6 col-12">
						<div className="form-group input-container">
							<DropDown
								label={data.field_desc}
								name={data.field_name}
								setFieldValue={setFieldValue}
							>
								<option value="">Select {data.field_desc}</option>
								<DropDownControl
									field_id={data.field_id}
									name={data.field_name}
								/>
							</DropDown>
							{/* <select name={data.field_name} id={data.field_name} className="form-control ">
                            <DropDownControl field_id={data.field_id} />
                        </select>
                        <label htmlFor={data.field_name} className="control-label font-medium overline-01">{data.field_desc}</label> */}
						</div>
					</div>
				);
		} else if (data.field_type == "textbox") {
			if (data.field_desc == "Full Name") {
				return (
					<div className="col-lg-8 col-12">
						<div className="form-group input-container">
							{/* <label htmlFor="user-name font-medium button-md neutral-70" className="control-label">{data.field_desc}</label>
                        <input type="text" id={data.field_name + "_" + data.field_id} value={data.value_name} className="form-control" placeholder="Textfields" /> */}
							<TextInput
								className="form-control"
								label={data.field_desc}
								name={data.field_name}
								type="text"
								placeholder={data.field_desc}
							/>
							<div className="right-icon clear-value">
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
									<g transform="translate(3.498 3.498)">
										<path
											d="M1648.295,1400.784a10.5,10.5,0,1,0,10.5,10.5A10.5,10.5,0,0,0,1648.295,1400.784Zm0,19.254a8.753,8.753,0,1,1,8.753-8.753A8.762,8.762,0,0,1,1648.295,1420.038Z"
											transform="translate(-1637.793 -1400.784)"
										/>
										<path
											d="M1652.651,1408.881a.876.876,0,0,0-1.237,0l-2.147,2.147-2.146-2.147a.875.875,0,0,0-1.238,1.237l2.148,2.147-2.148,2.147a.875.875,0,0,0,1.238,1.237l2.146-2.147,2.147,2.147a.875.875,0,0,0,1.237-1.237l-2.147-2.147,2.147-2.147A.877.877,0,0,0,1652.651,1408.881Z"
											transform="translate(-1638.772 -1401.764)"
										/>
									</g>
								</svg>
							</div>
						</div>
					</div>
				);
			} else {
				return (
					<div className="col-lg-4 col-12">
						<div className="form-group input-container">
							<TextInput
								className="form-control"
								label={data.field_desc}
								name={data.field_name}
								type="text"
								placeholder={data.field_desc}
							/>
							{/* <input type="text" id={data.field_name + "_" + data.field_id} value={data.value_name} className="form-control" placeholder="Textfields" />
                        <label htmlFor="user-name font-medium button-md neutral-70" className="control-label">{data.field_desc}</label>
                        <small id="errorhelp" className="massage error">Invalid text</small> */}
							<div
								className="right-icon clear-value"
								style={{ display: "none" }}
							>
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
									<g transform="translate(3.498 3.498)">
										<path
											d="M1648.295,1400.784a10.5,10.5,0,1,0,10.5,10.5A10.5,10.5,0,0,0,1648.295,1400.784Zm0,19.254a8.753,8.753,0,1,1,8.753-8.753A8.762,8.762,0,0,1,1648.295,1420.038Z"
											transform="translate(-1637.793 -1400.784)"
										/>
										<path
											d="M1652.651,1408.881a.876.876,0,0,0-1.237,0l-2.147,2.147-2.146-2.147a.875.875,0,0,0-1.238,1.237l2.148,2.147-2.148,2.147a.875.875,0,0,0,1.238,1.237l2.146-2.147,2.147,2.147a.875.875,0,0,0,1.237-1.237l-2.147-2.147,2.147-2.147A.877.877,0,0,0,1652.651,1408.881Z"
											transform="translate(-1638.772 -1401.764)"
										/>
									</g>
								</svg>
							</div>
						</div>
					</div>
				);
			}
		}

		//  }
	}

	function renderElement_Ref01(objSub) {
		const Ref1ids = [1, 23, 24, 25, 26, 27, 28, 28];
		var data = objSub?.filter((i) => Ref1ids.includes(i.field_id));
		return (
			<div className="card">
				<div className="card-header" id="heading1-1">
					<a
						href="/"
						data-toggle="collapse"
						data-target="#collapse1-1"
						aria-expanded="true"
						aria-controls="collapse1-1"
					>
						Referral 01
					</a>
				</div>
				<div
					id="collapse1-1"
					className="collapse show"
					aria-labelledby="heading1-1"
					data-parent="#acccordionTab-1"
				>
					<div className="card-body">
						<div className="row">
							{data?.map((objSub) => {
								return ReturnHtml(objSub);
							})}
						</div>
					</div>
				</div>
			</div>
		);
	}
	function renderElement_Ref02(objSub) {
		const Ref1ids = [30, 31, 32, 33, 34, 35, 36, 37];
		var data = objSub?.filter((i) => Ref1ids.includes(i.field_id));
		return (
			<div className="card">
				<div class="card-header" id="heading1-2">
					<a
						href="/"
						class="collapsed"
						data-toggle="collapse"
						data-target="#collapse1-2"
						aria-expanded="false"
						aria-controls="collapse1-2"
					>
						Referral 02
					</a>
				</div>
				<div
					id="collapse1-2"
					class="collapse"
					aria-labelledby="heading1-2"
					data-parent="#acccordionTab-1"
				>
					<div class="card-body">
						<div className="row">
							{data?.map((objSub) => {
								return ReturnHtml(objSub);
							})}
						</div>
					</div>
				</div>
			</div>
		);
	}
	function renderElement_Ref03(objSub) {
		const Ref1ids = [38, 39, 40, 41, 42, 43, 44, 45];
		var data = objSub?.filter((i) => Ref1ids.includes(i.field_id));
		return (
			<div className="card">
				<div class="card-header" id="heading1-3">
					<a
						href="/"
						class="collapsed"
						data-toggle="collapse"
						data-target="#collapse1-3"
						aria-expanded="false"
						aria-controls="collapse1-3"
					>
						Referral 03
					</a>
				</div>
				<div
					id="collapse1-3"
					class="collapse"
					aria-labelledby="heading1-3"
					data-parent="#acccordionTab-1"
				>
					<div class="card-body">
						<div className="row">
							{data?.map((objSub) => {
								return ReturnHtml(objSub);
							})}
						</div>
					</div>
				</div>
			</div>
		);
	}

	return (
		<section class="col-12 mt-2 ">
			<div class="faq">
				<div class="accordion" id="acccordionTab-1">
					{renderElement_Ref01(ReferencesData)}
				</div>
				<div class="accordion" id="acccordionTab-2">
					{renderElement_Ref02(ReferencesData)}
				</div>
				<div class="accordion" id="acccordionTab-3">
					{renderElement_Ref03(ReferencesData)}
				</div>
			</div>
		</section>
	);
}
