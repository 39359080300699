import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import $ from "jquery";
import moment from "moment";
import { Button, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import "./CurrentOpenings.css";
import { fetchUserSuccess, setUserLogout } from "../redux/UserDetails/UserActions";
import checkSession from "../Common/checkSession";
import { fetchMasterData, fetchApplicationStatusMasterData } from "../redux/Masterdata/MasterdataActions";
import { GetCookie } from "../Common/cookie";
import SessionExpireyPopup from "../Common/SessionExpireyPopup";
import { replace } from "lodash";
import { getCandidateProfile } from "../APICaller/getCandidateProfile";
import Offline from "react-offline"
import { ToastContainer, toast } from "react-toastify";
import CryptoJS from "crypto-js"

const CurrentOpenings2 = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch()
	const isAuthenticated = useSelector((state) => state.user);
	
	const [show, setshow] = useState(false);

	useEffect(() => {
		dispatch(fetchMasterData());
		if (isAuthenticated["isLoggedIn"]){
			dispatch(fetchApplicationStatusMasterData());
		}
	}, [])

	const params = useParams();
	const [category_desc, setcategory_desc] = useState("");
	const candidate_id = useSelector(
		(state) => state.user.user.userDetails?.candidate_id
	);

	//-------------------------------------------------------
	const [isUploading, setisUploading] = useState(false);
	const usrID = useSelector((state) => state.user.user.userID);
	let timeout;
	//Below useEffect is from home screen
	useEffect(() => {
		const cancelTokenSource = axios.CancelToken.source();
		// localStorage.removeItem("Category_id");
		// debugger;
		if (localStorage.getItem("redirect") == "true") {
			navigate(localStorage.getItem("current_job_path"), {
				replace: false,
				state: {
					user_applied: false,
				},
			});
		}
		// debugger;
		if (isAuthenticated["isLoggedIn"]) {
			setisUploading(true);
			const userHex = CryptoJS.MD5((usrID + process.env.REACT_APP_MD5_PASSCODE)?.toLowerCase())?.toString();
			const requestBody = {
				userName: usrID,
				CheckUser:userHex
			};
			// userDetails();
			axios
				.post("/api/Login/user-status", requestBody)
				.then((resp) => {
					if (resp?.data?.statusCode == 200 && resp?.data?.success) {
						dispatch(fetchUserSuccess(resp?.data?.data));
					}
				})
				.catch((error) => {
					//console.log(`error ${error}`);
					// if (error.message.includes("code 404")) {
					// 	navigate("../SignUp", { replace: false });
					// }
					//else
					if (error.message.includes("code 444")) {
						checkSession(error, dispatch, navigate);
					}
					//console.log("error ", error);
				});
		}
		return () => {
			cancelTokenSource.cancel();
			clearTimeout(timeout);
		};
	}, []);
	//--------------------------------------------------------------------


	
	useEffect(() => {
		if (isAuthenticated["isLoggedIn"]) {
			const isActiveSession = GetCookie("jic_auth_");
		// console.log(isActiveSession,'isActiveSession')
		if(!isActiveSession){
		setshow(true)
	
	}
		}
	}, []);

	useEffect(() => {
		const userCatId = localStorage.getItem('userCategoryId')
		if(!userCatId && candidate_id && isAuthenticated["isLoggedIn"]){
			getCandidateProfile(candidate_id).then((response) => {
				const contentdata = response?.data;
				if(contentdata){
					localStorage.setItem('userCategoryId', contentdata?.data?.category_id);
					if (contentdata?.data?.category_id == 1) {
						navigate(`/current-openings/Academics`)
						window.location.reload();
					} else if (contentdata?.data?.category_id == 2) {
						navigate(`/current-openings/Jio-Digital-Library`)
						window.location.reload();
					} else if (contentdata?.data?.category_id == 3) {
						navigate(`/current-openings/Non-Academic`)
						window.location.reload();
					}
				}
			});
		}
	}, []);


	$(".searchAbtJI").on("click", function () {
		$("body").css({ overflow: "hidden" });
		setTimeout(function () {
			$("input#topnav-search").focus();
		}, 100);
		setTimeout(function () {
			$(".pkv-searchview").addClass("slidedown");
		}, 500);
		setTimeout(function () {
			$(".pkv-searchview .search-backdrop.pkv-fade").addClass("show visible");
		}, 100);
		setTimeout(function () {
			$(".pkv-searchview .animate_search_result").addClass("animate");
			$(".pkv-searchview .animate_search_result").css({
				"box-sizing": "border-box",
				display: "block",
			});
		}, 700);
		setTimeout(function () {
			$(".pkv-searchview .pkv-searchresult-container").addClass("slidedown");
		}, 700);
	});

	$(".closeSearch, .search-backdrop").on("click", function () {
		$("body").css({ overflow: "auto" });
		setTimeout(function () {
			$(".pkv-searchview .pkv-searchresult-container").removeClass("slidedown");
		}, 100);
		setTimeout(function () {
			$("input#topnav-search").focus();
		}, 900);
		setTimeout(function () {
			$(".pkv-searchview").removeClass("slidedown");
		}, 100);
		setTimeout(function () {
			$(".pkv-searchview .search-backdrop.pkv-fade").removeClass(
				"show visible"
			);
		}, 500);
		setTimeout(function () {
			$(".pkv-searchview .animate_search_result").removeClass("animate");
			$(".pkv-searchview .animate_search_result").css({
				"box-sizing": "border-box",
				display: "none",
			});
		}, 100);
	});

	const [data, setdata] = useState([]);
	const [filteredJobs, setFilteredJobs] = useState([]);
	const [loading, setloading] = useState(true);
	const Departments = useSelector((state) => state.masterData.areaDeptMaster.departments)
	const Area = useSelector((state) => state.masterData.areaDeptMaster.area);
	const Role = useSelector((state) => state.masterData.areaDeptMaster.role)
	const [selectAllDepartments, setSelectAllDepartments] = useState(false)
	const [selectAllRoles, setSelectAllRoles] = useState(false)
	let particularCategory = params?.category ? params?.category : false;

	const [selectedFilters, setSelectedFilters] = useState({ category_ids: [], sub_category_ids: [], role_idss: [] })
	const { category_ids, sub_category_ids, role_idss } = selectedFilters;

	const [stopInitialDepartmentTick, setStopInitialDepartmentTick] = useState(false)
	const [stopInitialRolesTick, setStopInitialRolesTick] = useState(false)

	const [stopInitialSelectAllForDepartments, setStopInitialSelectAllForDepartments] = useState(false)
	const [stopInitialSelectAllForRoles, setStopInitialSelectAllForRoles] = useState(false)

	useEffect(() => {
		const filteredJobs = data?.filter(
			({ category_id, sub_category_id, role_ids }) => {
				//  if (category_ids?.length > 0 && sub_category_ids?.length > 0 && role_idss?.length > 0) {
				//	// console.log('category_id+sub_category_id+role_ids')
				//	return (
				//	  category_ids.includes(category_id) &&
				//	  sub_category_ids.includes(sub_category_id) &&
				//	  role_idss.includes(role_ids)
				//	);
				//  }
				if (category_ids.length > 0 && sub_category_ids.length > 0) {
					//console.log('category_id+sub_category_id')
					return (
						category_ids.includes(category_id) &&
						sub_category_ids.includes(sub_category_id)
					);
				}
				//  if (category_ids.length > 0 && role_idss.length > 0) {
				//	console.log('category_id+role_ids')
				//	return (
				//	  category_ids.includes(category_id) && role_idss.includes(role_ids)
				//	);
				//  }
				//  if (sub_category_ids.length > 0 && role_idss.length > 0) {
				//	console.log('sub_category_id+role_ids')
				//	return (
				//		sub_category_ids.includes(sub_category_id) && role_idss.includes(role_ids)
				//	);
				//  }
				if (category_ids?.length > 0 && !stopInitialDepartmentTick) {
					return category_ids.includes(category_id);
				}
				//  if (sub_category_ids.length > 0) {
				//	console.log('sub_category_id')
				//	return sub_category_ids.includes(sub_category_id);
				//  }
				//  if (role_idss.length > 0) {
				//	console.log('role_ids')
				//	return role_idss.includes(role_ids);
				//  }
				//  console.log('All jobs')
				//  return true;
			}
		);

		const sortedFiltered = filteredJobs.sort(function (a, b) {
			let c = new Date(a?.date_last_modified || a?.posted_on);
			let d = new Date(b?.date_last_modified || b?.posted_on);
			return d - c;
		})

		setFilteredJobs([...sortedFiltered])
	}, [data, category_ids, sub_category_ids])

	//useEffect(() => {
	//	//Discard deparment & Roles as per category (When user put incorrect filter from bottom to top)
	//	if(particularCategory){
	//		return
	//	}
	//	if(category_ids.length == 0){
	//		return
	//	}
	//	//roles not required
	//	const rolesNotRequired = Role?.sort()
	//    .filter((f)=>{
	//    	if(category_ids.length > 0){
	//    		return !category_ids.includes(f.category_id)
	//    	} else {
	//    		return f
	//    	}
	//    }).map((v)=>v?.role_id)

	//	rolesNotRequired.forEach((v,i)=>{
	//	if(role_idss.includes(String(v))){
	//		const fx = role_idss.filter((f)=>f !== String(v))
	//		setSelectedFilters((pre)=>({...pre,role_idss:fx}))
	//	}
	//	})
	//	//Departments not required
	//	const departmentsNotRequired = Area?.sort()
	//    .filter((f)=>{
	//    	if(category_ids.length > 0){
	//    		return !category_ids.includes(f.category_id)
	//    	} else {
	//    		return f
	//    	}
	//    }).map((v)=>v?.sub_category_id)

	//	departmentsNotRequired.forEach((v,i)=>{
	//	if(sub_category_ids.includes(v)){
	//		const fx = sub_category_ids.filter((f)=>f !== v)
	//		setSelectedFilters((pre)=>({...pre,sub_category_ids:fx}))
	//	}
	//	})
	//}, [category_ids,sub_category_ids,role_idss])

	//useEffect(() => {
	//	//Discard Roles per Deparments (When user put incorrect filter from bottom to top)
	//	if(particularCategory){
	//		return
	//	}
	//	if(sub_category_ids.length == 0){
	//		return
	//	}

	//	//roles not required
	//	const rolesNotRequired = Role?.sort()
	//    .filter((f)=>{
	//    	if(sub_category_ids.length > 0){
	//    		return !sub_category_ids.includes(f.sub_category_id)
	//    	} else {
	//    		return f
	//    	}
	//    }).map((v)=>v?.role_id)

	//	rolesNotRequired.forEach((v,i)=>{
	//	if(role_idss.includes(String(v))){
	//		const fx = role_idss.filter((f)=>f !== String(v))
	//		setSelectedFilters((pre)=>({...pre,role_idss:fx}))
	//	}
	//	})
	//}, [category_ids,sub_category_ids,role_idss])

	useEffect(() => {
		if (particularCategory == 'Academics') {
			setSelectedFilters((pre) => ({ ...pre, category_ids: [1] }))
		} else if (particularCategory == 'Jio-Digital-Library') {
			setSelectedFilters((pre) => ({ ...pre, category_ids: [2] }))
		} else if (particularCategory == 'Non-Academic') {
			setSelectedFilters((pre) => ({ ...pre, category_ids: [3] }))
		}
	}, [])

	const renderTooltip = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			View applied job
		</Tooltip>
	);

	useEffect(() => {
		axios
			.get("api/JobDetails/JobDetail", {
				params: {
					job_id: 0,
					page_no: 1,
				},
			})
			.then((response) => {
				const contentdata = response?.data;

				setdata(contentdata.data);
			})
			.catch((error) => {
				//console.log("error axios", error.message);
			});
	}, []);

	const ViewDetails = (jobid, category_id) => {
		navigate(`/JobDetails/${btoa(jobid)}/${btoa(category_id)}`, {
			replace: false,
		});
		//setTimeout(() => {
		//	window.location.reload();
		//}, 100);
	};

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	}, [])

	useEffect(() => {
		if (filteredJobs?.length > 0) {
			setloading(false)
		}

		if (filteredJobs?.length == 0) {
			setTimeout(() => {
				setloading(false)
			}, 5000);
		}
	}, [data, filteredJobs])

	//useEffect(() => {
	//	if(selectAllDepartments && stopInitialDepartmentTick){
	//		setFilteredArea(Area?.sort()?.filter((f)=>{
	//			if(category_ids.length > 0){
	//				return category_ids.includes(f.category_id)
	//			} else {
	//				return f
	//			}
	//		}))

	//		if(filteredArea.length){
	//			const dx = filteredArea.map((v)=>v?.sub_category_id)

	//			setSelectedFilters((pre)=>({...pre,sub_category_ids:dx}))
	//		}
	//	}

	//	if(!selectAllDepartments && stopInitialDepartmentTick){
	//		setSelectedFilters((pre)=>({...pre,sub_category_ids:[]}))
	//	}

	//  }, [category_ids,selectAllDepartments,filteredArea.length])

	//  useEffect(() => {
	//	if(selectAllRoles && stopInitialRolesTick){
	//		setFilteredRole(Role?.sort()?.filter((f)=>{
	//			if(category_ids.length > 0){
	//				return category_ids.includes(f.category_id)
	//			} else {
	//				return f
	//			}
	//		}))

	//		if(filteredRole.length){
	//			const dx = filteredRole.map((v)=>String(v?.role_id))
	//			setSelectedFilters((pre)=>({...pre,role_idss:dx}))
	//		}
	//	}

	//	if(!selectAllRoles && stopInitialRolesTick){
	//		setSelectedFilters((pre)=>({...pre,role_idss:[]}))
	//	}

	//  }, [category_ids,selectAllRoles, sub_category_ids,filteredRole.length])

	const ApplyNow = (
		jobid,
		is_applied,
		is_bookmarked,
		category_id,
		category_desc,
		job_title,
		user_applied,
		isOffline,
		status_id,
		job_application_tran_hdr_id
	) => {
		if(isOffline){
			toast.error(
				"You are currently offline. Please check your internet connection.",
				{
					position: toast.POSITION.TOP_CENTER,
				}
			);
		}
		setcategory_desc(category_desc);
		localStorage.setItem("__jbt_crx__", JSON.stringify(job_title));
		if (isAuthenticated["isLoggedIn"]) {
			var parameter = {
				candidate_id: Number(candidate_id),
				job_id: jobid,
				status_id: "1",
				modified_by: String(candidate_id),
				is_bookmarked: is_bookmarked,
				is_applied: is_applied,
				remarks: "",
				feedback: "null",
			};
			if(status_id == 0 || status_id == 1){

				axios
				.post("api/JobDetails/JobApplyDetails", parameter)
				.then((response) => {
					const contentdata = response?.data?.data;
					// console.log("trans_hdr_id", contentdata?.job_trans_id);
					localStorage.setItem("job_trans_id", contentdata?.job_trans_id);
					localStorage.setItem("category_id", category_id);
					localStorage.setItem("stageID", 1);
					localStorage.setItem("index", 0);
					localStorage.setItem("jobid", jobid);

					setTimeout(() => {
						navigate(`/JobApplication/${btoa(jobid)}/${btoa(category_id)}`, {
							replace: false,
							state: {
								user_applied,
							},
						});
					}, 300);
				})
				.catch((error) => {
					//console.log("error axios", error.message);
				});
			} else {
				localStorage.setItem("job_trans_id", job_application_tran_hdr_id);
				localStorage.setItem("category_id", category_id);
					localStorage.setItem("stageID", 1);
					localStorage.setItem("index", 0);
					localStorage.setItem("jobid", jobid);

				setTimeout(() => {
					navigate(`/JobApplication/${btoa(jobid)}/${btoa(category_id)}`, {
						replace: false,
						state: {
							user_applied,
						},
					});
				}, 300);

			}
			} else {
			localStorage.setItem("jobid", jobid);
			localStorage.setItem("isRedirected", true);
			navigate("../login", { replace: false });
		}
	};

	//this useEffect checked mark departments which have jobs in it
	useEffect(() => {
		if (selectAllDepartments) {
			return
		}
		if (stopInitialDepartmentTick) {
			return
		}
		const departmentsWithJobs = [...new Set(filteredJobs?.filter((f) => {
			if (category_ids?.length > 0) {
				return category_ids.includes(f.category_id)
			} else {
				return f
			}
		})
			?.map((f) => f.sub_category_id))]

		if (departmentsWithJobs && departmentsWithJobs?.length > 0) {
			setSelectedFilters((pre) => ({ ...pre, sub_category_ids: departmentsWithJobs }))
			setStopInitialDepartmentTick(true)
		}

	}, [data, filteredJobs])

	////this useEffect checked mark roles which have jobs in it
	//useEffect(() => {
	//	if(selectAllRoles){
	//		return
	//	}
	//	if(stopInitialRolesTick){
	//		return
	//	}
	//	const rolessWithJobs = [...new Set(filteredJobs?.filter((f)=>{
	//		if(category_ids?.length > 0){
	//			return category_ids.includes(f.category_id)
	//		} else {
	//			return f
	//		}
	//	})
	//	?.map((f)=>f.role_ids))].filter((f)=>f!=null)

	//	if(rolessWithJobs && rolessWithJobs?.length > 0){
	//		setSelectedFilters((pre)=>({...pre,role_idss:rolessWithJobs}))
	//		setStopInitialRolesTick(true)
	//	}

	//}, [data,filteredJobs])

	useEffect(() => {
		//This useEffect check selectAll for departments if all departments have jobs in it if yes it will make SELECT ALL true by default
		if (stopInitialSelectAllForDepartments) {
			return
		}
		const allSubCategories = Area?.filter((f) => {
			if (category_ids?.length > 0) {
				return category_ids.includes(f.category_id)
			} else {
				return f
			}
		})?.map((m) => m?.sub_category_id)


		if (allSubCategories?.length == sub_category_ids?.length) {
			setSelectAllDepartments(true)
			setStopInitialSelectAllForDepartments(true)
		}

	}, [data, filteredJobs, selectedFilters])

	//useEffect(() => {
	//	//This useEffect check selectAll for Roles if all departments have jobs in it if yes it will make SELECT ALL true by default
	//	if(stopInitialSelectAllForRoles){
	//		return
	//	}
	//	const allRoles = Role?.filter((f)=>{
	//		if(category_ids?.length > 0){
	//			return category_ids.includes(f.category_id)
	//		} else {
	//			return f
	//		}
	//	})?.map((m)=>String(m?.role_id))


	//	if(allRoles?.length == role_idss?.length){
	//		setSelectAllRoles(true)
	//		setStopInitialSelectAllForRoles(true)
	//	}

	//}, [data,filteredJobs,selectedFilters])


	return (
		<>
			<div
				className="main-container full_width current_openings"
				style={{ height: "100vh", display: "contents" }}
			>
				<section className="section-masthead space-before-breadcrumb section-common-space masthead-mob-space overflow-hidden pb-sm-0">
					<div className="container py-2 mb-2">
						<div className="row align-items-center mt-4 mt-md-0">
							<div className="col-lg-10">
								<nav aria-label="breadcrumb">
									<ol className="breadcrumb">
										<li className="breadcrumb-item">
											<a href="/">Home</a>
										</li>
										<li className={`breadcrumb-item ${particularCategory ? "" : "active"}`} aria-current="page">
											<div>Current Openings</div>
										</li>
										{particularCategory && <li className="breadcrumb-item active" aria-current="page">
											{particularCategory}
										</li>}
									</ol>
								</nav>
							</div>
							{particularCategory == 'Academics' && <div className="col d-flex justify-content-end">
								<a href="/faqs" style={{ fontSize: "0.875rem", textDecoration: "underline" }}>FAQ's</a>
							</div>}
						</div>
						<div className="row align-items-lg-center">
							<div className="col d-none">
								<div className="h2-02 font-light"></div>
								<div className="mt-4 mb-md-5">
									<div className="pkv-searchform with-reset mw-100 search-grey">
										<a className="back_search">
											<img
												src="assets/images/header/icon-search.svg"
												width="20px"
												height="20px"
												alt="search"
											/>
										</a>
										<input
											id="topnav-search"
											type="text"
											className="pkv-searchform-input"
											placeholder="Search"
											// value={srchRef.current}
											// value={searchInput}
											// autoFocus
											//ref={srchRef}
											//onBlur={searchItems}
											// onChange={(e)=>searchItems}
											onChange={(e) => (e.preventDefault())}
										/>
									</div>
									<br />
								</div>
							</div>
						</div>{" "}
					</div>{" "}
				</section>
				<section
					className="section-common-space  mt-md-5 pt-sm-0 "
					style={{ minHeight: "65vh" }}
				>
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="job-description">
									<div className="left-column mr-4 mb-5">
										<section className="section-common-space job-filter-container">
											<div className="faq">
												<div className="accordion" id="acccordionTab-1">
													<div className="card">
														<div className="card-header" id="heading1-1">
															<a
																href="#!"
																data-toggle="collapse"
																data-target="#collapse1-1"
																aria-expanded="true"
																aria-controls="collapse1-1"
															>
																Area of interest
															</a>
														</div>
														<div
															id="collapse1-1"
															className="collapse show"
															aria-labelledby="heading1-1"
															data-parent="#acccordionTab-1"
														>
															<div className="card-body">
																<div className="pl-1">
																	{Departments?.sort()
																		//?.filter((f)=>{
																		//	if(particularCategory == 'Academics'){
																		//		return f.category_id == 1
																		//	} else if (particularCategory == 'Jio-Digital-Library'){
																		//		 return f.category_id == 2
																		//	} else if(particularCategory == 'Non-Academic'){
																		//		return f.category_id == 3
																		//	}
																		//	return f
																		//})
																		?.map(
																			(deptvalue, index) => {
																				return (
																					<div
																						key={index}
																						className="checkbox-wrapper checkbox-wrapperX"
																					>
																						<input
																							key={index}
																							id={"d" + deptvalue?.category_id}
																							name="checkbox-group"
																							type="checkbox"
																							checked={category_ids.includes(deptvalue?.category_id) ? true : false}
																							disabled={category_ids.includes(deptvalue?.category_id) ? true : false}
																							onChange={(e) => {
																								if (e.target.checked && !category_ids.includes(deptvalue?.category_id)) {
																									if (deptvalue?.category_id == 1) {
																										navigate('/current-openings/Academics')
																										localStorage.setItem('category_id', 1)
																										window.location.reload()
																									}
																									if (deptvalue?.category_id == 2) {
																										navigate('/current-openings/Jio-Digital-Library')
																										localStorage.setItem('category_id', 2)
																										window.location.reload()
																									}
																									if (deptvalue?.category_id == 3) {
																										navigate('/current-openings/Non-Academic')
																										localStorage.setItem('category_id', 3)
																										window.location.reload()
																									}
																								}
																								//if(e.target.checked && !category_ids.includes(deptvalue?.category_id)){
																								//	setSelectedFilters((pre)=>({...pre,category_ids:[...pre.category_ids,deptvalue?.category_id]}))
																								//} else if (!e.target.checked && category_ids.includes(deptvalue?.category_id)){
																								//	const newArea = selectedFilters.category_ids.filter((f)=>{
																								//		return f !== deptvalue?.category_id
																								//	})
																								//	setSelectedFilters((pre)=>({...pre,category_ids:newArea}))
																								//}
																							}
																							}
																						//disabled={particularCategory}
																						></input>
																						<label
																							htmlFor={
																								"d" + deptvalue?.category_id
																							}
																						>
																							{deptvalue.category_name}
																							<div className="tick-icon"></div>
																						</label>
																					</div>
																				);
																			}
																		)}
																</div>
															</div>
														</div>
													</div>
													{/* {!checkBoxSelections.length==0 && */}
													{
														<div className="card">
															<div className="card-header" id="heading1-2">
																<a
																	href="#!"
																	className="collapsed"
																	data-toggle="collapse"
																	data-target="#collapse1-2"
																	aria-expanded="false"
																	aria-controls="collapse1-2"
																>
																	Departments
																</a>
															</div>
															<div
																id="collapse1-2"
																className="collapse"
																aria-labelledby="heading1-2"
																data-parent="#acccordionTab-1"
															>
																<div className="card-body">
																	<div className="pl-1">
																		{/* start */}
																		<div
																			className="checkbox-wrapper"
																		>
																			<input
																				name="checkbox-group"
																				type="checkbox"
																				id={
																					"a" + 100
																				}
																				checked={selectAllDepartments}
																				onChange={(e) => {
																					e.persist();
																					if (!stopInitialSelectAllForDepartments) {
																						setStopInitialSelectAllForDepartments(true)
																					}
																					//if(!stopInitialSelectAllForRoles){
																					//	setStopInitialSelectAllForRoles(true)
																					//}
																					if (e.target.checked) {
																						setSelectAllDepartments(true)
																						const allAreas = Area?.sort()?.filter((f) => {
																							if (category_ids?.length > 0) {
																								return category_ids.includes(f.category_id)
																							} else {
																								return f
																							}
																						})

																						if (allAreas?.length) {
																							const dx = allAreas?.map((v) => v?.sub_category_id)
																							setSelectedFilters((pre) => ({ ...pre, sub_category_ids: dx }))
																						}
																					}
																					if (!e.target.checked) {
																						setSelectAllDepartments(false)
																						setSelectedFilters((pre) => ({ ...pre, sub_category_ids: [] }))
																					}
																				}}
																			></input>
																			<label
																				htmlFor={
																					"a" + 100
																				}
																			>
																				Select All
																				<div className="tick-icon"></div>
																			</label>
																		</div>
																		{Area?.sort()
																			?.filter((f) => {
																				if (category_ids?.length > 0) {
																					return category_ids.includes(f.category_id)
																				} else {
																					return f
																				}
																			})
																			?.map((areaValue, index) => {
																				return (
																					<div
																						key={index}
																						className="checkbox-wrapper"
																					>
																						<input
																							id={
																								"a" + areaValue?.sub_category_id
																							}
																							name="checkbox-group"
																							type="checkbox"
																							checked={sub_category_ids.includes(areaValue?.sub_category_id) ? true : false}
																							onChange={(e) => {
																								if (!stopInitialSelectAllForDepartments) {
																									setStopInitialSelectAllForDepartments(true)
																								}
																								//if(!stopInitialSelectAllForRoles){
																								//	setStopInitialSelectAllForRoles(true)
																								//}
																								if (e.target.checked && !sub_category_ids.includes(areaValue?.sub_category_id)) {
																									setSelectedFilters((pre) => ({ ...pre, sub_category_ids: [...pre.sub_category_ids, areaValue?.sub_category_id] }))
																								} else if (!e.target.checked && sub_category_ids.includes(areaValue?.sub_category_id)) {
																									const newDepartments = selectedFilters.sub_category_ids.filter((f) => {
																										return f !== areaValue?.sub_category_id
																									})
																									setSelectedFilters((pre) => ({ ...pre, sub_category_ids: newDepartments }))
																									if (selectAllDepartments) {
																										setSelectAllDepartments(false)
																									}
																								}
																							}
																							}
																						></input>
																						<label
																							htmlFor={
																								"a" + areaValue?.sub_category_id
																							}
																						>
																							{areaValue.sub_category_desc}
																							<div className="tick-icon"></div>
																						</label>
																					</div>
																				);
																			})}
																	</div>
																</div>
															</div>
														</div>
													}
													{/* {!checkBoxSelections.length == 0 && */}
													{
														//<div className="card">
														//	<div className="card-header" id="heading1-3">
														//		<a
														//			href="#!"
														//			className="collapsed"
														//			data-toggle="collapse"
														//			data-target="#collapse1-3"
														//			aria-expanded="false"
														//			aria-controls="collapse1-3"
														//		>
														//			Role
														//		</a>
														//	</div>
														//	<div
														//		id="collapse1-3"
														//		className="collapse"
														//		aria-labelledby="heading1-3"
														//		data-parent="#acccordionTab-1"
														//	>
														//		<div className="card-body">
														//			<div className="pl-1">
														//			<div
														//							className="checkbox-wrapper"
														//						>
														//							<input
														//								name="checkbox-group"
														//								type="checkbox"
														//								id={
														//									"r" + 100
														//								}
														//								checked={selectAllRoles}
														//								onChange={(e) => {
														//									e.persist();
														//									if(!stopInitialSelectAllForDepartments){
														//										setStopInitialSelectAllForDepartments(true)
														//									}
														//									if(!stopInitialSelectAllForRoles){
														//										setStopInitialSelectAllForRoles(true)
														//									}
														//									if(e.target.checked){
														//										setSelectAllRoles(true)
														//										const allRoles = Role?.sort()?.filter((f)=>{
														//											if(category_ids?.length > 0){
														//												return category_ids.includes(f.category_id)
														//											} else {
														//												return f
														//											}
														//										})

														//										if(allRoles?.length){
														//											const dx = allRoles?.map((v)=>String(v?.role_id))
														//											setSelectedFilters((pre)=>({...pre,role_idss:dx}))
														//										}
														//									}
														//									if(!e.target.checked){
														//										setSelectAllRoles(false)
														//										setSelectedFilters((pre)=>({...pre,role_idss:[]}))
														//									}
														//								}}
														//							></input>
														//							<label
														//								htmlFor={
														//									"r" + 100
														//								}
														//							>
														//								Select All
														//								<div className="tick-icon"></div>
														//							</label>
														//						</div>
														//				{Role?.sort()
														//				.filter((f)=>{
														//					if(category_ids?.length > 0){
														//						return category_ids.includes(f.category_id)
														//					} else {
														//						return f
														//					}
														//				})
														//				?.map((roleValue, index) => {
														//					return (
														//						<div
														//							key={index}
														//							className="checkbox-wrapper"
														//						>
														//							<input
														//								id={"r" + roleValue.role_id}
														//								name="checkbox-group"
														//								type="checkbox"
														//								checked={role_idss.includes(String(roleValue.role_id)) ? true : false}
														//								onChange={(e) =>{
														//									if(!stopInitialSelectAllForDepartments){
														//										setStopInitialSelectAllForDepartments(true)
														//									}
														//									if(!stopInitialSelectAllForRoles){
														//										setStopInitialSelectAllForRoles(true)
														//									}
														//									if(e.target.checked && !role_idss.includes(String(roleValue.role_id))){
														//										setSelectedFilters((pre)=>({...pre,role_idss:[...pre.role_idss,String(roleValue.role_id)]}))
														//									} else if (!e.target.checked && role_idss.includes(String(roleValue.role_id))){
														//										const newRoles = selectedFilters.role_idss.filter((f)=>{
														//											return f !== String(roleValue.role_id)
														//										})
														//										setSelectedFilters((pre)=>({...pre,role_idss:newRoles}))
														//										if(selectAllRoles){
														//											setSelectAllRoles(false)
														//										}
														//									}
														//								}
														//								}
														//							></input>
														//							<label
														//								htmlFor={"r" + roleValue.role_id}
														//							>
														//								{roleValue.role_name}
														//								<div className="tick-icon"></div>
														//							</label>
														//						</div>
														//					);
														//				})}
														//			</div>
														//		</div>
														//	</div>
														//</div>
													}
												</div>
											</div>
										</section>
									</div>
									<div className="right-column1">
										<section className="section-common-space overflow-hidden">
											<div className="job-profile-wrapper">
												<div className="d-flex justify-content-between div-border pb-2 align-items-lg-center flex-column flex-lg-row ">
													{!loading && <div className="body-01 neutral-100 font-medium text-nowrap align-self-baseline">
														{/* Showing {data?.length} out of {count} job profiles */}
														{/*Showing {data?.length} job profiles*/}
														{filteredJobs?.length > 0
															? `Showing ${filteredJobs?.length} job profiles`
															: "No jobs found"}
													</div>}
													{loading && <div className="body-01 neutral-100 font-medium text-nowrap align-self-baseline">
														Loading...
													</div>}
													{/* Disabled for improvements */}

													{/* <div className="d-flex align-items-center text-nowrap align-self-baseline">
															<div className="mr-2">Sort by </div>
															<div className="">
																<select
																	onChange={(e) => {
																		sortDescending(e);
																	}}
																	name="purpose"
																	id="CountryOfCitizenship"
																	className="form-control mr-3 pr-3 "
																	required=""
																>
							
																	{Sortby?.sort()?.map((jobdata, index) => {
																		return (
																			<option key={index} value={jobdata}>
																				{jobdata}
																			</option>
																		);
																	})}
																</select>
												
															</div>
														</div> */}
												</div>
												{loading && <div className="d-flex justify-content-center mt-5">
													<Spinner animation="grow" variant="danger" />
													<Spinner animation="grow" variant="danger" />
													<Spinner animation="grow" variant="danger" />
												</div>}

												{!loading &&
													filteredJobs?.map((jobdata, index) => {
														return (
															<div key={index} className="border-bottom">
																<div className="my-4">
																	<div className="my-2">
																		<div className="d-flex justify-content-between">
																			<div className="row row-container">
																				<div
																					className="col-8 primary-color h5-05 font-medium mb-1"
																					onClick={() => {
																						ViewDetails(
																							jobdata.job_id,
																							jobdata.category_id
																						)
																					}
																					}
																				>
																					{jobdata.job_title}
																				</div>
																				{/* <div className="col-4 month1 ml-5 pl-2 d-none d-md-flex"> */}
																				<div className="col-4 duration-passed body-02 neutral-70 font-light text-right">
																					Job posted on <br className="job-update" /> {moment(jobdata.posted_on).format('Do MMM YYYY')}
																				</div>
																			</div>
																		</div>

																		<div className="d-flex justify-content-between align-items-lg-center flex-column flex-lg-row">
																			<p className="paragraph">
																				{jobdata.job_desc}
																			</p>
																			<div className="d-flex align-items-lg-center">
																				<div>
																					<a
																						className=" btn center-v mt-2 mt-lg-0 text-nowrap mr-2  fs-1 pl-0"
																						onClick={() => {
																							ViewDetails(
																								jobdata.job_id,
																								jobdata.category_id
																							)
																						}
																						}
																					>
																						<small>View Job</small>
																					</a>
																				</div>

																				{jobdata?.is_applied &&
																					jobdata?.status_id != 1 && (
																						<Offline>
																							{({ isOffline, isOnline }) => {
																								return <div
																								onClick={() => {
																									ApplyNow(
																										jobdata?.job_id,
																										true,
																										false,
																										jobdata?.category_id,
																										jobdata?.category_desc,
																										jobdata?.job_title,
																										true,
																										isOffline,
																										jobdata?.status_id,
																										jobdata?.job_application_tran_hdr_id
																									)
																								}
																								}
																							>
	
																								<OverlayTrigger
																									placement="bottom"
																									delay={{ show: 250, hide: 400 }}
																									overlay={renderTooltip}
																								>
																									<a
																										className="btn btn-success text-white  mt-2 mt-lg-0 text-nowrap"
																										style={{ width: "120px" }}
																									>
																										Applied{" "}
																									</a>
																								</OverlayTrigger>
																							</div>;
																								}}
																								</Offline>
																					)}

																				{(jobdata?.is_applied ||
																					jobdata?.is_bookmarked ||
																					jobdata?.status_id == 1 ||
																					jobdata?.status_id == 0) && (
																						<Offline>
																							{({ isOffline, isOnline }) => {
																								return <div
																								onClick={() => {
																									ApplyNow(
																										jobdata?.job_id,
																										true,
																										false,
																										jobdata?.category_id,
																										jobdata?.category_desc,
																										jobdata?.job_title,
																										false,
																										isOffline,
																										jobdata?.status_id,
																										jobdata?.job_application_tran_hdr_id
																									)
	
																								}
																								}
																								disabled={
																									jobdata?.is_applied &&
																									jobdata?.status_id == 2
																								}
																							>
																								{jobdata?.status_id == 1 && (
																									<a className=" btn btn-secondary  mt-2 mt-lg-0 text-nowrap" style={{ width: "120px" }}>
																										Drafted
																									</a>
																								)}
																								{jobdata?.status_id == 0 && (
																									<a
																										className="arrow-red btn btn-secondary center-v mt-2 mt-lg-0 text-nowrap"
																										style={{ width: "120px" }}
																										onClick={() => {
																											localStorage.setItem(
																												"category_id",
																												jobdata.category_id
																											);
	
																											localStorage.setItem(
																												"jobid",
																												jobdata.job_id
																											);
																										}}
																									>
																										Apply Now
																									</a>
																								)}
																							</div>;
																								}}
																								</Offline>
																					)}
																			</div>
																		</div>
																		{jobdata.date_last_modified ? (<div className="d-flex justify-content-end align-items-lg-center flex-column flex-lg-row  duration-passed body-02 neutral-70 font-light text-right">
																			Job updated on <br className="job-update" /> {moment(jobdata.date_last_modified).format('Do MMM YYYY')}
																		</div>) : ""}
																	</div>
																</div>
															</div>
														);
													})}
											</div>
										</section>
										<section>

											{/*<div
												className="ji-pagnation d-flex justify-content-center"
												aria-label="Page navigation"
											></div>*/}
										</section>
									</div>
									<div className="clearfix" />
								</div>
							</div>
						</div>
					</div>
				</section>

				<br />
			</div>
			<SessionExpireyPopup
							show={show}
							setshow={setshow}
			/>
			<ToastContainer autoClose={10000} theme="colored" />
		</>
	);
}

export default CurrentOpenings2;
