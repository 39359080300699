import {
	FETCH_USER_REQUEST,
	FETCH_USER_SUCCESS,
	FETCH_USER_JOB_PREFS,
	FETCH_USER_FAILURE,
	SET_OTP_VERIFICATION,
	SET_USER_LOGIN,
	SET_USER_LOGOUT,
	SET_USER_PROFILE_PHOTO,
	SET_USER_ID,
	SET_CANDIDATE_ID,
	SET_IS_REGISTERING,
} from "./UserActionTypes";

export const fetchUserRequest = () => {
	return {
		type: FETCH_USER_REQUEST,
	};
};

export const setRegisterationState = (state) => {
	return {
		type: SET_IS_REGISTERING,
		payload: state,
	};
};
export const fetchUserSuccess = (User) => {
	return {
		type: FETCH_USER_SUCCESS,
		payload: User,
	};
};
export const fetchUserJobPrefs = (User) => {
	return {
		type: FETCH_USER_JOB_PREFS,
		payload: User,
	};
};

export const fetchUserFailure = (error) => {
	return {
		type: FETCH_USER_FAILURE,
		payload: error,
	};
};

export const setUserID = (userID) => {
	return {
		type: SET_USER_ID,
		payload: userID,
	};
};
export const setUserOTPVerification = () => {
	return {
		type: SET_OTP_VERIFICATION,
	};
};
export const setUserLogin = () => {
	return {
		type: SET_USER_LOGIN,
	};
};
export const setUserLogout = () => {
	return {
		type: SET_USER_LOGOUT,
	};
};
export const setCandidateid = () => {
	return {
		type: SET_USER_LOGOUT,
	};
};
