import React from "react";
import { useField } from "formik";

const TextInput = ({ label, ...props }) => {
	// useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
	// which we can spread on <input>. We can use field meta to show an error
	// message if the field is invalid and it has been touched (i.e. visited)
	const [field, meta] = useField(props);
	return (
		<>
			<input className="form-control" {...field} {...props} />			 
			{meta.touched && meta.error ? (
				<small className="massage error d-block">{meta.error}</small>
			) : null}
		</>
	);
};
export default TextInput;
